import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Request } from 'src/../../Common/Model/request';
import { PAY_CREDIT_CARD, PAY_DIRECT_DEBIT } from 'src/globals';
import { Tag } from 'src/../../Common/Model/tag';
import { Sign } from 'src/../../Common/Model/sign';

interface RequestState {
  requests: Request[];
  tags: Tag[];
  ratedRequests: string[];
  signs: Sign[];
  needReload: boolean;
}

const initialState: RequestState = {
  requests: [],
  tags: [],
  signs: [],
  ratedRequests: [],
  needReload: true,
};

const slice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    clearState(state: RequestState) {
      state.requests.splice(0, state.requests.length);
      state.tags.splice(0, state.tags.length);
      state.ratedRequests.splice(0, state.ratedRequests.length);
      state.signs.splice(0, state.signs.length);
      state.needReload = true;
    },
    setNeedReload(state: RequestState, action: PayloadAction<boolean>): void {
      state.needReload = action.payload;
    },
    setRatedRequests(state: RequestState, action: PayloadAction<{ ids: string[]; }>) {
      const { ids } = action.payload;
      state.ratedRequests = ids;
    },
    addRatedRequest(state: RequestState, action: PayloadAction<{ id: string; }>) {
      const { id } = action.payload;
      state.ratedRequests = [...state.ratedRequests, id];
    },
    setRequests(state: RequestState, action: PayloadAction<{ requests: Request[]; signs: Sign[]; }>) {
      const { requests, signs } = action.payload;
      requests.sort((itm0, itm1) => {
        if (itm0.createdAt > itm1.createdAt) return -1;
        if (itm0.createdAt === itm1.createdAt) return 0;
        return 1;
      });
      state.requests = requests;
      state.signs = !signs ? [] : signs; // TODO
      state.needReload = false;
    },
    addRequests(state: RequestState, action: PayloadAction<{ requests: Request[]; }>) {
      const { requests } = action.payload;
      state.requests = [
        ...requests,
        ...state.requests
      ];
    },
    updateRequest(state: RequestState, action: PayloadAction<any>): void {
      const { requestId, props } = action.payload;
      state.requests.forEach((request) => {
        if (request.uid === requestId) {
          Object.keys(props).forEach((key) => {
            request[key] = props[key];
          });
        }
      });
    },
    removeChatIdFromRequests(state: RequestState, action: PayloadAction<string[]>): void {
      const requestIds = action.payload;
      const now = new Date().toISOString();
      state.requests.forEach((request) => {
        if (requestIds.includes(request.uid)) {
          request.conversation = '';
          request.updatedAt = now;
        }
      });
    },
    updateRawRequests(state: RequestState, action: PayloadAction<Request[]>): void {
      const updateds = action.payload;
      updateds.forEach((updated) => {
        const request = state.requests.find((item) => item.uid === updated.uid);
        if (request) {
          Object.keys(updated).forEach((key) => {
            if (key !== 'uid') request[key] = updated[key];
          });
        } else state.requests.push(updated);
      });
    },
    removeRequest(state: RequestState, action: PayloadAction<{ uid: string; }>) {
      const { uid } = action.payload;
      const index = state.requests.findIndex((item) => item.uid === uid);
      state.requests.splice(index, 1);
    },
    setTags(state: RequestState, action: PayloadAction<Tag[]>) {
      state.tags = action.payload;
    },
    updateAutoDebitRequest(state: RequestState, action: PayloadAction<any>): void {
      const { uid, checked } = action.payload;
      const request = state.requests.find((item) => item.uid === uid);
      if (request) {
        request.material = {
          ...request.material,
          payments: {
            ...request.material.payments,
            paymentMethod: checked ? PAY_DIRECT_DEBIT : PAY_CREDIT_CARD
          }
        };
      }
    },
    addSignInfo(state: RequestState, action: PayloadAction<{ sign: Sign }>): void {
      const { sign } = action.payload;
      state.signs.push(sign);
    }
  }
});

export const { reducer } = slice;

export const clearRequestState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setNeedReload(needReload));
};

export const loadRatedRequests = (ids: string[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setRatedRequests({ ids }));
};
export const addRatedRequest = (id: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addRatedRequest({ id }));
};
export const loadRequests = (requests: Request[], signs: Sign[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setRequests({ requests, signs }));
};
export const addRequests = (requests: Request[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addRequests({ requests }));
};
export const updateRequest = (requestId: string, props: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateRequest({ requestId, props }));
};
export const removeChatIdFromRequests = (requestIds: string[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeChatIdFromRequests(requestIds));
};
export const updateRawRequests = (requests: Request[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateRawRequests(requests));
};
export const removeRequest = (uid : string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeRequest({ uid }));
};
export const setTags = (tags : Tag[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTags(tags));
};
export const updateAutoDebitRequest = (uid: string, checked: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateAutoDebitRequest({ uid, checked }));
};

export const addSignInfo = (sign: Sign): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addSignInfo({ sign }));
};

export default slice;
