import { FC, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, ListItem, Skeleton, Typography, Popover, Paper } from '@material-ui/core';
import getUserDisplayName from 'src/utils/getUserDisplayName';
import getInitials, { getInitialBackground } from 'src/utils/getInitials';
import { UserMinInfo } from 'src/../../Common/Model/common';
import { ACCZIOM_CLIENT_ORG, ACCZIOM_SUPPLIER, ACCZIOM_ORG } from 'src/globals';
import { SiGroupon } from 'react-icons/si';
import { lambdaGetOrgMemberInfo, lambdaGetOrganizationInfo, lambdaGetUserInfo } from 'src/aws/lambdaDispatch';
import useMounted from 'src/hooks/useMounted';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PhoneIcon from '@mui/icons-material/Phone';
import LinkIcon from '@mui/icons-material/Link';
import MailIcon from '@mui/icons-material/Mail';
import CakeIcon from '@mui/icons-material/Cake';
import PlaceIcon from '@mui/icons-material/Place';
import { toDisplayDateFormat } from 'src/utils/dateFormatUtils';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useSelector } from 'src/store';
import { OrgMember } from 'src/../../Common/Model/organization';
// import { getCountry } from 'src/utils/countries';
import { memberToMiniUser } from 'src/utils/getUserMinInfo';

interface UserAvatarProps {
  userInfo?: any;
  size: number | string;
  showType?: boolean;
  isBatch?: boolean;
}

export const UserAvatar: FC<UserAvatarProps> = (props) => {
  const { userInfo, size, showType, isBatch } = props;
  const displayName = getUserDisplayName(userInfo);
  const initialStr = getInitials(displayName).slice(0, isBatch ? 1 : 2);
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const fontSize = Math.round(parseInt(size as string, 10) / 2);
  useEffect(() => {
    if (ref?.current?.clientWidth) {
      setWidth(ref?.current?.clientWidth);
    }
  }, [ref?.current?.clientWidth]);
  const avatar = (
    <Avatar
      src={userInfo?.avatar}
      ref={ref}
      sx={{
        width: size,
        height: size,
        fontSize: `${fontSize}px !important`, // `${Math.floor(size / 2.4)}px !important`,
        bgcolor: getInitialBackground(initialStr),
        color: (theme) => theme.palette.text.primary,
        fontWeight: 'bold'
      }}
    >
      {initialStr}
    </Avatar>
  );
  if (showType === false || (!userInfo || (userInfo.type !== ACCZIOM_ORG && userInfo.type !== ACCZIOM_CLIENT_ORG && userInfo.type !== ACCZIOM_SUPPLIER))) return avatar;
  // const orgIconSize = size / 3;
  return (
    <Box
      sx={{
        display: 'inline-block',
        position: 'relative'
      }}
    >
      {avatar}
      {false && (
        <SiGroupon
          style={{
            width: `calc(${width} / 3)`, // `${orgIconSize}px`,
            height: `calc(${width} / 3)`, // `${orgIconSize}px`,
            color: 'gray',
            position: 'absolute',
            right: `calc(-${width} / 3)`, // `-${orgIconSize / 3}px`,
            bottom: `calc(-${width} / 3)`, // `-${orgIconSize / 3}px`
          }}
        />
      )}
    </Box>
  );
};

UserAvatar.propTypes = {
  userInfo: PropTypes.any,
  size: PropTypes.number.isRequired,
  showType: PropTypes.bool,
  isBatch: PropTypes.bool
};

interface SmallUserInfoProps {
  userInfo?: UserMinInfo;
}

export const UserAvatarWithOrg: FC<{
  userInfo: UserMinInfo;
  orgInfo: UserMinInfo;
  size: number;
}> = ({
  userInfo,
  orgInfo,
  size
}) => {
  const orgSize = Math.floor(size / 2.2);
  return (
    <Box
      sx={{
        position: 'relative',
        width: size,
        height: size
      }}
    >
      <UserAvatar
        userInfo={userInfo}
        size={size}
        showType={false}
      />
      <Box
        sx={{
          position: 'absolute',
          right: `${-orgSize / 4}px`,
          bottom: `${-orgSize / 4}px`,
          bgcolor: 'background.paper',
          width: orgSize + 2,
          height: orgSize + 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%'
        }}
      >
        <UserAvatar
          userInfo={orgInfo}
          size={orgSize}
          showType={false}
          isBatch
        />
      </Box>
    </Box>
  );
};

UserAvatarWithOrg.propTypes = {
  userInfo: PropTypes.any,
  orgInfo: PropTypes.any,
  size: PropTypes.number
};

export const SmallUserInfo: FC<SmallUserInfoProps> = (props) => {
  const { userInfo } = props;
  return (
    <>
      <UserAvatar
        userInfo={userInfo}
        size={24}
      />
      <Typography
        variant="body1"
        sx={{
          ml: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: 'black'
        }}
      >
        {getUserDisplayName(userInfo)}
      </Typography>
    </>
  );
};

SmallUserInfo.propTypes = {
  userInfo: PropTypes.any
};

export const SmallUserInfoGrouped: FC<SmallUserInfoProps> = (props) => {
  const { userInfo } = props;
  return (
    <ListItem
      button
      sx={{
        borderRadius: '8px',
        py: 0.5,
        px: 1
      }}
    >
      <SmallUserInfo userInfo={userInfo} />
    </ListItem>
  );
};

SmallUserInfoGrouped.propTypes = {
  userInfo: PropTypes.any
};

const ProfileInfo = (Icon: any, text: string) => (
  <>
    {!!text && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '240px',
          ml: '30px',
          my: 1,
          '& *': {
            color: 'text.secondary'
          }
        }}
      >
        <Icon
          style={{
            width: '20px',
            height: '20px',
            marginRight: '8px'
          }}
        />
        <Typography
          variant="body2"
          sx={{
            width: '212px'
          }}
        >
          {text}
        </Typography>
      </Box>
    )}
  </>
);

interface MemberPopoverProps {
  memberInfo: OrgMember;
  children: any;
}

export const MemberPopover: FC<MemberPopoverProps> = (props) => {
  const { memberInfo, children } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const formalName = [memberInfo.firstName, memberInfo.middleName, memberInfo.lastName].filter((_) => !!_).map((_) => _.trim()).join(' ');
  // let realCountry = getCountry(memberInfo.country)?.text;
  // if (!realCountry) realCountry = memberInfo.country;
  // const detailAddr = [memberInfo.city, memberInfo.state, realCountry].filter((_) => !!_).map((_) => _.trim()).join(', ');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!memberInfo) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          px: 1,
          py: 0.5
        }}
      >
        <SmallUserInfo userInfo={memberToMiniUser(memberInfo)} />
      </Box>
    );
  }

  return (
    <Box>
      <Box
        onClick={handleClick}
        sx={{
          display: 'inline-block'
        }}
      >
        {children}
      </Box>
      <Box
        onClick={(e) => { e.stopPropagation(); }}
      >
        <Popover
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box
            sx={{
              width: '300px',
              position: 'relative',
              mb: '30px'
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100px',
                position: 'relative',
                backgroundColor: 'background.default',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                  position: 'relative'
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#1695ea',
                    opacity: 0.5
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  left: '50%',
                  top: '100%',
                  transform: 'translate(-50%, -50%)',
                  borderRadius: '50%',
                  border: '3px solid white'
                }}
              >
                <UserAvatar
                  userInfo={memberToMiniUser(memberInfo)}
                  size={100}
                  showType={false}
                />
              </Box>
            </Box>
            <Box
              sx={{
                mt: '50px',
                textAlign: 'center'
              }}
            >
              <Typography
                variant="h6"
                color="textPrimary"
              >
                {!formalName ? memberInfo.penName : formalName}
              </Typography>
            </Box>
            <Box
              sx={{
                maxHeight: '350px'
              }}
              className="scrollbarY"
            >
              <Box>
                {ProfileInfo(BorderColorIcon, memberInfo.penName)}
                {ProfileInfo(MailIcon, memberInfo.email)}
                {ProfileInfo(PhoneIcon, memberInfo.phone)}
                {ProfileInfo(LinkIcon, memberInfo.website)}
              </Box>
              <Box>
                {ProfileInfo(PlaceIcon, memberInfo.addressLine1)}
                {/* {detailAddr && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '240px',
                      ml: '30px',
                      my: 1,
                      '& *': {
                        color: 'text.secondary'
                      }
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        ml: '28px',
                        width: '212px'
                      }}
                    >
                      {detailAddr}
                    </Typography>
                  </Box>
                )} */}
              </Box>
              {memberInfo.hiMsg && (
                <Box
                  px="30px"
                  mt={2}
                >
                  <Paper
                    variant="outlined"
                    sx={{
                      p: 1,
                      background: 'transparent',
                      width: '100%'
                    }}
                  >
                    <Typography
                      variant="body1"
                    >
                      {memberInfo.hiMsg}
                    </Typography>
                  </Paper>
                </Box>
              )}
            </Box>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

MemberPopover.propTypes = {
  memberInfo: PropTypes.any,
  children: PropTypes.any
};

interface SmallMemberInfoProps {
  memberInfo?: OrgMember;
}

export const SmallMemberInfoWithDetail: FC<SmallMemberInfoProps> = (props) => {
  const { memberInfo } = props;
  if (!memberInfo) return (<></>);

  return (
    <MemberPopover memberInfo={memberInfo}>
      <SmallUserInfoGrouped
        userInfo={memberToMiniUser(memberInfo)}
      />
    </MemberPopover>
  );
};

SmallMemberInfoWithDetail.propTypes = {
  memberInfo: PropTypes.any
};

interface ProfilePopoverProps {
  userInfo: any;
  children: any;
  showMe?: boolean;
}

export const ProfilePopover: FC<ProfilePopoverProps> = (props) => {
  const { userInfo, children, showMe } = props;
  if (!userInfo) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          px: 1,
          py: 0.5
        }}
      >
        <SmallUserInfo userInfo={userInfo} />
      </Box>
    );
  }
  const mounted = useMounted();
  const { activeOrgId } = useSelector((state) => state.organization);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [loadedInfo, setLoadedInfo] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoadFailed, setLoadFailed] = useState(false);

  useEffect(() => {
    setLoadedInfo(null);
    setLoading(false);
    setLoadFailed(false);
  }, [userInfo]);

  const loadInfo = async () => {
    setLoading(true);
    try {
      if (userInfo.isOrgMember) {
        const loadedOrgInfo = await lambdaGetOrgMemberInfo(userInfo.uid);
        console.log('loadedOrgInfo', loadedOrgInfo);
        if (loadedOrgInfo.status === 200) {
          console.log('aaaaaa', loadedOrgInfo.data);
          setLoadedInfo(loadedOrgInfo.data);
        } else {
          setLoadedInfo(userInfo);
        }
        setLoading(false);
      } else if (userInfo.type === ACCZIOM_ORG) {
        const loadedOrgInfo = await lambdaGetOrganizationInfo(userInfo.uid);
        if (mounted.current) {
          setLoadedInfo(loadedOrgInfo);
        }
      } else {
        const loadedUserInfo = await lambdaGetUserInfo(userInfo.uid);
        if (mounted.current) {
          setLoadedInfo(loadedUserInfo);
        }
      }
    } catch (err) {
      if (mounted.current) setLoadFailed(true);
    } finally {
      if (mounted.current) setLoading(false);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!showMe && userInfo.uid === activeOrgId) return;
    setAnchorEl(event.currentTarget);
    loadInfo();
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onVisitUs = () => {
    if (userInfo.uid === activeOrgId && showMe) return;
    const myUrl = window.location.origin;
    if (loadedInfo) {
      window.open(`${myUrl}/about/${loadedInfo.domainName}`);
    }
  };

  return (
    <Box>
      <Box
        onClick={handleClick}
        sx={{
          display: 'inline-block'
        }}
      >
        {children}
      </Box>
      <Box
        onClick={(e) => { e.stopPropagation(); }}
      >
        <Popover
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box
            sx={{
              width: '300px',
              position: 'relative',
              mb: `${(userInfo.type === ACCZIOM_ORG && loadedInfo) ? 10 : 30}px`
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100px',
                position: 'relative',
                backgroundColor: 'background.default',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                  position: 'relative'
                }}
              >
                <img
                  alt="user avatar"
                  style={{
                    position: 'absolute',
                    left: '-50px',
                    top: '-150px',
                    filter: 'blur(4px)',
                    WebkitFilter: 'blur(4px)',
                    width: '400px',
                    height: '400px',
                    opacity: '.3'
                  }}
                  src={userInfo.avatar}
                />
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#1695ea',
                    opacity: 0.5
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  left: '50%',
                  top: '100%',
                  transform: 'translate(-50%, -50%)',
                  borderRadius: '50%',
                  border: '3px solid white'
                }}
              >
                <UserAvatar
                  userInfo={userInfo}
                  size={100}
                  showType={false}
                />
              </Box>
            </Box>
            <Box
              sx={{
                mt: '50px'
              }}
            >
              {!isLoadFailed && (
                <Box
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  <Typography
                    variant="h6"
                    color="textPrimary"
                  >
                    {/* {userInfo.type !== ACCZIOM_ORG && `${userInfo.firstName} ${userInfo.lastName}`} */}
                    {userInfo.penName}
                  </Typography>
                </Box>
              )}
              {!isLoading && userInfo.type !== ACCZIOM_ORG && loadedInfo && (
                <Box>
                  {ProfileInfo(BorderColorIcon, loadedInfo.penName)}
                  {loadedInfo.publishedProfile?.includes('email') && ProfileInfo(MailIcon, loadedInfo.email)}
                  {loadedInfo.publishedProfile?.includes('phone') && ProfileInfo(PhoneIcon, loadedInfo.phone)}
                  {(!loadedInfo.publishedProfile || loadedInfo.publishedProfile?.includes('website')) && ProfileInfo(LinkIcon, loadedInfo.website)}
                  {(!loadedInfo.publishedProfile || loadedInfo.publishedProfile?.includes('dateOfBirth')) && ProfileInfo(CakeIcon, toDisplayDateFormat(loadedInfo.dateOfBirth))}
                  {loadedInfo.publishedProfile?.includes('location') && ProfileInfo(PlaceIcon, loadedInfo.addressLine1)}
                </Box>
              )}
              {!isLoading && (userInfo.type === ACCZIOM_ORG || userInfo.type === 0 || userInfo.type === 5) && loadedInfo && (
                <Box>
                  {ProfileInfo(MailIcon, loadedInfo.email)}
                  {ProfileInfo(PhoneIcon, loadedInfo.phone)}
                  {ProfileInfo(LinkIcon, loadedInfo.website)}
                  {ProfileInfo(PlaceIcon, loadedInfo.addressLine1)}
                  {loadedInfo.domainName && (
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      pr={2}
                    >
                      <Button
                        variant="text"
                        endIcon={<OpenInNewIcon />}
                        onClick={onVisitUs}
                      >
                        Visit Us
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
              {isLoading && (
                <Box
                  sx={{
                    width: '240px',
                    ml: '30px'
                  }}
                >
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </Box>
              )}
              {isLoadFailed && (
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'center',
                    color: 'red'
                  }}
                >
                  Failed to get information.
                </Typography>
              )}
            </Box>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

ProfilePopover.propTypes = {
  userInfo: PropTypes.any,
  children: PropTypes.any,
  showMe: PropTypes.bool
};

export const SmallUserInfoWithDetail: FC<SmallUserInfoProps> = (props) => {
  const { userInfo } = props;
  if (!userInfo) return (<></>);

  return (
    <ProfilePopover userInfo={userInfo}>
      <SmallUserInfoGrouped userInfo={userInfo} />
    </ProfilePopover>
  );
};

SmallUserInfoWithDetail.propTypes = {
  userInfo: PropTypes.any
};

interface SmallUserInfoListProps {
  userMinInfos: UserMinInfo[];
}

export const SmallUserInfoList: FC<SmallUserInfoListProps> = ({ userMinInfos }) => (
  <>
    {userMinInfos.map((info) => (
      <SmallUserInfoWithDetail
        userInfo={info}
        key={info.uid}
      />
    ))}
  </>
);

SmallUserInfoList.propTypes = {
  userMinInfos: PropTypes.array
};

export default SmallUserInfo;
