const acceptLetterTemplate = `<p>Hi &lt;client name&gt;,</p>
<p><br></p>
<p>You successfully sent request to us.</p>
<p>We just wanted you to know that we truly enjoy working with you and feel honored to be your chosen.</p>
<p>We will do our very best to continue to meet your needs.</p>
<p><br></p>
<p>Sincerely.</p>
<p><br></p>
<p>&lt;date&gt;</p>
<p><br></p>
`;

export default acceptLetterTemplate;
