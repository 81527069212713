// import { MicroserviceInfo } from 'src/../../Common/Model/microservice';
import { httpGetMicroserviceForRequest } from 'src/apis/httpDispatch';
import { updateUserMinInfos } from 'src/slices/organization';
import gstore from 'src/store';
import { orgToMiniUser } from './getUserMinInfo';

const getServiceInfo = async (serviceId: string) => {
  const res = await httpGetMicroserviceForRequest(serviceId);
  const { serviceInfos, organizationInfos, tags, ratings } = res;
  gstore.dispatch(updateUserMinInfos([orgToMiniUser(organizationInfos)]));
  return {
    ...serviceInfos,
    providerName: organizationInfos.tradingName,
    providerLogo: organizationInfos.logo,
    tags,
    banner: organizationInfos.banner,
    url: organizationInfos.domainName,
    showRating: organizationInfos.showRating,
    statistics: ratings
  };
};

export default getServiceInfo;
