import { FC, useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import { PRICE_MODIFICATION_DISCOUNT, RATE_PERCENT, modificationList } from 'src/globals';
import PrettySelect from 'src/components/interact/PrettySelect';
import Delete from '@mui/icons-material/Delete';
import ActionButton from 'src/components/interact/ActionButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { convertCurrency } from 'src/utils/priceUtils';

const getDisplayValue = (info) => {
  if (info.type === PRICE_MODIFICATION_DISCOUNT && typeof info.amount === 'number') return -info.amount;
  return info.amount;
};

const getSaveValue = (type, amount) => {
  if (type === PRICE_MODIFICATION_DISCOUNT && typeof amount === 'number') return -amount;
  return amount;
};

export const convertModificationsCurrency = (modifications, currency) => (!modifications ? [] : modifications.map((mod) => ({
  type: mod.type,
  amount: mod.unit === RATE_PERCENT ? mod.amount : Math.floor(convertCurrency(mod.amount, mod.unit, currency) * 10000 + 0.001) / 10000,
  unit: mod.unit === RATE_PERCENT ? mod.unit : currency
})));

export const isValueError = (values) => values.some((info) => typeof info.amount !== 'number');

const ModificationInfo: FC<{
  values: any[];
  update: any;
  currency: string;
}> = ({
  values,
  update,
  currency
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      {values.map((info) => {
        const item = modificationList.find((v) => v.id === info.type);
        return (
          <Box
            display="flex"
            alignItems="center"
            sx={{
              pb: 1
            }}
            key={`${info.type}`}
          >
            <Typography
              color="primary.main"
              sx={{
                width: '120px'
              }}
            >
              {item.title}
            </Typography>
            <TextField
              size="small"
              value={getDisplayValue(info)}
              sx={{
                mr: 1
              }}
              onChange={(e) => {
                let val = Number(e.target.value);
                let saveValue: any = e.target.value;
                if (!Number.isNaN(val) && e.target.value !== '') {
                  if (info.unit === RATE_PERCENT) {
                    const minv = info.type === PRICE_MODIFICATION_DISCOUNT ? 0 : -100;
                    const maxv = info.type === PRICE_MODIFICATION_DISCOUNT ? 100 : null;
                    if (val < minv) val = minv;
                    if (maxv && val > maxv) val = maxv;
                  }
                  saveValue = val;
                }
                update(values.map((value) => {
                  if (value.type === info.type) {
                    return {
                      ...value,
                      amount: getSaveValue(info.type, saveValue)
                    };
                  }
                  return value;
                }));
              }}
              error={typeof info.amount !== 'number'}
            />
            <Box
              sx={{
                width: '100px'
              }}
            >
              <PrettySelect
                value={info.unit}
                onChange={(v) => {
                  update(values.map((value) => {
                    if (value.type === info.type) {
                      return {
                        ...value,
                        unit: v,
                        // amount: 0
                      };
                    }
                    return value;
                  }));
                }}
                items={[{
                  id: RATE_PERCENT,
                  title: '%'
                }, {
                  id: currency,
                  title: currency
                }]}
                itemComponent={({ title }) => (
                  <Typography
                    color="primary.main"
                    noWrap
                  >
                    {title}
                  </Typography>
                )}
              />
            </Box>
            <ActionButton
              title="Remove"
              icon={Delete}
              onClick={() => {
                update(values.filter((v) => v.type !== info.type));
              }}
              color="secondary"
            />
          </Box>
        );
      })}
      {values.length === 0 && (
        <Box
          sx={{
            height: '40px',
            alignItems: 'center',
            mb: 1,
            display: 'flex'
          }}
        >
          <Typography
            sx={{ pr: 2 }}
          >
            You can apply modifications to service price.
          </Typography>
          <Button
            variant="contained"
            size="medium"
            sx={{
              width: '100px'
            }}
            color="primary"
            onClick={onOpen}
            endIcon={<ArrowRightIcon />}
          >
            Add
          </Button>
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          ml: 1
        }}
      >
        {modificationList.filter((v) => !values.some((info) => v.id === info.type)).map((mod) => (
          <MenuItem
            key={mod.id}
            disableRipple
            onClick={() => {
              update([
                ...values,
                {
                  type: mod.id,
                  // amount: 0,
                  unit: RATE_PERCENT
                }
              ]);
              handleClose();
            }}
          >
            {mod.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ModificationInfo;
