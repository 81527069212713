import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import objFromArray from 'src/utils/objFromArray';
import type {
  Template
} from 'src/../../Common/Model/marketplace';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { ACCZIOM_TEAM } from 'src/globals';

interface TemplateState {
  isInitialised: boolean,
  needReload: boolean,
  needReloadProposal: boolean,
  needRefresh: boolean,
  activeTemplateId?: string,
  templates: {
    byId: Record<string, Template>;
    allIds: string[];
  },
  data: NodeModel[],
  maxId: number,
  selTaxonomy: string,
  selTemplate: any,
  selGoods: any,
  curQuery: string,
}

const initialState: TemplateState = {
  isInitialised: false,
  needReload: true,
  needReloadProposal: true,
  needRefresh: true,
  activeTemplateId: null,
  templates: {
    byId: {},
    allIds: [],
  },
  data: [],
  maxId: 0,
  selTaxonomy: null,
  selTemplate: null,
  selGoods: null,
  curQuery: null,
};

const slice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    initializeTemplateState(state: TemplateState, action: PayloadAction<{ isInitialised: boolean; }>) {
      const { isInitialised } = action.payload;
      state.isInitialised = isInitialised;
    },
    setTemplates(state: TemplateState, action: PayloadAction<{ templates: Template[]; }>) {
      const { templates } = action.payload;
      state.templates.byId = objFromArray(templates);
      state.templates.allIds = Object.keys(state.templates.byId);
      state.isInitialised = true;
      state.needRefresh = !state.needRefresh;
      state.needReloadProposal = false;
      state.needReload = false;
    },
    setActiveTemplate(state: TemplateState, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.activeTemplateId = id;
    },
    addNewTemplate(state: TemplateState, action: PayloadAction<{ template: Template; }>) {
      state.isInitialised = false;
      const { template } = action.payload;
      state.templates.byId[template.id] = template;
      state.templates.allIds = Object.keys(state.templates.byId);
      state.activeTemplateId = template.id;
      state.isInitialised = true;
      state.needRefresh = !state.needRefresh;
    },
    updateTemplate(state: TemplateState, action: PayloadAction<{ template: Template; }>) {
      state.isInitialised = false;
      const { template } = action.payload;
      state.templates.byId[template.id] = template;
      state.templates.allIds = Object.keys(state.templates.byId);
      state.activeTemplateId = template.id;
      state.isInitialised = true;
      state.needRefresh = !state.needRefresh;
    },
    removeTemplate(state: TemplateState, action: PayloadAction<{ id: string; }>) {
      state.isInitialised = false;
      const { id } = action.payload;
      delete state.templates.byId[id];
      state.templates.allIds = Object.keys(state.templates.byId);
      state.needRefresh = !state.needRefresh;
      state.isInitialised = true;
      state.activeTemplateId = null;
    },
    setNeedReload(state: TemplateState, action: PayloadAction<{ needReload: boolean; }>) {
      const { needReload } = action.payload;
      state.needReload = needReload;
    },
    setNeedReloadProposal(state: TemplateState) {
      state.needReloadProposal = !state.needReloadProposal;
    },
    setNeedRefresh(state: TemplateState) {
      state.needRefresh = !state.needRefresh;
    },
    clearAllTemplateSlice(state: TemplateState) {
      state.isInitialised = false;
      state.templates.allIds = [];
      state.templates.byId = null;
      state.needReload = true;
      state.needReloadProposal = true;
      state.needRefresh = true;
      state.activeTemplateId = null;
      state.data = [];
      state.maxId = 0;
      state.selGoods = null;
      state.selTemplate = null;
      state.selTaxonomy = null;
      state.curQuery = null;
    },
    setData(state: TemplateState, action: PayloadAction<{ data: NodeModel[]; }>) {
      const { data } = action.payload;
      state.data = data;
    },
    setMaxId(state: TemplateState, action: PayloadAction<{ maxId: number; }>) {
      const { maxId } = action.payload;
      state.maxId = maxId;
    },
    setSelTemplate(state: TemplateState, action: PayloadAction<{ template: any; }>) {
      const { template } = action.payload;
      state.selTemplate = template;
    },
    setSelTaxnomy(state: TemplateState, action: PayloadAction<{ taxonomy: string; }>) {
      const { taxonomy } = action.payload;
      state.selTaxonomy = taxonomy;
    },
    setSelGoods(state: TemplateState, action: PayloadAction<{ goods: any; }>) {
      const { goods } = action.payload;
      state.selGoods = goods;
    },
    setCurQuery(state: TemplateState, action: PayloadAction<{ query: string; }>) {
      const { query } = action.payload;
      state.curQuery = query;
    },
    setAgents(state: TemplateState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      assignedTeams.forEach(({ id, teamId }) => {
        state.templates.byId[id] = {
          ...state.templates.byId[id],
          ownerAgent: { id: teamId, type: ACCZIOM_TEAM }
        };
      });
    }
  }
});

export const { reducer } = slice;

export const initializeTemplateState = (value: boolean): AppThunk => async (dispatch) => {
  const isInitialised = value;
  dispatch(slice.actions.initializeTemplateState({ isInitialised }));
};
export const loadTemplates = (templates: Template[]): AppThunk => async (dispatch) => {
  templates.sort((b, a) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  dispatch(slice.actions.setTemplates({ templates }));
};
export const setActiveTemplate = (value : string): AppThunk => async (dispatch) => {
  const id = value;
  dispatch(slice.actions.setActiveTemplate({ id }));
};
export const addNewTemplate = (template : Template): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNewTemplate({ template }));
};
export const removeTemplate = (id : string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeTemplate({ id }));
};
export const updateTemplate = (template : Template): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateTemplate({ template }));
};
export const resetActiveTemplate = (): AppThunk => async (dispatch) => {
  const id = null;
  dispatch(slice.actions.setActiveTemplate({ id }));
};
export const clearAllTemplateSlice = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearAllTemplateSlice());
};
export const setNeedReload = (value:boolean): AppThunk => async (dispatch) => {
  const needReload = value;
  dispatch(slice.actions.setNeedReload({ needReload }));
};
export const setNeedReloadProposal = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setNeedReloadProposal());
};
export const setNeedRefresh = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setNeedRefresh());
};
export const setData = (data:NodeModel[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setData({ data }));
};
export const setMaxId = (maxId:number): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setMaxId({ maxId }));
};
export const setSelTaxnomy = (taxonomy:string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setSelTaxnomy({ taxonomy }));
};
export const setSelTemplate = (template:any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setSelTemplate({ template }));
};
export const setSelGoods = (goods:any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setSelGoods({ goods }));
};
export const setCurQuery = (query:string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurQuery({ query }));
};
export const setAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setAgents(assignedTeams));
};
export default slice;
