import type { FC } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { alpha, makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import { useState } from 'react';
import { DblButtonMessageProps } from 'src/globals';

const useStyles = makeStyles({
  dialogPaper: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    margin: 0,
    borderRadius: 0,
    boxShadow: 'none'
  },
});

const DblButtonMessage: FC<DblButtonMessageProps> = (props): JSX.Element => {
  const classes = useStyles();
  const { onYesClick, onNoClick, messageTitle, messageContent, onClose } = props;
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const handleYesClick = async () => {
    if (onYesClick) await onYesClick();
    setIsOpen(false);
  };
  const handleNoClick = async () => {
    if (onNoClick) await onNoClick();
    onClose(false);
  };
  return (
    <Dialog
      fullWidth
      open={isOpen}
      onBackdropClick={() => onClose(false)}
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <Box
        sx={{
          display: 'flex',
          p: 1,
        }}
      >
        <Avatar
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.background.default, 0),
            color: 'info.main',
            mr: 1
          }}
        >
          <WarningIcon />
        </Avatar>
        <Box
          mt={1}
        >
          <Typography
            color="textPrimary"
            variant="h5"
          >
            {messageTitle}
          </Typography>
        </Box>
      </Box>
      <Typography
        color="textSecondary"
        textAlign="center"
        sx={{
          px: 1,
          py: 2
        }}
        variant="body1"
      >
        {messageContent}
      </Typography>
      <Divider />
      <Button
        fullWidth
        sx={{
          my: 1,
          color: 'error.main',
          fontSize: '16px'
        }}
        variant="text"
        onClick={async () => {
          await handleYesClick();
        }}
      >
        YES
      </Button>
      <Box
        sx={{
          width: '100%',
          height: '8px',
          backgroundColor: 'background.default'
        }}
      />
      <Button
        fullWidth
        color="primary"
        sx={{
          my: 1,
          fontSize: '16px'
        }}
        variant="text"
        onClick={async () => {
          await handleNoClick();
        }}
      >
        NO
      </Button>
    </Dialog>
  );
};

DblButtonMessage.propTypes = {
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func,
  messageTitle: PropTypes.string,
  messageContent: PropTypes.string,
  onClose: PropTypes.func
};

DblButtonMessage.defaultProps = {
  messageTitle: null,
  messageContent: null
};

export default DblButtonMessage;
