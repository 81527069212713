import type { FC } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Popover,
  Portal,
  SvgIcon,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/Info';

interface SingButtonPopupProps {
  messageContent: string;
  onOkClick?: () => void;
  onClose?: () => void;
  anchorEl: any;
  anchorOrigin?: any;
  transformOrigin?: any;
}

const SingButtonPopup: FC<SingButtonPopupProps> = (props): JSX.Element => {
  const { onOkClick, messageContent, onClose, anchorEl, anchorOrigin, transformOrigin } = props;
  const open = Boolean(anchorEl);

  const handleOkClick = () => {
    onClose();
    if (onOkClick) onOkClick();
  };

  return (
    <Portal>
      <Backdrop open={open} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={transformOrigin || {
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          sx: {
            width: 400,
            mt: 1
          }
        }}
        transitionDuration={500}
      >
        <Box
          sx={{
            display: 'flex',
            pt: 3,
            px: 3,
            alignItems: 'center'
          }}
        >
          <SvgIcon
            sx={{
              width: 24,
              height: 24,
              mr: 1
            }}
          >
            <InfoIcon
              color="secondary"
            />
          </SvgIcon>
          <Typography
            color="textPrimary"
            variant="body1"
            fontWeight="medium"
          >
            {messageContent}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: 3,
            py: 1
          }}
        >
          <Button
            color="primary"
            variant="text"
            onClick={handleOkClick}
          >
            Got it
          </Button>
        </Box>
      </Popover>
    </Portal>
  );
};

SingButtonPopup.propTypes = {
  messageContent: PropTypes.string,
  onOkClick: PropTypes.func,
  onClose: PropTypes.func,
  anchorEl: PropTypes.any,
  anchorOrigin: PropTypes.any,
  transformOrigin: PropTypes.any
};

export default SingButtonPopup;
