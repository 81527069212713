import {
  MICROSERVICE,
  periodLengthList,
  PAY_CREDIT_CARD,
  cryptoCurrencies,
  currencyUnitToType,
  PAY_CRYPTOCURRENCY,
  PAY_STRIPE,
  UPFRONT_PAYMENT,
  LENGTH_YEAR
} from 'src/globals';
import { v4 as uuidv4 } from 'uuid';
import { Organization } from 'src/../../Common/Model/organization';
import { MdInfoOutline } from 'react-icons/md';
import { HiOutlineTemplate } from 'react-icons/hi';
import { RiMoneyDollarBoxLine } from 'react-icons/ri';
import { IoNewspaperOutline } from 'react-icons/io5';
import { FiCheckCircle } from 'react-icons/fi';
import { ImMakeGroup } from 'react-icons/im';
import { Microservice } from 'src/../../Common/Model/microservice';
import { initialValues } from './PaymentPad';
import engagementLetterTemplate from './engagementLetterTemplate';
import acceptLetterTemplate from './acceptLetterTemplate';

export const paymentOptionFromStringToNumber = (paymentOptions: any) => ({
  ...paymentOptions,
  payment: paymentOptions.payment.map((v) => {
    if (v === null) return null;
    return {
      type: Number(v.type),
      initialAmount: Number(v.initialAmount),
      initialOffset: Number(v.initialOffset),
      finalPeriod: Number(v.finalPeriod),
      finalOffset: Number(v.finalOffset)
    };
  }),
  interest: paymentOptions.interest === null ? null : {
    type: Number(paymentOptions.interest.type),
    rateType: Number(paymentOptions.interest.rateType),
    rate: Number(paymentOptions.interest.rate)
  },
  gst: paymentOptions.gst === null ? null : {
    rate: Number(paymentOptions.gst.rate)
  },
  invoiceOffset: Number(paymentOptions.invoiceOffset)
});

export const getDefaultPaymentOptions = (orgInfo: Organization) => (
  (orgInfo.paymentTemplates && orgInfo.paymentTemplates[MICROSERVICE])
    ? {
      ...orgInfo.paymentTemplates[MICROSERVICE][0],
      modifications: !orgInfo.paymentTemplates[MICROSERVICE][0].modifications ? [] : orgInfo.paymentTemplates[MICROSERVICE][0].modifications
    } : {
      payment: [initialValues(UPFRONT_PAYMENT), null, null, null, null],
      interest: null,
      gst: null,
      invoiceOffset: '0',
      platforms: [{
        paymentTool: cryptoCurrencies.includes(currencyUnitToType(orgInfo.currency)) ? PAY_CRYPTOCURRENCY : PAY_STRIPE,
        paymentMethod: PAY_CREDIT_CARD,
        paymentCurrency: orgInfo.currency
      }],
      modifications: []
    }
);

export const getDefaultEngagementLetter = (orgInfo: Organization) => (
  orgInfo.paymentTemplates && orgInfo.paymentTemplates[MICROSERVICE] && orgInfo.paymentTemplates[MICROSERVICE][1]
    ? orgInfo.paymentTemplates[MICROSERVICE][1]
    : engagementLetterTemplate
);

export const getDefaultAcceptLetter = (orgInfo: Organization) => (
  orgInfo.paymentTemplates && orgInfo.paymentTemplates[MICROSERVICE] && orgInfo.paymentTemplates[MICROSERVICE][2]
    ? orgInfo.paymentTemplates[MICROSERVICE][2]
    : acceptLetterTemplate
);

export const getEmptyService = (orgInfo: Organization, creator: any, agent: any, type: number, defaultCode?: string) => {
  const orgCode = !defaultCode ? orgInfo.anzicCategory : defaultCode;

  return {
    id: uuidv4(),
    name: '',
    entity: {
      legalName: ''
    },
    type: orgCode,
    description: '',
    creator,
    agent: [agent],
    durationType: {
      type: LENGTH_YEAR,
      length: 1
    },
    bundleType: type,
    rating: 0,
    stat: null,
    baseFee: 0,
    baseFeeDescription: '',
    defaultCurrency: orgInfo.currency,
    engagementLetter: '',
    acceptLetter: '',
    uri: '',
    json: type === MICROSERVICE ? [] : { full: [] },
    paymentOptions: getDefaultPaymentOptions(orgInfo),
    publishedAt: null,
    props: {
      showPrice: true,
      showSchedule: false
    }
  };
};

export const NOT_SUBMITTING = 0;
export const NEXT_SUBMITTING = 1;
export const PREV_SUBMITTING = 2;

export const SERVICE_INFO_STEP = 0;
export const SERVICE_TEMPLATE_STEP = 1;
export const SERVICE_COLLECTION_STEP = 1;
export const SERVICE_PAYMENT_STEP = 2;
export const SERVICE_ENGAGEMENT_STEP = 3;
export const SERVICE_ACCEPT_STEP = 4;

export const SINGLE_SERVICE_EDIT_STEPS = [
  {
    id: SERVICE_INFO_STEP,
    label: 'Main Information',
    Icon: MdInfoOutline
  },
  {
    id: SERVICE_TEMPLATE_STEP,
    label: 'Questionnaire Template',
    Icon: HiOutlineTemplate
  },
  {
    id: SERVICE_PAYMENT_STEP,
    label: 'Payment Term',
    Icon: RiMoneyDollarBoxLine
  },
  {
    id: SERVICE_ENGAGEMENT_STEP,
    label: 'Engagement Letter',
    Icon: IoNewspaperOutline
  },
  {
    id: SERVICE_ACCEPT_STEP,
    label: 'Accept Page',
    Icon: FiCheckCircle
  }
];

export const BUNDLE_SERVICE_EDIT_STEPS = [
  {
    id: SERVICE_INFO_STEP,
    label: 'Main Information',
    Icon: MdInfoOutline
  },
  {
    id: SERVICE_COLLECTION_STEP,
    label: 'Service Collection',
    Icon: ImMakeGroup
  },
  {
    id: SERVICE_PAYMENT_STEP,
    label: 'Payment Term',
    Icon: RiMoneyDollarBoxLine
  },
  {
    id: SERVICE_ENGAGEMENT_STEP,
    label: 'Engagement Letter',
    Icon: IoNewspaperOutline
  },
  {
    id: SERVICE_ACCEPT_STEP,
    label: 'Accept Page',
    Icon: FiCheckCircle
  }
];

export const getServiceCycleText = (service: Microservice): string => {
  const servicePeriod = periodLengthList.find((v) => v.type === service.durationType.type);
  return `${service.durationType.length} ${servicePeriod.text}${service.durationType.length > 1 ? 's' : ''}`;
};
