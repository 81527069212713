import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { CircularProgress } from '@mui/material';

interface CheckLabelProps {
  checked: boolean;
  onChange?: (event?: any) => void;
  label: string;
  indeterminate?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const CheckLabel: FC<CheckLabelProps> = (props) => {
  const { checked, onChange, label, indeterminate, loading, disabled, ...others } = props;

  return (
    <FormControlLabel
      control={(
        <Checkbox
          id="checklabel"
          checked={checked}
          indeterminate={!!indeterminate}
          onChange={(e) => {
            if (onChange) onChange(e);
          }}
        />
      )}
      label={(
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="body1"
          >
            {label}
          </Typography>
          {!!loading && (
            <CircularProgress
              color="primary"
              size={16}
              sx={{
                ml: 1
              }}
            />
          )}
        </Box>
      )}
      disabled={!!loading || !!disabled}
      {...others}
    />
  );
};

CheckLabel.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  indeterminate: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

export default CheckLabel;
