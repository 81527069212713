export const fetchUrl = async (url: string) => {
  try {
    const res = await fetch(url);
    const buf = await res.arrayBuffer();
    return URL.createObjectURL(new Blob([buf]));
  } catch (e) {
    return null;
  }
};

export const uploadFile = async (file: File | Blob, url: string): Promise<any> => fetch(url, {
  method: 'put',
  headers: {
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': ''
  },
  body: file,
})
  .then((reponse) => Promise.resolve(reponse));
