import { FC, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress
} from '@material-ui/core';
import { useDispatch, useSelector } from 'src/store';
import toast from 'react-hot-toast';
import useMounted from 'src/hooks/useMounted';
import { v4 as uuidv4 } from 'uuid';
import { addRequests, removeRequest, updateRequest } from 'src/slices/request';
import { MicroserviceForReq, setRequest, setStep } from 'src/slices/serviceRequest';
import {
  MICROSERVICE,
  DRAFT,
  SERVICE_REQUEST_STEP_INFOCOLLECT,
  SERVICE_REQUEST_STEP_COMPLETED,
  SERVICE_REQUEST_STEP_CONFIRMED,
  PAY_DIRECT_DEBIT,
  PAY_STRIPE,
  ACCZIOM_NONE,
  TICKET_MODE_NORMAL,
  ACCZIOM_CLIENT,
  ACCZIOM_ORG,
  ACCZIOM_TEAM,
  OFFERED,
  OFFERREQUESTED,
  OFFERREQUESTDRAFT,
  REQUESTED,
  CONNECT_DLG_SIGNIN,
  CONNECT_DLG_CLOSED
} from 'src/globals';
import { getInitialRequestFromService } from 'src/utils/requestUtils';
import { Request } from 'src/../../Common/Model/request';
import { useNavigate } from 'react-router-dom';
import {
  lambdaCreateRequest,
  lambdaUpdateRequest,
  lambdaGetAttachmentFileUploadUrl,
  lambdaSendRequestMerged
} from 'src/aws/lambdaDispatch';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import SingButtonPopup from 'src/components/dialog/SingButtonPopup';
import { infoToast } from 'src/components/toast';
import uploadFile from 'src/utils/uploadFile';
import { getInitializedContract } from 'src/utils/procurement/contractUtils';
import useAuth from 'src/hooks/useAuth';
import AlertLoginDialog from 'src/components/dialog/AlertLoginDialog';
import ConnectDialog from 'src/components/ClientDashboard/ConnectDialog';
import { Organization } from 'src/../../Common/Model/organization';
import { getActiveInfo, getActiveOrgInfo, getActiveSuppliers, getActiveType, getUserMinInfoByID } from 'src/utils/getActiveOrgInfo';
import { setConnectDlgOpen, setTabTicket } from 'src/slices/user';
import { convert2Array } from 'src/utils/agentUtils';
import TooltipDlg from '../dialog/TooltipDlg';

const uploadAttachment = async (file: File): Promise<string> => {
  const attachUri = uuidv4();
  const uploadUrl = await lambdaGetAttachmentFileUploadUrl(attachUri);
  await uploadFile(file, uploadUrl);
  return attachUri;
};

export const createDirectRequest = async (
  service: MicroserviceForReq,
  bundledServices: MicroserviceForReq[],
  demander: any,
  demanderAgent: any,
  demanderSignCount: number,
  supplierSignCount: number,
  files: File[],
  mode: number
): Promise<Request> => {
  const attachmentUri = files.length > 0 ? await uploadAttachment(files[0]) : '';
  const fileName = files.length > 0 ? files[0].name : '';
  const initialized = getInitialRequestFromService(service, bundledServices, demander, demanderAgent, mode);
  return {
    ...initialized,
    demanderSignCount,
    supplierSignCount,
    fileName,
    attachment_uri: attachmentUri,
    history: [...initialized.history, { eventName: 'requestedAt', occuredAt: new Date().toISOString() }]
  };
};

export const createRequestFromRequest = async (
  service: MicroserviceForReq,
  bundledServices: MicroserviceForReq[],
  demander: any,
  demanderAgent: any,
  demanderSignCount: number,
  supplierSignCount: number,
  request: Request,
  files: File[]
): Promise<Request> => {
  const attachmentUri = files.length > 0 ? await uploadAttachment(files[0]) : '';
  const fileName = files.length > 0 ? files[0].name : '';
  const initialized = getInitialRequestFromService(service, bundledServices, demander, demanderAgent, request.mode);
  return {
    ...initialized,
    demanderSignCount: !request.demanderSignCount ? demanderSignCount : request.demanderSignCount,
    supplierSignCount: !request.supplierSignCount ? supplierSignCount : request.supplierSignCount,
    status: [OFFERED, OFFERREQUESTDRAFT].includes(request.status) ? OFFERREQUESTED : REQUESTED,
    fileName,
    attachment_uri: attachmentUri,
    history: [...request.history, { eventName: [OFFERED, OFFERREQUESTDRAFT].includes(request.status) ? 'editedAt' : 'requestedAt', occuredAt: new Date().toISOString() }],
    uid: request.uid,
    supplierAgent: convert2Array(request.supplierAgent),
    material: {
      ...request.material,
      ...initialized.material
    }
  };
};

export const createDirectDraft = (
  service: MicroserviceForReq,
  bundledServices: MicroserviceForReq[],
  demander: any,
  demanderAgent: any,
  demanderSignCount: number,
  supplierSignCount: number,
  mode: number
): Request => {
  const reqTemplate = getInitialRequestFromService(service, bundledServices, demander, demanderAgent, mode);
  return {
    ...reqTemplate,
    demanderSignCount,
    supplierSignCount,
    status: DRAFT,
  };
};

const createDraftFromRequest = (
  service: MicroserviceForReq,
  bundledServices: MicroserviceForReq[],
  request: Request
) => {
  const reqTemplate = getInitialRequestFromService(service, bundledServices, { id: '', type: ACCZIOM_NONE }, { id: '', type: ACCZIOM_NONE }, request.mode);
  let newDraft = {
    ...request,
    title: reqTemplate.title,
    hiMsg: reqTemplate.hiMsg,
    detail: reqTemplate.detail,
    price: reqTemplate.price,
    priceDescription: reqTemplate.priceDescription,
    material: {
      ...request.material,
      ...reqTemplate.material
    }
  };
  if (request.status === OFFERED) {
    newDraft = {
      ...newDraft,
      uid: uuidv4(),
      material: {
        ...newDraft.material,
        offerId: newDraft.uid
      },
      status: OFFERREQUESTDRAFT
    };
  }
  return newDraft;
};

const Actions: FC<{
  showSubmit?: boolean;
  onGotoSubmit: any;
  goBackUrl?: string;
  files: File[];
}> = ({
  showSubmit,
  onGotoSubmit,
  goBackUrl,
  files
}) => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const navigate = useNavigate();
  const auth = useAuth();
  const [isSavingDraft, setSavingDraft] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [cookies, setCookie] = useCookies(['ms_draft_notify']);
  const [isLoginOpened, setLoginOpened] = useState<boolean>(false);
  const activeInfo = getActiveInfo(ACCZIOM_CLIENT, ACCZIOM_ORG);
  const activeAgentInfo = getActiveInfo(ACCZIOM_CLIENT, ACCZIOM_TEAM);
  const {
    step,
    service,
    bundledServices,
    request,
    postponeId,
    selectedReferralId,
    referrals,
    entityInfo
  } = useSelector((state) => state.serviceRequest);
  const demanderSignCount = getActiveType(ACCZIOM_CLIENT, ACCZIOM_ORG) === ACCZIOM_CLIENT ? 1 : getActiveOrgInfo()?.signerCount;
  const supplierSignCount = getUserMinInfoByID(service.creator).signerCount;
  const { stripeAccount } = useSelector((state) => state.payAccount);
  const successDirectDebit = !!stripeAccount && !!stripeAccount.customerID && !!stripeAccount.detail && !!stripeAccount.detail.setupIntents && !!stripeAccount.detail.setupIntents.find((item) => (item.type === 'au_becs_debit' && item.status === 'succeeded'));
  const { needReload: ticketNeedReload } = useSelector((state) => state.request);
  const { connectDlgOpen } = useSelector((state) => state.user);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleSaveDraft = async () => {
    setSavingDraft(true);
    try {
      if (request) {
        const reqBody = createDraftFromRequest(
          service,
          bundledServices,
          request
        );
        if (postponeId) reqBody.material.postponeId = postponeId;
        if (entityInfo) reqBody.material.entityInfo = entityInfo;
        if (request.status === OFFERED) {
          await lambdaCreateRequest(reqBody);
          dispatch(addRequests([reqBody]));
        } else {
          await lambdaUpdateRequest(request.uid, reqBody);
          dispatch(setRequest(reqBody));
          dispatch(updateRequest(request.uid, reqBody));
        }
      } else {
        const reqBody = createDirectDraft(
          service,
          bundledServices,
          activeInfo,
          activeAgentInfo,
          demanderSignCount,
          supplierSignCount,
          TICKET_MODE_NORMAL
        );
        if (postponeId) reqBody.material.postponeId = postponeId;
        if (entityInfo) reqBody.material.entityInfo = entityInfo;
        await lambdaCreateRequest(reqBody);
        dispatch(addRequests([reqBody]));
        dispatch(setRequest(reqBody));
      }
      toast.success('Successfully saved!');
    } catch (err) {
      console.log(JSON.stringify(err));
      toast.error('Failed to save as draft!');
    } finally {
      if (mounted.current) setSavingDraft(false);
    }
  };

  const handleDraftNotify = async () => {
    setCookie('ms_draft_notify', 'true', { path: '/' });
    await handleSaveDraft();
  };

  const handleConfirm = () => {
    if (service.periodInfo.periodCount === null || service.periodInfo.count === null) {
      infoToast('Service timeframe is required.');
      document.getElementById('offer-container').scrollTo({
        top: document.getElementById('timeframe').offsetTop,
        behavior: 'smooth'
      });
      return;
    }
    let uncompletedEleId = '';
    if (service.bundleType === MICROSERVICE) {
      if (!service.completedQuestionnaire) {
        uncompletedEleId = 'questionnaire';
      }
    } else {
      const uncompletedBs = bundledServices.find((bs) => !bs.completedQuestionnaire);
      if (uncompletedBs) uncompletedEleId = `questionnaire-${uncompletedBs.id}-${uncompletedBs.sid}`;
    }
    if (uncompletedEleId) {
      infoToast('Complete questionnaire(s) first!');
      document.getElementById('offer-container').scrollTo({
        top: document.getElementById(uncompletedEleId).offsetTop - 32,
        behavior: 'smooth'
      });
      return;
    }
    if (auth.isAuthenticated && service.selectedPaymentPlatform.paymentTool === PAY_STRIPE && service.selectedPaymentPlatform.paymentMethod === PAY_DIRECT_DEBIT && !successDirectDebit) {
      // infoToast('You should enable Stripe Auto-Debit option in Configuration / External Platforms.');
      setShowTooltip(true);
      document.getElementById('offer-container').scrollTo({
        top: document.getElementById('service-rating-list')?.offsetTop - 300,
        behavior: 'smooth'
      });
      return;
    }
    document.getElementById('offer-container').scrollTo({
      // top: document.getElementById('service-info-pad').offsetTop,
      top: 0,
      behavior: 'smooth'
    });
    dispatch(setStep(SERVICE_REQUEST_STEP_CONFIRMED));
  };

  const handleSendRequest = async () => {
    if (!auth.isAuthenticated) {
      setLoginOpened(true);
      return;
    }
    setSubmitting(true);
    try {
      const reqBody = request
        ? await createRequestFromRequest(
          service,
          bundledServices,
          activeInfo,
          activeAgentInfo,
          demanderSignCount,
          supplierSignCount,
          request,
          files
        )
        : await createDirectRequest(
          service,
          bundledServices,
          activeInfo,
          activeAgentInfo,
          demanderSignCount,
          supplierSignCount,
          files,
          TICKET_MODE_NORMAL
        );
      if (postponeId) reqBody.material.postponeId = postponeId;
      if (entityInfo) reqBody.material.entityInfo = entityInfo;
      let contract = null;
      if (selectedReferralId) {
        contract = { ...getInitializedContract(null, reqBody), id: uuidv4() };
        const referral = referrals.find((ref) => ref.id === selectedReferralId);
        reqBody.referralId = referral.id;
        contract.referral = {
          id: referral.id,
          amount: referral.amount,
          unit: referral.unit,
          creator: referral.creator,
          creatorAgent: convert2Array(referral.creatorAgent),
          partner: referral.partner,
          partnerAgent: convert2Array(referral.partnerAgent)
        };
      }
      const res = await lambdaSendRequestMerged(request ? request.uid : null, reqBody, contract);
      if (res && !res.errorMessage) {
        if (!ticketNeedReload) {
          if (!request) dispatch(addRequests([res.request]));
          else {
            if (request.material.offerId) dispatch(removeRequest(request.material.offerId));
            dispatch(updateRequest(request.uid, res.request));
          }
        }
        dispatch(setStep(SERVICE_REQUEST_STEP_COMPLETED));
      }
    } catch (err) {
      console.log(JSON.stringify(err));
      toast.error('Failed to send request!');
    } finally {
      if (mounted.current) setSubmitting(false);
    }
  };

  return (
    <Box>
      {step !== SERVICE_REQUEST_STEP_COMPLETED && !onGotoSubmit && auth.isAuthenticated && (
        <Button
          variant="contained"
          onClick={async (event) => {
            if (cookies.ms_draft_notify === 'true') await handleSaveDraft();
            else setAnchorEl(event.currentTarget);
          }}
          startIcon={isSavingDraft ? <CircularProgress size={16} /> : null}
          disabled={isSavingDraft}
        >
          Save Draft
        </Button>
      )}
      {goBackUrl !== undefined && (
        <Button
          variant="text"
          color="secondary"
          onClick={() => navigate(goBackUrl)}
          sx={{ mb: 2 }}
        >
          Cancel
        </Button>
      )}
      <TooltipDlg
        open={showTooltip}
        lblOk="Enable Now"
        lblClose="Close"
        content="You should enable Stripe Auto-Debit option in Configuration / External Platforms. Before navigation, please ensure that you've already saved in draft since current working will be discard if you click 'Enable Now' to navigate."
        onOk={() => navigate('/configuration/externalplatforms')}
        onClose={() => setShowTooltip(false)}
      />
      {onGotoSubmit && (
        <Button
          variant="contained"
          onClick={onGotoSubmit}
        >
          Go To Submit
        </Button>
      )}
      {step === SERVICE_REQUEST_STEP_INFOCOLLECT && (
        <Button
          variant="contained"
          onClick={handleConfirm}
        >
          Confirm & Go To Engagement
        </Button>
      )}
      {showSubmit && (
        <Button
          variant="contained"
          onClick={handleSendRequest}
          startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
          disabled={isSubmitting || (!getActiveSuppliers().some(({ organizationId }) => organizationId === service.creator.id) && !referrals && auth.isAuthenticated)}
        >
          Send Request
        </Button>
      )}
      {step === SERVICE_REQUEST_STEP_COMPLETED && (
        <Button
          variant="contained"
          onClick={() => {
            dispatch(setTabTicket('Offer & Draft'));
            navigate('/deal/purchase');
          }}
        >
          Go to my tickets
        </Button>
      )}
      <SingButtonPopup
        messageContent="You can find your draft in Deal/Purchases."
        onOkClick={handleDraftNotify}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
      />
      {isLoginOpened && (
        <AlertLoginDialog
          open={isLoginOpened}
          handleClose={() => setLoginOpened(false)}
          handleSign={() => {
            dispatch(setConnectDlgOpen(CONNECT_DLG_SIGNIN));
            setLoginOpened(false);
          }}
        />
      )}
      <ConnectDialog
        open={connectDlgOpen === CONNECT_DLG_SIGNIN}
        mode={connectDlgOpen}
        onClose={() => {
          dispatch(setConnectDlgOpen(CONNECT_DLG_CLOSED));
        }}
        org={{
          tradingName: service.providerName,
          domainName: service.url,
          logo: service.providerLogo,
        } as Organization}
        recoverUrl="/deal/purchase"
      />
    </Box>
  );
};

Actions.propTypes = {
  showSubmit: PropTypes.bool,
  onGotoSubmit: PropTypes.func,
  goBackUrl: PropTypes.string,
  files: PropTypes.array
};

export default Actions;
