import type { FC } from 'react';
import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import RTL from 'src/components/RTL';
import useAuth from 'src/hooks/useAuth';
import useScrollReset from 'src/hooks/useScrollReset';
import useSettings from 'src/hooks/useSettings';
import routes from './routes';
import { createCustomTheme } from 'src/theme';
import SpinnerScreen from 'src/components/layout/SpinnerScreen';
import { isMobile } from 'react-device-detect';
import useMounted from 'src/hooks/useMounted';

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const { isAuthenticated, isInitialized } = useAuth();
  const mounted = useMounted();
  const lframe = document.getElementById('launcher-frame');
  if (isMobile) {
    if (window.location.origin === 'https://go.accziom.com') {
      window.location.href = window.location.href.replace('go.accziom.com', 'go.m.accziom.com');
      return <div />;
    }
  }
  useScrollReset();
  const themeMobile = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'MOBILE'
  });

  useEffect(() => {
    if (false && mounted.current && isAuthenticated && !lframe) {
      const script1st = document.createElement('script');
      script1st.innerHTML = `
        window.fwSettings={
          'widget_id':60000004192
        };
        !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
      `;
      document.body.appendChild(script1st);
      const script2nd = document.createElement('script');
      script2nd.src = 'https://widget.freshworks.com/widgets/60000004192.js';
      script2nd.async = true;
      script2nd.defer = true;
      document.body.appendChild(script2nd);
    }
  }, [mounted, isAuthenticated]);

  useEffect(() => {
    if (lframe) {
      if (isAuthenticated) lframe.style.visibility = 'visible';
      else lframe.style.visibility = 'hidden';
    }
  }, [isAuthenticated]);

  return (
    <ThemeProvider theme={themeMobile}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <Toaster
          position="top-center"
          toastOptions={{
            duration: 4000
          }}
        />
        {isInitialized ? content : <SpinnerScreen />}
      </RTL>
    </ThemeProvider>
  );
};

export default App;
