import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogActions,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import { toast } from 'react-hot-toast';
import { lambdaMercSell } from 'src/aws/lambdaDispatch';
import { useSelector } from 'src/store';
import CheckLabel from 'src/components/CheckLabel';
import GasLimit from './GasLimit';
import SatoshiFee from './SatoshiFee';
import { getActiveInfo } from 'src/utils/getActiveOrgInfo';
import useMounted from 'src/hooks/useMounted';
import { ACCZIOM_CLIENT, ACCZIOM_ORG } from 'src/globals';
import BottomPopupDialog from '../dialog/BottomPopupDialog';

const SellMERcDialog: FC<{
  open: boolean;
  handleClose: any;
}> = ({
  open,
  handleClose
}) => {
  const mounted = useMounted();
  const { wallet } = useSelector((state) => state.wallet);
  const activeInfo = getActiveInfo(ACCZIOM_CLIENT, ACCZIOM_ORG);

  const [isSubmitting, setSubmitting] = useState(false);
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState<number>(2040);
  const [layer2, setLayer2] = useState(true);
  const minGas = 2;
  const [gasLimit, setGasLimit] = useState<number>(minGas);
  const [satoshiGasFee, setSatoshiGasFee] = useState<number>(1040);

  const addressError = !address;
  const amountError = amount < satoshiGasFee + 1000;
  const gasError = gasLimit < minGas;

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const res = await lambdaMercSell(activeInfo, address, amount, layer2, gasLimit, satoshiGasFee);
      if (res && !res.errorMessage) {
        toast.success('Transaction is successfully submitted.');
      } else {
        toast.error('Failed to sell!');
      }
    } catch (err) {
      console.log(JSON.stringify(err));
      toast.error('Failed to sell!');
    } finally {
      if (mounted.current) setSubmitting(false);
    }
  };

  return (
    <BottomPopupDialog
      onClose={handleClose}
      open={open}
      title="Sell MERc"
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            sx={{
              mb: 3,
              textAlign: 'center'
            }}
          >
            Please input Bitcoin address and amount.
          </Typography>
          <TextField
            autoFocus
            label="To *"
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            size="small"
            sx={{
              mb: 3
            }}
            error={addressError}
            helperText={addressError && 'Address is required.'}
          />
          <TextField
            label="Amount *"
            variant="outlined"
            value={amount}
            onChange={(e) => {
              let nv = Number(e.target.value);
              if (Number.isNaN(nv) || nv < 0) nv = 0;
              setAmount(nv);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                >
                  MERc
                </InputAdornment>
              )
            }}
            size="small"
            sx={{
              mb: 2
            }}
            error={amountError}
            helperText={amountError && `Amount should be at least ${satoshiGasFee + 1000}.`}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column'
            }}
          >
            <CheckLabel
              checked={layer2}
              onChange={(e) => setLayer2(e.target.checked)}
              label="Withdraw from Layer2"
            />
            <Box
              sx={{
                my: 3
              }}
            >
              <GasLimit
                gasLimit={gasLimit}
                onSetGasLimit={(v) => setGasLimit(v)}
                gasParams={{
                  type: 'sell',
                  address: wallet.address,
                  ledger: layer2 ? 2 : 1,
                  amount
                }}
                minGas={minGas}
              />
            </Box>
            <SatoshiFee
              gasLimit={satoshiGasFee}
              onSetGasLimit={(v) => {
                setSatoshiGasFee(v);
                if (amount < v + 1000) setAmount(v + 1000);
              }}
              btcAddress={address}
              amount={amount}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={handleClose}
          sx={{
            mx: 2,
            my: 1
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          startIcon={isSubmitting ? <CircularProgress size={14} /> : null}
          disabled={isSubmitting || addressError || amountError || gasError}
          sx={{
            mx: 2,
            my: 1
          }}
        >
          Sell
        </Button>
      </DialogActions>
    </BottomPopupDialog>
  );
};

SellMERcDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default SellMERcDialog;
