import { chatApi } from 'src/apis/chatApi';
import { clearJobs } from './intervalJobs';
import { wsDisconnect, wsTeamDisconnect } from 'src/apis/wsApi';

const cleanUp = (): void => {
  chatApi.cleanUp();
  clearJobs();
  wsDisconnect();
  wsTeamDisconnect();
};

export default cleanUp;
