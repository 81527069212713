import store from 'src/store';
import { UserMinInfo } from 'src/../../Common/Model/common';
import { orgToMiniUser, userToMiniUser } from './getUserMinInfo';
import { ACCZIOM_CLIENT, ACCZIOM_MEMBER, ACCZIOM_NONE, ACCZIOM_ORG, ACCZIOM_TEAM, ACCZIOM_USER, getRoleInfo, INDIVIDUAL_TYPES, ORG_TYPES, STATUS_ACTIVATED } from 'src/globals';
import { Organization, OrganizationExt } from 'src/../../Common/Model/organization';
import { deepCopy } from './copyObject';
import getUserDisplayName from './getUserDisplayName';
import { AnzicCategory } from 'src/../../Common/Model/taxonomy';

export const getActiveOrgInfo = (): OrganizationExt => {
  const { activeOrgId, orgInfos } = store.getState().organization;
  if (!activeOrgId) return null;
  const activeOrg = orgInfos.find((v) => v.organizationId === activeOrgId);
  return activeOrg;
};

export const getActiveOrgMinInfo = (): UserMinInfo => {
  const activeOrgInfo = getActiveOrgInfo();
  if (!activeOrgInfo) return null;
  return orgToMiniUser(activeOrgInfo);
};

export const getActiveUserMinInfo = (): UserMinInfo => {
  const { userInfo } = store.getState().user;
  const { activeOrgId, memberInfos } = store.getState().organization;
  if (!userInfo) return null;
  if (!activeOrgId) return null;
  const activeMember = memberInfos.find((mi) => mi.oid === activeOrgId);
  const initialized = userToMiniUser(userInfo);
  return {
    ...initialized,
    allIds: [...initialized.allIds, { id: activeMember.mid, type: activeMember.type }]
  } as UserMinInfo;
};

export const isOwner = (): boolean => {
  const { userInfo } = store.getState().user;
  const { activeOrgId, orgInfos } = store.getState().organization;
  if (!userInfo) return false;
  if (!activeOrgId) return false;
  const activeOrg = orgInfos.find((orgInfo) => orgInfo.organizationId === activeOrgId);
  return activeOrg.ownerId === userInfo.uid;
};

export const getActiveIdByType = (type: number): string => {
  const { activeOrgId, memberInfos } = store.getState().organization;
  if (!activeOrgId) return '';
  const activeMember = memberInfos.find((mi) => mi.oid === activeOrgId);
  if (INDIVIDUAL_TYPES.includes(type)) return activeMember?.mid;
  if (ORG_TYPES.includes(type)) return activeOrgId;
  return '';
};

export const getActiveId = (clientMode: number, memberMode: number): string => {
  const { userInfo } = store.getState().user;
  const { activeOrgId, activeTeamId, memberInfos } = store.getState().organization;
  if (!userInfo || !activeOrgId) return '';
  const activeMember = memberInfos.find((mi) => mi.oid === activeOrgId);
  if (activeMember?.type === ACCZIOM_CLIENT) {
    if (clientMode === ACCZIOM_CLIENT) return activeMember.mid;
    if (clientMode === ACCZIOM_USER) return userInfo.uid;
  } else if (activeMember?.type === ACCZIOM_MEMBER) {
    if (memberMode === ACCZIOM_ORG) return activeOrgId;
    if (memberMode === ACCZIOM_TEAM) return activeTeamId;
    if (memberMode === ACCZIOM_MEMBER) return activeMember.mid;
  }
  return '';
};

export const getActiveType = (clientMode: number, memberMode: number): number => {
  const { activeOrgId, memberInfos } = store.getState().organization;
  if (!activeOrgId) return ACCZIOM_NONE;
  const activeMember = memberInfos.find((mi) => mi.oid === activeOrgId);
  if (activeMember?.type === ACCZIOM_CLIENT) {
    if (clientMode === ACCZIOM_CLIENT) return ACCZIOM_CLIENT;
    if (clientMode === ACCZIOM_USER) return ACCZIOM_USER;
  } else if (activeMember?.type === ACCZIOM_MEMBER) {
    if (memberMode === ACCZIOM_ORG) return ACCZIOM_ORG;
    if (memberMode === ACCZIOM_TEAM) return ACCZIOM_TEAM;
    if (memberMode === ACCZIOM_MEMBER) return ACCZIOM_MEMBER;
  }
  return ACCZIOM_NONE;
};

export const getActiveInfo = (clientMode: number, memberMode: number): { id: string, type: number } => ({
  id: getActiveId(clientMode, memberMode),
  type: getActiveType(clientMode, memberMode)
});

export const getActiveMemberItemsPinned = (): string[] => {
  const { activeOrgId, memberInfos } = store.getState().organization;
  if (!activeOrgId) return [];
  const activeMember = memberInfos.find((mi) => mi.oid === activeOrgId);
  return activeMember?.itemsPinned;
};

export const getActiveMinInfo = (): UserMinInfo => {
  const { activeOrgId, memberInfos } = store.getState().organization;
  if (!activeOrgId) return null;
  const activeMember = memberInfos.find((mi) => mi.oid === activeOrgId);
  if (activeMember?.type === ACCZIOM_CLIENT) return getActiveUserMinInfo();
  return getActiveOrgMinInfo();
};

export const getActiveRole = (): any => {
  const { activeOrgId, memberInfos, orgInfos, roleInfos } = store.getState().organization;
  const activeMember = memberInfos.find((mi) => mi.oid === activeOrgId);
  if (activeMember?.type === ACCZIOM_CLIENT) return getRoleInfo('clientDefault');
  const activeRole = roleInfos.find((roleInfo) => roleInfo.rid === activeMember?.rid);
  if (!activeRole) return null;
  const activeOrg = orgInfos.find((v) => v.organizationId === activeOrgId);
  const enabledRole = getRoleInfo('memberEnabled');
  const clientOrgRole = getRoleInfo('clientOrgDefault');
  const memberRole = deepCopy(activeRole);
  Object.keys(activeRole).forEach((firstKey) => {
    if (['department', 'assignment', 'production', 'transaction'].includes(firstKey)) {
      Object.keys(activeRole[firstKey]).forEach((secondKey) => {
        Object.keys(activeRole[firstKey][secondKey]).forEach((thirdKey) => {
          if (activeOrg?.suppliers?.length > 0) {
            memberRole[firstKey][secondKey][thirdKey] = activeRole[firstKey][secondKey][thirdKey] && clientOrgRole[firstKey][secondKey][thirdKey];
          } else {
            memberRole[firstKey][secondKey][thirdKey] = activeRole[firstKey][secondKey][thirdKey] && enabledRole[firstKey][secondKey][thirdKey];
          }
        });
      });
    }
  });
  return memberRole;
};

export const getActiveCategory = (): AnzicCategory => {
  const { activeOrgId, memberInfos, orgInfos, categoryInfos } = store.getState().organization;
  const activeOrg = orgInfos.find((v) => v.organizationId === activeOrgId);
  if (activeOrg) {
    const activeMember = memberInfos.find((mi) => mi.oid === activeOrgId);
    if (activeMember?.type === ACCZIOM_MEMBER) return categoryInfos.find((cat) => `${cat.code}` === `${activeOrg.anzicCategory}`);
  }
  return null;
};

export const getActiveSuppliers = (): Organization[] => {
  const { activeOrgId, memberInfos, orgInfos } = store.getState().organization;
  if (!activeOrgId) return [];
  const activeMember = memberInfos.find((mi) => mi.oid === activeOrgId);
  const activeOrg = orgInfos.find((v) => v.organizationId === activeOrgId);
  if (!activeMember || !activeOrg) return [];
  return activeMember.type === ACCZIOM_MEMBER ? activeOrg.suppliers : [activeOrg as Organization];
};

export const getActiveClients = (): any[] => {
  const { activeOrgId, membersInCurOrg } = store.getState().organization;
  if (!activeOrgId) return [];
  return membersInCurOrg.filter((member) => {
    if (member.status !== STATUS_ACTIVATED) return false;
    if (member.type === ACCZIOM_CLIENT) return true;
    if (member.type === ACCZIOM_MEMBER) return false;
    return member.oid === activeOrgId;
  }).map(({ mid, type }) => ({ id: mid, type }));
};

export const getUserMinInfoByID = (info: { id: string; type: number; }): UserMinInfo => {
  const { userMinInfos } = store.getState().organization;
  const candidates = [...userMinInfos];
  if (INDIVIDUAL_TYPES.includes(info.type)) {
    const activeUserMinInfo = getActiveUserMinInfo();
    if (activeUserMinInfo) candidates.push(activeUserMinInfo);
  } else if (ORG_TYPES.includes(info.type)) {
    const activeOrgMinInfo = getActiveOrgMinInfo();
    if (activeOrgMinInfo) candidates.push(activeOrgMinInfo);
  }
  return candidates.find((miniUser) => miniUser.allIds.some(({ id, type }) => id === info.id && type === info.type));
};

export const getActiveDisplayName = (): string => (getUserDisplayName(getActiveMinInfo()));
