import {
  ACCZIOM_NONE,
  TRADE_CONTRACT,
  NONE_TYPE_ITEM,
  STATUS_NORMAL,
  NOT_REQUIRED_PO,
  REQUIRED_PO,
  COMPOUND_INTEREST_TYPE,
  FROM_NONE,
  FROM_PROPOSAL,
  FROM_REQUEST,
  UPFRONT_PAYMENT,
  REQUEST_ONE_OFF,
  LENGTH_YEAR,
  LENGTH_MONTH,
  SCHEDULE_OFF,
  CONTRACT_MODE,
  DELIVERY_MODE,
  DAY_MILISECONDS,
  PAY_CREDIT_CARD,
  PAY_STRIPE,
  STATUS_TEXT_SUBMITTED,
  LENGTH_DAY,
  STATUS_TEXT_POSTED,
  MICROSERVICE_BUNDLE
} from 'src/globals';
import { Contract, Proposal, DeliveryContract, DeliveryItem, PaymentContract, PaymentItem, InterestOption, TaxOption, Invoice } from 'src/../../Common/Model/procurement';
import { Request } from 'src/../../Common/Model/request';
import { v4 as uuidv4 } from 'uuid';
import { MdInfoOutline, MdLocalShipping } from 'react-icons/md';
import { RiMoneyDollarBoxLine } from 'react-icons/ri';
import { getFormatedPeriodInfo, getPeriodicalDatesByCount, getTimeMovedByDuration } from 'src/utils/getPeriodOffsetDays';
import { PurchaseItem, DeliveredItem } from 'src/../../Common/Model/purchaseItem';
import { getSchedulesByGenerator } from './scheduleUtils';
import { lambdaGetSignature, lambdaGetWalletAddress } from 'src/aws/lambdaDispatch';
import { convert2Array } from '../agentUtils';

const emptyInterest = {
  rate: 0,
  period: LENGTH_YEAR,
  type: COMPOUND_INTEREST_TYPE
} as InterestOption;

const tt = new Date();
const emptyContract: Contract = {
  id: '',
  generator: FROM_NONE,
  material: '',
  description: '',
  validFrom: new Date(Math.floor(tt.getTime() / 1000) * 1000).toISOString(),
  validTo: new Date(Math.floor(tt.getTime() / 1000) * 1000).toISOString(),
  itemBriefs: {
    id: '',
    type: NONE_TYPE_ITEM,
    title: '',
    description: '',
    category: '',
    costDescription: '',
    payments: {}
  },
  delivery: [{
    itemId: '',
    itemTitle: '',
    itemDescription: '',
    itemLife: { length: 1, type: LENGTH_MONTH },
    itemStatus: STATUS_NORMAL,
    itemType: NONE_TYPE_ITEM,
    itemCollected: {},
    itemCategory: '',
    itemPeriod: { type: REQUEST_ONE_OFF, offset: 0, offsetType: LENGTH_DAY },
    requirePO: NOT_REQUIRED_PO,
    volumes: ['1'],
    prices: ['1']
  } as DeliveryContract],
  payment: [{
    costPrincipal: 1,
    costInterest: emptyInterest,
    costTax: {
      gstRate: 10
    } as TaxOption,
    costOther: [],
    paymentOption: UPFRONT_PAYMENT,
    paymentInitialAmount: 100,
    paymentInitialOffset: 0,
    paymentFinalPeriod: 1,
    paymentFinalOffset: 0,
    paymentInvoiceOffset: 1,
    paymentInterestLate: emptyInterest,
    paymentMethod: PAY_CREDIT_CARD,
    paymentTool: PAY_STRIPE,
    paymentCurrency: 'AUD'
  } as PaymentContract],
  customer: { id: '', type: ACCZIOM_NONE },
  customerAgent: [{ id: '', type: ACCZIOM_NONE }],
  customer_sign: '',
  customerSignedAt: '',
  supplier: { id: '', type: ACCZIOM_NONE },
  supplierAgent: [{ id: '', type: ACCZIOM_NONE }],
  supplier_sign: '',
  supplierSignedAt: '',
  status: STATUS_TEXT_SUBMITTED,
  type: TRADE_CONTRACT,
  publishStatus: '',
  chatId: '',
  scheduled: SCHEDULE_OFF,
  txHash: ''
};

export const getInitializedContract = (fromProposal: Proposal, fromRequest: Request, fiatCurrency?: string): Contract => {
  if (fromProposal) {
    const initializedContract: Contract = {
      ...emptyContract,
      generator: FROM_PROPOSAL,
      material: fromProposal.material,
      description: fromProposal.description,
      delivery: [{
        ...emptyContract.delivery[0],
        itemTitle: fromProposal.material,
        itemDescription: fromProposal.description,
        requirePO: REQUIRED_PO,
        volumes: fromProposal.volumes,
        prices: fromProposal.prices
      } as DeliveryContract],
      customer: fromProposal.customer,
      customerAgent: convert2Array(fromProposal.customerAgent),
      supplier: fromProposal.supplier,
      supplierAgent: convert2Array(fromProposal.supplierAgent),
      chatId: fromProposal.chatId
    };
    return initializedContract;
  }
  if (fromRequest) {
    const { title: reqTitle, detail, demander, demanderAgent, supplier, supplierAgent, conversation, reqType, material, priceDescription } = fromRequest;
    const deliveryContracts: DeliveryContract[] = [];
    const paymentContracts: PaymentContract[] = [];
    const { cost: totalCost, payments, period, duration } = material;
    const { paymentOption, interest, gst, invoiceOffset, paymentMethod, paymentTool, paymentCurrency, modifications } = payments;
    const { type: payType, initialAmount, initialOffset, finalPeriod, finalOffset } = paymentOption;
    let gstRate = 0;
    if (gst) gstRate = gst.rate;
    paymentContracts.push({
      ...emptyContract.payment[0],
      costPrincipal: totalCost,
      costInterest: emptyInterest,
      costTax: {
        gstRate
      } as TaxOption,
      costOther: modifications,
      paymentOption: payType,
      paymentInitialAmount: initialAmount,
      paymentInitialOffset: initialOffset,
      paymentFinalPeriod: finalPeriod,
      paymentFinalOffset: finalOffset,
      paymentInvoiceOffset: !invoiceOffset ? 0 : invoiceOffset,
      paymentInterestLate: interest ? ({
        rate: interest.rate,
        period: interest.rateType,
        type: interest.type
      } as InterestOption) : emptyInterest,
      paymentMethod,
      paymentTool,
      paymentCurrency
    } as PaymentContract);
    detail.forEach((item) => {
      const {
        id: itemId,
        title: itemTitle,
        description: itemDescription,
        duration: itemLife,
        type: itemType,
        collected: itemCollected,
        quantity: itemQuantity,
        cost: itemCost,
        period: itemPeriod,
        category: itemCategory
      } = item;
      deliveryContracts.push({
        ...emptyContract.delivery[0],
        itemId,
        itemTitle,
        itemDescription,
        itemLife,
        itemType,
        itemCollected,
        itemCategory,
        itemPeriod,
        requirePO: NOT_REQUIRED_PO,
        volumes: [`${itemQuantity}`],
        prices: [`${itemCost}`]
      } as DeliveryContract);
    });
    const { startDate: validFrom, endDate: validTo } = getFormatedPeriodInfo(period, duration);
    let itemTitles = '';
    if (reqType === MICROSERVICE_BUNDLE) {
      itemTitles = `<br />${itemTitles}The bundle consists of the following services:<br />`;
      itemTitles = `${itemTitles}<ul><li>${detail.map((item) => item.title).join('</li><li>')}</li></ul>`;
    }
    const initializedContract = {
      ...emptyContract,
      generator: FROM_REQUEST,
      material: reqTitle,
      description: `${material.description}${itemTitles}`,
      validFrom,
      validTo,
      itemBriefs: {
        id: material.id,
        cost: material.cost,
        title: material.title,
        period: material.period,
        category: material.category,
        duration: material.duration,
        payments: material.payments,
        quantity: material.quantity,
        description: material.description,
        costDescription: priceDescription,
        type: reqType
      },
      delivery: deliveryContracts,
      payment: paymentContracts,
      customer: demander,
      customerAgent: convert2Array(demanderAgent),
      supplier,
      supplierAgent: convert2Array(supplierAgent),
      chatId: conversation
    };
    return initializedContract;
  }
  return {
    ...emptyContract,
    payment: [{
      ...emptyContract.payment[0],
      paymentCurrency: fiatCurrency
    } as PaymentContract]
  };
};

export const CREATE_CONTRACT_STEPS = [
  {
    label: 'Main Information',
    Icon: MdInfoOutline
  },
  {
    label: 'Delivery Contract',
    Icon: MdLocalShipping
  },
  {
    label: 'Payment Contract',
    Icon: RiMoneyDollarBoxLine
  }
];

export const CONTRACT_INFO_STEP = 0;
export const DELIVERY_CONTRACT_STEP = 1;
export const PAYMENT_CONTRACT_STEP = 2;

export const createPurchaseItemAfterContractSign = (signedBody: Contract): PurchaseItem => {
  const { itemBriefs, validFrom, validTo } = signedBody;
  const { period, duration } = itemBriefs;
  const bundlePeriodicalDates: { startDate: Date | string, endDate: Date | string }[] = [];
  if (period.type === REQUEST_ONE_OFF) {
    bundlePeriodicalDates.push({
      startDate: validFrom,
      endDate: validTo
    });
  } else {
    const bundleStartDates = getPeriodicalDatesByCount(validFrom, validTo, period.per, period.count);
    bundleStartDates.forEach((startDate) => {
      bundlePeriodicalDates.push({
        startDate,
        endDate: getTimeMovedByDuration(new Date(startDate), duration).toISOString()
      });
    });
  }
  const purchaseItem: PurchaseItem = {
    id: uuidv4(),
    contractId: signedBody.id,
    itemId: signedBody.itemBriefs?.id,
    itemType: signedBody.itemBriefs?.type,
    itemTitle: signedBody.itemBriefs?.title,
    itemDescription: signedBody.itemBriefs?.description,
    itemCategory: signedBody.itemBriefs?.category,
    delivery: signedBody.delivery.filter((deliveryContract) => deliveryContract.requirePO !== REQUIRED_PO).map((deliveryContract) => {
      const deliveryDueDates = [];
      const { itemPeriod } = deliveryContract;
      bundlePeriodicalDates.forEach(({ startDate, endDate }) => {
        const itemStartDate = getTimeMovedByDuration(new Date(startDate), { length: itemPeriod.offset, type: itemPeriod.offsetType }).toISOString();
        if (itemPeriod.type === REQUEST_ONE_OFF) {
          deliveryDueDates.push(itemStartDate);
        } else {
          deliveryDueDates.push(...getPeriodicalDatesByCount(itemStartDate, endDate, itemPeriod.per, itemPeriod.count));
        }
      });
      return ({
        id: deliveryContract.itemId,
        title: deliveryContract.itemTitle,
        description: deliveryContract.itemDescription,
        life: deliveryContract.itemLife,
        status: deliveryContract.itemStatus,
        type: deliveryContract.itemType,
        collected: deliveryContract.itemCollected,
        quantity: Array(deliveryDueDates.length).fill((parseFloat(deliveryContract.volumes[0]) * bundlePeriodicalDates.length) / deliveryDueDates.length),
        miniInvoices: [],
        dueDates: deliveryDueDates
      } as DeliveredItem);
    }),
    supplier: signedBody.supplier,
    supplierAgent: convert2Array(signedBody.supplierAgent),
    customer: signedBody.customer,
    customerAgent: convert2Array(signedBody.customerAgent),
    validFrom: signedBody.validFrom,
    validTo: signedBody.validTo
  };
  return purchaseItem;
};

export const handleGenerateInvoices = async (fromContract: Contract): Promise<Invoice[]> => {
  const supplierWalletAddr = await lambdaGetWalletAddress(fromContract.supplier);
  const { deliverySchedules, paymentSchedules } = getSchedulesByGenerator(CONTRACT_MODE, fromContract);
  const schedules = [...deliverySchedules, ...paymentSchedules];
  const invoices = [];
  const promises = [];
  const now = new Date().toISOString();
  schedules.forEach((schedule) => {
    const metadataInvoice = {
      contractId: fromContract.id,
      poId: '',
      scheduleId: '',
      title: schedule.type === DELIVERY_MODE ? (schedule.material as DeliveryItem).title : fromContract.material,
      description: schedule.type === DELIVERY_MODE ? (schedule.material as DeliveryItem).description : fromContract.description,
      dueDate: schedule.type === DELIVERY_MODE ? schedule.dueDate : new Date(new Date(schedule.dueDate).getTime() + (schedule.material as PaymentItem).invoiceOffset * DAY_MILISECONDS).toISOString(),
      invoiceDate: schedule.dueDate,
      material: schedule.material,
      customer: schedule.customer,
      supplier: schedule.supplier,
      type: schedule.type
    };
    if (schedule.type === DELIVERY_MODE) {
      const messageInvoice = JSON.stringify(metadataInvoice);
      promises.push(lambdaGetSignature(messageInvoice, supplierWalletAddr)
        .then((invoiceSupplierSignature) => {
          invoices.push({
            ...metadataInvoice,
            id: uuidv4(),
            customerAgent: convert2Array(schedule.customerAgent),
            customer_sign: '',
            customerSignedAt: null,
            supplierAgent: convert2Array(schedule.supplierAgent),
            supplier_sign: invoiceSupplierSignature,
            supplierSignedAt: now,
            status: STATUS_TEXT_POSTED,
            scheduled: fromContract.scheduled
          } as Invoice);
        }));
    } else {
      invoices.push({
        ...metadataInvoice,
        id: uuidv4(),
        customerAgent: convert2Array(schedule.customerAgent),
        customer_sign: '',
        customerSignedAt: null,
        supplierAgent: convert2Array(schedule.supplierAgent),
        supplier_sign: '',
        supplierSignedAt: null,
        status: STATUS_TEXT_POSTED,
        scheduled: fromContract.scheduled
      } as Invoice);
    }
  });
  await Promise.all(promises);
  return invoices;
};

export const handleGenerateInvoicesWithHash = (fromContract: Contract): Invoice[] => {
  const { deliverySchedules, paymentSchedules, referralSchedules } = getSchedulesByGenerator(CONTRACT_MODE, fromContract);
  const schedules = [...deliverySchedules, ...paymentSchedules, ...referralSchedules];
  const invoices = [];
  const now = new Date().toISOString();
  schedules.forEach((schedule) => {
    const metadataInvoice = {
      contractId: fromContract.id,
      poId: '',
      scheduleId: '',
      title: schedule.type === DELIVERY_MODE ? (schedule.material as DeliveryItem).title : fromContract.material,
      description: schedule.type === DELIVERY_MODE ? (schedule.material as DeliveryItem).description : fromContract.description,
      dueDate: schedule.type === DELIVERY_MODE ? schedule.dueDate : new Date(new Date(schedule.dueDate).getTime() + (schedule.material as PaymentItem).invoiceOffset * DAY_MILISECONDS).toISOString(),
      invoiceDate: schedule.dueDate,
      material: schedule.material,
      customer: schedule.customer,
      supplier: schedule.supplier,
      type: schedule.type
    };
    if (schedule.type === DELIVERY_MODE) {
      const messageInvoice = JSON.stringify(metadataInvoice);
      invoices.push({
        ...metadataInvoice,
        id: uuidv4(),
        customerAgent: convert2Array(schedule.customerAgent),
        customer_sign: '',
        customerSignedAt: null,
        supplierAgent: convert2Array(schedule.supplierAgent),
        supplier_sign: messageInvoice,
        supplierSignedAt: now,
        status: STATUS_TEXT_POSTED,
        scheduled: fromContract.scheduled
      } as Invoice);
    } else {
      invoices.push({
        ...metadataInvoice,
        id: uuidv4(),
        customerAgent: convert2Array(schedule.customerAgent),
        customer_sign: '',
        customerSignedAt: null,
        supplierAgent: convert2Array(schedule.supplierAgent),
        supplier_sign: '',
        supplierSignedAt: null,
        status: STATUS_TEXT_POSTED,
        scheduled: fromContract.scheduled
      } as Invoice);
    }
  });
  return invoices;
};
