import { useState, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, ThemeProvider, Box } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { AppBarProps } from '@material-ui/core';
import TopbarItem from './TopbarItem';
import MessagingIcon from 'src/icons/Messaging';
import { useSelector } from 'src/store';
import { AccountCircle, Book, Home, Menu, Work } from '@material-ui/icons';
import { useLocation, matchPath } from 'react-router-dom';
import useMounted from 'src/hooks/useMounted';
import useAuth from 'src/hooks/useAuth';
import { getNavbarApprisingCount } from 'src/utils/getApprisingCount';
import { createCustomTheme } from 'src/theme';
import useSettings from 'src/hooks/useSettings';
import BusinessSearch from 'src/icons/BusinessSearch';
import getUserDisplayName from 'src/utils/getUserDisplayName';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { getPossibleHrefs } from 'src/utils/filterUrlsByRole';
import { ACCZIOM_MEMBER } from 'src/globals';

export interface Item {
  href?: string;
  popover?: any;
  icon?: ReactNode | string;
  title: string;
  children?: any[];
  authRequired?: boolean;
  info?: ReactNode;
}

export const DashboardBottombarItems = [
  {
    title: 'Deals',
    href: '/deal',
    icon: ConfirmationNumberIcon,
    authRequired: true,
  },
  {
    title: 'Chat',
    href: '/communication/external',
    icon: MessagingIcon,
    authRequired: true,
  },
  {
    title: 'Community',
    href: 'https://community.m.accziom.com',
    onClick: null,
    icon: Book,
    authRequired: true,
  }
];

export const HomeMenuItem : Item = {
  title: 'Home',
  href: '/home',
  icon: Home,
  authRequired: false,
};
export const MoreMenuItem : Item = {
  title: 'More',
  href: '#',
  icon: Menu,
  authRequired: true,
};
export const MeMenuItem: Item = {
  title: 'Me',
  href: '/configuration',
  authRequired: true,
  icon: AccountCircle,
};
export const MicroserviceItem: Item = {
  title: 'Products',
  href: '/marketplace',
  authRequired: false,
  icon: Work,
};
export const MessagingItem: Item = {
  title: 'Chat',
  href: '/communication/external',
  icon: MessagingIcon,
  authRequired: true,
};
export const BSearchItem: Item = {
  title: 'BSearch',
  href: '/bs',
  authRequired: true,
  icon: BusinessSearch,
};
export const AccziomMenu : Item[] = [
  // {
  //   title: 'Structure',
  //   href: '/management/structure',
  //   authRequired: true,
  //   icon: AccountTree,
  // },
  {
    title: 'BSearch',
    href: '/bs',
    authRequired: true,
    icon: BusinessSearch,
  },
  // {
  //   title: 'Services',
  //   href: '/management/microservice',
  //   authRequired: true,
  //   icon: Apps,
  // },
];

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
  trigger?: boolean;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: 'rgb(32, 41, 55)'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
);

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, trigger, ...other } = props;
  const [value, setValue] = useState(-1);
  const { userInfo } = useSelector((state) => state.user);
  const [unreadThreadCount, setUnreadThreadCount] = useState<number>(0);
  const { pathname } = useLocation();
  const mounted = useMounted();
  const auth = useAuth();
  const { convs } = useSelector((state) => state.chat);
  const { apprisings } = useSelector((state) => state.apprising);
  const [items, setItems] = useState<Item[]>([]);
  const { activeOrgId, memberInfos } = useSelector((state) => state.organization);
  const activeMember = memberInfos.find((mi) => mi.oid === activeOrgId);

  const handleChange = (newValue: number) => {
    if (value === -2) setValue(newValue);
  };

  const setTopbarItems = () => {
    if (!mounted.current) return;
    const hrefs = getPossibleHrefs();
    const mm : Item[] = [];
    mm.push(HomeMenuItem);
    DashboardBottombarItems.forEach((item) => {
      if (item.title === 'Community') mm.push(item);
      else if (hrefs.filter((href) => href.startsWith(item.href)).length > 0) mm.push(item);
    });
    // if (activeMember.type === ACCZIOM_CLIENT) {
    //   mm.splice(2, 1);
    // }
    const meItem = MeMenuItem;
    if (userInfo?.avatar) meItem.icon = userInfo.avatar;
    else if (userInfo) meItem.icon = getUserDisplayName(userInfo);
    if (auth.isAuthenticated) {
      meItem.title = 'Me';
    } else {
      meItem.title = 'Sign in';
      meItem.icon = AccountCircle;
    }
    if (activeMember.type === ACCZIOM_MEMBER) {
      const dealItem = mm.find((item) => item.title === 'Deals');
      if (dealItem) dealItem.href = '/deal/sale';
    } else {
      const dealItem = mm.find((item) => item.title === 'Deals');
      if (dealItem) dealItem.href = '/deal/purchase';
    }
    mm.push(meItem);
    setItems(mm);
  };

  useEffect(() => {
    setUnreadThreadCount(convs.filter((_conv) => _conv.unreadCount > 0).length);
  }, [convs]);

  useEffect(() => {
    if (activeOrgId) {
      setTopbarItems();
    }
  }, [activeOrgId]);

  function isSamePath(href): boolean {
    if (href === '/home' && pathname === '/') return true;
    return !!matchPath({
      path: href,
      caseSensitive: false,
      end: false
    }, pathname);
  }

  const { settings } = useSettings();
  const themeSearch = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'CUSTOM'
  });
  const themeMobile = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'TOPBARMOBILE'
  });

  const isApple = /webOS|iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);

  return (
    <ThemeProvider theme={themeMobile}>
      <DashboardNavbarRoot
        sx={{
          top: 'auto',
          bottom: 0,
        }}
        {...other}
      >
        <Toolbar
          sx={{
            minHeight: isApple && trigger ? '72px' : '48px',
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
            px: 1
          }}
        >
          <ThemeProvider theme={themeSearch}>
            {
              items.map((item, i) => (
                <Box
                  key={item.title}
                  sx={{
                    width: `calc(100% / ${items.length})`,
                    flexDirection: 'row'
                  }}
                >
                  <TopbarItem
                    index={i}
                    icon={item.icon}
                    href={item.href}
                    title={item.title}
                    value={item.title === 'Chat' ? unreadThreadCount : getNavbarApprisingCount(apprisings, item.href)}
                    onClick={handleChange}
                    selected={isSamePath(item.href)} // value === i
                    enable={!item.authRequired || auth.isAuthenticated}
                  />
                </Box>
              ))
            }
          </ThemeProvider>
        </Toolbar>
      </DashboardNavbarRoot>
    </ThemeProvider>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
  trigger: PropTypes.bool
};

DashboardNavbar.defaultProps = {
  trigger: false
};

export default DashboardNavbar;
