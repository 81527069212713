import { FC } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import './loading.css';

const LoadingItem: FC<{
  p?: number;
  size?: number;
  height?: number;
}> = ({
  p,
  size,
  height
}) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    p={!p ? 3 : p}
    width="100%"
    {...(height ? { height } : {})}
    sx={{ position: 'relative' }}
  >
    <CircularProgress size={!size ? 30 : size} />
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <img
        alt="Logo"
        src="/favicon.svg"
        style={{
          width: `${(!size ? 30 : size) / 2}px`,
        }}
        className="loading-logo"
      />
    </Box>
  </Box>
);

LoadingItem.propTypes = {
  p: PropTypes.number,
  size: PropTypes.number,
  height: PropTypes.number
};

export default LoadingItem;
