import { chatApi } from 'src/apis/chatApi';
import { contactApi } from 'src/apis/contactApi';
import { lambdaFirstLoadWallet, lambdaGetOrgBannerUrl, lambdaCreateRequest, lambdaGetFeedback, lambdaInitialLoad, lambdaGetTemplateList, lambdaGetOrgStructure } from 'src/aws/lambdaDispatch';
import { cleanUpChat, setMessages } from 'src/slices/chat';
import { clearContactState, setConnectStateNeedReload, setContacts, setContactsNeedReload, setSyncState, setSyncStateNeedReload } from 'src/slices/contact';
import { clearUserState, LoadingStatus, setLoadingStatus, setMemberNeedReload, setRegisteredVerificationInfo, setRequestedLocation, setUserInfo, setVerificationStatus } from 'src/slices/user';
import { Thread } from 'src/types/chat';
import { clearWalletState, setWalletInfo } from 'src/slices/wallet';
import { setCurOrgNeedReload, setOrgStructures, setActiveOrgId, setActiveTeamId, cleanUserMinInfos, setBannerUrl, clearOrganizationState, setCurOrgStructure } from 'src/slices/organization';
import intervalJobs from 'src/utils/intervalJobs';
import { clearApprisingState, loadApprising } from 'src/slices/apprising';
import { ACCZIOM_CLIENT, ACCZIOM_MEMBER, ACCZIOM_NONE, ACCZIOM_ORG, ACCZIOM_TEAM, ACCZIOM_USER, CHECK_INTERVAL_MINUTES, MICROSERVICE_BUNDLE, TICKET_MODE_NORMAL, VERIFY_COMPLETED } from 'src/globals';
import { fetchUrl } from 'src/utils/fileUpDownload';
import { setNeedReload as setAssetNeedReload, clearAssetState } from 'src/slices/asset';
import { setNeedReload as setPurchaseNeedReload, clearPurchaseItemState } from 'src/slices/business';
import { setNeedReload as setFavoriteNeedReload, clearFavoriteState } from 'src/slices/favorite';
import { setNeedReload as setFileNeedReload, clearFileShareState } from 'src/slices/fileshare';
import { setNeedReload as setKanbanNeedReload, clearKanbanState } from 'src/slices/kanban';
import { setNeedReload as setMSTemplateNeedReload, clearMsTemplateState } from 'src/slices/msTemplate';
import { setInvitationNeedReload, setReferralNeedReload, clearNetworkState } from 'src/slices/network';
import { setNeedReload as setPayAccountNeedReload, clearPayAccountState } from 'src/slices/payAccount';
import { setNeedReload as setRequestNeedReload, clearRequestState } from 'src/slices/request';
import { clearShareState } from 'src/slices/share';
import { wsConnect, wsTeamConnect } from 'src/apis/wsApi';
import { clearServiceRequestState } from 'src/slices/serviceRequest';
import { clearProcurementState, setContractReload, setInvoiceReload, setPOReload, setRFQReload, setScheduleReload } from 'src/slices/procurement';
import { objToArray } from 'src/utils/copyObject';
import getUserDisplayName from 'src/utils/getUserDisplayName';
import gstore from 'src/store';
import { createDirectDraft } from 'src/components/request/Actions';
import toast from 'react-hot-toast';
import { getUserMinInfoByID } from 'src/utils/getActiveOrgInfo';
import { loadFeedback } from 'src/slices/feedback';
import { clearTaxonomyState, setAssetCategoryNeedReload, setChatCategoryNeedReload, setOrgCategoryNeedReload, setServiceCategoryNeedReload } from 'src/slices/taxonomy';
import { setTemplateList } from 'src/slices/template';

let isLoading: boolean = false;

export const initChatApi = (uid: string, dispatch): Promise<boolean> => {
  dispatch(cleanUpChat());
  chatApi.cleanUp();
  dispatch(cleanUserMinInfos());
  return new Promise((resolve) => {
    chatApi.initConnection(uid, dispatch)
      .then(() => {
        const threads: Thread[] = [];
        chatApi.getConversations(threads)
          .then((_threads) => {
            _threads.forEach((item) => {
              chatApi.getMessages(item.sid)
                .then((msgs) => {
                  if (msgs.length > 0) dispatch(setMessages(msgs));
                })
                .catch(() => {});
            });
            resolve(true);
          });
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const loadContact = async (uid: string, dispatch): Promise<void> => {
  try {
    console.log(uid);
    const cs = await contactApi.loadAllContacts(uid);
    const { QBO, XERO } = await contactApi.getSyncStatus(uid);
    dispatch(setContacts(cs));
    dispatch(setSyncState(QBO, XERO));
  } catch (e) {
    console.log('Loading contact : ', e);
  }
  return Promise.resolve();
};

export const loadWallet = async (walletId: any, dispatch): Promise<void> => {
  try {
    const w = await lambdaFirstLoadWallet(walletId);
    dispatch(setWalletInfo(w));
  } catch (e) {
    console.log('Loading wallet : ', JSON.stringify(e));
  }
  return Promise.resolve();
};

export const loadTemplateList = async (_uid: string, dispatch): Promise<void> => {
  try {
    const ts = await lambdaGetTemplateList(_uid);
    dispatch(setTemplateList(ts));
  } catch (e) {
    console.log('Loading Template list : ', e);
  }
  return Promise.resolve();
};

// export const loadApprising = async (email: string, dispatch): Promise<void> => {
//   try {
//     const apprisings = await lambdaGetApprising(email);
//     dispatch(getApprisings(apprisings));
//   } catch (e) {
//     console.log('Loading apprisings : ', JSON.stringify(e));
//   }
//   return Promise.resolve();
// };

export const initNeedReloads = (dispatch) => {
  dispatch(setCurOrgNeedReload(true));
  dispatch(setMemberNeedReload(true));
  dispatch(setOrgCategoryNeedReload(true));
  dispatch(setServiceCategoryNeedReload(true));
  dispatch(setAssetCategoryNeedReload(true));
  dispatch(setChatCategoryNeedReload(true));
  dispatch(setAssetNeedReload(true));
  dispatch(setPurchaseNeedReload(true));
  dispatch(setFavoriteNeedReload(true));
  dispatch(setFileNeedReload(true));
  dispatch(setContactsNeedReload(true));
  dispatch(setConnectStateNeedReload(true));
  dispatch(setSyncStateNeedReload(true));
  dispatch(setKanbanNeedReload(true));
  dispatch(setMSTemplateNeedReload(true));
  dispatch(setInvitationNeedReload(true));
  dispatch(setReferralNeedReload(true));
  dispatch(setPayAccountNeedReload(true));
  dispatch(setRequestNeedReload(true));
  dispatch(setRFQReload(true));
  dispatch(setContractReload(true));
  dispatch(setPOReload(true));
  dispatch(setScheduleReload(true));
  dispatch(setInvoiceReload(true));
};

export const initializeStates = (dispatch) => {
  dispatch(clearKanbanState());
  dispatch(clearContactState());
  dispatch(clearFavoriteState());
  dispatch(clearFileShareState());
  dispatch(clearNetworkState());
  dispatch(clearPayAccountState());
  dispatch(clearServiceRequestState());
  dispatch(clearMsTemplateState());
  dispatch(clearAssetState());
  dispatch(clearProcurementState());
  dispatch(clearPurchaseItemState());
  dispatch(clearShareState());
  dispatch(clearRequestState());
  dispatch(clearWalletState());
  dispatch(clearApprisingState());
  dispatch(clearTaxonomyState());
  dispatch(clearOrganizationState());
  dispatch(clearUserState());
};

const initialLoad = (id: string, checkLive, dispatch, logout, domainName: string, orgId?: string, teamId?: string): void => {
  if (isLoading) return;

  intervalJobs(CHECK_INTERVAL_MINUTES * 60 * 1000, checkLive, true);

  isLoading = true;
  dispatch(setLoadingStatus(LoadingStatus.LOADING));
  const orgDomainName = gstore.getState().user.domainName;
  const recoverUrl = gstore.getState().user.requestedLocation;
  let requestDraft = null;
  const loginAsClientToOrg = !!orgDomainName && !!recoverUrl;
  if (loginAsClientToOrg) {
    if (recoverUrl === '/deal/purchase') {
      const { service, bundledServices } = gstore.getState().serviceRequest;
      requestDraft = createDirectDraft(
        service,
        bundledServices,
        { id: '', type: ACCZIOM_NONE }, // set after org load
        { id: '', type: ACCZIOM_NONE }, // set after org load
        0, // set after org load
        getUserMinInfoByID(service.creator).signerCount,
        TICKET_MODE_NORMAL
      );
    }
  }
  initializeStates(dispatch);
  // console.log(id);
  lambdaInitialLoad(id)
  // lambdaInitialLoad('fcb2bd19-79da-49ab-97b8-098d8e6abfcb') // for Noble & Associates
  // lambdaInitialLoad('28322ca1-f94f-48b2-8268-a78149521218') // for James
  // lambdaGetUserInfo('469d19ff-2c34-4409-81b3-2feea660b7e7') // for LodgeiT
  // lambdaGetUserInfo('a2f14af2-0868-413f-a64d-001d45d68163') // for ac-accounting
  // lambdaGetUserInfo('0ea9e202-5490-44b3-a54e-e3a4cf6f2ca1')
  // lambdaGetUserInfo('080341aa-e7fc-4007-ab56-c51c9e665404')
    .then((res) => {
      const { userInfo: ui, verificationResult: vr, memberInfos: members, orgInfos: organizations, categoryInfos: categories, roleInfos: strRoles, teamInfos: teams } = res;
      if (ui) {
        dispatch(setUserInfo(ui));
        if (ui.isFirst) {
          dispatch(setLoadingStatus(LoadingStatus.COMPLETED));
          isLoading = false;
          return;
        }
        if (ui.identityVerified === 1 && vr) {
          if (vr.verificationResult.overallVerificationStatus.startsWith('VERIFIED')) {
            dispatch(setVerificationStatus(VERIFY_COMPLETED));
          } else {
            vr.verificationResult.individualResult = objToArray(vr.verificationResult.individualResult);
            dispatch(setRegisteredVerificationInfo(vr));
          }
        }
        const roles = [];
        strRoles.forEach((item) => {
          roles.push({
            rid: item.rid,
            oid: item.oid,
            name: item.name,
            level: item.level,
            department: JSON.parse(item.department),
            assignment: JSON.parse(item.assignment),
            production: JSON.parse(item.production),
            transaction: JSON.parse(item.transaction),
            createdAt: item.createdAt,
            updatedAt: item.updatedAt
          });
        });
        const memberInfos = [];
        const orgInfos = [];
        const categoryInfos = [];
        const roleInfos = [];
        const teamInfos = [];
        const teamIds = [];
        members.forEach((member) => {
          if (member.rid) {
            const role = roles.find((item) => item.rid === member.rid);
            const departmentValues = Object.values(role.department).map((obj) => Object.values(obj).includes(true));
            const assignmentValues = Object.values(role.assignment).map((obj) => Object.values(obj).includes(true));
            const teamCount = member.tids.length;
            if (departmentValues.includes(true) || assignmentValues.includes(true) || teamCount > 0) {
              memberInfos.push(member);
              const organization = organizations.find((item) => item.organizationId === member.oid);
              if (organization) orgInfos.push(organization);
              const category = categories.find((cat) => `${cat.code}` === `${organization.anzicCategory}`);
              if (category) categoryInfos.push(category);
              if (role) roleInfos.push(role);
              member.tids.forEach((tid) => {
                if (!teamIds.includes(tid)) {
                  teamIds.push(tid);
                  const team = teams.find((item) => item.tid === tid);
                  teamInfos.push(team);
                }
              });
            }
          } else {
            memberInfos.push(member);
            const organization = organizations.find((item) => item.organizationId === member.oid);
            if (organization) orgInfos.push(organization);
            const category = categories.find((cat) => `${cat.code}` === `${organization.anzicCategory}`);
            if (category) categoryInfos.push(category);
          }
        });
        memberInfos.sort((member0, member1) => { if (member0.type < member1.type) return 1; if (member0.type > member1.type) return -1; return 0; }); // sort by type, client first, member second
        dispatch(setOrgStructures(memberInfos, orgInfos, categoryInfos, roleInfos, teamInfos));
        let firstOrg = null;
        let firstMember = null;
        if (orgInfos.length > 0) {
          if (domainName) {
            firstOrg = orgInfos.find((organizationInfo) => organizationInfo.domainName === domainName);
            if (firstOrg) firstMember = memberInfos.find((member) => member.oid === firstOrg?.organizationId);
          } else {
            if (orgId) {
              firstOrg = orgInfos.find((organizationInfo) => organizationInfo.organizationId === orgId);
            } else {
              firstOrg = orgInfos.at(0);
            }
            firstMember = memberInfos.find((member) => member.oid === firstOrg?.organizationId);
          }
        }
        if (firstOrg && firstMember) {
          if (firstMember.type === ACCZIOM_MEMBER && loginAsClientToOrg) {
            console.log('User not client.');
            dispatch(setActiveOrgId(null));
            dispatch(setLoadingStatus(LoadingStatus.NONE));
            logout();
            toast.error('You are not a client of this organization.');
            isLoading = false;
          } else {
            // eslint-disable-next-line
            if (process.env.NODE_ENV === 'production' && (!domainName || domainName === orgDomainName)) {
              if (requestDraft) {
                requestDraft = {
                  ...requestDraft,
                  demander: {
                    id: firstMember.type === ACCZIOM_CLIENT ? firstMember.mid : firstOrg.organizationId,
                    type: firstMember.type === ACCZIOM_CLIENT ? ACCZIOM_CLIENT : ACCZIOM_ORG
                  },
                  demanderAgent: [{
                    id: firstMember.type === ACCZIOM_CLIENT ? firstMember.mid : '',
                    type: firstMember.type === ACCZIOM_CLIENT ? ACCZIOM_CLIENT : ACCZIOM_NONE
                  }],
                  demanderSignCount: firstMember.type === ACCZIOM_CLIENT ? 1 : firstOrg.signerCount
                };
                lambdaCreateRequest(requestDraft).then((createdReq) => {
                  window.location.href = `https://${firstOrg.domainName}.go.m.accziom.com/deal/purchase/${requestDraft.reqType === MICROSERVICE_BUNDLE ? 'service-bundle' : 'service'}/${createdReq.uid}`;
                });
              } else if (recoverUrl?.startsWith('/marketplace/asset')) {
                window.location.href = `https://${firstOrg.domainName}.go.m.accziom.com${recoverUrl}`;
              } else {
                window.location.href = `https://${firstOrg.domainName}.go.m.accziom.com`;
              }
            } else {
              wsConnect(firstMember.type === ACCZIOM_MEMBER ? ({
                id: firstOrg.organizationId,
                type: ACCZIOM_ORG,
                name: firstOrg.tradingName
              }) : ({
                id: firstMember.mid,
                type: ACCZIOM_CLIENT,
                name: getUserDisplayName(ui)
              }));
              const initWork = async (afterInit) => {
                initChatApi(firstMember.mid, dispatch);
                if (firstMember.type === ACCZIOM_MEMBER) loadTemplateList(firstOrg.organizationId, dispatch);
                loadWallet(firstMember.type === ACCZIOM_MEMBER ? { id: firstOrg.organizationId, type: ACCZIOM_ORG } : { id: ui.uid, type: ACCZIOM_USER }, dispatch);
                loadContact(firstMember.type === ACCZIOM_MEMBER ? firstOrg.organizationId : ui.uid, dispatch);
                if (firstMember.tids.length > 0) {
                  const firstTeamId = (firstMember.tids)[0];
                  const currentTeamId = firstMember.tids.find((t) => t === teamId) ?? firstTeamId;
                  dispatch(setActiveTeamId(currentTeamId));
                  loadApprising(firstOrg.organizationId, currentTeamId);
                  lambdaGetFeedback(firstOrg.organizationId).then((feedRes) => {
                    dispatch(loadFeedback(feedRes.feedback, feedRes.feedbackConnection));
                  });
                  wsTeamConnect({
                    id: currentTeamId,
                    type: ACCZIOM_TEAM,
                    name: currentTeamId
                  });
                } else {
                  dispatch(setActiveTeamId(null));
                  loadApprising(firstMember.type === ACCZIOM_MEMBER ? firstOrg.organizationId : firstMember.mid, null);
                  lambdaGetFeedback(firstMember.type === ACCZIOM_MEMBER ? firstOrg.organizationId : firstMember.mid).then((feedRes) => {
                    dispatch(loadFeedback(feedRes.feedback, feedRes.feedbackConnection));
                  });
                }
                if (firstOrg.banner) {
                  lambdaGetOrgBannerUrl(firstOrg.banner)
                    .then((bannerUrl) => {
                      fetchUrl(bannerUrl)
                        .then((localUrl) => {
                          dispatch(setBannerUrl(localUrl));
                        })
                        .catch((err) => { console.log(JSON.stringify(err)); });
                    })
                    .catch((err) => { console.log(JSON.stringify(err)); });
                }
                const curOrg = await lambdaGetOrgStructure(firstOrg.organizationId);
                const { members: curMembers, roles: curOrgRoles, teams: curTeams, userMinInfos: orgUserMinInfos } = curOrg;
                const curRoles = [];
                curOrgRoles.forEach((item) => {
                  curRoles.push({
                    rid: item.rid,
                    oid: item.oid,
                    name: item.name,
                    level: item.level,
                    department: JSON.parse(item.department),
                    assignment: JSON.parse(item.assignment),
                    production: JSON.parse(item.production),
                    transaction: JSON.parse(item.transaction),
                    createdAt: item.createdAt,
                    updatedAt: item.updatedAt
                  });
                });
                dispatch(setCurOrgStructure(curMembers, curRoles, curTeams, orgUserMinInfos));
                dispatch(setActiveOrgId(firstOrg.organizationId));
                dispatch(setLoadingStatus(LoadingStatus.COMPLETED));
                isLoading = false;
                afterInit();
              };
              if (requestDraft) {
                requestDraft = {
                  ...requestDraft,
                  demander: {
                    id: firstMember.type === ACCZIOM_CLIENT ? firstMember.mid : firstOrg.organizationId,
                    type: firstMember.type === ACCZIOM_CLIENT ? ACCZIOM_CLIENT : ACCZIOM_ORG
                  },
                  demanderAgent: [{
                    id: firstMember.type === ACCZIOM_CLIENT ? firstMember.mid : '',
                    type: firstMember.type === ACCZIOM_CLIENT ? ACCZIOM_CLIENT : ACCZIOM_NONE
                  }],
                  demanderSignCount: firstMember.type === ACCZIOM_CLIENT ? 1 : firstOrg.signerCount
                };
                lambdaCreateRequest(requestDraft)
                  .then((createdReq) => {
                    initWork(() => {
                      dispatch(setRequestedLocation(`/deal/purchase/${requestDraft.reqType === MICROSERVICE_BUNDLE ? 'service-bundle' : 'service'}/${createdReq.uid}`));
                    });
                  })
                  .catch((e) => {
                    console.log('Create ticket error : ', JSON.stringify(e));
                    dispatch(setActiveOrgId(null));
                    dispatch(setLoadingStatus(LoadingStatus.NONE));
                    logout();
                    toast.error('Failed to create ticket!');
                    isLoading = false;
                  });
              } else {
                initWork(() => {});
              }
            }
          }
        } else {
          console.log('No organizations found.');
          dispatch(setActiveOrgId(null));
          dispatch(setLoadingStatus(LoadingStatus.NONE));
          logout();
          toast.error('You should join in at least one Organization, Please try join in Organization first.');
          isLoading = false;
        }
      } else {
        console.log('User not exists.');
        dispatch(setActiveOrgId(null));
        dispatch(setLoadingStatus(LoadingStatus.NONE));
        logout();
        toast.error('Error occurred while initializing. Please try login again.');
        isLoading = false;
      }
    })
    .catch((e) => {
      console.log('User not exists.', e);
      dispatch(setActiveOrgId(null));
      dispatch(setLoadingStatus(LoadingStatus.NONE));
      logout();
      toast.error('Failed to get user information!');
      isLoading = false;
    });
};

export default initialLoad;
