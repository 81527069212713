import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  dialogPaper: {
    position: 'absolute',
    width: '100%',
    margin: 0,
    borderRadius: 0,
    boxShadow: 'none'
  },
});

const AlertLoginDialog: FC<{
  open: boolean;
  handleClose: any;
  handleSign: any;
}> = ({
  open,
  handleClose,
  handleSign
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      onBackdropClick={handleClose}
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle>
        You aren&apos;t signed in.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You should sign in first to request contract. Your data will be saved, would you mind to continue to sign in?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSign}
          variant="contained"
        >
          Sign in
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertLoginDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSign: PropTypes.func,
};

export default AlertLoginDialog;
