const shortid = require('shortid');

enum ControlType {
  SELECTOR = 'Selector',
  TICKBOX = 'TickBox',
  REPEATER = 'Repeater',
  TEXTBOX = 'TextBox'
}

enum ValueType {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  ADDRESS = 'address'
}

enum AlignType {
  HORIZON = 'horizon',
  VERTICAL = 'vertical'
}

export type MicroserviceData = {
  type: ControlType;
  json: any;
  hasChild: boolean;
};

export type SurveyItemType = {
  id: string | number;
  type: ControlType;
  title?: string;
  help?: string;
  eg?: string;
  valuetype?: ValueType;
  cpr?: number;
  exlink?: string;
  unit?: any;
  child?: any;
  align?: string;
};

const serviceTypes = [
  'Graphics and Web Design',
  'Accounting and Taxation',
  'Chemicals -> Solvents',
  'Chemicals -> Cleaning Agents',
  'Chemicals -> Engine Coolants',
  'Chemicals -> Degrasing Agents'
];

const shortId = () : any => shortid.generate();

export {
  ControlType,
  ValueType,
  AlignType,
  serviceTypes,
  shortId,
};
