import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { Invitation, Referral } from 'src/../../Common/Model/network';
import { STATUS_REMOVED } from 'src/globals';

interface NetworkState {
  invitations: Invitation[],
  invitationNeedReload: boolean,
  referrals: Referral[],
  referralNeedReload: boolean
}

const initialState: NetworkState = {
  invitations: [],
  invitationNeedReload: true,
  referrals: [],
  referralNeedReload: true
};

const slice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    clearState(state: NetworkState): void {
      state.invitationNeedReload = true;
      state.invitations.splice(0, state.invitations.length);
      state.referralNeedReload = true;
      state.referrals.splice(0, state.referrals.length);
    },
    setInvitations(state: NetworkState, action: PayloadAction<Invitation[]>) {
      const invitations = action.payload;
      state.invitations = [...invitations];
      state.invitationNeedReload = false;
    },
    addNewInvitation(state: NetworkState, action: PayloadAction<{ invitations: Invitation[] }>) {
      const { invitations } = action.payload;
      state.invitations = invitations.concat(state.invitations);
    },
    updateInvitation(state: NetworkState, action: PayloadAction<Invitation>) {
      const invitation = action.payload;
      const index = state.invitations.findIndex((item) => item.id === invitation.id);
      if (index > -1) state.invitations[index] = invitation;
      else state.invitations.push(invitation);
    },
    replaceInvitation(state: NetworkState, action: PayloadAction<any>) {
      const body = action.payload;
      const invitation = state.invitations.find((item) => item.id === body.id);
      if (invitation) {
        Object.keys(body).forEach((key) => {
          if (key !== 'id') invitation[key] = body[key];
        });
      }
    },
    removeInvitation(state: NetworkState, action: PayloadAction<string>) {
      const id = action.payload;
      state.invitations = state.invitations.filter((item) => item.id !== id);
    },
    setInvitationNeedReload(state: NetworkState, action: PayloadAction<boolean>) {
      state.invitationNeedReload = action.payload;
    },
    setReferrals(state: NetworkState, action: PayloadAction<Referral[]>) {
      const referrals = action.payload;
      state.referrals = [...referrals];
      state.referralNeedReload = false;
    },
    addNewReferral(state: NetworkState, action: PayloadAction<{ referrals: Referral[] }>) {
      const { referrals } = action.payload;
      state.referrals = referrals.concat(state.referrals);
    },
    updateReferral(state: NetworkState, action: PayloadAction<Referral>) {
      const referral = action.payload;
      const index = state.referrals.findIndex((item) => item.id === referral.id);
      if (index > -1) state.referrals[index] = referral;
      else state.referrals.push(referral);
    },
    replaceReferral(state: NetworkState, action: PayloadAction<any>) {
      const body = action.payload;
      const referral = state.referrals.find((item) => item.id === body.id);
      if (referral) {
        Object.keys(body).forEach((key) => {
          if (key !== 'id') referral[key] = body[key];
        });
      }
    },
    removeReferral(state: NetworkState, action: PayloadAction<string>) {
      const id = action.payload;
      state.referrals = state.referrals.filter((item) => item.id !== id);
    },
    removeReferralFromReq(state: NetworkState, action: PayloadAction<{ removed: number; id : string; updatedAt: Date | string; }>) {
      const { removed, id, updatedAt } = action.payload;
      if (removed > 0) {
        const index = state.referrals.findIndex((item) => item.id === id);
        state.referrals.splice(index, 1);
      } else {
        const referral = state.referrals.find((item) => item.id === id);
        referral.status = STATUS_REMOVED;
        referral.updatedAt = updatedAt;
      }
    },
    setReferralNeedReload(state: NetworkState, action: PayloadAction<boolean>) {
      state.referralNeedReload = action.payload;
    },
  }
});

export const { reducer } = slice;

export const clearNetworkState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};
export const loadInvitations = (invitations: Invitation[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInvitations(invitations));
};
export const addNewInvitation = (invitations: Invitation[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNewInvitation({ invitations }));
};
export const updateInvitation = (invitation: Invitation): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateInvitation(invitation));
};
export const replaceInvitation = (body: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.replaceInvitation(body));
};
export const removeInvitation = (id: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeInvitation(id));
};
export const setInvitationNeedReload = (needReload: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInvitationNeedReload(needReload));
};
export const loadReferrals = (referrals: Referral[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setReferrals(referrals));
};
export const addNewReferral = (referrals: Referral[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNewReferral({ referrals }));
};
export const updateReferral = (referral: Referral): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateReferral(referral));
};
export const replaceReferral = (body: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.replaceReferral(body));
};
export const removeReferral = (id: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeReferral(id));
};
export const removeReferralFromReq = (removed: number, id : string, updatedAt: Date | string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeReferralFromReq({ removed, id, updatedAt }));
};
export const setReferralNeedReload = (needReload: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setReferralNeedReload(needReload));
};

export default slice;
