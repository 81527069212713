import { format } from 'date-fns';

export const chatDateFormat = (date: number | string | Date): string => {
  if (!date) return '';
  const now = new Date();
  const mm = new Date(date);
  if (mm.getFullYear() !== now.getFullYear()) return format(mm, 'dd/MM/yyyy');
  if (mm.getMonth() !== now.getMonth() || mm.getDate() !== now.getDate()) return format(mm, 'MM-dd');

  return format(mm, 'HH:mm a');
};

export const dateTimeFormat = (date: number | Date | string): string => {
  if (!date) return '';
  return format(new Date(date), 'dd/MM/yyyy hh:mm:ss a');
};

export const getDateString = (date: Date): string => {
  if (!date) return '';
  if (!Number.isNaN(date.getTime())) return format(date, 'dd/MM/yyyy');
  return format(Date.now(), 'dd/MM/yyyy');
};

export const ausFormatToDate = (date: string) => {
  if (!date) return '';
  const [d, m, y] = date.split('/');
  return `${m}/${d}/${y}`;
};

export const getTodayDateStr = (): string => format(new Date(), 'dd/MM/yyyy');

export const getTodayDate = (): Date => (new Date((new Date()).toDateString()));
export const removeTime = (date: any) => (new Date((new Date(date)).toDateString()));

export const toDisplayDateFormat = (date: any): string => {
  if (!date) return 'Invalid date';
  const d = new Date(date);
  return format(d, 'dd/MM/yyyy');
};
