import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Favorite } from 'src/../../Common/Model/favorite';
import type { PaidProfile } from 'src/../../Common/Model/paidProfile';

interface FavoriteState {
  favorites: Favorite[];
  needReload: boolean;
  searchHistory: string[];
  paidProfiles: PaidProfile[];
}

const initialState: FavoriteState = {
  favorites: [],
  needReload: true,
  searchHistory: [],
  paidProfiles: []
};

const slice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    clearState(state: FavoriteState) {
      state.favorites.splice(0, state.favorites.length);
      state.searchHistory.splice(0, state.searchHistory.length);
      state.paidProfiles.splice(0, state.paidProfiles.length);
      state.needReload = true;
    },
    setFavorites(state: FavoriteState, action: PayloadAction<{ favorites: Favorite[]; }>) {
      const { favorites } = action.payload;
      state.favorites = favorites;
    },
    removeFavorite(state: FavoriteState, action: PayloadAction<{ id: string; }>) {
      const { id } = action.payload;
      const index = state.favorites.findIndex((item) => item.id === id);
      state.favorites.splice(index, 1);
    },
    addFavorite(state: FavoriteState, action: PayloadAction<{ favorite: Favorite }>) {
      const { favorite } = action.payload;
      if (!state.favorites.some((fav) => fav.data.abn === favorite.data.abn)) {
        state.favorites = [...state.favorites, favorite];
      }
    },
    addPaidProfile(state: FavoriteState, action: PayloadAction<{ paidProfile: PaidProfile }>) {
      const { paidProfile } = action.payload;
      if (!state.paidProfiles.some((paid) => paid.abn === paidProfile.abn)) {
        state.paidProfiles = [...state.paidProfiles, paidProfile];
      }
    },
    setNeedReload(state: FavoriteState, action: PayloadAction<boolean>) {
      state.needReload = action.payload;
    },
    setSearchHistory(state: FavoriteState, action: PayloadAction<string[]>) {
      state.searchHistory = action.payload;
    },
    setPaidProfiles(state: FavoriteState, action: PayloadAction<{ paidProfiles: PaidProfile[]; }>) {
      const { paidProfiles } = action.payload;
      state.paidProfiles = paidProfiles;
    },
  }
});

export const { reducer } = slice;

export const clearFavoriteState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setFavorites = (favorites: Favorite[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setFavorites({ favorites }));
};

export const removeFavorite = (id: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeFavorite({ id }));
};

export const addFavorite = (favorite: Favorite): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addFavorite({ favorite }));
};

export const addPaidProfile = (paidProfile: PaidProfile): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addPaidProfile({ paidProfile }));
};

export const setNeedReload = (state: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setNeedReload(state));
};

export const setSearchHistory = (hist: string[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setSearchHistory(hist));
};

export const setPaidProfiles = (paidProfiles: PaidProfile[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setPaidProfiles({ paidProfiles }));
};

export default slice;
