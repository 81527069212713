import { User } from 'src/../../Common/Model/user';
import { Organization, OrgMember } from 'src/../../Common/Model/organization';
import { UserMinInfo } from 'src/../../Common/Model/common';
import { null2empty, ACCZIOM_USER, ACCZIOM_ORG } from 'src/globals';

export const orgToMiniUser = (orgInfo: Organization): UserMinInfo => ({
  uid: null2empty(orgInfo.organizationId),
  allIds: [{ id: null2empty(orgInfo.organizationId), type: ACCZIOM_ORG }],
  type: ACCZIOM_ORG,
  firstName: null2empty(orgInfo.firstName),
  middleName: null2empty(orgInfo.middleName),
  lastName: null2empty(orgInfo.lastName),
  penName: null2empty(orgInfo.tradingName),
  avatar: null2empty(orgInfo.logo),
  addressLine1: null2empty(orgInfo.addressLine1),
  country: null2empty(orgInfo.country),
  state: null2empty(orgInfo.region),
  city: null2empty(orgInfo.city),
  postalCode: null2empty(orgInfo.postalCode),
  phone: null2empty(orgInfo.phone),
  email: null2empty(orgInfo.email),
  website: null2empty(orgInfo.website),
  published: orgInfo.publishedProfile && orgInfo.publishedProfile.accziom,
  abn: null2empty(orgInfo.abn),
  acn: null2empty(orgInfo.acn),
  signerCount: orgInfo?.signerCount
});

export const userToMiniUser = (userInfo: User): UserMinInfo => ({
  uid: null2empty(userInfo.uid),
  allIds: [{ id: null2empty(userInfo.uid), type: ACCZIOM_USER }],
  type: ACCZIOM_USER,
  firstName: null2empty(userInfo.firstName),
  middleName: null2empty(userInfo.middleName),
  lastName: null2empty(userInfo.lastName),
  penName: null2empty(userInfo.penName),
  avatar: null2empty(userInfo.avatar),
  addressLine1: null2empty(userInfo.addressLine1),
  city: null2empty(userInfo.city),
  state: null2empty(userInfo.state),
  country: null2empty(userInfo.country),
  postalCode: null2empty(userInfo.postalCode),
  phone: null2empty(userInfo.phone),
  email: null2empty(userInfo.email),
  website: null2empty(userInfo.website),
  published: userInfo.publishedProfile && userInfo.publishedProfile.accziom,
  abn: null2empty(userInfo.abn),
  acn: null2empty(userInfo.acn)
});

export const memberToMiniUser = (memberInfo: OrgMember): UserMinInfo => ({
  uid: null2empty(memberInfo.mid),
  allIds: [{ id: null2empty(memberInfo.mid), type: memberInfo.type }],
  type: memberInfo.type,
  firstName: null2empty(memberInfo.firstName),
  middleName: null2empty(memberInfo.middleName),
  lastName: null2empty(memberInfo.lastName),
  penName: null2empty(memberInfo.penName),
  avatar: '',
  addressLine1: null2empty(memberInfo.addressLine1),
  city: null2empty(memberInfo.city),
  state: null2empty(memberInfo.state),
  country: null2empty(memberInfo.country),
  postalCode: null2empty(memberInfo.postalCode),
  phone: null2empty(memberInfo.phone),
  email: null2empty(memberInfo.email),
  website: null2empty(memberInfo.website),
  published: ['addressLine1', 'city', 'state', 'country', 'postalCode', 'phone', 'email', 'website']
});
