import {
  REQUEST_ONE_OFF,
  REQUEST_CUSTOM,
  LENGTH_MONTH,
  LENGTH_YEAR,
  periodLengthList,
  REQUEST_FINANCIAL,
  MICROSERVICE,
  ACCZIOM_NONE,
  REQUESTED,
  PAY_DIRECT_DEBIT,
  ASSET,
  ASSET_PURPOSE_LEASE,
  OFFERED,
  REOFFERED,
  PROGRESSING,
  ARCHIVED,
  SCHEDULE_ON,
  STATUS_TEXT_ACTIVATED,
  LENGTH_DAY,
  CRYPTO_ETHER,
  CRYPTO_TETHER,
  CRYPTO_MRC,
  fiatCurrencies,
  currencyUnitToType,
  ACCZIOM_USER,
  ACCZIOM_ORG,
  TICKET_MODE_NORMAL,
  calcTotalPrice,
  OFFERREQUESTED,
  TICKET_MODE_PROPOSAL,
  CONTRACT_MODE,
  ACCZIOM_ADMIN_ORG_ID,
  ORGANIZATION,
  PAY_CREDIT_CARD,
  PAY_STRIPE,
  COMPOUND_INTEREST_TYPE,
  scaleCardDetail,
  UPFRONT_PAYMENT,
  ACCZIOM_TEAM,
  ACCZIOM_ADMIN_ORG_TEAM_ID
} from 'src/globals';
import { Microservice, MicroserviceInfo, MicroserviceInfoEx } from 'src/../../Common/Model/microservice';
import { MicroserviceForReq, setBundledServices, setService } from 'src/slices/serviceRequest';
import { sum } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Request } from 'src/../../Common/Model/request';
import { convertCurrency } from './priceUtils';
import { Asset } from 'src/../../Common/Model/asset';
import { ItemRelation } from 'src/../../Common/Model/itemRelation';
import { getTodayDate } from './dateFormatUtils';
import { lambdaGetProcurementOfTicketMerged, lambdaGetStripeAccount, lambdaGetWalletBalances } from 'src/aws/lambdaDispatch';
import { updateContract, updateInvoices, updateSchedules } from 'src/slices/procurement';
import { ExPurchaseItem, updatePurchaseItem } from 'src/slices/business';
import { shortId } from 'src/types/microservice';
import getServiceInfo from './getServiceInfo';
import { getActiveId } from './getActiveOrgInfo';
import { setStripeAccount, setNeedReload as setPayAccountNeedReload } from 'src/slices/payAccount';
// import { httpGetOrgBannerUrl } from 'src/apis/httpDispatch';
// import { fetchUrl } from './fileUpDownload';
import { paymentOptionFromStringToNumber } from 'src/components/microservices/edit/utils';
import { getInitializedContract } from './procurement/contractUtils';
import { getDateList, getPriceList } from './procurement/scheduleUtils';
import type { Referral } from 'src/../../Common/Model/network';
import { convert2Array } from './agentUtils';
import { Organization } from '../../../Common/Model/organization';
import { convertModificationsCurrency } from 'src/components/microservices/edit/ModificationInfo';

const curTime = (new Date()).getTime();

export const financialYearModel = Array.from(Array(12).keys()).map((i) => (
  `${(new Date(curTime)).getFullYear() + i} - ${(new Date(curTime)).getFullYear() + i + 1}`
));

export const financialMonthModel = [
  // 'January',
  // 'February',
  // 'January - March',
  // 'April',
  // 'May',
  // 'April - June',
  // 'July',
  // 'August',
  // 'July - September',
  // 'October',
  // 'November',
  // 'October - December'
  'July - June'
];

export const getServiceLength = (service: Microservice): number => (
  periodLengthList.find((itm) => `${itm.type}` === `${service.durationType.type}`).minutes * service.durationType.length
);

export const getMaxCountInOnePeriod = (service: Microservice, periodType: number) => {
  const serLength = getServiceLength(service);
  const reqLength = periodLengthList.find((itm) => `${itm.type}` === `${periodType}`).minutes;
  return Math.floor(reqLength / serLength);
};

export const checkPeriodOver = (service: Microservice, periodType: number) => {
  const ret = service.json?.full.filter((itm) => itm.periodInfo?.offsetType <= periodType);
  return ret.length;
};

export const financialYearModelDefaultperiodInfo = (service: Microservice) => ({
  type: REQUEST_FINANCIAL,
  year: financialYearModel[0],
  period: financialMonthModel[0],
  count: 1,
  per: getMaxCountInOnePeriod(service, LENGTH_MONTH) > 0 ? LENGTH_MONTH : LENGTH_YEAR
});

export const onceOffModelDefaultPeriodInfo = () => ({
  type: REQUEST_ONE_OFF,
  start: getTodayDate().toISOString()
});

export const getDefaultValues = (
  service: MicroserviceInfo,
  periodInfo: any,
  isOffer: boolean,
  count: number,
  sid: string
): MicroserviceForReq => ({
  ...service,
  selectedPaymentOption: `${service.paymentOptions.payment.find((v) => v !== null).type}`,
  selectedPaymentPlatform: service.paymentOptions.platforms[0],
  modifications: !service.paymentOptions.modifications ? [] : convertModificationsCurrency(service.paymentOptions.modifications, service.paymentOptions.platforms[0].paymentCurrency),
  periodInfo,
  reqTitle: service.name,
  hiMsg: isOffer
    ? 'Hello <client name>, I\'ve enclosed my service offer for your attention. This is based on the discussions we had.'
    : `Hello ${service.providerName},\r\nI've attached the information about my ${service.name} and look forward to working with you to complete the form and have it lodged.\r\nThe proposed fee is acceptable to me and I'll sign the contract when you share it with me.`,
  count,
  sid,
  answers: {},
  completedQuestionnaire: !service.json.length
});

export const getValuesFromRequest = (service: MicroserviceInfo, request: Request): MicroserviceForReq => ({
  ...service,
  selectedPaymentOption: `${request.material.payments.paymentOption.type}`,
  selectedPaymentPlatform: {
    paymentMethod: request.material.payments.paymentMethod,
    paymentTool: request.material.payments.paymentTool,
    paymentCurrency: request.material.payments.paymentCurrency,
  },
  modifications: convertModificationsCurrency(request.material.payments.modifications, request.material.payments.paymentCurrency),
  periodInfo: request.material.period,
  reqTitle: request.title,
  hiMsg: request.hiMsg,
  count: 1,
  sid: '',
  answers: service.bundleType === MICROSERVICE ? request.detail[0].collected : {},
  completedQuestionnaire: service.bundleType === MICROSERVICE ? request.detail[0].completedQuestionnaire : false
});

export const getValuesFromRequestEx = (service: MicroserviceInfoEx, request: Request): MicroserviceForReq => ({
  ...service,
  selectedPaymentOption: `${request.material.payments.paymentOption.type}`,
  selectedPaymentPlatform: {
    paymentMethod: request.material.payments.paymentMethod,
    paymentTool: request.material.payments.paymentTool,
    paymentCurrency: request.material.payments.paymentCurrency,
  },
  modifications: convertModificationsCurrency(request.material.payments.modifications, request.material.payments.paymentCurrency),
  periodInfo: request.material.period,
  reqTitle: request.title,
  hiMsg: request.hiMsg,
  count: 1,
  sid: '',
  answers: service.bundleType === MICROSERVICE ? request.detail[0].collected : {},
  completedQuestionnaire: service.bundleType === MICROSERVICE ? request.detail[0].completedQuestionnaire : false
});

export const getValuesFromMaterial = (service: MicroserviceInfo, material: any): MicroserviceForReq => ({
  ...service,
  sid: material.sid,
  periodInfo: material.period,
  selectedPaymentOption: `${material.payments.paymentOption.type}`,
  selectedPaymentPlatform: {
    paymentMethod: material.payments.paymentMethod,
    paymentTool: material.payments.paymentTool,
    paymentCurrency: material.payments.paymentCurrency
  },
  answers: material.collected,
  completedQuestionnaire: material.completedQuestionnaire
});

export const getServiceQuantity = (service: MicroserviceForReq): number => {
  const serCount = service.count ? service.count : 1;
  if (service.periodInfo.type === REQUEST_ONE_OFF) return 1 * serCount;
  if (service.periodInfo.type === REQUEST_CUSTOM) return Number(service.periodInfo.periodCount) * Number(service.periodInfo.count) * serCount;
  if (`${service.periodInfo.per}` === `${LENGTH_MONTH}`) return 12 * Number(service.periodInfo.count) * serCount;
  if (`${service.periodInfo.per}` === `${LENGTH_YEAR}`) return Number(service.periodInfo.count) * serCount;
  const year = Number(service.periodInfo.year.substring(0, 4)) + 1;
  const periodDay = 365 + ((year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) ? 1 : 0);
  const periodMinute = periodDay * 24 * 60;
  const perMinute = periodLengthList.find((itm) => `${itm.type}` === `${service.periodInfo.per}`).minutes;
  return Math.floor(periodMinute / perMinute) * Number(service.periodInfo.count) * serCount;
};

export const getBundledServiceQuantity = (periodInfo: any, bundle: Microservice): number => {
  if (periodInfo.type === REQUEST_ONE_OFF) return 1;
  const periodLength = periodLengthList.find((v) => v.type === periodInfo.per).minutes;
  const bundleLength = getServiceLength(bundle);
  return Math.floor(bundleLength / periodLength) * periodInfo.count;
};

export const estPriceOfSingleService = (service: MicroserviceForReq): number => (
  calcTotalPrice(service.answers, service.baseFee)
);

export const fullEstPrice = (service: MicroserviceForReq, bundledServices: MicroserviceForReq[]): number => (
  service.bundleType === MICROSERVICE
    ? getServiceQuantity(service) * estPriceOfSingleService(service)
    : sum(bundledServices.map(
      (bs) => (
        getServiceQuantity(service) * (!bs.count ? 1 : bs.count) * estPriceOfSingleService(bs) * getBundledServiceQuantity(bs.periodInfo, service)
      )
    ))
);

export const getPaymentOptionsOfService = (service: MicroserviceForReq): any => ({
  paymentOption: service.paymentOptions.payment[service.selectedPaymentOption],
  interest: service.paymentOptions.interest,
  gst: service.paymentOptions.gst,
  invoiceOffset: service.paymentOptions.invoiceOffset,
  modifications: service.modifications,
  ...service.selectedPaymentPlatform
});

export const getServiceMaterial = (service: MicroserviceForReq, parentService: MicroserviceForReq) => {
  const payments = getPaymentOptionsOfService(parentService);
  return ({
    id: service.id,
    sid: service.sid,
    title: service.name,
    description: service.description,
    duration: service.durationType,
    category: service.type,
    cost: convertCurrency(estPriceOfSingleService(service), service.defaultCurrency, payments.paymentCurrency),
    type: MICROSERVICE,
    quantity: getBundledServiceQuantity(service.periodInfo, parentService),
    collected: service.answers,
    period: service.periodInfo,
    payments,
    completedQuestionnaire: service.completedQuestionnaire
  });
};

export const getInitialRequestFromService = (service: MicroserviceForReq, bundledServices: MicroserviceForReq[], demander: any, demanderAgent: any, mode: number): Request => {
  const payments = getPaymentOptionsOfService(service);
  const totalPrice = convertCurrency(fullEstPrice(service, bundledServices), service.defaultCurrency, payments.paymentCurrency);
  const now = new Date().toISOString();
  return {
    uid: uuidv4(),
    title: service.reqTitle,
    hiMsg: service.hiMsg,
    demander,
    demanderAgent: [demanderAgent],
    supplier: service.creator,
    supplierAgent: convert2Array(service.agent),
    reqType: service.bundleType,
    fileName: '',
    attachment_uri: '',
    status: REQUESTED,
    detail: (
      service.bundleType === MICROSERVICE
        ? [
          getServiceMaterial({
            ...service,
            periodInfo: {
              type: REQUEST_ONE_OFF,
              offset: 0,
              offsetType: LENGTH_DAY
            }
          }, service)
        ]
        : bundledServices.map((ser) => getServiceMaterial(ser, service))
    ),
    material: {
      id: service.id,
      title: service.name,
      description: service.description,
      duration: service.durationType,
      category: service.type,
      cost: totalPrice,
      quantity: getServiceQuantity(service),
      period: service.periodInfo,
      payments
    },
    price: totalPrice,
    priceDescription: '',
    referralId: '',
    history: [],
    mode,
    createdAt: now,
    updatedAt: now
  } as Request;
};

export const getInitialRequestFromAsset = (asset: Asset, assetRelations: ItemRelation[], childAssets: Asset[], actionType: number, reqInfo: any, demander: any, demanderAgent: any): Request => {
  const payments = {
    paymentOption: asset.paymentOptions.payment[0],
    interest: asset.paymentOptions.interest,
    gst: asset.paymentOptions.gst,
    invoiceOffset: asset.paymentOptions.invoiceOffset,
    modifications: [],
    ...reqInfo.selectedPaymentPlatform
  };
  const pricePerValue = convertCurrency(asset.price, asset.defaultCurrency, reqInfo.selectedPaymentPlatform.paymentCurrency);
  const totalPrice = (pricePerValue + sum(childAssets.map((item) => (convertCurrency(item.price, item.defaultCurrency, reqInfo.selectedPaymentPlatform.paymentCurrency) * assetRelations.find((rel) => (rel.childId === item.id)).childQty)))) * reqInfo.selectedCount * reqInfo.selectedPeriodCount;

  return {
    uid: uuidv4(),
    title: asset.title,
    hiMsg: '',
    demander,
    demanderAgent: [demanderAgent],
    supplier: asset.owner,
    supplierAgent: convert2Array(asset.ownerAgent),
    reqType: ASSET,
    fileName: '',
    attachment_uri: '',
    status: REQUESTED,
    detail: [
      {
        id: asset.id,
        sid: shortId(),
        title: asset.title,
        description: asset.description,
        duration: { type: asset.leasePeriodUnit, length: asset.purpose === ASSET_PURPOSE_LEASE ? reqInfo.selectedPeriodCount : 0 },
        category: asset.subCategory,
        cost: pricePerValue,
        type: ASSET,
        quantity: reqInfo.selectedCount,
        period: {
          type: REQUEST_ONE_OFF,
          offset: 0,
          offsetType: LENGTH_DAY
        },
        payments,
        collected: {
          actionType,
          fromId: ''
        }
      },
      ...childAssets.map((item) => ({
        id: item.id,
        sid: shortId(),
        title: item.title,
        description: item.description,
        duration: { type: asset.leasePeriodUnit, length: asset.purpose === ASSET_PURPOSE_LEASE ? reqInfo.selectedPeriodCount : 0 },
        category: item.subCategory,
        cost: convertCurrency(item.price, item.defaultCurrency, reqInfo.selectedPaymentPlatform.paymentCurrency),
        type: ASSET,
        quantity: reqInfo.selectedCount * assetRelations.find((rel) => (rel.childId === item.id)).childQty,
        period: {
          type: REQUEST_ONE_OFF,
          offset: 0,
          offsetType: LENGTH_DAY
        },
        payments,
        collected: {
          actionType,
          fromId: ''
        }
      }))
    ],
    material: {
      id: asset.id,
      title: asset.title,
      description: asset.description,
      duration: { type: asset.leasePeriodUnit, length: asset.purpose === ASSET_PURPOSE_LEASE ? reqInfo.selectedPeriodCount : 0 },
      category: asset.subCategory,
      cost: totalPrice,
      quantity: 1,
      period: { type: REQUEST_ONE_OFF, start: getTodayDate().toISOString() },
      payments
    },
    price: totalPrice,
    priceDescription: '',
    referralId: '',
    history: [],
    mode: TICKET_MODE_NORMAL,
    demanderSignCount: 1,
    supplierSignCount: 1
  } as Request;
};

export const getProcurementOfTicket = async (ticket: Request, dispatch: any): Promise<any> => {
  const { contract, schedules, purchaseItem, invoices } = await lambdaGetProcurementOfTicketMerged(ticket);
  if ([REQUESTED, OFFERREQUESTED, OFFERED, REOFFERED].includes(ticket.status) && !!ticket.contract) {
    if (contract) dispatch(updateContract(contract));
  }
  if (ticket.status === PROGRESSING || ticket.status === ARCHIVED) {
    if (contract) {
      dispatch(updateContract(contract));
      if (contract.scheduled === SCHEDULE_ON) {
        if (schedules && schedules.length > 0) {
          dispatch(updateSchedules(schedules));
          const schedule = schedules[0];
          if (schedule.status === STATUS_TEXT_ACTIVATED && purchaseItem) {
            dispatch(updatePurchaseItem({ ...purchaseItem, chatId: ticket.conversation } as ExPurchaseItem));
          }
        }
      } else if (purchaseItem) dispatch(updatePurchaseItem({ ...purchaseItem, chatId: ticket.conversation } as ExPurchaseItem));
      if (invoices && invoices.length > 0) dispatch(updateInvoices(invoices));
    }
  }
  return contract;
};

export const checkPaymentCapability = async (price: number, currency: string, paymentMethod: number, user: any) => {
  console.log(price, currency, paymentMethod, user);
  const currencyUnit = currencyUnitToType(currency);
  if (fiatCurrencies.includes(currencyUnit)) {
    if (paymentMethod !== PAY_DIRECT_DEBIT) return false;
    const stripeAccount = await lambdaGetStripeAccount(user);
    console.log(stripeAccount);
    return !!stripeAccount && !!stripeAccount.customerID && !!stripeAccount.detail && !!stripeAccount.detail.setupIntents && !!stripeAccount.detail.setupIntents.find((item) => (item.type === 'au_becs_debit' && item.status === 'succeeded'));
  }
  const res = await lambdaGetWalletBalances(user);
  if (currencyUnit === CRYPTO_ETHER) return price <= res.eth;
  if (currencyUnit === CRYPTO_TETHER) return price <= res.usdt;
  if (currencyUnit === CRYPTO_MRC) return price <= res.mrc.layer2;
  return true;
};

export const initRequestInfo = async (isAuthenticated: boolean, serviceId: string, request: Request, payAccountNeedReload: boolean, dispatch: any) => {
  const s: MicroserviceInfoEx = await getServiceInfo(serviceId);
  if (s.bundleType === MICROSERVICE) {
    if (request) dispatch(setService(getValuesFromRequestEx(s, request)));
    else dispatch(setService(getDefaultValues(s, onceOffModelDefaultPeriodInfo(), false, 1, '')));
  } else {
    const bundledServices = [];
    const getBundledServicesPromises = [];
    if (request) {
      getBundledServicesPromises.push(...request.detail.map((material) => getServiceInfo(material.id).then((ser) => {
        bundledServices.push(getValuesFromMaterial(ser, material));
      })));
      dispatch(setService(getValuesFromRequestEx(s, request)));
    } else {
      getBundledServicesPromises.push(...s.json.full.map(({ id, count, sid, periodInfo }) => getServiceInfo(id).then((ser) => {
        bundledServices.push(getDefaultValues(ser, periodInfo, false, count, sid));
      })));
      dispatch(setService(getDefaultValues(s, onceOffModelDefaultPeriodInfo(), false, 1, '')));
    }
    await Promise.all(getBundledServicesPromises);
    dispatch(setBundledServices(bundledServices));
  }
  if (isAuthenticated) {
    if (payAccountNeedReload) {
      const res = await lambdaGetStripeAccount(getActiveId(ACCZIOM_USER, ACCZIOM_ORG));
      if (res && !res.errorMessage) {
        dispatch(setStripeAccount(res));
        dispatch(setPayAccountNeedReload(false));
      }
    }
  }
  // if (s.banner) {
  //   const { url: newBannerUrl } = await httpGetOrgBannerUrl(s.banner);
  //   const newLocalUrl = await fetchUrl(newBannerUrl);
  //   dispatch(setActiveBannerUrl(newLocalUrl));
  // }
};

export const getPriceFromService = (service: MicroserviceForReq, bundledServices: MicroserviceForReq[]) => {
  const request = getInitialRequestFromService({
    ...service,
    paymentOptions: paymentOptionFromStringToNumber(service.paymentOptions)
  }, bundledServices.map((bs) => ({
    ...bs,
    paymentOptions: paymentOptionFromStringToNumber(bs.paymentOptions)
  })), { id: '', type: ACCZIOM_NONE }, { id: '', type: ACCZIOM_NONE }, TICKET_MODE_PROPOSAL);
  const contract = {
    ...getInitializedContract(null, request),
    supplierSignedAt: new Date(),
    customerSignedAt: new Date()
  };
  return getPriceList(CONTRACT_MODE, contract, null);
};

export const getPriceFromRequest = (request: Request, referral: Referral) => {
  const contract = {
    ...getInitializedContract(null, request),
    supplierSignedAt: new Date(),
    customerSignedAt: new Date()
  };
  if (referral) {
    contract.referral = {
      id: referral.id,
      amount: referral.amount,
      unit: referral.unit,
      creator: referral.creator,
      creatorAgent: convert2Array(referral.creatorAgent),
      partner: referral.partner,
      partnerAgent: convert2Array(referral.partnerAgent)
    };
  }
  return getPriceList(CONTRACT_MODE, contract, null);
};

export const getDateFromRequest = (request: Request, referral: Referral) => {
  const contract = {
    ...getInitializedContract(null, request),
    supplierSignedAt: new Date(),
    customerSignedAt: new Date()
  };
  if (referral) {
    contract.referral = {
      id: referral.id,
      amount: referral.amount,
      unit: referral.unit,
      creator: referral.creator,
      creatorAgent: convert2Array(referral.creatorAgent),
      partner: referral.partner,
      partnerAgent: convert2Array(referral.partnerAgent)
    };
  }
  return getDateList(CONTRACT_MODE, contract, null);
};

export const getTicketPrice = (ticket: Request): number => {
  const { material, price } = ticket;
  const { payments } = material;
  const { gst } = payments;
  let gstRate = 0;
  if (gst) gstRate = gst.rate;
  return Math.round(price * (100 + gstRate)) / 100;
};

export const getOrganizationTicket = (orgInfo: Organization, prevScale: number, prevExtraMembers = 0): Request => {
  const now = new Date().toISOString();
  // const curPrice = scaleCardDetail.find((v) => v.value === `${orgInfo.totalMembers}`).price;
  // const prevPrice = scaleCardDetail.find((v) => v.value === `${prevScale}`).price;
  const curCard = scaleCardDetail.find((v) => v.value === `${orgInfo.totalMembers}`);
  const curPrice = curCard.price + curCard.extra_cost_per_one * (orgInfo.businessInfo.extraClients ?? 0);
  const prevCard = scaleCardDetail.find((v) => v.value === `${prevScale}`);
  const prevPrice = prevCard.price + (prevCard.extra_cost_per_one ?? 0) * (prevExtraMembers ?? 0);
  const price = curPrice - prevPrice;
  return {
    uid: uuidv4(),
    title: 'Organization Usage Fee',
    hiMsg: '',
    demander: { id: orgInfo.organizationId, type: ACCZIOM_ORG },
    demanderAgent: [],
    supplier: { id: ACCZIOM_ADMIN_ORG_ID, type: ACCZIOM_ORG },
    supplierAgent: [{ id: ACCZIOM_ADMIN_ORG_TEAM_ID, type: ACCZIOM_TEAM }],
    reqType: ORGANIZATION,
    fileName: '',
    attachment_uri: '',
    status: REQUESTED,
    detail: [{
      id: orgInfo.organizationId,
      sid: shortId(),
      title: orgInfo.tradingName,
      description: '',
      duration: { length: 1, type: LENGTH_YEAR },
      category: ORGANIZATION,
      cost: price,
      type: ORGANIZATION,
      quantity: 1,
      collected: {},
      period: {
        type: REQUEST_ONE_OFF,
        offset: 0,
        offsetType: LENGTH_DAY
      },
      payments: {
        gst: {
          rate: 0
        },
        interest: {
          rate: 0,
          type: COMPOUND_INTEREST_TYPE,
          rateType: LENGTH_YEAR
        },
        paymentTool: PAY_STRIPE,
        invoiceOffset: 0,
        paymentMethod: PAY_CREDIT_CARD,
        // paymentOption: {
        //   type: PERIOD_PAYMENT,
        //   finalOffset: 1,
        //   finalPeriod: LENGTH_MONTH,
        //   initialAmount: 0,
        //   initialOffset: 1
        // }
        paymentOption: {
          type: UPFRONT_PAYMENT,
          initialOffset: 1,
          initialAmount: '100',
          finalPeriod: 1,
          finalOffset: 0
        },
        paymentCurrency: 'AUD',
        modifications: []
      }
    }],
    material: {
      id: orgInfo.organizationId,
      title: orgInfo.tradingName,
      description: '',
      duration: { length: 1, type: LENGTH_YEAR },
      category: ORGANIZATION,
      cost: price,
      quantity: 1,
      period: {
        type: REQUEST_ONE_OFF,
        // start: new Date(new Date(orgInfo.createdAt).toDateString()).toISOString,
        start: new Date(new Date().toDateString()).toISOString()
      },
      payments: {
        gst: {
          rate: 0
        },
        interest: {
          rate: 10,
          type: COMPOUND_INTEREST_TYPE,
          rateType: LENGTH_YEAR
        },
        paymentTool: PAY_STRIPE,
        invoiceOffset: 0,
        paymentMethod: PAY_CREDIT_CARD,
        // paymentOption: {
        //   type: PERIOD_PAYMENT,
        //   finalOffset: 1,
        //   finalPeriod: LENGTH_MONTH,
        //   initialAmount: 0,
        //   initialOffset: 1
        // }
        paymentOption: {
          type: UPFRONT_PAYMENT,
          initialOffset: 1,
          initialAmount: '100',
          finalPeriod: 1,
          finalOffset: 0
        },
        paymentCurrency: 'AUD',
        modifications: []
      },
      targetScale: orgInfo.totalMembers,
      extraScale: orgInfo.businessInfo.extraClients ?? 0,
    },
    price,
    priceDescription: '',
    referralId: '',
    history: [{ eventName: 'requestedAt', occuredAt: new Date().toISOString() }],
    mode: TICKET_MODE_NORMAL,
    demanderSignCount: 1,
    supplierSignCount: 1,
    createdAt: now,
    updatedAt: now
  } as Request;
};
