import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  ThemeProvider,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useSettings from 'src/hooks/useSettings';
import { createCustomTheme } from 'src/theme';
import { useSelector } from 'src/store';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import useMounted from 'src/hooks/useMounted';
import { UserAvatar } from 'src/components/SmallUserInfo';
import { OrgMemberForOrg, Organization } from 'src/../../Common/Model/organization';
import Collapse from '@material-ui/core/Collapse';
import { orgToMiniUser, userToMiniUser } from 'src/utils/getUserMinInfo';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import useAuth from 'src/hooks/useAuth';
import toast from 'react-hot-toast';
import { getActiveType, getActiveUserMinInfo } from 'src/utils/getActiveOrgInfo';
import BottomPopupDialog from '../dialog/BottomPopupDialog';
import { ACCZIOM_CLIENT, ACCZIOM_MEMBER, tenary } from 'src/globals';
import getUserDisplayName from 'src/utils/getUserDisplayName';
import { useLocation, useNavigate } from 'react-router-dom';

interface MeOrgsProps {
  orgs: Organization[];
  onMeClicked: () => void;
  onOrgClicked: (id: string) => Promise<void>;
  opened: boolean;
  isSwitchingOrg: boolean;
}

const MeOrgs: FC<MeOrgsProps> = ({ orgs, onMeClicked, onOrgClicked, opened, isSwitchingOrg }) => {
  const { activeOrgId } = useSelector((state) => state.organization);
  const activeUserMinInfo = getActiveUserMinInfo();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <MenuItem
        sx={{
          px: 3,
          py: 1.5
        }}
        onClick={onMeClicked}
        disabled={isSwitchingOrg}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <UserAvatar
            userInfo={activeUserMinInfo}
            size={25}
            showType={false}
          />
          <Typography
            variant="body1"
            pl={1}
            mt={0.5}
            sx={{
              ...(
                getActiveType(ACCZIOM_CLIENT, ACCZIOM_MEMBER) === ACCZIOM_CLIENT && {
                  color: 'primary.main',
                  fontWeight: 'bold'
                }
              )
            }}
          >
            {getUserDisplayName(activeUserMinInfo)}
          </Typography>
        </Box>
      </MenuItem>
      <Collapse in={opened}>
        {orgs.map((org) => {
          const orgMinInfo = orgToMiniUser(org);
          return (
            <MenuItem
              key={org.organizationId}
              onClick={async () => { await onOrgClicked(org.organizationId); }}
              sx={{
                pl: 6,
                pr: 3,
                py: 1.5
              }}
              disabled={isSwitchingOrg}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <UserAvatar
                  userInfo={orgMinInfo}
                  size={20}
                  showType={false}
                />
                <Typography
                  variant="body2"
                  pl={1}
                  mt={0.5}
                  sx={{
                    ...(
                      activeOrgId === org.organizationId && {
                        color: 'primary.main',
                        fontWeight: 'bold'
                      }
                    )
                  }}
                >
                  {getUserDisplayName(orgMinInfo)}
                </Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Collapse>
    </Box>
  );
};

MeOrgs.propTypes = {
  orgs: PropTypes.array.isRequired,
  onMeClicked: PropTypes.func.isRequired,
  onOrgClicked: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  isSwitchingOrg: PropTypes.bool.isRequired
};

interface OrgTeamsProps {
  member: OrgMemberForOrg;
  onOrgClicked: (id: string) => Promise<void>;
  onTeamClicked: (id: string) => Promise<void>;
  opened: boolean;
  isSwitchingOrg: boolean;
}

const OrgTeams: FC<OrgTeamsProps> = ({ member, onOrgClicked, onTeamClicked, opened, isSwitchingOrg }) => {
  const { orgInfos, teamInfos, activeOrgId, activeTeamId } = useSelector((state) => state.organization);
  const orgInfo = orgInfos.find((org) => org.organizationId === member.oid);
  const [id, setId] = useState('');
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <MenuItem
        sx={{
          px: 3,
          py: 1.5
        }}
        onClick={async () => { await onOrgClicked(orgInfo.organizationId); }}
        disabled={isSwitchingOrg}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <UserAvatar
            userInfo={orgToMiniUser(orgInfo)}
            size={25}
            showType={false}
          />
          <Typography
            variant="body1"
            pl={1}
            mt={0.5}
            sx={{
              ...(
                activeOrgId === orgInfo.organizationId && {
                  color: 'primary.main',
                  fontWeight: !activeTeamId ? 'bold' : 'medium'
                }
              )
            }}
          >
            {orgInfo.tradingName}
          </Typography>
        </Box>
      </MenuItem>
      <Collapse in={opened}>
        {member.tids.map((teamId) => {
          const team = teamInfos.find((teamInfo) => teamInfo.tid === teamId);
          return (
            <MenuItem
              key={teamId}
              onClick={async () => { setId(teamId); await onTeamClicked(teamId); }}
              sx={{
                pl: 6,
                pr: 3,
                py: 1.5
              }}
              disabled={isSwitchingOrg}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                width="100%"
              >
                <PeopleAltIcon
                  fontSize="small"
                  sx={{
                    ...(
                      activeTeamId === teamId && {
                        color: 'primary.main'
                      }
                    )
                  }}
                />
                <Typography
                  variant="body2"
                  pl={1}
                  mt={0.5}
                  sx={{
                    ...(
                      activeTeamId === teamId && {
                        color: 'primary.main',
                        fontWeight: 'bold'
                      }
                    )
                  }}
                >
                  {team.name}
                </Typography>
                <Box flexGrow={1} />
                {id === teamId && isSwitchingOrg && <CircularProgress size={16} />}
              </Box>
            </MenuItem>
          );
        })}
      </Collapse>
    </Box>
  );
};

OrgTeams.propTypes = {
  member: PropTypes.any.isRequired,
  onOrgClicked: PropTypes.func.isRequired,
  onTeamClicked: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  isSwitchingOrg: PropTypes.bool.isRequired
};

interface OrgPopoverProps {
  loading: boolean;
  onChange: (oid: string, tid: string) => Promise<void>;
  isSwitchingOrg: boolean;
}

const maxCount = 10;

const getPopHeight = (orgCount: number, teamCount: number, idx: number): number => {
  if (orgCount + teamCount > maxCount) {
    if (idx >= maxCount) return maxCount * 52;
    if (idx + teamCount >= maxCount) return (idx + 1) * 52 + (maxCount - 1 - idx) * 48;
    return teamCount * 48 + (maxCount - teamCount) * 52 + 52 + 1;
  }
  return orgCount * 52 + teamCount * 48 + 52 + 1;
};

const OrgPopover: FC<OrgPopoverProps> = ({ loading, onChange, isSwitchingOrg }) => {
  const auth = useAuth();
  const { pathname } = useLocation();
  const { settings } = useSettings();
  const mounted = useMounted();
  const navigate = useNavigate();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { memberInfos, orgInfos, activeOrgId, activeTeamId } = useSelector((state) => state.organization);
  const clientSupplierInfos = orgInfos.filter((org) => memberInfos.filter((member) => member.type === ACCZIOM_CLIENT).map((member) => member.oid).includes(org.organizationId));
  const clientSupplierIds = clientSupplierInfos.map((org) => org.organizationId);
  const { userInfo } = useSelector((state) => state.user);
  const [orgMenuOpen, setOrgMenuOpen] = useState<boolean>(false);
  const [openedOrgId, setOpenedOrgId] = useState<string>(activeOrgId);
  const [popHeight, setPopHeight] = useState<number>(getPopHeight(
    clientSupplierIds.length > 0 ? memberInfos.length - clientSupplierIds.length + 1 : memberInfos.length,
    clientSupplierIds.length > 0 && clientSupplierIds.includes(openedOrgId) ? clientSupplierIds.length : memberInfos.find((member) => member.oid === openedOrgId).tids.length,
    clientSupplierIds.length > 0 ? tenary(clientSupplierIds.includes(openedOrgId), 0, memberInfos.findIndex((member) => member.oid === openedOrgId) + 1) : memberInfos.findIndex((member) => member.oid === openedOrgId)
  ));

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'CUSTOM'
  });

  const handleClickMe = (): void => {
    setOpenedOrgId(clientSupplierIds[0]);
  };

  const handleClickOrg = async (orgId: string): Promise<void> => {
    setOpenedOrgId(orgId);
    if (memberInfos.find((member) => member.oid === orgId).tids.length < 1) {
      if (activeOrgId !== orgId) await onChange(orgId, null);
      if (mounted.current) setOrgMenuOpen(false);
    }
  };

  const handleClickSupplierOrg = async (orgId: string): Promise<void> => {
    if (activeOrgId !== orgId) await onChange(orgId, null);
    if (mounted.current) setOrgMenuOpen(false);
  };

  const handleClickTeam = async (teamId: string): Promise<void> => {
    if (activeTeamId !== teamId) await onChange(openedOrgId, teamId);
    if (mounted.current) setOrgMenuOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      await auth.logout();
      if (pathname === '/' || pathname.startsWith('/marketplace')) {
        if (process.env.NODE_ENV === 'production') window.location.href = 'https://go.m.accziom.com';
        else navigate('/');
      }
    } catch (err) {
      console.log(err);
      toast.error('Unexpected error occured, Please try again!');
    }
  };

  useEffect(() => {
    if (mounted.current) {
      setPopHeight(getPopHeight(
        clientSupplierIds.length > 0 ? memberInfos.length - clientSupplierIds.length + 1 : memberInfos.length,
        clientSupplierIds.length > 0 && clientSupplierIds.includes(openedOrgId) ? clientSupplierIds.length : memberInfos.find((member) => member.oid === openedOrgId).tids.length,
        clientSupplierIds.length > 0 ? tenary(clientSupplierIds.includes(openedOrgId), 0, memberInfos.findIndex((member) => member.oid === openedOrgId) + 1) : memberInfos.findIndex((member) => member.oid === openedOrgId)
      ));
    }
  }, [mounted, memberInfos, openedOrgId]);

  useEffect(() => {
    if (mounted.current && orgMenuOpen) setOpenedOrgId(activeOrgId);
  }, [mounted, orgMenuOpen]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      sx={{
        p: 0,
        cursor: 'pointer',
        ':hover p': {
          textDecoration: 'underline'
        },
        ':hover div:nth-of-type(1)': {
          transform: 'scale(.9, .9)'
        }
      }}
      ref={anchorRef}
      onClick={() => {
        if (!orgMenuOpen) setOrgMenuOpen(true);
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: 32,
          height: 32,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <UserAvatar
          userInfo={userToMiniUser(userInfo)}
          size={28}
          showType={false}
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
      >
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start'
            }}
          >
            <IconButton
              sx={{
                width: 24,
                height: 24
              }}
            >
              <ArrowDropDownIcon
                sx={{
                  width: 21,
                  height: 21,
                  mt: '-3px',
                  ml: '-2px',
                  transition: '.3s',
                  transform: `rotate(${orgMenuOpen ? 180 : 0}deg)`
                }}
              />
            </IconButton>
          </Box>
          <BottomPopupDialog
            open={orgMenuOpen}
            onClose={() => { setOrgMenuOpen(false); }}
            title="Switch Org/Client"
          >
            <Box>
              {
                loading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <CircularProgress
                      color="primary"
                      size={20}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      height: `${popHeight}px`
                    }}
                    className="scrollbarY"
                  >
                    {clientSupplierInfos.length > 0 && (
                      <MeOrgs
                        orgs={clientSupplierInfos}
                        onMeClicked={handleClickMe}
                        onOrgClicked={handleClickSupplierOrg}
                        opened={clientSupplierIds.includes(openedOrgId)}
                        isSwitchingOrg={isSwitchingOrg}
                      />
                    )}
                    {memberInfos.filter((member) => member.type === ACCZIOM_MEMBER).map((member) => (
                      <OrgTeams
                        key={member.mid}
                        member={member}
                        onOrgClicked={handleClickOrg}
                        onTeamClicked={handleClickTeam}
                        opened={openedOrgId === member.oid}
                        isSwitchingOrg={isSwitchingOrg}
                      />
                    ))}
                    <Divider
                      sx={{
                        mx: 1
                      }}
                    />
                    <MenuItem
                      sx={{
                        px: 3,
                        py: 1.5
                      }}
                      onClick={handleLogout}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        sx={{
                          '& *': {
                            color: (thm) => thm.palette.secondary.main
                          }
                        }}
                      >
                        <LogoutIcon
                          style={{
                            width: 25,
                            height: 25
                          }}
                        />
                        <Typography
                          variant="body1"
                          pl={1}
                          mt={0.5}
                        >
                          Sign Out
                        </Typography>
                      </Box>
                    </MenuItem>
                  </Box>
                )
              }
            </Box>
          </BottomPopupDialog>
        </ThemeProvider>
      </Box>
    </Box>
  );
};

OrgPopover.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isSwitchingOrg: PropTypes.bool.isRequired
};

export default OrgPopover;
