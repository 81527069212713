import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, InputAdornment, Typography } from '@material-ui/core';
import useMounted from 'src/hooks/useMounted';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'src/store';
import { lambdaMercDeposit } from 'src/aws/lambdaDispatch';
import GasLimit from './GasLimit';
import { getActiveInfo } from 'src/utils/getActiveOrgInfo';
import { setTransactions } from 'src/slices/wallet';
import { v4 as uuidv4 } from 'uuid';
import NumberField from '../interact/NumberField';
import { ACCZIOM_CLIENT, ACCZIOM_ORG } from 'src/globals';
import BottomPopupDialog from '../dialog/BottomPopupDialog';

interface MRCTransactionProps {
  open: boolean;
  handleClose: () => void;
}

const MRCDepositDialog: FC<MRCTransactionProps> = (props) => {
  const { open, handleClose } = props;
  const mounted = useMounted();
  const dispatch = useDispatch();
  const [fee, setFee] = useState<number>(1);
  const [isSubmitting, setSubmitting] = useState(false);
  const minGas = 1;
  const [gasLimit, setGasLimit] = useState<number>(minGas);
  const gasError = gasLimit > 0 && gasLimit < minGas;

  const amountError = fee === null || (gasLimit > 0 && fee <= gasLimit);

  const { balances, wallet, isMRCL0, transactions } = useSelector((state) => state.wallet);
  const activeInfo = getActiveInfo(ACCZIOM_CLIENT, ACCZIOM_ORG);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const res = await lambdaMercDeposit(activeInfo, fee, gasLimit);
      if (res && !res.errorMessage) {
        dispatch(setTransactions([{
          uid: uuidv4(),
          fromId: activeInfo,
          from: wallet.address,
          fee: isMRCL0 ? -fee : fee,
          event: 'deposit',
          time: new Date().toISOString(),
          txn: {},
          status: 'Pending'
        }, ...transactions]));
        toast.success('The transaction has been successfully queued.');
        handleClose();
      } else {
        toast.error('Failed to deposit!');
      }
    } catch (err) {
      toast.error('Failed to deposit!');
    } finally {
      if (mounted.current) setSubmitting(false);
    }
  };

  return (
    <BottomPopupDialog
      open={open}
      onClose={handleClose}
      title="Deposit"
    >
      <Box
        sx={{
          mx: 2,
          mb: 1
        }}
      >
        <Typography>
          Deposit from Layer 1 to Layer 2 to use in Accziom services (e.g. Business Search Service).
          Please enter the amount to deposit and click Deposit button.
          The transaction may take several minutes.
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{
            my: 5
          }}
        >
          <NumberField
            size="small"
            value={fee}
            // type="number"
            onNumChange={(val) => {
              if (val === null) {
                setFee(val);
                return;
              }
              if (val < 0) val = 0;
              if (balances && balances.mrc && val > balances.mrc.layer1) val = balances.mrc.layer1;
              setFee(val);
            }}
            label="Amount *"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    MERc
                  </Typography>
                </InputAdornment>
              )
            }}
            sx={{
              mb: 2
            }}
            error={amountError}
            helperText={amountError && 'Amount should be larger than gas limit.'}
          />
          <GasLimit
            gasLimit={gasLimit}
            onSetGasLimit={(v) => setGasLimit(v)}
            gasParams={{
              type: 'deposit'
            }}
            minGas={1}
            estimateDisabled={fee === 0}
          />
        </Box>
        {isSubmitting && (
          <Typography
            sx={{
              textAlign: 'center'
            }}
          >
            Transaction is submitting. You can close this dialog.
          </Typography>
        )}
        <Box
          display="flex"
          justifyContent="center"
          my={2}
        >
          <Button
            color="primary"
            size="medium"
            sx={{
              width: '120px'
            }}
            type="button"
            variant="text"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="medium"
            sx={{
              ml: 2,
              width: '120px'
            }}
            type="button"
            onClick={handleSubmit}
            disabled={isSubmitting || fee === 0 || amountError || gasError}
            variant="contained"
            startIcon={isSubmitting ? <CircularProgress size={14} /> : null}
          >
            Deposit
          </Button>
        </Box>
      </Box>
    </BottomPopupDialog>
  );
};

MRCDepositDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};
export default MRCDepositDialog;
