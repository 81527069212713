import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { OrganizationExt, OrgMemberRole, OrgMemberForOrg, OrgTeam, OrgExtendedTeam } from 'src/../../Common/Model/organization';
import { UserMinInfo } from 'src/../../Common/Model/common';
import { AnzicCategory } from 'src/../../Common/Model/taxonomy';

interface ExchangeRate {
  AUD: number;
  USD: number;
  ETH: number;
  USDT: number;
  MERC: number;
}

const initialRate = { AUD: 1, USD: 1, ETH: 1, USDT: 1, MERC: 1 };

interface OrganizationState {
  memberInfos: OrgMemberForOrg[],
  orgInfos: OrganizationExt[];
  categoryInfos: AnzicCategory[];
  roleInfos: OrgMemberRole[];
  teamInfos: OrgTeam[];
  activeOrgId: string;
  activeTeamId: string;
  membersInCurOrg: OrgMemberForOrg[];
  memberRolesInCurOrg: OrgMemberRole[];
  teamsInCurOrg: OrgExtendedTeam[];
  bannerUrl: string,
  itemsPinned: any[];
  userMinInfos: UserMinInfo[];
  orgsNeedReload: boolean;
  curOrgNeedReload: boolean;
  curExRate: ExchangeRate;
}

const initialState: OrganizationState = {
  memberInfos: [],
  orgInfos: [],
  categoryInfos: [],
  roleInfos: [],
  teamInfos: [],
  activeOrgId: null,
  activeTeamId: null,
  membersInCurOrg: [],
  memberRolesInCurOrg: [],
  teamsInCurOrg: [],
  bannerUrl: null,
  itemsPinned: [],
  userMinInfos: [],
  orgsNeedReload: true,
  curOrgNeedReload: true,
  curExRate: initialRate
};

const cmpRoleFn = (a: OrgMemberRole, b: OrgMemberRole): number => (a.level - b.level);

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    clearState(state: OrganizationState): void {
      state.memberInfos.splice(0, state.memberInfos.length);
      state.orgInfos.splice(0, state.orgInfos.length);
      state.categoryInfos.splice(0, state.categoryInfos.length);
      state.roleInfos.splice(0, state.roleInfos.length);
      state.teamInfos.splice(0, state.teamInfos.length);
      state.activeOrgId = null;
      state.activeTeamId = null;
      state.membersInCurOrg.splice(0, state.membersInCurOrg.length);
      state.memberRolesInCurOrg.splice(0, state.memberRolesInCurOrg.length);
      state.teamsInCurOrg.splice(0, state.teamsInCurOrg.length);
      state.bannerUrl = null;
      state.itemsPinned.splice(0, state.itemsPinned.length);
      state.userMinInfos.splice(0, state.userMinInfos.length);
      state.orgsNeedReload = true;
      state.curOrgNeedReload = true;
      state.curExRate = initialRate;
    },
    setOrgsNeedReload(state: OrganizationState, action: PayloadAction<boolean>): void {
      state.orgsNeedReload = action.payload;
    },
    setBannerUrl(state: OrganizationState, action: PayloadAction<string>): void {
      if (state.bannerUrl) window.URL.revokeObjectURL(state.bannerUrl);
      state.bannerUrl = action.payload;
    },
    setOrgStructures(state: OrganizationState, action: PayloadAction<any>): void {
      const { members, organizations, categories, roles, teams } = action.payload;
      state.memberInfos = members;
      state.orgInfos = organizations;
      state.categoryInfos = categories;
      state.roleInfos = roles;
      state.teamInfos = teams;
      state.orgsNeedReload = false;
    },
    updateOrganizationInfo(state: OrganizationState, action: PayloadAction<OrganizationExt>): void {
      const newOrgInfo = action.payload as OrganizationExt;
      const oldOrgId = state.orgInfos.findIndex((orgInfo) => orgInfo.organizationId === newOrgInfo.organizationId);
      if (oldOrgId >= 0) state.orgInfos.splice(oldOrgId, 1, newOrgInfo);
    },
    createOrganizationInfo(state: OrganizationState, action: PayloadAction<{ memberInfo: OrgMemberForOrg, orgInfo: OrganizationExt, roleInfo: OrgMemberRole, teamInfos: OrgTeam[] }>): void {
      const { memberInfo, orgInfo, roleInfo, teamInfos } = action.payload;
      state.memberInfos.push(memberInfo);
      state.orgInfos.push(orgInfo);
      state.roleInfos.push(roleInfo);
      state.teamInfos.push(...teamInfos);
    },
    updateCategoryInfo(state: OrganizationState, action: PayloadAction<AnzicCategory>): void {
      const newCategory = action.payload;
      const oldCategoryIndex = state.categoryInfos.findIndex((cat) => cat.id === newCategory.id);
      if (oldCategoryIndex >= 0) state.categoryInfos.splice(oldCategoryIndex, 1, newCategory);
      else state.categoryInfos.push(newCategory);
    },
    setActiveOrgId(state: OrganizationState, action: PayloadAction<string>): void {
      state.activeOrgId = action.payload as string;
    },
    setActiveTeamId(state: OrganizationState, action: PayloadAction<string>): void {
      state.activeTeamId = action.payload as string;
    },
    updateMemberRoleInCurOrg(state: OrganizationState, action: PayloadAction<OrgMemberRole>): void {
      const newRole = action.payload as OrgMemberRole;
      let oldRoleId = state.roleInfos.findIndex((role) => role.rid === newRole.rid);
      if (oldRoleId >= 0) state.roleInfos.splice(oldRoleId, 1, newRole);
      oldRoleId = state.memberRolesInCurOrg.findIndex((role) => role.rid === newRole.rid);
      if (oldRoleId >= 0) state.memberRolesInCurOrg.splice(oldRoleId, 1, newRole);
      state.memberRolesInCurOrg.sort(cmpRoleFn);
    },
    setCurOrgNeedReload(state: OrganizationState, action: PayloadAction<boolean>): void {
      state.curOrgNeedReload = action.payload;
    },
    setCurOrgStructure(state: OrganizationState, action: PayloadAction<any>): void {
      const { members, roles, teams } = action.payload;
      state.membersInCurOrg = members;
      state.memberRolesInCurOrg = roles;
      state.teamsInCurOrg = teams;
      state.memberRolesInCurOrg.sort(cmpRoleFn);
      state.curOrgNeedReload = false;
    },
    addMemberToCurOrg(state: OrganizationState, action: PayloadAction<OrgMemberForOrg>): void {
      const newMember = action.payload as OrgMemberForOrg;
      state.membersInCurOrg.push(newMember);
    },
    updateMemberInCurOrg(state: OrganizationState, action: PayloadAction<any>): void {
      const body = action.payload;
      const curMember = state.membersInCurOrg.find((member) => member.mid === body.mid);
      if (curMember) {
        Object.keys(body).forEach((key) => {
          if (key !== 'mid') curMember[key] = body[key];
        });
      }
    },
    updateOrgMembers(state: OrganizationState, action: PayloadAction<any>): void {
      const { newMembers, deleteIds } = action.payload;
      state.membersInCurOrg.push(...newMembers.map((member) => ({ ...member, tids: [] } as OrgMemberForOrg)));
      state.membersInCurOrg = state.membersInCurOrg.filter((member) => !deleteIds.includes(member.mid));
      state.teamsInCurOrg.forEach((team) => {
        team.mids = team.mids.filter((memberId) => !deleteIds.includes(memberId));
      });
    },
    deleteMemberFromCurOrg(state: OrganizationState, action: PayloadAction<string>): void {
      const mid = action.payload as string;
      state.membersInCurOrg = state.membersInCurOrg.filter((member) => mid !== member.mid);
      state.teamsInCurOrg.forEach((team) => {
        team.mids = team.mids.filter((memberId) => memberId !== mid);
      });
    },
    addMemberRoleToCurOrg(state: OrganizationState, action: PayloadAction<OrgMemberRole>): void {
      const newRole = action.payload as OrgMemberRole;
      state.memberRolesInCurOrg.push(newRole);
      state.memberRolesInCurOrg.sort(cmpRoleFn);
    },
    deleteMemberRoleFromCurOrg(state: OrganizationState, action: PayloadAction<string>): void {
      const rid = action.payload as string;
      state.roleInfos = state.roleInfos.filter((role) => role.rid !== rid);
      state.membersInCurOrg.filter((member) => member.rid === rid).forEach((member) => {
        state.teamsInCurOrg.forEach((team) => {
          team.mids = team.mids.filter((memberId) => memberId !== member.mid);
        });
      });
      state.membersInCurOrg = state.membersInCurOrg.filter((member) => member.rid !== rid);
      state.memberRolesInCurOrg = state.memberRolesInCurOrg.filter((role) => role.rid !== rid);
    },
    addTeamToCurOrg(state: OrganizationState, action: PayloadAction<OrgExtendedTeam>): void {
      const newTeam = action.payload as OrgExtendedTeam;
      state.teamsInCurOrg.push(newTeam);
      state.membersInCurOrg.forEach((member) => {
        if (newTeam.mids.includes(member.mid)) {
          member.tids.push(newTeam.tid);
        }
      });
      state.memberInfos.forEach((member) => {
        if (newTeam.mids.includes(member.mid)) {
          member.tids.push(newTeam.tid);
        }
      });
      const activeMember = state.memberInfos.find((member) => member.oid === state.activeOrgId);
      if (activeMember && newTeam.mids.includes(activeMember.mid)) state.teamInfos.push(newTeam as OrgTeam);
      const orgId = state.orgInfos.findIndex((orgInfo) => orgInfo.organizationId === state.activeOrgId);
      if (!state.orgInfos[orgId].businessInfo.activatedTeams) {
        const newOrgInfo = {
          ...state.orgInfos[orgId],
          businessInfo: {
            ...state.orgInfos[orgId].businessInfo,
            activatedTeams: true
          }
        };
        state.orgInfos.splice(orgId, 1, newOrgInfo);
      }
    },
    deleteTeamFromCurOrg(state: OrganizationState, action: PayloadAction<string>): void {
      const tid = action.payload as string;
      state.teamInfos = state.teamInfos.filter((team) => team.tid !== tid);
      state.teamsInCurOrg = state.teamsInCurOrg.filter((team) => team.tid !== tid);
      state.membersInCurOrg.forEach((member) => {
        member.tids = member.tids.filter((teamId) => teamId !== tid);
      });
      state.memberInfos.forEach((member) => {
        member.tids = member.tids.filter((teamId) => teamId !== tid);
      });
    },
    updateTeamInCurOrg(state: OrganizationState, action: PayloadAction<OrgExtendedTeam>): void {
      const newTeam = action.payload as OrgExtendedTeam;
      const oldTeam = state.teamsInCurOrg.find((team) => team.tid === newTeam.tid);
      if (oldTeam) {
        const oldMids = oldTeam.mids;
        const newMids = newTeam.mids;
        const deleteMids = oldMids.filter((memberId) => !newMids.includes(memberId));
        const addMids = newMids.filter((memberId) => !oldMids.includes(memberId));
        state.membersInCurOrg.forEach((member) => {
          if (deleteMids.includes(member.mid)) {
            member.tids = member.tids.filter((teamId) => teamId !== newTeam.tid);
          }
          if (addMids.includes(member.mid)) {
            member.tids.push(newTeam.tid);
          }
        });
        state.memberInfos.forEach((member) => {
          if (deleteMids.includes(member.mid)) {
            member.tids = member.tids.filter((teamId) => teamId !== newTeam.tid);
          }
          if (addMids.includes(member.mid)) {
            member.tids.push(newTeam.tid);
          }
        });
        const activeMember = state.memberInfos.find((member) => member.oid === state.activeOrgId);
        if (activeMember && deleteMids.includes(activeMember.mid)) state.teamInfos = state.teamInfos.filter((team) => team.tid !== newTeam.tid);
        if (activeMember && addMids.includes(activeMember.mid)) state.teamInfos.push(newTeam as OrgTeam);
        state.teamsInCurOrg = state.teamsInCurOrg.map((team) => (team.tid === newTeam.tid ? newTeam : team));
      }
    },
    updateMemberPinnedItem(state: OrganizationState, action: PayloadAction<any>): void {
      const { memberId, itemsPinned } = action.payload;
      state.itemsPinned = itemsPinned;
      const member = state.memberInfos.find((memberInfo) => memberInfo.mid === memberId);
      if (member) member.itemsPinned = itemsPinned;
      const memberInCurOrg = state.membersInCurOrg.find((memberInfo) => memberInfo.mid === memberId);
      if (memberInCurOrg) memberInCurOrg.itemsPinned = itemsPinned;
    },
    setItemsPinned(state: OrganizationState, action: PayloadAction<any>): void {
      state.itemsPinned = action.payload;
    },
    setUserMinInfos(state: OrganizationState, action: PayloadAction<{ userMinInfos: UserMinInfo[]; }>) {
      const { userMinInfos } = action.payload;
      state.userMinInfos = userMinInfos;
    },
    updateUserMinInfos(state: OrganizationState, action: PayloadAction<{ userMinInfos: UserMinInfo[]; }>) {
      const { userMinInfos } = action.payload;
      userMinInfos.forEach((newInfo) => {
        const oldInfo = state.userMinInfos.find((item) => item.uid === newInfo.uid);
        if (oldInfo) {
          Object.keys(newInfo).forEach((key) => {
            if (key !== 'id' && key !== 'allIds') oldInfo[key] = newInfo[key];
            if (key === 'allIds') {
              newInfo.allIds.forEach((newId) => {
                if (newId.id) {
                  const index = oldInfo.allIds.findIndex((id) => id.id === newId.id);
                  oldInfo.allIds.splice(index < 0 ? oldInfo.allIds.length : index, index < 0 ? 0 : 1, newId);
                }
              });
            }
          });
        } else state.userMinInfos.push(newInfo);
      });
    },
    updateCurExRate(state: OrganizationState, action: PayloadAction<any>): void {
      const updated = action.payload;
      if (updated && Object.keys(updated)?.length > 0) {
        Object.keys(updated).forEach((key) => {
          state.curExRate[key] = updated[key];
        });
      }
    }
  }
});

export const { reducer } = slice;

export const clearOrganizationState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};
export const setOrgsNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setOrgsNeedReload(needReload));
};
export const setBannerUrl = (url: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setBannerUrl(url));
};
export const setOrgStructures = (_members: any[], _orgs: any[], _categories: any[], _roles: any[], _teams: any[]) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setOrgStructures({
    members: _members as OrgMemberForOrg[],
    organizations: _orgs as OrganizationExt[],
    categories: _categories as AnzicCategory[],
    roles: _roles as OrgMemberRole[],
    teams: _teams as OrgTeam[]
  }));
};
export const updateOrganizationInfo = (org: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateOrganizationInfo(org as OrganizationExt));
};
export const createOrganizationInfo = (memberInfo: OrgMemberForOrg, orgInfo: OrganizationExt, roleInfo: OrgMemberRole, teamInfos: OrgTeam[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createOrganizationInfo({ memberInfo, orgInfo, roleInfo, teamInfos }));
};
export const updateCategoryInfo = (cat: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateCategoryInfo(cat as AnzicCategory));
};
export const setActiveOrgId = (oid: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setActiveOrgId(oid));
};
export const setActiveTeamId = (tid: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setActiveTeamId(tid));
};
export const setCurOrgNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setCurOrgNeedReload(needReload));
};
export const setCurOrgStructure = (members: any[], roles: any[], teams: any[], userMinInfos: any[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateUserMinInfos({ userMinInfos: userMinInfos as UserMinInfo[] }));
  dispatch(slice.actions.setCurOrgStructure({
    members: members as OrgMemberForOrg[],
    roles: roles as OrgMemberRole[],
    teams: teams as OrgExtendedTeam[]
  }));
};
export const addMemberToCurOrg = (member: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.addMemberToCurOrg(member as OrgMemberForOrg));
};
export const updateMemberInCurOrg = (body: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateMemberInCurOrg(body));
};
export const deleteMemberFromCurOrg = (mid: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteMemberFromCurOrg(mid));
};
export const addMemberRoleToCurOrg = (role: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addMemberRoleToCurOrg(role as OrgMemberRole));
};
export const updateMemberRoleInfo = (role: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateMemberRoleInCurOrg(role as OrgMemberRole));
};
export const deleteMemberRoleFromCurOrg = (rid: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteMemberRoleFromCurOrg(rid));
};
export const addTeamToCurOrg = (team: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.addTeamToCurOrg(team as OrgExtendedTeam));
};
export const deleteTeamFromCurOrg = (tid: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteTeamFromCurOrg(tid));
};
export const updateTeamInCurOrg = (team: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateTeamInCurOrg(team as OrgExtendedTeam));
};
export const updateMemberPinnedItem = (memberId: string, itemsPinned: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateMemberPinnedItem({ memberId, itemsPinned }));
};
export const setItemsPinned = (itemsPinned: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setItemsPinned(itemsPinned));
};
export const updateOrgMembers = (newMembers: any[], deleteIds: string[], userMinInfos: any[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateUserMinInfos({ userMinInfos: userMinInfos as UserMinInfo[] }));
  dispatch(slice.actions.updateOrgMembers({ newMembers, deleteIds }));
};
export const updateUserMinInfos = (userMinInfos: any[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateUserMinInfos({ userMinInfos: userMinInfos as UserMinInfo[] }));
};
export const cleanUserMinInfos = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setUserMinInfos({ userMinInfos: [] }));
};

export const updateCurExRate = (rateData: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateCurExRate(rateData));
};

export default slice;
