import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { FileThread } from 'src/../../Common/Model/fileshare';
import { Tag } from 'src/../../Common/Model/tag';
import type { Message, Contact } from 'src/types/chat';
import { deepCopy } from 'src/utils/copyObject';

export interface MiniChatThread {
  sid: string;
  title: string;
  messages: Message[];
  createdBy: string;
  participants: Contact[];
  type: number;
}

interface FileshareState {
  needReload: boolean,
  fileThreads: FileThread[],
  tags: Tag[],
  chatThread: MiniChatThread | null
}

const initialState: FileshareState = {
  needReload: true,
  fileThreads: [],
  tags: [],
  chatThread: null
};

const slice = createSlice({
  name: 'fileshare',
  initialState,
  reducers: {
    clearState(state: FileshareState) {
      state.needReload = true;
      state.fileThreads.splice(0, state.fileThreads.length);
      state.tags.splice(0, state.tags.length);
      state.chatThread = null;
    },
    setThreads(state: FileshareState, action: PayloadAction<{ threads: FileThread[]; }>) {
      const { threads } = action.payload;
      state.fileThreads = [...threads];
      state.needReload = false;
    },
    addFilethread(state: FileshareState, action: PayloadAction<{ filethread: FileThread; }>) {
      const { filethread } = action.payload;
      state.fileThreads = [filethread].concat(state.fileThreads);
      // state.needRefresh = !state.needRefresh;
    },
    updateFilethread(state: FileshareState, action: PayloadAction<{ thread: FileThread; }>) {
      const { thread } = action.payload;
      const index = state.fileThreads.findIndex((item) => item.id === thread.id);
      if (index > -1) state.fileThreads[index] = thread;
      // state.needRefresh = !state.needRefresh;
    },
    setNeedReload(state: FileshareState, action: PayloadAction<{ needReload: boolean; }>) {
      const { needReload } = action.payload;
      state.needReload = needReload;
    },
    setTags(state: FileshareState, action: PayloadAction<Tag[]>): void {
      state.tags = action.payload;
    },
    addTags(state: FileshareState, action: PayloadAction<Tag[]>): void {
      state.tags = [...state.tags, ...action.payload];
    },
    setChatThread(state: FileshareState, action: PayloadAction<MiniChatThread>) {
      state.chatThread = deepCopy(action.payload);
    },
    initChatThread(state: FileshareState): void {
      state.chatThread = null;
    }
  }
});

export const { reducer } = slice;

export const clearFileShareState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};
export const loadThreads = (threads: FileThread[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setThreads({ threads }));
};
export const setNeedReload = (value: boolean): AppThunk => async (dispatch) => {
  const needReload = value;
  dispatch(slice.actions.setNeedReload({ needReload }));
};
export const addFilethread = (filethread: FileThread): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addFilethread({ filethread }));
};
export const updateFilethread = (thread: FileThread): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFilethread({ thread }));
};
export const setTags = (tags: Tag[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTags(tags));
};
export const addTags = (tags: Tag[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addTags(tags));
};
export const setChatThread = (conv: MiniChatThread): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setChatThread(conv));
};
export const initChatThread = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.initChatThread());
};

export default slice;
