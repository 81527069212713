import { useEffect, type FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

interface PrettySelectProps {
  onChange: (string) => void;
  value: string;
  keyValue?: string;
  title?: string;
  items: any[];
  itemComponent: any;
  hasBorder?: boolean;
  inline?: boolean;
  sx?: any;
}

const PrettySelect: FC<PrettySelectProps> = (props) => {
  const { onChange, value, keyValue, title, items, itemComponent: ItemComponent, hasBorder = true, inline, sx } = props;
  const key = keyValue || 'id';
  useEffect(() => {
    if (value === undefined) return;
    if (items[0]?.id === undefined) return;
    const v = items.find((item) => item.id === value);
    if (v === undefined) {
      onChange(items[0]?.id);
    }
  }, [items]);

  return (
    <FormControl
      fullWidth
      size="small"
      sx={{
        ...(!hasBorder && { '& fieldset': { borderColor: 'transparent !important' } }),
        ...(inline && { mt: -1 }),
        ...sx
      }}
    >
      {title && (
        <InputLabel id={`${title}_label`}>
          {title}
        </InputLabel>
      )}
      <Select
        {...(title ? { label: title } : {})}
        labelId={`${title}_label`}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        value={value}
      >
        {items.map((item) => (
          <MenuItem
            value={item[key]}
            key={item[key]}
          >
            <ItemComponent {...item} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

PrettySelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  keyValue: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
  itemComponent: PropTypes.any,
  hasBorder: PropTypes.bool,
  inline: PropTypes.bool
};

export default PrettySelect;

export const TextItem1: FC<{ title: string }> = ({ title }) => (
  <Typography
    color="textPrimary"
    variant="body1"
    noWrap
  >
    {title}
  </Typography>
);

TextItem1.propTypes = {
  title: PropTypes.string
};

const TextItem2: FC<{ title: string }> = ({ title }) => (
  <Typography
    color="textPrimary"
    variant="body2"
    noWrap
  >
    {title}
  </Typography>
);

TextItem2.propTypes = {
  title: PropTypes.string
};
