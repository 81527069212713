import toast from 'react-hot-toast';
import {
  UPFRONT_PAYMENT,
  COMPLETION_PAYMENT,
  TWOTIME_PAYMENT,
  PERIOD_PAYMENT,
  DELIVERY_PAYMENT,
  NOT_REQUIRED_PO,
  SIMPLE_INTEREST_TYPE,
  DELIVERY_MODE,
  PAYMENT_MODE,
  CONTRACT_MODE,
  PURCHASE_ORDER_MODE,
  COMPOUND_INTEREST_TYPE,
  DAY_MILISECONDS,
  REQUEST_ONE_OFF,
  ON_COMPLETION,
  periodLengthList,
  LENGTH_MONTH,
  LENGTH_YEAR,
  STATUS_TEXT_SUBMITTED,
  REFERRAL_MODE,
  RATE_PERCENT
} from 'src/globals';
import { Schedule, Contract, PO, DeliveryItem, PaymentItem } from 'src/../../Common/Model/procurement';
import { v4 as uuidv4 } from 'uuid';
import { getPeriodOffsetDaysByCount, getPeriodOffsetFromDate, getPeriodicalDatesByCount, getTimeMovedByDuration } from 'src/utils/getPeriodOffsetDays';
import { removeTime, toDisplayDateFormat } from 'src/utils/dateFormatUtils';
import { PurchaseItem, DeliveredItem } from 'src/../../Common/Model/purchaseItem';
import { convert2Array } from '../agentUtils';

const getMergedPaymentSchedule = (schedules: Schedule[]): Schedule[] => {
  const paymentSchedulesDict = {};
  schedules.forEach((schedule) => {
    const dueDate = toDisplayDateFormat(schedule.dueDate);
    if (!paymentSchedulesDict[dueDate]) {
      paymentSchedulesDict[dueDate] = {
        ...schedule,
        dueDate: removeTime(schedule.dueDate).toISOString()
      };
    } else {
      const mat = schedule.material as PaymentItem;
      const prevMat = paymentSchedulesDict[dueDate].material as PaymentItem;
      paymentSchedulesDict[dueDate] = {
        ...paymentSchedulesDict[dueDate],
        material: {
          ...prevMat,
          principal: mat.principal + prevMat.principal,
          interest: mat.interest + prevMat.interest,
          other: mat.other + prevMat.other,
          paidTax: mat.paidTax + prevMat.paidTax,
          paidInterestLate: mat.paidInterestLate + prevMat.paidInterestLate
        }
      };
    }
  });
  return Object.keys(paymentSchedulesDict).map((k) => paymentSchedulesDict[k]);
};

const getMergedPrices = (prices: { dueDate: Date | string, amount: number }[]): { dueDate: Date | string, amount: number }[] => {
  const pricesDict = {};
  prices.forEach(({ dueDate, amount }) => {
    const strDueDate = toDisplayDateFormat(dueDate);
    if (!pricesDict[strDueDate]) {
      pricesDict[strDueDate] = {
        amount,
        dueDate: removeTime(dueDate).toISOString()
      };
    } else {
      const prevAmount = pricesDict[strDueDate].amount;
      pricesDict[strDueDate] = {
        ...pricesDict[strDueDate],
        amount: prevAmount + amount
      };
    }
  });
  return Object.keys(pricesDict).map((k) => pricesDict[k]);
};

const getMergedDates = (dueDates: (Date | string)[]): (Date | string)[] => {
  const mergedDates = [];
  dueDates.forEach((dueDate) => {
    const strDueDate = removeTime(dueDate).toISOString();
    if (!mergedDates.includes(strDueDate)) mergedDates.push(strDueDate);
  });
  return mergedDates;
};

export const getOtherPaymentByType = (principal: number, others: any): number => {
  let otherPayment = 0;
  if (others?.length > 0) {
    others.forEach(({ amount, unit }) => {
      if (unit === RATE_PERCENT) otherPayment += (principal * amount) / 100;
      else otherPayment += amount;
    });
  }
  return otherPayment;
};

export const getSchedulesByGenerator = (mode: number, fromContract: Contract, fromPO: PO = null, showEmptyError = true): { deliverySchedules: Schedule[]; paymentSchedules: Schedule[]; referralSchedules: Schedule[]; } => {
  const matchIndices = fromContract.delivery.filter((deliveryContract) => {
    if (mode === CONTRACT_MODE) return (deliveryContract.requirePO === NOT_REQUIRED_PO);
    return (deliveryContract.itemId === fromPO.material.id);
  }).map((deliveryContract, index) => (index));
  if (matchIndices.length < 1) {
    if (showEmptyError) toast.error('The material does not exist.');
    return { deliverySchedules: [], paymentSchedules: [], referralSchedules: [] };
  }
  const initSchedule = {
    contractId: fromContract.id,
    poId: mode === PURCHASE_ORDER_MODE ? fromPO.id : '',
    customer: mode === PURCHASE_ORDER_MODE ? fromPO.customer : fromContract.customer,
    customerAgent: mode === PURCHASE_ORDER_MODE ? convert2Array(fromPO.customerAgent) : convert2Array(fromContract.customerAgent),
    supplier: mode === PURCHASE_ORDER_MODE ? fromPO.supplier : fromContract.supplier,
    supplierAgent: mode === PURCHASE_ORDER_MODE ? convert2Array(fromPO.supplierAgent) : convert2Array(fromContract.supplierAgent),
    status: STATUS_TEXT_SUBMITTED,
    invoiceId: ''
  };
  const deliverySchedules: Schedule[] = [];
  const paymentSchedules: Schedule[] = [];
  const referralSchedules: Schedule[] = [];
  const { itemBriefs, validFrom, validTo } = fromContract;
  const { period, duration } = itemBriefs;
  const payment = fromContract.payment[0];
  const { costPrincipal, costInterest, costTax, costOther, paymentOption, paymentInitialAmount, paymentInitialOffset, paymentFinalPeriod, paymentFinalOffset, paymentInvoiceOffset, paymentInterestLate, paymentMethod, paymentTool, paymentCurrency } = payment;
  const bundlePeriodicalDates: { startDate: Date | string, endDate: Date | string }[] = [];
  if (period.type === REQUEST_ONE_OFF) {
    bundlePeriodicalDates.push({
      startDate: validFrom,
      endDate: validTo
    });
  } else {
    const bundleStartDates = getPeriodicalDatesByCount(validFrom, validTo, period.per, period.count);
    bundleStartDates.forEach((startDate) => {
      bundlePeriodicalDates.push({
        startDate,
        endDate: getTimeMovedByDuration(new Date(startDate), duration).toISOString()
      });
    });
  }
  bundlePeriodicalDates.forEach(({ startDate, endDate }) => {
    matchIndices.forEach((index) => {
      const delivery = fromContract.delivery[index];
      const deliveryDueDates = [];
      const { itemPeriod } = delivery;
      if (mode === PURCHASE_ORDER_MODE) {
        deliveryDueDates.push(fromPO.deliveryDate);
      } else if (itemPeriod.type === REQUEST_ONE_OFF) {
        const itemStartDate = getTimeMovedByDuration(new Date(startDate), { length: itemPeriod.offset, type: itemPeriod.offsetType }).toISOString();
        deliveryDueDates.push(itemStartDate);
      } else {
        const itemStartDate = getTimeMovedByDuration(new Date(startDate), { length: itemPeriod.offset, type: itemPeriod.offsetType }).toISOString();
        const itemEndDate = getTimeMovedByDuration(new Date(endDate), { length: itemPeriod.offset, type: itemPeriod.offsetType }).toISOString();
        deliveryDueDates.push(...getPeriodicalDatesByCount(itemStartDate, itemEndDate, itemPeriod.per, itemPeriod.count));
      }
      const totalQuantity = mode === PURCHASE_ORDER_MODE ? fromPO.material.quantity : delivery.volumes[0];
      const newQuantity = totalQuantity / deliveryDueDates.length;
      deliveryDueDates.forEach((dueDate) => {
        deliverySchedules.push({
          ...initSchedule,
          id: uuidv4(),
          dueDate,
          type: DELIVERY_MODE,
          material: {
            id: mode === PURCHASE_ORDER_MODE ? fromPO.material.id : delivery.itemId,
            title: mode === PURCHASE_ORDER_MODE ? fromPO.material.title : delivery.itemTitle,
            description: mode === PURCHASE_ORDER_MODE ? fromPO.material.description : delivery.itemDescription,
            life: mode === PURCHASE_ORDER_MODE ? fromPO.material.life : delivery.itemLife,
            status: mode === PURCHASE_ORDER_MODE ? fromPO.material.status : delivery.itemStatus,
            type: mode === PURCHASE_ORDER_MODE ? fromPO.material.type : delivery.itemType,
            collected: mode === PURCHASE_ORDER_MODE ? fromPO.material.collected : delivery.itemCollected,
            quantity: newQuantity,
            netPrice: mode === PURCHASE_ORDER_MODE ? fromPO.material.netPrice : delivery.prices[0],
            netValue: mode === PURCHASE_ORDER_MODE ? fromPO.material.netPrice * newQuantity : delivery.prices[0] * newQuantity
          } as DeliveryItem
        } as Schedule);
      });
    });
  });
  const dailyIR = (costInterest.rate / periodLengthList.find((lengthItem) => lengthItem.type === costInterest.period).days) / 100;
  const referralRate = fromContract.referral ? fromContract.referral.amount / 100 : 0;
  const otherPayment = getOtherPaymentByType(costPrincipal, costOther);
  if (paymentOption === UPFRONT_PAYMENT) {
    let signedDate = validFrom;
    if (mode === PURCHASE_ORDER_MODE) {
      signedDate = removeTime(fromPO.supplierSignedAt);
    } else {
      signedDate = new Date(fromContract.supplierSignedAt).getTime() >= new Date(fromContract.customerSignedAt).getTime() ? removeTime(fromContract.supplierSignedAt) : removeTime(fromContract.customerSignedAt);
    }
    const newDueDate = new Date(new Date(signedDate).getTime() + paymentInitialOffset * DAY_MILISECONDS).toISOString();
    paymentSchedules.push({
      ...initSchedule,
      id: uuidv4(),
      dueDate: newDueDate,
      type: PAYMENT_MODE,
      material: {
        principal: costPrincipal,
        interest: 0,
        tax: costTax,
        paidTax: ((costPrincipal + otherPayment) * costTax.gstRate) / 100,
        other: otherPayment,
        interestLate: paymentInterestLate,
        paidInterestLate: 0,
        invoiceOffset: paymentInvoiceOffset,
        paymentMethod,
        paymentTool,
        paymentCurrency
      } as PaymentItem
    } as Schedule);
    if (fromContract.referral) {
      referralSchedules.push({
        ...initSchedule,
        customer: fromContract.supplier,
        customerAgent: convert2Array(fromContract.supplierAgent),
        supplier: fromContract.referral.creator,
        supplierAgent: convert2Array(fromContract.referral.creatorAgent),
        id: uuidv4(),
        dueDate: newDueDate,
        type: REFERRAL_MODE,
        material: {
          principal: costPrincipal * referralRate,
          interest: 0,
          tax: costTax,
          paidTax: (costPrincipal * referralRate * costTax.gstRate) / 100,
          other: 0,
          interestLate: { ...paymentInterestLate, rate: 0 },
          paidInterestLate: 0,
          invoiceOffset: paymentInvoiceOffset,
          paymentMethod,
          paymentTool,
          paymentCurrency
        } as PaymentItem
      } as Schedule);
    }
  } else if (paymentOption === COMPLETION_PAYMENT) {
    let finalDate = validFrom;
    deliverySchedules.forEach((deliverySchedule) => {
      const finishDate = getTimeMovedByDuration(new Date(deliverySchedule.dueDate), (deliverySchedule.material as DeliveryItem).life).toISOString();
      if (new Date(finalDate).getTime() < new Date(finishDate).getTime()) finalDate = finishDate;
    });
    finalDate = new Date(new Date(finalDate).getTime() + paymentFinalOffset * DAY_MILISECONDS).toISOString();
    let newInterest = 0;
    if (mode === CONTRACT_MODE && dailyIR > 0) {
      const daysCount = Math.floor(((new Date(finalDate)).getTime() - (new Date(validFrom)).getTime() + 1) / DAY_MILISECONDS);
      if (costInterest.type === SIMPLE_INTEREST_TYPE) newInterest = costPrincipal * (dailyIR * daysCount);
      if (costInterest.type === COMPOUND_INTEREST_TYPE) newInterest = costPrincipal * ((1 + dailyIR) ** daysCount - 1);
    }
    paymentSchedules.push({
      ...initSchedule,
      id: uuidv4(),
      dueDate: finalDate,
      type: PAYMENT_MODE,
      material: {
        principal: costPrincipal,
        interest: newInterest,
        tax: costTax,
        paidTax: ((costPrincipal + newInterest + otherPayment) * costTax.gstRate) / 100,
        other: otherPayment,
        interestLate: paymentInterestLate,
        paidInterestLate: 0,
        invoiceOffset: paymentInvoiceOffset,
        paymentMethod,
        paymentTool,
        paymentCurrency
      } as PaymentItem
    } as Schedule);
    if (fromContract.referral) {
      referralSchedules.push({
        ...initSchedule,
        customer: fromContract.supplier,
        customerAgent: convert2Array(fromContract.supplierAgent),
        supplier: fromContract.referral.creator,
        supplierAgent: convert2Array(fromContract.referral.creatorAgent),
        id: uuidv4(),
        dueDate: finalDate,
        type: REFERRAL_MODE,
        material: {
          principal: costPrincipal * referralRate,
          interest: 0,
          tax: costTax,
          paidTax: ((costPrincipal + otherPayment) * referralRate * costTax.gstRate) / 100,
          other: otherPayment * referralRate,
          interestLate: { ...paymentInterestLate, rate: 0 },
          paidInterestLate: 0,
          invoiceOffset: paymentInvoiceOffset,
          paymentMethod,
          paymentTool,
          paymentCurrency
        } as PaymentItem
      } as Schedule);
    }
  } else if (paymentOption === TWOTIME_PAYMENT) {
    if (paymentInitialAmount > 0) {
      let signedDate = validFrom;
      if (mode === PURCHASE_ORDER_MODE) {
        signedDate = removeTime(fromPO.supplierSignedAt);
      } else {
        signedDate = new Date(fromContract.supplierSignedAt).getTime() >= new Date(fromContract.customerSignedAt).getTime() ? removeTime(fromContract.supplierSignedAt) : removeTime(fromContract.customerSignedAt);
      }
      const firstDueDate = new Date(new Date(signedDate).getTime() + paymentInitialOffset * DAY_MILISECONDS).toISOString();
      const firstPrincipal = (costPrincipal * paymentInitialAmount) / 100;
      const firstOtherPayment = (otherPayment * paymentInitialAmount) / 100;
      paymentSchedules.push({
        ...initSchedule,
        id: uuidv4(),
        dueDate: firstDueDate,
        type: PAYMENT_MODE,
        material: {
          principal: firstPrincipal,
          interest: 0,
          tax: costTax,
          paidTax: ((firstPrincipal + firstOtherPayment) * costTax.gstRate) / 100,
          other: firstOtherPayment,
          interestLate: paymentInterestLate,
          paidInterestLate: 0,
          invoiceOffset: paymentInvoiceOffset,
          paymentMethod,
          paymentTool,
          paymentCurrency
        } as PaymentItem
      } as Schedule);
      if (fromContract.referral) {
        referralSchedules.push({
          ...initSchedule,
          customer: fromContract.supplier,
          customerAgent: convert2Array(fromContract.supplierAgent),
          supplier: fromContract.referral.creator,
          supplierAgent: convert2Array(fromContract.referral.creatorAgent),
          id: uuidv4(),
          dueDate: firstDueDate,
          type: REFERRAL_MODE,
          material: {
            principal: firstPrincipal * referralRate,
            interest: 0,
            tax: costTax,
            paidTax: ((firstPrincipal + firstOtherPayment) * referralRate * costTax.gstRate) / 100,
            other: firstOtherPayment * referralRate,
            interestLate: { ...paymentInterestLate, rate: 0 },
            paidInterestLate: 0,
            invoiceOffset: paymentInvoiceOffset,
            paymentMethod,
            paymentTool,
            paymentCurrency
          } as PaymentItem
        } as Schedule);
      }
    }
    if (paymentInitialAmount < 100) {
      let finalDueDate = validFrom;
      deliverySchedules.forEach((deliverySchedule) => {
        const finishDate = getTimeMovedByDuration(new Date(deliverySchedule.dueDate), (deliverySchedule.material as DeliveryItem).life).toISOString();
        if (new Date(finalDueDate).getTime() < new Date(finishDate).getTime()) finalDueDate = finishDate;
      });
      finalDueDate = new Date(new Date(finalDueDate).getTime() + paymentFinalOffset * DAY_MILISECONDS).toISOString();
      const finalPrincipal = (costPrincipal * (100 - paymentInitialAmount)) / 100;
      let finalInterest = 0;
      if (mode === CONTRACT_MODE && dailyIR > 0) {
        const daysCount = Math.floor(((new Date(finalDueDate)).getTime() - (new Date(validFrom)).getTime() + 1) / DAY_MILISECONDS);
        if (costInterest.type === SIMPLE_INTEREST_TYPE) finalInterest = finalPrincipal * (dailyIR * daysCount);
        if (costInterest.type === COMPOUND_INTEREST_TYPE) finalInterest = finalPrincipal * ((1 + dailyIR) ** daysCount - 1);
      }
      const finalOtherPayment = (otherPayment * (100 - paymentInitialAmount)) / 100;
      paymentSchedules.push({
        ...initSchedule,
        id: uuidv4(),
        dueDate: finalDueDate,
        type: PAYMENT_MODE,
        material: {
          principal: finalPrincipal,
          interest: finalInterest,
          tax: costTax,
          paidTax: ((finalPrincipal + finalInterest + finalOtherPayment) * costTax.gstRate) / 100,
          other: finalOtherPayment,
          interestLate: paymentInterestLate,
          paidInterestLate: 0,
          invoiceOffset: paymentInvoiceOffset,
          paymentMethod,
          paymentTool,
          paymentCurrency
        } as PaymentItem
      } as Schedule);
      if (fromContract.referral) {
        referralSchedules.push({
          ...initSchedule,
          customer: fromContract.supplier,
          customerAgent: convert2Array(fromContract.supplierAgent),
          supplier: fromContract.referral.creator,
          supplierAgent: convert2Array(fromContract.referral.creatorAgent),
          id: uuidv4(),
          dueDate: finalDueDate,
          type: REFERRAL_MODE,
          material: {
            principal: finalPrincipal * referralRate,
            interest: 0,
            tax: costTax,
            paidTax: ((finalPrincipal + finalOtherPayment) * referralRate * costTax.gstRate) / 100,
            other: finalOtherPayment * referralRate,
            interestLate: { ...paymentInterestLate, rate: 0 },
            paidInterestLate: 0,
            invoiceOffset: paymentInvoiceOffset,
            paymentMethod,
            paymentTool,
            paymentCurrency
          } as PaymentItem
        } as Schedule);
      }
    }
  } else if (paymentOption === PERIOD_PAYMENT) {
    if (paymentInitialAmount > 0) {
      let signedDate = validFrom;
      if (mode === PURCHASE_ORDER_MODE) {
        signedDate = removeTime(fromPO.supplierSignedAt);
      } else {
        signedDate = new Date(fromContract.supplierSignedAt).getTime() >= new Date(fromContract.customerSignedAt).getTime() ? removeTime(fromContract.supplierSignedAt) : removeTime(fromContract.customerSignedAt);
      }
      const firstDueDate = new Date(new Date(signedDate).getTime() + paymentInitialOffset * DAY_MILISECONDS).toISOString();
      const firstPrincipal = (costPrincipal * paymentInitialAmount) / 100;
      const firstOtherPayment = (otherPayment * paymentInitialAmount) / 100;
      paymentSchedules.push({
        ...initSchedule,
        id: uuidv4(),
        dueDate: firstDueDate,
        type: PAYMENT_MODE,
        material: {
          principal: firstPrincipal,
          interest: 0,
          tax: costTax,
          paidTax: ((firstPrincipal + firstOtherPayment) * costTax.gstRate) / 100,
          other: firstOtherPayment,
          interestLate: paymentInterestLate,
          paidInterestLate: 0,
          invoiceOffset: paymentInvoiceOffset,
          paymentMethod,
          paymentTool,
          paymentCurrency
        } as PaymentItem
      } as Schedule);
      if (fromContract.referral) {
        referralSchedules.push({
          ...initSchedule,
          customer: fromContract.supplier,
          customerAgent: convert2Array(fromContract.supplierAgent),
          supplier: fromContract.referral.creator,
          supplierAgent: convert2Array(fromContract.referral.creatorAgent),
          id: uuidv4(),
          dueDate: firstDueDate,
          type: REFERRAL_MODE,
          material: {
            principal: firstPrincipal * referralRate,
            interest: 0,
            tax: costTax,
            paidTax: ((firstPrincipal + firstOtherPayment) * referralRate * costTax.gstRate) / 100,
            other: firstOtherPayment * referralRate,
            interestLate: { ...paymentInterestLate, rate: 0 },
            paidInterestLate: 0,
            invoiceOffset: paymentInvoiceOffset,
            paymentMethod,
            paymentTool,
            paymentCurrency
          } as PaymentItem
        } as Schedule);
      }
    }
    const periodicalDueDates = getPeriodOffsetDaysByCount(validFrom, validTo, paymentFinalPeriod, paymentFinalOffset);
    const balance = (costPrincipal * (100 - paymentInitialAmount)) / 100;
    const periodicalPrincipal = balance / periodicalDueDates.length;
    const periodicalOtherPayment = (otherPayment * (100 - paymentInitialAmount)) / 100 / periodicalDueDates.length;
    let dateDifference = periodLengthList.find((lengthItem) => lengthItem.type === paymentFinalPeriod).days;
    if (paymentFinalPeriod === LENGTH_MONTH || paymentFinalPeriod === LENGTH_YEAR) {
      const st = new Date(validFrom).getTime();
      const ed = new Date(validTo).getTime() + DAY_MILISECONDS;
      dateDifference = (ed - st) / periodicalDueDates.length / DAY_MILISECONDS;
    }
    let periodicalInterest = 0;
    if (dailyIR > 0) {
      if (costInterest.type === COMPOUND_INTEREST_TYPE) {
        let newInstallment = balance;
        newInstallment *= ((1 + dailyIR) ** (paymentFinalOffset + dateDifference * (periodicalDueDates.length - 1)));
        newInstallment *= ((1 + dailyIR) ** dateDifference - 1);
        newInstallment /= ((1 + dailyIR) ** (dateDifference * periodicalDueDates.length) - 1);
        periodicalInterest = newInstallment - periodicalPrincipal;
      } else {
        const totalInterest = balance * dailyIR * (((periodicalDueDates.length - 1) * dateDifference) / 2 + paymentFinalOffset);
        periodicalInterest = totalInterest / periodicalDueDates.length;
      }
    }
    for (let i = 0; i < periodicalDueDates.length; i++) {
      paymentSchedules.push({
        ...initSchedule,
        id: uuidv4(),
        dueDate: periodicalDueDates[i],
        type: PAYMENT_MODE,
        material: {
          principal: periodicalPrincipal,
          interest: periodicalInterest,
          tax: costTax,
          paidTax: ((periodicalPrincipal + periodicalInterest + periodicalOtherPayment) * costTax.gstRate) / 100,
          other: periodicalOtherPayment,
          interestLate: paymentInterestLate,
          paidInterestLate: 0,
          invoiceOffset: paymentInvoiceOffset,
          paymentMethod,
          paymentTool,
          paymentCurrency
        } as PaymentItem
      } as Schedule);
      if (fromContract.referral) {
        referralSchedules.push({
          ...initSchedule,
          customer: fromContract.supplier,
          customerAgent: convert2Array(fromContract.supplierAgent),
          supplier: fromContract.referral.creator,
          supplierAgent: convert2Array(fromContract.referral.creatorAgent),
          id: uuidv4(),
          dueDate: periodicalDueDates[i],
          type: REFERRAL_MODE,
          material: {
            principal: periodicalPrincipal * referralRate,
            interest: 0,
            tax: costTax,
            paidTax: ((periodicalPrincipal + periodicalOtherPayment) * referralRate * costTax.gstRate) / 100,
            other: periodicalOtherPayment * referralRate,
            interestLate: { ...paymentInterestLate, rate: 0 },
            paidInterestLate: 0,
            invoiceOffset: paymentInvoiceOffset,
            paymentMethod,
            paymentTool,
            paymentCurrency
          } as PaymentItem
        } as Schedule);
      }
    }
  } else if (paymentOption === DELIVERY_PAYMENT) {
    deliverySchedules.forEach((deliverySchedule) => {
      let newDueDate = deliverySchedule.dueDate;
      if (paymentInitialOffset === ON_COMPLETION) newDueDate = getTimeMovedByDuration(new Date(newDueDate), (deliverySchedule.material as DeliveryItem).life).toISOString();
      newDueDate = new Date(new Date(newDueDate).getTime() + paymentFinalOffset * DAY_MILISECONDS).toISOString();
      const principalPayment = (deliverySchedule.material as DeliveryItem).netValue;
      const deliverOtherPayment = (principalPayment / costPrincipal) * otherPayment;
      paymentSchedules.push({
        ...initSchedule,
        id: uuidv4(),
        dueDate: newDueDate,
        type: PAYMENT_MODE,
        material: {
          principal: principalPayment,
          interest: 0,
          tax: costTax,
          paidTax: ((principalPayment + deliverOtherPayment) * costTax.gstRate) / 100,
          other: deliverOtherPayment,
          interestLate: paymentInterestLate,
          paidInterestLate: 0,
          invoiceOffset: paymentInvoiceOffset,
          paymentMethod,
          paymentTool,
          paymentCurrency
        } as PaymentItem
      } as Schedule);
      if (fromContract.referral) {
        referralSchedules.push({
          ...initSchedule,
          customer: fromContract.supplier,
          customerAgent: convert2Array(fromContract.supplierAgent),
          supplier: fromContract.referral.creator,
          supplierAgent: convert2Array(fromContract.referral.creatorAgent),
          id: uuidv4(),
          dueDate: newDueDate,
          type: REFERRAL_MODE,
          material: {
            principal: principalPayment * referralRate,
            interest: 0,
            tax: costTax,
            paidTax: ((principalPayment + deliverOtherPayment) * referralRate * costTax.gstRate) / 100,
            other: deliverOtherPayment * referralRate,
            interestLate: { ...paymentInterestLate, rate: 0 },
            paidInterestLate: 0,
            invoiceOffset: paymentInvoiceOffset,
            paymentMethod,
            paymentTool,
            paymentCurrency
          } as PaymentItem
        } as Schedule);
      }
    });
  }
  return { deliverySchedules, paymentSchedules: getMergedPaymentSchedule(paymentSchedules), referralSchedules: getMergedPaymentSchedule(referralSchedules) };
};

export const getPriceList = (mode: number, fromContract: Contract, fromPO: PO = null): {
  total: number; first: number; last: any; serviceCost: any;
}[] => {
  const matchIndices = fromContract.delivery.filter((deliveryContract) => {
    if (mode === CONTRACT_MODE) return (deliveryContract.requirePO === NOT_REQUIRED_PO);
    return (deliveryContract.itemId === fromPO.material.id);
  }).map((deliveryContract, index) => (index));
  if (matchIndices.length < 1) {
    return [{ total: 0, first: 0, last: 0, serviceCost: 0 }];
  }
  const initSchedule = {
    contractId: fromContract.id,
    poId: mode === PURCHASE_ORDER_MODE ? fromPO.id : '',
    customer: mode === PURCHASE_ORDER_MODE ? fromPO.customer : fromContract.customer,
    customerAgent: mode === PURCHASE_ORDER_MODE ? convert2Array(fromPO.customerAgent) : convert2Array(fromContract.customerAgent),
    supplier: mode === PURCHASE_ORDER_MODE ? fromPO.supplier : fromContract.supplier,
    supplierAgent: mode === PURCHASE_ORDER_MODE ? convert2Array(fromPO.supplierAgent) : convert2Array(fromContract.supplierAgent),
    status: STATUS_TEXT_SUBMITTED,
    invoiceId: ''
  };
  const deliverySchedules: Schedule[] = [];
  const { itemBriefs, validFrom, validTo } = fromContract;
  const { period, duration } = itemBriefs;
  const payment = fromContract.payment[0];
  // const { costPrincipal, costTax, costOther } = payment;
  const { costPrincipal, costInterest, costTax, costOther, paymentOption, paymentInitialAmount, paymentFinalPeriod, paymentFinalOffset, paymentInitialOffset } = payment;
  const bundlePeriodicalDates: { startDate: Date | string, endDate: Date | string }[] = [];
  if (period.type === REQUEST_ONE_OFF) {
    bundlePeriodicalDates.push({
      startDate: validFrom,
      endDate: validTo
    });
  } else {
    const bundleStartDates = getPeriodicalDatesByCount(validFrom, validTo, period.per, period.count);
    bundleStartDates.forEach((startDate) => {
      bundlePeriodicalDates.push({
        startDate,
        endDate: getTimeMovedByDuration(new Date(startDate), duration).toISOString()
      });
    });
  }
  bundlePeriodicalDates.forEach(({ startDate, endDate }) => {
    matchIndices.forEach((index) => {
      const delivery = fromContract.delivery[index];
      const deliveryDueDates = [];
      const { itemPeriod } = delivery;
      if (mode === PURCHASE_ORDER_MODE) {
        deliveryDueDates.push(fromPO.deliveryDate);
      } else if (itemPeriod.type === REQUEST_ONE_OFF) {
        const itemStartDate = getTimeMovedByDuration(new Date(startDate), { length: itemPeriod.offset, type: itemPeriod.offsetType }).toISOString();
        deliveryDueDates.push(itemStartDate);
      } else {
        const itemStartDate = getTimeMovedByDuration(new Date(startDate), { length: itemPeriod.offset, type: itemPeriod.offsetType }).toISOString();
        const itemEndDate = getTimeMovedByDuration(new Date(endDate), { length: itemPeriod.offset, type: itemPeriod.offsetType }).toISOString();
        deliveryDueDates.push(...getPeriodicalDatesByCount(itemStartDate, itemEndDate, itemPeriod.per, itemPeriod.count));
      }
      const totalQuantity = mode === PURCHASE_ORDER_MODE ? fromPO.material.quantity : delivery.volumes[0];
      const newQuantity = totalQuantity / deliveryDueDates.length;
      deliveryDueDates.forEach((dueDate) => {
        deliverySchedules.push({
          ...initSchedule,
          id: uuidv4(),
          dueDate,
          type: DELIVERY_MODE,
          material: {
            id: mode === PURCHASE_ORDER_MODE ? fromPO.material.id : delivery.itemId,
            title: mode === PURCHASE_ORDER_MODE ? fromPO.material.title : delivery.itemTitle,
            description: mode === PURCHASE_ORDER_MODE ? fromPO.material.description : delivery.itemDescription,
            life: mode === PURCHASE_ORDER_MODE ? fromPO.material.life : delivery.itemLife,
            status: mode === PURCHASE_ORDER_MODE ? fromPO.material.status : delivery.itemStatus,
            type: mode === PURCHASE_ORDER_MODE ? fromPO.material.type : delivery.itemType,
            collected: mode === PURCHASE_ORDER_MODE ? fromPO.material.collected : delivery.itemCollected,
            quantity: newQuantity,
            netPrice: mode === PURCHASE_ORDER_MODE ? fromPO.material.netPrice : delivery.prices[0],
            netValue: mode === PURCHASE_ORDER_MODE ? fromPO.material.netPrice * newQuantity : delivery.prices[0] * newQuantity
          } as DeliveryItem
        } as Schedule);
      });
    });
  });
  // const dailyIR = (costInterest.rate / periodLengthList.find((lengthItem) => lengthItem.type === costInterest.period).days) / 100;
  const dailyIR = 0;
  const referralRate = fromContract.referral ? fromContract.referral.amount / 100 : 0;
  const otherPayment = getOtherPaymentByType(costPrincipal, costOther);
  const priceList = [];

  // new

  // const total = (costPrincipal + otherPayment) * (1 + costTax.gstRate / 100);

  // const total = (costPrincipal + otherPayment) * (1 + costTax.gstRate / 100);
  // const serviceCost = (costPrincipal + otherPayment);
  // priceList.push({ total, first: total, last: 0, serviceCost });
  // if (fromContract.referral) {
  //   priceList.push({ total: total * referralRate, first: total * referralRate, last: 0 });
  // }
  if (paymentOption === UPFRONT_PAYMENT) {
    const total = (costPrincipal + otherPayment) * (1 + costTax.gstRate / 100);
    priceList.push({ total, first: total, last: 0 });
    if (fromContract.referral) {
      priceList.push({ total: total * referralRate, first: total * referralRate, last: 0 });
    }
  } else if (paymentOption === COMPLETION_PAYMENT) {
    let finalDate = validFrom;
    deliverySchedules.forEach((deliverySchedule) => {
      const finishDate = getTimeMovedByDuration(new Date(deliverySchedule.dueDate), (deliverySchedule.material as DeliveryItem).life).toISOString();
      if (new Date(finalDate).getTime() < new Date(finishDate).getTime()) finalDate = finishDate;
    });
    finalDate = new Date(new Date(finalDate).getTime() + paymentFinalOffset * DAY_MILISECONDS).toISOString();
    let newInterest = 0;
    if (mode === CONTRACT_MODE && dailyIR > 0) {
      const daysCount = Math.floor(((new Date(finalDate)).getTime() - (new Date(validFrom)).getTime() + 1) / DAY_MILISECONDS);
      if (costInterest.type === SIMPLE_INTEREST_TYPE) newInterest = costPrincipal * (dailyIR * daysCount);
      if (costInterest.type === COMPOUND_INTEREST_TYPE) newInterest = costPrincipal * ((1 + dailyIR) ** daysCount - 1);
    }
    const total = (costPrincipal + newInterest + otherPayment) * (1 + costTax.gstRate / 100);
    priceList.push({ total, first: 0, last: total });
    if (fromContract.referral) {
      priceList.push({ total: total * referralRate, first: 0, last: total * referralRate });
    }
  } else if (paymentOption === TWOTIME_PAYMENT) {
    let firstPay = 0;
    let lastPay = 0;
    if (paymentInitialAmount > 0) {
      const firstPrincipal = (costPrincipal * paymentInitialAmount) / 100;
      const firstOtherPayment = (otherPayment * paymentInitialAmount) / 100;
      firstPay = (firstPrincipal + firstOtherPayment) * (1 + costTax.gstRate / 100);
    }
    if (paymentInitialAmount < 100) {
      let finalDueDate = validFrom;
      deliverySchedules.forEach((deliverySchedule) => {
        const finishDate = getTimeMovedByDuration(new Date(deliverySchedule.dueDate), (deliverySchedule.material as DeliveryItem).life).toISOString();
        if (new Date(finalDueDate).getTime() < new Date(finishDate).getTime()) finalDueDate = finishDate;
      });
      finalDueDate = new Date(new Date(finalDueDate).getTime() + paymentFinalOffset * DAY_MILISECONDS).toISOString();
      const finalPrincipal = (costPrincipal * (100 - paymentInitialAmount)) / 100;
      let finalInterest = 0;
      if (mode === CONTRACT_MODE && dailyIR > 0) {
        const daysCount = Math.floor(((new Date(finalDueDate)).getTime() - (new Date(validFrom)).getTime() + 1) / DAY_MILISECONDS);
        if (costInterest.type === SIMPLE_INTEREST_TYPE) finalInterest = finalPrincipal * (dailyIR * daysCount);
        if (costInterest.type === COMPOUND_INTEREST_TYPE) finalInterest = finalPrincipal * ((1 + dailyIR) ** daysCount - 1);
      }
      const finalOtherPayment = (otherPayment * (100 - paymentInitialAmount)) / 100;
      lastPay = (finalPrincipal + finalInterest + finalOtherPayment) * (1 + costTax.gstRate / 100);
    }
    priceList.push({ total: firstPay + lastPay, first: firstPay, last: lastPay });
    if (fromContract.referral) {
      priceList.push({ total: (firstPay + lastPay) * referralRate, first: firstPay * referralRate, last: lastPay * referralRate });
    }
  } else if (paymentOption === PERIOD_PAYMENT) {
    let totalPay = 0;
    let firstPay = 0;
    let lastPay = 0;
    if (paymentInitialAmount > 0) {
      const firstPrincipal = (costPrincipal * paymentInitialAmount) / 100;
      const firstOtherPayment = (otherPayment * paymentInitialAmount) / 100;
      firstPay = (firstPrincipal + firstOtherPayment) * (1 + costTax.gstRate / 100);
      totalPay += firstPay;
    }
    const periodicalDueDates = getPeriodOffsetDaysByCount(validFrom, validTo, paymentFinalPeriod, paymentFinalOffset);
    const balance = (costPrincipal * (100 - paymentInitialAmount)) / 100;
    const periodicalOtherPayment = (otherPayment * (100 - paymentInitialAmount)) / 100 / periodicalDueDates.length;
    if (dailyIR > 0) {
      let dateDifference = periodLengthList.find((lengthItem) => lengthItem.type === paymentFinalPeriod).days;
      if (paymentFinalPeriod === LENGTH_MONTH || paymentFinalPeriod === LENGTH_YEAR) {
        const st = new Date(validFrom).getTime();
        const ed = new Date(validTo).getTime() + DAY_MILISECONDS;
        dateDifference = (ed - st) / periodicalDueDates.length / DAY_MILISECONDS;
      }
      if (costInterest.type === COMPOUND_INTEREST_TYPE) {
        let newInstallment = balance;
        newInstallment *= ((1 + dailyIR) ** (paymentFinalOffset + dateDifference * (periodicalDueDates.length - 1)));
        newInstallment *= ((1 + dailyIR) ** dateDifference - 1);
        newInstallment /= ((1 + dailyIR) ** (dateDifference * periodicalDueDates.length) - 1);
        lastPay = newInstallment;
        lastPay += periodicalOtherPayment;
        lastPay *= (1 + costTax.gstRate / 100);
        totalPay += (lastPay * periodicalDueDates.length);
      } else {
        const totalInterest = balance * dailyIR * (((periodicalDueDates.length - 1) * dateDifference) / 2 + paymentFinalOffset);
        lastPay = (balance + totalInterest) / periodicalDueDates.length;
        lastPay += periodicalOtherPayment;
        lastPay *= (1 + costTax.gstRate / 100);
        totalPay += lastPay;
        totalPay += (lastPay * periodicalDueDates.length);
      }
    } else {
      lastPay += balance;
      lastPay += periodicalOtherPayment;
      lastPay *= (1 + costTax.gstRate / 100);
      totalPay += lastPay;
      lastPay /= periodicalDueDates.length;
    }
    priceList.push({ total: totalPay, first: firstPay, last: lastPay });
    if (fromContract.referral) {
      priceList.push({ total: totalPay * referralRate, first: firstPay * referralRate, last: lastPay * referralRate });
    }
  } else if (paymentOption === DELIVERY_PAYMENT) {
    const lastPrices: { dueDate: Date | string, amount: number }[] = [];
    const lastReferralPrices: { dueDate: Date | string, amount: number }[] = [];
    deliverySchedules.forEach((deliverySchedule) => {
      const principalPayment = (deliverySchedule.material as DeliveryItem).netValue;
      const deliverOtherPayment = (principalPayment / costPrincipal) * otherPayment;
      const totalPayment = (principalPayment + deliverOtherPayment) * (1 + costTax.gstRate / 100);
      let newDueDate = deliverySchedule.dueDate;
      if (paymentInitialOffset === ON_COMPLETION) newDueDate = getTimeMovedByDuration(new Date(newDueDate), (deliverySchedule.material as DeliveryItem).life).toISOString();
      newDueDate = new Date(new Date(newDueDate).getTime() + paymentFinalOffset * DAY_MILISECONDS).toISOString();
      lastPrices.push({ dueDate: newDueDate, amount: totalPayment });
      if (fromContract.referral) {
        lastReferralPrices.push({ dueDate: newDueDate, amount: totalPayment * referralRate });
      }
    });
    const totalOther = getOtherPaymentByType(costPrincipal, costOther);
    const totalPrincipal = (costPrincipal + totalOther) * (1 + costTax.gstRate / 100);
    priceList.push({ total: totalPrincipal, first: 0, last: getMergedPrices(lastPrices) });
    if (fromContract.referral) {
      priceList.push({ total: totalPrincipal * referralRate, first: 0, last: getMergedPrices(lastReferralPrices) });
    }
  }
  return priceList;
};

export const getDateList = (mode: number, fromContract: Contract, fromPO: PO = null): { first: string; last: any; } => {
  const matchIndices = fromContract.delivery.filter((deliveryContract) => {
    if (mode === CONTRACT_MODE) return (deliveryContract.requirePO === NOT_REQUIRED_PO);
    return (deliveryContract.itemId === fromPO.material.id);
  }).map((deliveryContract, index) => (index));
  if (matchIndices.length < 1) {
    return { first: null, last: null };
  }
  const initSchedule = {
    contractId: fromContract.id,
    poId: mode === PURCHASE_ORDER_MODE ? fromPO.id : '',
    customer: mode === PURCHASE_ORDER_MODE ? fromPO.customer : fromContract.customer,
    customerAgent: mode === PURCHASE_ORDER_MODE ? convert2Array(fromPO.customerAgent) : convert2Array(fromContract.customerAgent),
    supplier: mode === PURCHASE_ORDER_MODE ? fromPO.supplier : fromContract.supplier,
    supplierAgent: mode === PURCHASE_ORDER_MODE ? convert2Array(fromPO.supplierAgent) : convert2Array(fromContract.supplierAgent),
    status: STATUS_TEXT_SUBMITTED,
    invoiceId: ''
  };
  const deliverySchedules: Schedule[] = [];
  const { itemBriefs, validFrom, validTo } = fromContract;
  const { period, duration } = itemBriefs;
  const payment = fromContract.payment[0];
  const { paymentOption, paymentInitialAmount, paymentInitialOffset, paymentFinalPeriod, paymentFinalOffset } = payment;
  const bundlePeriodicalDates: { startDate: Date | string, endDate: Date | string }[] = [];
  if (period.type === REQUEST_ONE_OFF) {
    bundlePeriodicalDates.push({
      startDate: validFrom,
      endDate: validTo
    });
  } else {
    const bundleStartDates = getPeriodicalDatesByCount(validFrom, validTo, period.per, period.count);
    bundleStartDates.forEach((startDate) => {
      bundlePeriodicalDates.push({
        startDate,
        endDate: getTimeMovedByDuration(new Date(startDate), duration).toISOString()
      });
    });
  }
  bundlePeriodicalDates.forEach(({ startDate, endDate }) => {
    matchIndices.forEach((index) => {
      const delivery = fromContract.delivery[index];
      const deliveryDueDates = [];
      const { itemPeriod } = delivery;
      if (mode === PURCHASE_ORDER_MODE) deliveryDueDates.push(fromPO.deliveryDate);
      else if (itemPeriod.type === REQUEST_ONE_OFF) {
        const itemStartDate = getTimeMovedByDuration(new Date(startDate), { length: itemPeriod.offset, type: itemPeriod.offsetType }).toISOString();
        deliveryDueDates.push(itemStartDate);
      } else {
        const itemStartDate = getTimeMovedByDuration(new Date(startDate), { length: itemPeriod.offset, type: itemPeriod.offsetType }).toISOString();
        const itemEndDate = getTimeMovedByDuration(new Date(endDate), { length: itemPeriod.offset, type: itemPeriod.offsetType }).toISOString();
        deliveryDueDates.push(...getPeriodicalDatesByCount(itemStartDate, itemEndDate, itemPeriod.per, itemPeriod.count));
      }
      const totalQuantity = mode === PURCHASE_ORDER_MODE ? fromPO.material.quantity : delivery.volumes[0];
      const newQuantity = totalQuantity / deliveryDueDates.length;
      deliveryDueDates.forEach((dueDate) => {
        deliverySchedules.push({
          ...initSchedule,
          id: uuidv4(),
          dueDate,
          type: DELIVERY_MODE,
          material: {
            id: mode === PURCHASE_ORDER_MODE ? fromPO.material.id : delivery.itemId,
            title: mode === PURCHASE_ORDER_MODE ? fromPO.material.title : delivery.itemTitle,
            description: mode === PURCHASE_ORDER_MODE ? fromPO.material.description : delivery.itemDescription,
            life: mode === PURCHASE_ORDER_MODE ? fromPO.material.life : delivery.itemLife,
            status: mode === PURCHASE_ORDER_MODE ? fromPO.material.status : delivery.itemStatus,
            type: mode === PURCHASE_ORDER_MODE ? fromPO.material.type : delivery.itemType,
            collected: mode === PURCHASE_ORDER_MODE ? fromPO.material.collected : delivery.itemCollected,
            quantity: newQuantity,
            netPrice: mode === PURCHASE_ORDER_MODE ? fromPO.material.netPrice : delivery.prices[0],
            netValue: mode === PURCHASE_ORDER_MODE ? fromPO.material.netPrice * newQuantity : delivery.prices[0] * newQuantity
          } as DeliveryItem
        } as Schedule);
      });
    });
  });
  if (paymentOption === UPFRONT_PAYMENT) {
    let signedDate = validFrom;
    if (mode === PURCHASE_ORDER_MODE) signedDate = removeTime(fromPO.supplierSignedAt);
    else signedDate = new Date(fromContract.supplierSignedAt).getTime() >= new Date(fromContract.customerSignedAt).getTime() ? removeTime(fromContract.supplierSignedAt) : removeTime(fromContract.customerSignedAt);
    const newDueDate = new Date(new Date(signedDate).getTime() + paymentInitialOffset * DAY_MILISECONDS).toISOString();
    return { first: newDueDate, last: null };
  }
  if (paymentOption === COMPLETION_PAYMENT) {
    let finalDate = validFrom;
    deliverySchedules.forEach((deliverySchedule) => {
      const finishDate = getTimeMovedByDuration(new Date(deliverySchedule.dueDate), (deliverySchedule.material as DeliveryItem).life).toISOString();
      if (new Date(finalDate).getTime() < new Date(finishDate).getTime()) finalDate = finishDate;
    });
    finalDate = new Date(new Date(finalDate).getTime() + paymentFinalOffset * DAY_MILISECONDS).toISOString();
    return { first: null, last: finalDate };
  }
  if (paymentOption === TWOTIME_PAYMENT) {
    let signedDate = validFrom;
    if (mode === PURCHASE_ORDER_MODE) signedDate = removeTime(fromPO.supplierSignedAt);
    else signedDate = new Date(fromContract.supplierSignedAt).getTime() >= new Date(fromContract.customerSignedAt).getTime() ? removeTime(fromContract.supplierSignedAt) : removeTime(fromContract.customerSignedAt);
    const firstDueDate = new Date(new Date(signedDate).getTime() + paymentInitialOffset * DAY_MILISECONDS).toISOString();
    let finalDueDate = validFrom;
    deliverySchedules.forEach((deliverySchedule) => {
      const finishDate = getTimeMovedByDuration(new Date(deliverySchedule.dueDate), (deliverySchedule.material as DeliveryItem).life).toISOString();
      if (new Date(finalDueDate).getTime() < new Date(finishDate).getTime()) finalDueDate = finishDate;
    });
    finalDueDate = new Date(new Date(finalDueDate).getTime() + paymentFinalOffset * DAY_MILISECONDS).toISOString();
    return { first: firstDueDate, last: finalDueDate };
  }
  if (paymentOption === PERIOD_PAYMENT) {
    let firstDueDate = null;
    if (paymentInitialAmount > 0) {
      let signedDate = validFrom;
      if (mode === PURCHASE_ORDER_MODE) signedDate = removeTime(fromPO.supplierSignedAt);
      else signedDate = new Date(fromContract.supplierSignedAt).getTime() >= new Date(fromContract.customerSignedAt).getTime() ? removeTime(fromContract.supplierSignedAt) : removeTime(fromContract.customerSignedAt);
      firstDueDate = new Date(new Date(signedDate).getTime() + paymentInitialOffset * DAY_MILISECONDS).toISOString();
    }
    const periodicalDueDates = getPeriodOffsetDaysByCount(validFrom, validTo, paymentFinalPeriod, paymentFinalOffset);
    return { first: firstDueDate, last: { from: periodicalDueDates[0], period: paymentFinalPeriod, offset: getPeriodOffsetFromDate(periodicalDueDates[0], paymentFinalPeriod) } };
  }
  // paymentOption === DELIVERY_PAYMENT
  const dueDates = deliverySchedules.map((deliverySchedule) => deliverySchedule.dueDate);
  return { first: null, last: getMergedDates(dueDates) };
};

export const createPurchasesAfterConfirmFromContract = (fromContract: Contract, schedules: Schedule[]): PurchaseItem => {
  const purchaseItem: PurchaseItem = {
    id: uuidv4(),
    contractId: fromContract.id,
    itemId: fromContract.itemBriefs?.id,
    itemType: fromContract.itemBriefs?.type,
    itemTitle: fromContract.itemBriefs?.title,
    itemDescription: fromContract.itemBriefs?.description,
    itemCategory: fromContract.itemBriefs?.category,
    delivery: fromContract.delivery.filter((deliveryContract) => deliveryContract.requirePO === NOT_REQUIRED_PO).map((deliveryContract) => {
      const subSchedules = schedules.filter((schedule) => deliveryContract.itemId === (schedule.material as DeliveryItem).id);
      return ({
        id: deliveryContract.itemId,
        title: deliveryContract.itemTitle,
        description: deliveryContract.itemDescription,
        life: deliveryContract.itemLife,
        status: deliveryContract.itemStatus,
        type: deliveryContract.itemType,
        collected: deliveryContract.itemCollected,
        quantity: subSchedules.map((schedule) => (schedule.material as DeliveryItem).quantity),
        miniInvoices: [],
        dueDates: subSchedules.map((schedule) => schedule.dueDate)
      } as DeliveredItem);
    }),
    supplier: fromContract.supplier,
    supplierAgent: convert2Array(fromContract.supplierAgent),
    customer: fromContract.customer,
    customerAgent: convert2Array(fromContract.customerAgent),
    validFrom: fromContract.validFrom,
    validTo: fromContract.validTo
  };
  return purchaseItem;
};
