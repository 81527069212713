import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Search = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    height="24"
    width="24"
    data-supported-dps="24x24"
  >
    <path
      d="M21.41 18.59l-5.27-5.28A6.83 6.83 0 0017 10a7 7 0 10-7 7 6.83 6.83 0 003.31-.86l5.28 5.27a2 2 0 002.82-2.82zM5 10a5 5 0 115 5 5 5 0 01-5-5z"
    />
  </svg>,
  'Search'
);

export default Search;
