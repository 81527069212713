import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Card, Comment, Attachment, Contact } from 'src/types/kanban';
import { Todo, TodoResource } from 'src/../../Common/Model/todo';
import { TODO_RESOURCE_COMMENT, TODO_RESOURCE_ATTACHMENT } from 'src/globals';

interface KanbanState {
  cards: Card[];
  participants: Contact[];
  needReload: boolean;
}

const initialState: KanbanState = {
  cards: [],
  participants: [],
  needReload: true
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    clearState(state: KanbanState) {
      state.cards.splice(0, state.cards.length);
      state.participants.splice(0, state.participants.length);
      state.needReload = true;
    },
    setNeedReload(
      state: KanbanState,
      action: PayloadAction<boolean>
    ): void {
      state.needReload = action.payload;
    },
    setData(
      state: KanbanState,
      action: PayloadAction<{
        todos: Todo[],
        resources: TodoResource[]
      }>
    ): void {
      const { todos, resources } = action.payload;
      state.cards = todos.map((todo) => ({
        ...todo,
        comments: resources.filter((sour) => sour.type === TODO_RESOURCE_COMMENT && sour.todoId === todo.id).map((sour) => ({
          id: sour.id,
          owner: sour.owner,
          createdAt: sour.createdAt,
          message: sour.resource,
          orgId: sour.orgId
        })),
        attachments: resources.filter((sour) => sour.type === TODO_RESOURCE_ATTACHMENT && sour.todoId === todo.id).map((sour) => {
          const r = JSON.parse(sour.resource);
          return {
            id: sour.id,
            owner: sour.owner,
            createdAt: sour.createdAt,
            orgId: sour.orgId,
            name: r.name,
            type: r.type,
            size: r.size,
            uri: r.uri
          };
        })
      }));
    },
    createCard(
      state: KanbanState,
      action: PayloadAction<Todo>
    ): void {
      const todo = action.payload;
      state.cards = [...state.cards, {
        ...todo,
        comments: [],
        attachments: []
      }];
    },
    updateCard(
      state: KanbanState,
      action: PayloadAction<{ id: string; content: any }>
    ): void {
      const { id, content } = action.payload;
      state.cards = state.cards.map((card) => (card.id === id ? { ...card, ...content } : card));
    },
    deleteCard(
      state: KanbanState,
      action: PayloadAction<string>
    ): void {
      const cardId = action.payload;
      state.cards = state.cards.filter((card) => card.id !== cardId);
    },
    addComment(
      state: KanbanState,
      action: PayloadAction<{ cardId: string, comment: Comment }>
    ): void {
      const { cardId, comment } = action.payload;
      state.cards = state.cards.map((card) => (card.id === cardId ? {
        ...card,
        comments: [...card.comments, comment]
      } : card));
    },
    addAttachment(
      state: KanbanState,
      action: PayloadAction<{ cardId: string, attachment: Attachment }>
    ): void {
      const { cardId, attachment } = action.payload;
      state.cards = state.cards.map((card) => (card.id === cardId ? {
        ...card,
        attachments: [...card.attachments, attachment]
      } : card));
    },
    setParticipants(
      state: KanbanState,
      action: PayloadAction<Contact[]>
    ): void {
      state.participants = action.payload;
    }
  }
});

export const { reducer } = slice;

export const clearKanbanState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setNeedReload = (
  needReload: boolean
) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setNeedReload(needReload));
};

export const setData = (
  res: {
    todos: Todo[],
    resources: TodoResource[]
  }
): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setData(res));
};

export const createCard = (
  todo: Todo
): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.createCard(todo));
};

export const updateCard = (
  id: string,
  content: any
): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateCard({ id, content }));
};

export const deleteCard = (cardId: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.deleteCard(cardId));
};

export const addComment = (
  cardId: string,
  comment: Comment
): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.addComment({ cardId, comment }));
};

export const addAttachment = (
  cardId: string,
  attachment: Attachment
): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.addAttachment({ cardId, attachment }));
};

export const setParticipants = (
  participants: Contact[]
): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setParticipants(participants));
};

export default slice;
