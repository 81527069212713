import { FC, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  DialogContent,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import useMounted from 'src/hooks/useMounted';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { LoadingStatus, setLoadingStatus, setDomainName, setRequestedLocation } from 'src/slices/user';
import { useDispatch } from 'src/store';
import Microsoft from 'src/icons/Microsoft';
import { Organization } from 'src/../../Common/Model/organization';
import { httpFindAddress, httpJoinAsClient, httpJoinAsClientOrg } from 'src/apis/httpDispatch';
import { CONNECT_DLG_SIGNIN, null2empty } from 'src/globals';
import { countries, getCountry, getCountryByDialCode } from 'src/utils/countries';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import toast from 'react-hot-toast';
import * as querystring from 'querystring';
import BottomPopupDialog from '../dialog/BottomPopupDialog';
import { Link as RouterLink } from 'react-router-dom';
import { getClientAddress } from 'src/utils/getAddressUtils';
import LoadingItem from 'src/components/display/LoadingItem';
import cleanUp from 'src/utils/cleanUp';
import getUserDisplayName from 'src/utils/getUserDisplayName';
import PopupTooltip from 'src/components/dialog/PopupTooltip';

const ConnectDialog: FC<{
  open: boolean;
  mode: number;
  onClose: any;
  org: Organization;
  recoverUrl?: string;
}> = ({
  open,
  mode,
  onClose,
  org,
  recoverUrl
}) => {
  const mounted = useMounted();
  const dispatch = useDispatch();
  const { login, loginSocial } = useAuth() as any;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [socialLoginType, setSocialLoginType] = useState<number>(0);
  const [joinDetail, setJoinDetail] = useState<any>({
    firstName: '',
    middleName: '',
    lastName: '',
    penName: '',
    country: 'AU',
    state: '',
    city: '',
    postalCode: '',
    addressLine1: '',
    website: '',
    phone: '',
    email: '',
    hiMsg: ''
  });
  const [addressSuggestions, setAddressSuggestions] = useState<any[]>([]);
  const [addressAnchorEl, setAddressAnchorEl] = useState<any>(null);
  const [isSending, setSending] = useState<boolean>(false);
  const [isIndividual, setIndividual] = useState<boolean>(true);
  const [isGettingAddr, setGettingAddr] = useState<boolean>(false);
  const isLogin = mode === CONNECT_DLG_SIGNIN;
  const orgName = getUserDisplayName(org);

  const setDefaultAddress = useCallback(async () => {
    if (!mounted.current) return;
    setGettingAddr(true);
    try {
      const address = await getClientAddress();
      if (address && mounted.current) {
        setJoinDetail({
          ...joinDetail,
          addressLine1: address.addressLine1,
          city: address.city,
          state: address.region,
          postalCode: address.postalCode,
          phone: address.phone,
          country: address.country
        });
      }
    } catch (err) {
      console.log(JSON.stringify(err));
    } finally {
      if (mounted.current) setSending(false);
    }
  }, [mounted]);

  useEffect(() => {
    setDefaultAddress();
  }, [setDefaultAddress]);

  const requireError = !joinDetail.email
  || !joinDetail.hiMsg
  || (isIndividual && (!joinDetail.firstName || !joinDetail.lastName))
  || (!isIndividual && !joinDetail.penName);

  const updateJoinDetail = (k, v) => {
    if (k === 'phone') {
      const countryCode = getCountryByDialCode(v);
      if (countryCode) {
        setJoinDetail({
          ...joinDetail,
          [k]: v,
          country: countryCode
        });
        return;
      }
    }
    setJoinDetail({ ...joinDetail, [k]: v });
  };

  const loginWithGoogle = () => {
    setSocialLoginType(1);
    loginSocial('Google');
  };

  const loginWithFacebook = () => {
    setSocialLoginType(2);
    loginSocial('Facebook');
  };

  const loginWithMicrosoft = () => {
    setSocialLoginType(3);
    loginSocial('MicrosoftIdP');
  };

  const joinAsClient = async () => {
    setSending(true);
    try {
      let res = null;
      if (isIndividual) {
        res = await httpJoinAsClient(querystring.stringify(joinDetail), org.domainName);
      } else {
        res = await httpJoinAsClientOrg(querystring.stringify(joinDetail), org.domainName);
      }
      if (res.status === 'error') {
        throw new Error('org not found');
      } else if (res.status === 'exist') {
        toast.error('You are already a client in accziom!');
      } else if (res.status === 'exist member') {
        toast.error('You have already tried to register as member!');
      } else if (res.status === 'exist joint') {
        toast.error('You already sent join request!');
      } else if (res.status === 'exist received') {
        toast.error('You already received invitation request!');
      } else {
        toast.success('Successfully sent join request.');
      }
    } catch (err) {
      console.log(JSON.stringify(err));
      toast.error('Failed to join!');
    } finally {
      if (mounted.current) {
        setSending(false);
      }
    }
  };

  return (
    <BottomPopupDialog
      open={open}
      onClose={onClose}
      title={org.tradingName}
    >
      <DialogContent
        sx={{
          pb: 5
        }}
      >
        {isLogin && (
          <>
            <Box
              px={2}
            >
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                  submit: null
                }}
                validationSchema={
                  Yup
                    .object()
                    .shape({
                      email: Yup
                        .string()
                        .email('Must be a valid email')
                        .max(255)
                        .required('Email is required')
                    })
                }
                onSubmit={async (values, {
                  setErrors,
                  setStatus,
                  setSubmitting
                }): Promise<void> => {
                  try {
                    cleanUp();
                    dispatch(setLoadingStatus(LoadingStatus.NONE));
                    dispatch(setDomainName(org.domainName));
                    dispatch(setRequestedLocation(recoverUrl));
                    await login(values.email.toLowerCase(), values.password);
                    if (mounted.current) {
                      setStatus({ success: true });
                      setSubmitting(false);
                    }
                  } catch (err) {
                    console.log(JSON.stringify(err));
                    toast.error('Failed to sign in!');
                    if (mounted.current) {
                      setStatus({ success: false });
                      setErrors({ submit: err.message });
                      setSubmitting(false);
                    }
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }): JSX.Element => (
                  <form
                    noValidate
                    onSubmit={handleSubmit}
                    autoComplete="off"
                  >
                    <Grid
                      container
                      spacing={1}
                    >
                      <Grid
                        item
                        xs={12}
                        md={12}
                      >
                        <Button
                          color="primary"
                          size="large"
                          disabled={socialLoginType !== 0 || isSubmitting}
                          variant="outlined"
                          fullWidth
                          onClick={loginWithGoogle}
                        >
                          {socialLoginType !== 1 && (
                            <Box
                              alt="Google"
                              component="img"
                              src="/static/icons/google.svg"
                              sx={{ mr: 3 }}
                            />
                          )}
                          {socialLoginType === 1 && (
                            <CircularProgress
                              color="primary"
                              size={20}
                              sx={{ mr: 3 }}
                            />
                          )}
                          {`Sign in ${orgName} with Google`}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                      >
                        <Button
                          color="primary"
                          size="large"
                          disabled={socialLoginType !== 0 || isSubmitting}
                          variant="outlined"
                          fullWidth
                          onClick={loginWithMicrosoft}
                        >
                          {socialLoginType !== 3 && (
                            <Box
                              sx={{
                                mr: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <Microsoft
                                sx={{
                                  fontSize: '24px'
                                }}
                              />
                            </Box>
                          )}
                          {socialLoginType === 3 && (
                            <CircularProgress
                              color="primary"
                              size={20}
                              sx={{ mr: 1 }}
                            />
                          )}
                          {`Sign in ${orgName} with Microsoft`}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                      >
                        <Button
                          color="primary"
                          size="large"
                          variant="outlined"
                          disabled={socialLoginType !== 0 || isSubmitting}
                          onClick={loginWithFacebook}
                          fullWidth
                        >
                          {socialLoginType !== 2 && (
                            <Box
                              alt="Facebook"
                              component="img"
                              src="/static/icons/facebook.svg"
                              sx={{ mr: 1 }}
                            />
                          )}
                          {socialLoginType === 2 && (
                            <CircularProgress
                              color="primary"
                              size={20}
                              sx={{ marginRight: '8px' }}
                            />
                          )}
                          {`Sign in ${orgName} with Facebook`}
                        </Button>
                      </Grid>
                    </Grid>
                    <Divider
                      sx={{
                        mt: 3,
                        mb: 2,
                        color: 'text.secondary',
                        px: 1
                      }}
                    >
                      or
                    </Divider>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      size="small"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      size="small"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => { setShowPassword(!showPassword); }}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {errors.submit && (
                      <Box sx={{ mt: 1 }}>
                        <FormHelperText error>
                          {errors.submit}
                        </FormHelperText>
                      </Box>
                    )}
                    <Box sx={{ mt: 2 }}>
                      <Button
                        color="primary"
                        disabled={isSubmitting || socialLoginType !== 0}
                        fullWidth
                        size="large"
                        onClick={() => { handleSubmit(); }}
                        variant="contained"
                        startIcon={isSubmitting && socialLoginType === 0 ? <CircularProgress size={16} /> : null}
                      >
                        {`Sign in ${orgName} with Email`}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
            >
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/authentication/password-recovery"
                variant="body2"
                mt={1}
              >
                Forgot your password?
              </Link>
            </Box>
          </>
        )}
        {!isLogin && (
          <>
            {isGettingAddr ? (
              <LoadingItem
                size={30}
                height={200}
              />
            ) : (
              <Box
                px={2}
              >
                <Typography
                  color="text.secondary"
                  textAlign="center"
                  pb={1}
                >
                  {`If you want to become a client of ${orgName} please join.`}
                </Typography>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={isIndividual}
                      onChange={(e) => {
                        setIndividual(e.target.checked);
                      }}
                    />
                  )}
                  label={(
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <Typography
                        sx={{
                          mr: 1
                        }}
                      >
                        {isIndividual ? 'Join as an individual' : 'Join as a business'}
                      </Typography>
                      <PopupTooltip>
                        <Typography>
                          {isIndividual ? `You will become a client of ${orgName} as an individual.` : `You will establish an organization and your organization will become a client of ${orgName}.`}
                        </Typography>
                      </PopupTooltip>
                    </Box>
                  )}
                  sx={{
                    mb: 1
                  }}
                />
                <Grid
                  container
                  spacing={2}
                >
                  {isIndividual ? (
                    <>
                      <Grid
                        item
                        xs={4}
                      >
                        <TextField
                          fullWidth
                          value={joinDetail.firstName}
                          label="First Name *"
                          onChange={(e) => updateJoinDetail('firstName', e.target.value)}
                          size="small"
                          sx={{ mb: joinDetail.firstName ? 3 : 1 }}
                          error={!joinDetail.firstName}
                          helperText={!joinDetail.firstName && 'First name is required'}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                      >
                        <TextField
                          fullWidth
                          value={joinDetail.middleName}
                          label="Middle Name"
                          onChange={(e) => updateJoinDetail('middleName', e.target.value)}
                          size="small"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                      >
                        <TextField
                          fullWidth
                          value={joinDetail.lastName}
                          label="Last Name *"
                          onChange={(e) => updateJoinDetail('lastName', e.target.value)}
                          size="small"
                          sx={{ mb: joinDetail.lastName ? 3 : 1 }}
                          error={!joinDetail.lastName}
                          helperText={!joinDetail.lastName && 'Last name is required'}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        value={joinDetail.penName}
                        label="Trading Name *"
                        onChange={(e) => updateJoinDetail('penName', e.target.value)}
                        size="small"
                        sx={{ mb: joinDetail.firstName ? 3 : 1 }}
                        error={!joinDetail.penName}
                        helperText={!joinDetail.penName && 'Trading name is required'}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <TextField
                      size="small"
                      label="Email *"
                      fullWidth
                      value={joinDetail.email}
                      onChange={(e) => updateJoinDetail('email', e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailIcon />
                          </InputAdornment>
                        )
                      }}
                      sx={{ mb: joinDetail.email ? 3 : 1 }}
                      error={!joinDetail.email}
                      helperText={!joinDetail.email && 'Email is required'}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <TextField
                      size="small"
                      label="Phone"
                      fullWidth
                      value={joinDetail.phone}
                      onChange={(e) => updateJoinDetail('phone', e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                </Grid>
                <TextField
                  size="small"
                  fullWidth
                  label="Address"
                  sx={{
                    mb: 1
                  }}
                  value={joinDetail.addressLine1}
                  onChange={async (event) => {
                    const { value } = event.target;
                    updateJoinDetail('addressLine1', value);
                    setAddressAnchorEl(event.currentTarget);
                    if (value?.length > 0) {
                      try {
                        const addrs = await httpFindAddress(value);
                        setAddressSuggestions(addrs);
                      } catch (err) {
                        console.log(JSON.stringify(err));
                      }
                    } else {
                      setAddressSuggestions([]);
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PlaceIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mb: 1
                  }}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <TextField
                      size="small"
                      label="City"
                      fullWidth
                      value={joinDetail.city}
                      onChange={(e) => updateJoinDetail('city', e.target.value)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <TextField
                      size="small"
                      label="Region/State"
                      fullWidth
                      value={joinDetail.state}
                      onChange={(e) => updateJoinDetail('state', e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mb: 2
                  }}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <TextField
                      size="small"
                      label="Postal Code"
                      fullWidth
                      value={joinDetail.postalCode}
                      onChange={(e) => updateJoinDetail('postalCode', e.target.value)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Autocomplete
                      getOptionLabel={(option): string => option.text}
                      options={countries}
                      value={joinDetail?.country ? getCountry(joinDetail.country) : getCountry('AU')}
                      onChange={(e, v) => {
                        if (v) updateJoinDetail('country', v.value);
                      }}
                      fullWidth
                      renderInput={(params): JSX.Element => (
                        <TextField
                          fullWidth
                          variant="outlined"
                          {...params}
                          size="small"
                          label="Country"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <TextField
                  placeholder="Leave a message"
                  fullWidth
                  multiline
                  rows={3}
                  value={joinDetail.hiMsg}
                  onChange={(e) => updateJoinDetail('hiMsg', e.target.value)}
                  sx={{ mb: joinDetail.hiMsg ? 3 : 1 }}
                  error={!joinDetail.hiMsg}
                  helperText={!joinDetail.hiMsg && 'Message is required'}
                />
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  startIcon={isSending ? <CircularProgress size={16} /> : null}
                  onClick={async () => {
                    await joinAsClient();
                    onClose();
                  }}
                  disabled={isSending || requireError}
                >
                  {`Join ${orgName}`}
                </Button>
              </Box>
            )}
          </>
        )}
        {addressSuggestions?.length > 0 && (
          <ClickAwayListener
            onClickAway={() => {
              setAddressSuggestions([]);
            }}
          >
            <Popper
              anchorEl={addressAnchorEl}
              open={addressSuggestions?.length > 0}
              placement="bottom-start"
              style={{
                zIndex: 1120
              }}
            >
              <Paper
                sx={{
                  mt: 1,
                  maxWidth: '100%',
                  width: 320
                }}
              >
                <Box
                  sx={{
                    px: 2,
                    pt: 2
                  }}
                >
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    Suggestions
                  </Typography>
                </Box>
                <List>
                  {addressSuggestions.map((addr) => (
                    <ListItem
                      button
                      key={addr.fullAddress}
                      onClick={() => {
                        const temp = joinDetail;
                        temp.addressLine1 = null2empty(addr.fullAddress);
                        temp.city = null2empty(addr.locality);
                        temp.state = null2empty(addr.state);
                        temp.postalCode = null2empty(addr.postcode);
                        setJoinDetail(temp);
                        setAddressSuggestions([]);
                      }}
                    >
                      <ListItemText
                        primary={addr.fullAddress}
                        primaryTypographyProps={{
                          color: 'textPrimary',
                          noWrap: true,
                          variant: 'subtitle2'
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Popper>
          </ClickAwayListener>
        )}
      </DialogContent>
    </BottomPopupDialog>
  );
};

export default ConnectDialog;
