export const countries = [
  {
    text: 'Afghanistan',
    dial: '+93',
    value: 'AF'
  },
  {
    text: 'Aland Islands',
    dial: '+358',
    value: 'AX'
  },
  {
    text: 'Albania',
    dial: '+355',
    value: 'AL'
  },
  {
    text: 'Algeria',
    dial: '+213',
    value: 'DZ'
  },
  {
    text: 'AmericanSamoa',
    dial: '+1684',
    value: 'AS'
  },
  {
    text: 'Andorra',
    dial: '+376',
    value: 'AD'
  },
  {
    text: 'Angola',
    dial: '+244',
    value: 'AO'
  },
  {
    text: 'Anguilla',
    dial: '+1264',
    value: 'AI'
  },
  {
    text: 'Antarctica',
    dial: '+672',
    value: 'AQ'
  },
  {
    text: 'Antigua and Barbuda',
    dial: '+1268',
    value: 'AG'
  },
  {
    text: 'Argentina',
    dial: '+54',
    value: 'AR'
  },
  {
    text: 'Armenia',
    dial: '+374',
    value: 'AM'
  },
  {
    text: 'Aruba',
    dial: '+297',
    value: 'AW'
  },
  {
    text: 'Australia',
    dial: '+61',
    value: 'AU'
  },
  {
    text: 'Austria',
    dial: '+43',
    value: 'AT'
  },
  {
    text: 'Azerbaijan',
    dial: '+994',
    value: 'AZ'
  },
  {
    text: 'Bahamas',
    dial: '+1242',
    value: 'BS'
  },
  {
    text: 'Bahrain',
    dial: '+973',
    value: 'BH'
  },
  {
    text: 'Bangladesh',
    dial: '+880',
    value: 'BD'
  },
  {
    text: 'Barbados',
    dial: '+1246',
    value: 'BB'
  },
  {
    text: 'Belarus',
    dial: '+375',
    value: 'BY'
  },
  {
    text: 'Belgium',
    dial: '+32',
    value: 'BE'
  },
  {
    text: 'Belize',
    dial: '+501',
    value: 'BZ'
  },
  {
    text: 'Benin',
    dial: '+229',
    value: 'BJ'
  },
  {
    text: 'Bermuda',
    dial: '+1441',
    value: 'BM'
  },
  {
    text: 'Bhutan',
    dial: '+975',
    value: 'BT'
  },
  {
    text: 'Bolivia, Plurinational State of',
    dial: '+591',
    value: 'BO'
  },
  {
    text: 'Bosnia and Herzegovina',
    dial: '+387',
    value: 'BA'
  },
  {
    text: 'Botswana',
    dial: '+267',
    value: 'BW'
  },
  {
    text: 'Brazil',
    dial: '+55',
    value: 'BR'
  },
  {
    text: 'British Indian Ocean Territory',
    dial: '+246',
    value: 'IO'
  },
  {
    text: 'Brunei Darussalam',
    dial: '+673',
    value: 'BN'
  },
  {
    text: 'Bulgaria',
    dial: '+359',
    value: 'BG'
  },
  {
    text: 'Burkina Faso',
    dial: '+226',
    value: 'BF'
  },
  {
    text: 'Burundi',
    dial: '+257',
    value: 'BI'
  },
  {
    text: 'Cambodia',
    dial: '+855',
    value: 'KH'
  },
  {
    text: 'Cameroon',
    dial: '+237',
    value: 'CM'
  },
  {
    text: 'Canada',
    dial: '+1',
    value: 'CA'
  },
  {
    text: 'Cape Verde',
    dial: '+238',
    value: 'CV'
  },
  {
    text: 'Cayman Islands',
    dial: '+ 345',
    value: 'KY'
  },
  {
    text: 'Central African Republic',
    dial: '+236',
    value: 'CF'
  },
  {
    text: 'Chad',
    dial: '+235',
    value: 'TD'
  },
  {
    text: 'Chile',
    dial: '+56',
    value: 'CL'
  },
  {
    text: 'China',
    dial: '+86',
    value: 'CN'
  },
  {
    text: 'Christmas Island',
    dial: '+61',
    value: 'CX'
  },
  {
    text: 'Cocos (Keeling) Islands',
    dial: '+61',
    value: 'CC'
  },
  {
    text: 'Colombia',
    dial: '+57',
    value: 'CO'
  },
  {
    text: 'Comoros',
    dial: '+269',
    value: 'KM'
  },
  {
    text: 'Congo',
    dial: '+242',
    value: 'CG'
  },
  {
    text: 'Congo, The Democratic Republic of the Congo',
    dial: '+243',
    value: 'CD'
  },
  {
    text: 'Cook Islands',
    dial: '+682',
    value: 'CK'
  },
  {
    text: 'Costa Rica',
    dial: '+506',
    value: 'CR'
  },
  {
    text: 'Cote d\'Ivoire',
    dial: '+225',
    value: 'CI'
  },
  {
    text: 'Croatia',
    dial: '+385',
    value: 'HR'
  },
  {
    text: 'Cuba',
    dial: '+53',
    value: 'CU'
  },
  {
    text: 'Cyprus',
    dial: '+357',
    value: 'CY'
  },
  {
    text: 'Czech Republic',
    dial: '+420',
    value: 'CZ'
  },
  {
    text: 'Denmark',
    dial: '+45',
    value: 'DK'
  },
  {
    text: 'Djibouti',
    dial: '+253',
    value: 'DJ'
  },
  {
    text: 'Dominica',
    dial: '+1767',
    value: 'DM'
  },
  {
    text: 'Dominican Republic',
    dial: '+1849',
    value: 'DO'
  },
  {
    text: 'Ecuador',
    dial: '+593',
    value: 'EC'
  },
  {
    text: 'Egypt',
    dial: '+20',
    value: 'EG'
  },
  {
    text: 'El Salvador',
    dial: '+503',
    value: 'SV'
  },
  {
    text: 'Equatorial Guinea',
    dial: '+240',
    value: 'GQ'
  },
  {
    text: 'Eritrea',
    dial: '+291',
    value: 'ER'
  },
  {
    text: 'Estonia',
    dial: '+372',
    value: 'EE'
  },
  {
    text: 'Ethiopia',
    dial: '+251',
    value: 'ET'
  },
  {
    text: 'Falkland Islands (Malvinas)',
    dial: '+500',
    value: 'FK'
  },
  {
    text: 'Faroe Islands',
    dial: '+298',
    value: 'FO'
  },
  {
    text: 'Fiji',
    dial: '+679',
    value: 'FJ'
  },
  {
    text: 'Finland',
    dial: '+358',
    value: 'FI'
  },
  {
    text: 'France',
    dial: '+33',
    value: 'FR'
  },
  {
    text: 'French Guiana',
    dial: '+594',
    value: 'GF'
  },
  {
    text: 'French Polynesia',
    dial: '+689',
    value: 'PF'
  },
  {
    text: 'Gabon',
    dial: '+241',
    value: 'GA'
  },
  {
    text: 'Gambia',
    dial: '+220',
    value: 'GM'
  },
  {
    text: 'Georgia',
    dial: '+995',
    value: 'GE'
  },
  {
    text: 'Germany',
    dial: '+49',
    value: 'DE'
  },
  {
    text: 'Ghana',
    dial: '+233',
    value: 'GH'
  },
  {
    text: 'Gibraltar',
    dial: '+350',
    value: 'GI'
  },
  {
    text: 'Greece',
    dial: '+30',
    value: 'GR'
  },
  {
    text: 'Greenland',
    dial: '+299',
    value: 'GL'
  },
  {
    text: 'Grenada',
    dial: '+1473',
    value: 'GD'
  },
  {
    text: 'Guadeloupe',
    dial: '+590',
    value: 'GP'
  },
  {
    text: 'Guam',
    dial: '+1671',
    value: 'GU'
  },
  {
    text: 'Guatemala',
    dial: '+502',
    value: 'GT'
  },
  {
    text: 'Guernsey',
    dial: '+44',
    value: 'GG'
  },
  {
    text: 'Guinea',
    dial: '+224',
    value: 'GN'
  },
  {
    text: 'Guinea-Bissau',
    dial: '+245',
    value: 'GW'
  },
  {
    text: 'Guyana',
    dial: '+595',
    value: 'GY'
  },
  {
    text: 'Haiti',
    dial: '+509',
    value: 'HT'
  },
  {
    text: 'Holy See (Vatican City State)',
    dial: '+379',
    value: 'VA'
  },
  {
    text: 'Honduras',
    dial: '+504',
    value: 'HN'
  },
  {
    text: 'Hong Kong',
    dial: '+852',
    value: 'HK'
  },
  {
    text: 'Hungary',
    dial: '+36',
    value: 'HU'
  },
  {
    text: 'Iceland',
    dial: '+354',
    value: 'IS'
  },
  {
    text: 'India',
    dial: '+91',
    value: 'IN'
  },
  {
    text: 'Indonesia',
    dial: '+62',
    value: 'ID'
  },
  {
    text: 'Iran, Islamic Republic of Persian Gulf',
    dial: '+98',
    value: 'IR'
  },
  {
    text: 'Iraq',
    dial: '+964',
    value: 'IQ'
  },
  {
    text: 'Ireland',
    dial: '+353',
    value: 'IE'
  },
  {
    text: 'Isle of Man',
    dial: '+44',
    value: 'IM'
  },
  {
    text: 'Israel',
    dial: '+972',
    value: 'IL'
  },
  {
    text: 'Italy',
    dial: '+39',
    value: 'IT'
  },
  {
    text: 'Jamaica',
    dial: '+1876',
    value: 'JM'
  },
  {
    text: 'Japan',
    dial: '+81',
    value: 'JP'
  },
  {
    text: 'Jersey',
    dial: '+44',
    value: 'JE'
  },
  {
    text: 'Jordan',
    dial: '+962',
    value: 'JO'
  },
  {
    text: 'Kazakhstan',
    dial: '+77',
    value: 'KZ'
  },
  {
    text: 'Kenya',
    dial: '+254',
    value: 'KE'
  },
  {
    text: 'Kiribati',
    dial: '+686',
    value: 'KI'
  },
  {
    text: 'Korea, Democratic People\'s Republic of Korea',
    dial: '+850',
    value: 'KP'
  },
  {
    text: 'Korea, Republic of South Korea',
    dial: '+82',
    value: 'KR'
  },
  {
    text: 'Kuwait',
    dial: '+965',
    value: 'KW'
  },
  {
    text: 'Kyrgyzstan',
    dial: '+996',
    value: 'KG'
  },
  {
    text: 'Laos',
    dial: '+856',
    value: 'LA'
  },
  {
    text: 'Latvia',
    dial: '+371',
    value: 'LV'
  },
  {
    text: 'Lebanon',
    dial: '+961',
    value: 'LB'
  },
  {
    text: 'Lesotho',
    dial: '+266',
    value: 'LS'
  },
  {
    text: 'Liberia',
    dial: '+231',
    value: 'LR'
  },
  {
    text: 'Libyan Arab Jamahiriya',
    dial: '+218',
    value: 'LY'
  },
  {
    text: 'Liechtenstein',
    dial: '+423',
    value: 'LI'
  },
  {
    text: 'Lithuania',
    dial: '+370',
    value: 'LT'
  },
  {
    text: 'Luxembourg',
    dial: '+352',
    value: 'LU'
  },
  {
    text: 'Macao',
    dial: '+853',
    value: 'MO'
  },
  {
    text: 'Macedonia',
    dial: '+389',
    value: 'MK'
  },
  {
    text: 'Madagascar',
    dial: '+261',
    value: 'MG'
  },
  {
    text: 'Malawi',
    dial: '+265',
    value: 'MW'
  },
  {
    text: 'Malaysia',
    dial: '+60',
    value: 'MY'
  },
  {
    text: 'Maldives',
    dial: '+960',
    value: 'MV'
  },
  {
    text: 'Mali',
    dial: '+223',
    value: 'ML'
  },
  {
    text: 'Malta',
    dial: '+356',
    value: 'MT'
  },
  {
    text: 'Marshall Islands',
    dial: '+692',
    value: 'MH'
  },
  {
    text: 'Martinique',
    dial: '+596',
    value: 'MQ'
  },
  {
    text: 'Mauritania',
    dial: '+222',
    value: 'MR'
  },
  {
    text: 'Mauritius',
    dial: '+230',
    value: 'MU'
  },
  {
    text: 'Mayotte',
    dial: '+262',
    value: 'YT'
  },
  {
    text: 'Mexico',
    dial: '+52',
    value: 'MX'
  },
  {
    text: 'Micronesia, Federated States of Micronesia',
    dial: '+691',
    value: 'FM'
  },
  {
    text: 'Moldova',
    dial: '+373',
    value: 'MD'
  },
  {
    text: 'Monaco',
    dial: '+377',
    value: 'MC'
  },
  {
    text: 'Mongolia',
    dial: '+976',
    value: 'MN'
  },
  {
    text: 'Montenegro',
    dial: '+382',
    value: 'ME'
  },
  {
    text: 'Montserrat',
    dial: '+1664',
    value: 'MS'
  },
  {
    text: 'Morocco',
    dial: '+212',
    value: 'MA'
  },
  {
    text: 'Mozambique',
    dial: '+258',
    value: 'MZ'
  },
  {
    text: 'Myanmar',
    dial: '+95',
    value: 'MM'
  },
  {
    text: 'Namibia',
    dial: '+264',
    value: 'NA'
  },
  {
    text: 'Nauru',
    dial: '+674',
    value: 'NR'
  },
  {
    text: 'Nepal',
    dial: '+977',
    value: 'NP'
  },
  {
    text: 'Netherlands',
    dial: '+31',
    value: 'NL'
  },
  {
    text: 'Netherlands Antilles',
    dial: '+599',
    value: 'AN'
  },
  {
    text: 'New Caledonia',
    dial: '+687',
    value: 'NC'
  },
  {
    text: 'New Zealand',
    dial: '+64',
    value: 'NZ'
  },
  {
    text: 'Nicaragua',
    dial: '+505',
    value: 'NI'
  },
  {
    text: 'Niger',
    dial: '+227',
    value: 'NE'
  },
  {
    text: 'Nigeria',
    dial: '+234',
    value: 'NG'
  },
  {
    text: 'Niue',
    dial: '+683',
    value: 'NU'
  },
  {
    text: 'Norfolk Island',
    dial: '+672',
    value: 'NF'
  },
  {
    text: 'Northern Mariana Islands',
    dial: '+1670',
    value: 'MP'
  },
  {
    text: 'Norway',
    dial: '+47',
    value: 'NO'
  },
  {
    text: 'Oman',
    dial: '+968',
    value: 'OM'
  },
  {
    text: 'Pakistan',
    dial: '+92',
    value: 'PK'
  },
  {
    text: 'Palau',
    dial: '+680',
    value: 'PW'
  },
  {
    text: 'Palestinian Territory, Occupied',
    dial: '+970',
    value: 'PS'
  },
  {
    text: 'Panama',
    dial: '+507',
    value: 'PA'
  },
  {
    text: 'Papua New Guinea',
    dial: '+675',
    value: 'PG'
  },
  {
    text: 'Paraguay',
    dial: '+595',
    value: 'PY'
  },
  {
    text: 'Peru',
    dial: '+51',
    value: 'PE'
  },
  {
    text: 'Philippines',
    dial: '+63',
    value: 'PH'
  },
  {
    text: 'Pitcairn',
    dial: '+872',
    value: 'PN'
  },
  {
    text: 'Poland',
    dial: '+48',
    value: 'PL'
  },
  {
    text: 'Portugal',
    dial: '+351',
    value: 'PT'
  },
  {
    text: 'Puerto Rico',
    dial: '+1939',
    value: 'PR'
  },
  {
    text: 'Qatar',
    dial: '+974',
    value: 'QA'
  },
  {
    text: 'Romania',
    dial: '+40',
    value: 'RO'
  },
  {
    text: 'Russia',
    dial: '+7',
    value: 'RU'
  },
  {
    text: 'Rwanda',
    dial: '+250',
    value: 'RW'
  },
  {
    text: 'Reunion',
    dial: '+262',
    value: 'RE'
  },
  {
    text: 'Saint Barthelemy',
    dial: '+590',
    value: 'BL'
  },
  {
    text: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial: '+290',
    value: 'SH'
  },
  {
    text: 'Saint Kitts and Nevis',
    dial: '+1869',
    value: 'KN'
  },
  {
    text: 'Saint Lucia',
    dial: '+1758',
    value: 'LC'
  },
  {
    text: 'Saint Martin',
    dial: '+590',
    value: 'MF'
  },
  {
    text: 'Saint Pierre and Miquelon',
    dial: '+508',
    value: 'PM'
  },
  {
    text: 'Saint Vincent and the Grenadines',
    dial: '+1784',
    value: 'VC'
  },
  {
    text: 'Samoa',
    dial: '+685',
    value: 'WS'
  },
  {
    text: 'San Marino',
    dial: '+378',
    value: 'SM'
  },
  {
    text: 'Sao Tome and Principe',
    dial: '+239',
    value: 'ST'
  },
  {
    text: 'Saudi Arabia',
    dial: '+966',
    value: 'SA'
  },
  {
    text: 'Senegal',
    dial: '+221',
    value: 'SN'
  },
  {
    text: 'Serbia',
    dial: '+381',
    value: 'RS'
  },
  {
    text: 'Seychelles',
    dial: '+248',
    value: 'SC'
  },
  {
    text: 'Sierra Leone',
    dial: '+232',
    value: 'SL'
  },
  {
    text: 'Singapore',
    dial: '+65',
    value: 'SG'
  },
  {
    text: 'Slovakia',
    dial: '+421',
    value: 'SK'
  },
  {
    text: 'Slovenia',
    dial: '+386',
    value: 'SI'
  },
  {
    text: 'Solomon Islands',
    dial: '+677',
    value: 'SB'
  },
  {
    text: 'Somalia',
    dial: '+252',
    value: 'SO'
  },
  {
    text: 'South Africa',
    dial: '+27',
    value: 'ZA'
  },
  {
    text: 'South Sudan',
    dial: '+211',
    value: 'SS'
  },
  {
    text: 'South Georgia and the South Sandwich Islands',
    dial: '+500',
    value: 'GS'
  },
  {
    text: 'Spain',
    dial: '+34',
    value: 'ES'
  },
  {
    text: 'Sri Lanka',
    dial: '+94',
    value: 'LK'
  },
  {
    text: 'Sudan',
    dial: '+249',
    value: 'SD'
  },
  {
    text: 'Suriname',
    dial: '+597',
    value: 'SR'
  },
  {
    text: 'Svalbard and Jan Mayen',
    dial: '+47',
    value: 'SJ'
  },
  {
    text: 'Swaziland',
    dial: '+268',
    value: 'SZ'
  },
  {
    text: 'Sweden',
    dial: '+46',
    value: 'SE'
  },
  {
    text: 'Switzerland',
    dial: '+41',
    value: 'CH'
  },
  {
    text: 'Syrian Arab Republic',
    dial: '+963',
    value: 'SY'
  },
  {
    text: 'Taiwan',
    dial: '+886',
    value: 'TW'
  },
  {
    text: 'Tajikistan',
    dial: '+992',
    value: 'TJ'
  },
  {
    text: 'Tanzania, United Republic of Tanzania',
    dial: '+255',
    value: 'TZ'
  },
  {
    text: 'Thailand',
    dial: '+66',
    value: 'TH'
  },
  {
    text: 'Timor-Leste',
    dial: '+670',
    value: 'TL'
  },
  {
    text: 'Togo',
    dial: '+228',
    value: 'TG'
  },
  {
    text: 'Tokelau',
    dial: '+690',
    value: 'TK'
  },
  {
    text: 'Tonga',
    dial: '+676',
    value: 'TO'
  },
  {
    text: 'Trinidad and Tobago',
    dial: '+1868',
    value: 'TT'
  },
  {
    text: 'Tunisia',
    dial: '+216',
    value: 'TN'
  },
  {
    text: 'Turkey',
    dial: '+90',
    value: 'TR'
  },
  {
    text: 'Turkmenistan',
    dial: '+993',
    value: 'TM'
  },
  {
    text: 'Turks and Caicos Islands',
    dial: '+1649',
    value: 'TC'
  },
  {
    text: 'Tuvalu',
    dial: '+688',
    value: 'TV'
  },
  {
    text: 'Uganda',
    dial: '+256',
    value: 'UG'
  },
  {
    text: 'Ukraine',
    dial: '+380',
    value: 'UA'
  },
  {
    text: 'United Arab Emirates',
    dial: '+971',
    value: 'AE'
  },
  {
    text: 'United Kingdom',
    dial: '+44',
    value: 'GB'
  },
  {
    text: 'United States',
    dial: '+1',
    value: 'US'
  },
  {
    text: 'Uruguay',
    dial: '+598',
    value: 'UY'
  },
  {
    text: 'Uzbekistan',
    dial: '+998',
    value: 'UZ'
  },
  {
    text: 'Vanuatu',
    dial: '+678',
    value: 'VU'
  },
  {
    text: 'Venezuela, Bolivarian Republic of Venezuela',
    dial: '+58',
    value: 'VE'
  },
  {
    text: 'Vietnam',
    dial: '+84',
    value: 'VN'
  },
  {
    text: 'Virgin Islands, British',
    dial: '+1284',
    value: 'VG'
  },
  {
    text: 'Virgin Islands, U.S.',
    dial: '+1340',
    value: 'VI'
  },
  {
    text: 'Wallis and Futuna',
    dial: '+681',
    value: 'WF'
  },
  {
    text: 'Yemen',
    dial: '+967',
    value: 'YE'
  },
  {
    text: 'Zambia',
    dial: '+260',
    value: 'ZM'
  },
  {
    text: 'Zimbabwe',
    dial: '+263',
    value: 'ZW'
  }
];

export const getCountry = (value: string): any => countries.find((item) => item.value === value);

export const getCountryByDialCode = (phone: string): any => countries.find((item) => `${phone.startsWith('+') ? '' : '+'}${phone}`.startsWith(item.dial))?.value;
