import { FC, useState } from 'react';
import {
  Box,
  TextField,
  MenuItem
} from '@material-ui/core';
import ActionButton from 'src/components/interact/ActionButton';
import SyncIcon from '@mui/icons-material/Sync';
import { getGasFee } from 'src/utils/merchantUtils';
import { CRYPTO_ETHER, CRYPTO_MRC } from 'src/globals';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import useMounted from 'src/hooks/useMounted';
import NumberField from 'src/components/interact/NumberField';

const gasFeePayOption = [
  {
    value: CRYPTO_ETHER,
    label: 'Ether',
  },
  {
    value: CRYPTO_MRC,
    label: 'MERc'
  }
];

const GasLimit: FC<{
  gasLimit: number;
  onSetGasLimit: any;
  gasParams: any;
  estimateDisabled?: boolean;
  minGas: number;
}> = ({ gasLimit, onSetGasLimit, gasParams, estimateDisabled, minGas }) => {
  const mounted = useMounted();
  const [isEstimatingGasFee, setEstimatingGasFEe] = useState<boolean>(false);

  const payOption = gasLimit === 0 ? CRYPTO_ETHER : CRYPTO_MRC;

  const onEstimateGasFee = async () => {
    setEstimatingGasFEe(true);
    try {
      const gf = await getGasFee(gasParams);
      onSetGasLimit(gf);
    } catch (err) {
      console.log(JSON.stringify(err));
      toast.error('Failed to estimate gas fee!');
    } finally {
      if (mounted.current) setEstimatingGasFEe(false);
    }
  };

  const handleChangePayOption = (v) => onSetGasLimit(v === CRYPTO_ETHER ? 0 : minGas);

  return (
    <Box
      display="flex"
      // alignItems="center"
    >
      <TextField
        size="small"
        select
        label="Pay gas fee by using"
        value={payOption}
        onChange={(e) => handleChangePayOption(e.target.value)}
        sx={{ width: 150 }}
      >
        {gasFeePayOption.map((opt) => (
          <MenuItem
            key={opt.value}
            value={opt.value}
          >
            {opt.label}
          </MenuItem>
        ))}
      </TextField>
      {payOption === CRYPTO_MRC && (
        <>
          <NumberField
            size="small"
            label="Gas Limit *"
            value={gasLimit}
            // type="number"
            onNumChange={(newValue) => {
              if (newValue === null) {
                onSetGasLimit(newValue);
                return;
              }
              if (newValue < 1) newValue = 1;
              onSetGasLimit(newValue);
            }}
            sx={{
              ml: 3,
              width: 150
            }}
            error={gasLimit === null || gasLimit < minGas}
            helperText={gasLimit < minGas && `Gas limit should be at least ${minGas}.`}
          />
          <Box
            pl={1}
            pt={0.5}
          >
            <ActionButton
              title="Estimate Gas Fee"
              icon={SyncIcon}
              onClick={onEstimateGasFee}
              isLoading={isEstimatingGasFee}
              color="action"
              disabled={estimateDisabled}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

GasLimit.propTypes = {
  gasLimit: PropTypes.number,
  onSetGasLimit: PropTypes.func,
  gasParams: PropTypes.any,
  estimateDisabled: PropTypes.bool,
  minGas: PropTypes.number
};

export default GasLimit;
