import { Apprising } from 'src/../../Common/Model/apprising';
import { activityTypes } from 'src/globals';

export const getSidebarApprisingCount = (apprs: Apprising[], href: string): number => (apprs.filter((appr) => appr.href === href).length);

export const getNavbarApprisingCount = (apprs: Apprising[], href: string): number => {
  if (href === '/home') {
    return apprs.filter((appr) => (
      (appr.type === 'add' || appr.type === 'followup')
      && activityTypes.some((typ) => typ.href === appr.href)
    )).length;
  }
  return apprs.filter((appr) => appr.href.startsWith(href)).length;
};
