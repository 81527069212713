import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import { Box, IconButton, SvgIcon } from '@material-ui/core';

const PopupTooltip: FC<{ children: any; size?: number; icon?: any }> = ({ children, size, icon: Icon, ...others }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      {...others}
    >
      <IconButton
        onClick={handleClick}
        sx={{
          p: 0
        }}
      >
        {!Icon ? (
          <InfoIcon
            sx={{
              width: !size ? 18 : size,
              height: !size ? 18 : size
            }}
          />
        ) : (
          <SvgIcon
            sx={{
              width: !size ? 18 : size,
              height: !size ? 18 : size
            }}
          >
            <Icon />
          </SvgIcon>
        )}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            maxWidth: '80%',
            p: 1
          }}
        >
          {children}
        </Box>
      </Popover>
    </Box>
  );
};

PopupTooltip.propTypes = {
  children: PropTypes.any,
  size: PropTypes.number,
  icon: PropTypes.any
};

export default PopupTooltip;
