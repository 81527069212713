const engagementLetterTemplate = `<p>&lt;date&gt;</p>
<p><br></p>
<p>&lt;client name&gt;</p>
<p>&lt;client email&gt;</p>
<p><br></p>
<p>Hi &lt;client name&gt;,</p>
`;

/*
<p><strong><u>Engagement as your tax agent for an individual income tax return</u></strong></p>
<p><br></p>
<p>Thank you for instructions on &lt;date&gt;.&nbsp;We are pleased to accept an appointment as your tax agent.</p>
<p><br></p>
<p>At the outset, we need to enter into an agreement with you setting out the terms on which we will assist you,
  including how we will charge you for the work.</p>
<p><br></p>
<p>This letter and the enclosed Terms of Business set out the terms of the engagement. Any additions will be by written
  agreement of both parties. Please read this letter and the Terms of Business carefully. If the terms are acceptable
  to you, please sign and return this letter to us. If you do not return a signed copy of this letter, but continue to
  provide us with information and instructions, we will assume that you have accepted the terms contained in this
  letter.</p>
<p><br></p>
<p><strong><u>Scope of services</u></strong></p>
<p><br></p>
<p>In addition to the financial information required to complete your tax return, it is expected that you will make
  available all relevant source documentation to us.</p>
<p>In preparing your individual tax return we will rely on the documents and information provided, and representations
  made by you.</p>
<p><br></p>
<p><strong><u>Matters outside the scope of services</u></strong></p>
<p><br></p>
<p>In performing the Services, we will not perform an audit or review. Accordingly, no assurances are made in this
  regard. This engagement cannot be relied upon to disclose irregularities including fraud, other illegal acts and
  errors that may occur. However, we will inform you of any such matters that come to our attention.</p>
<p><br></p>
<p>In performing the Services we will not review the accuracy of any previous tax returns lodged by you or by a previous
  tax agent.</p>
<p><br></p>
<p><strong><u>Third Party Involvement</u></strong></p>
<p><br></p>
<p>At times we may outsource some of our work which involves us entering into an agreement with a third party to provide
  specific processes, functions, services or activities for us.&nbsp;If we decide to do this as part of performing the
  services for you, we will contact you first to seek your approval to engage other parties.</p>
<p><br></p>
<p>This engagement is a contract between you and us, and you agree that none of the third parties we used will have any
  liability to you and you will not bring any claim or proceedings of any nature in connection with this engagement
  against any third party that we may use to provide the services. This exclusion will not apply to any liability,
  claim or proceeding founded on an allegation of fraud or other liability that cannot be excluded under law.</p>
<p><br></p>
<p><strong><u>Professional fee and payments</u></strong></p>
<p>Total fee of this service is $&lt;total fee&gt;.</p>
<p><br></p>
<p><strong><u>Deduction of professional fees from your tax refund</u></strong></p>
<p><br></p>
<p>It is agreed that fees for the Services will be deducted directly from any tax refund you receive. In accordance with
  professional standards requirements, your refund will be deposited into our Trust Account with our professional fees
  deducted and the balance of the funds forwarded to you as agreed.</p>
<p><br></p>
<p><br></p>
*/

export default engagementLetterTemplate;
