import numeral from 'numeral';
import { CRYPTO_ETHER, CRYPTO_MRC, CRYPTO_TETHER, currencyTypeToUnit, FIAT_AUD, FIAT_USD } from 'src/globals';
import { httpGetExchangeRate } from 'src/apis/httpDispatch';
import gstore from 'src/store';

export const getExchangeRatesForOneUSD = async (currencies: string[]): Promise<any> => new Promise((resolve, reject) => {
  httpGetExchangeRate(currencies)
    .then((res) => {
      resolve(res);
    })
    .catch((err) => {
      reject(new Error(JSON.stringify(err)));
    });
});

export const displayUnit = {
  AUD: 'AUD',
  USD: 'USD',
  ETH: 'ETH',
  USDT: 'USDT',
  MERC: 'MERC'
};

export const getFormatedPrice = (price: any, currency: string, hideUnit?: boolean): string => {
  let strRet = '';
  let value = Number(price);
  switch (currency) {
    case CRYPTO_ETHER:
    case currencyTypeToUnit(CRYPTO_ETHER):
      value = Math.round(value * 10000) / 10000;
      strRet = `${value === 0 ? `${!hideUnit ? 'Free' : '0'}` : `${!hideUnit ? `${displayUnit.ETH} ` : ''}${numeral(value).format('0,0.0000')}`.replace('.0000', '')}`;
      break;
    case CRYPTO_TETHER:
    case currencyTypeToUnit(CRYPTO_TETHER):
      value = Math.round(value * 100) / 100;
      strRet = `${value === 0 ? `${!hideUnit ? 'Free' : '0'}` : `${!hideUnit ? `${displayUnit.USDT} ` : ''}${numeral(value).format('0,0.00')}`.replace('.00', '')}`;
      break;
    case CRYPTO_MRC:
    case currencyTypeToUnit(CRYPTO_MRC):
      value = Math.round(value);
      strRet = `${value === 0 ? `${!hideUnit ? 'Free' : '0'}` : `${!hideUnit ? `${displayUnit.MERC} ` : ''}${numeral(value).format('0,0')}`}`;
      break;
    case FIAT_AUD:
    case currencyTypeToUnit(FIAT_AUD):
      value = Math.round(value * 100) / 100;
      strRet = `${value === 0 ? `${!hideUnit ? 'Free' : '0'}` : `${!hideUnit ? `${displayUnit.AUD} ` : ''}${numeral(value).format('0,0.00')}`.replace('.00', '')}`;
      break;
    case FIAT_USD:
    case currencyTypeToUnit(FIAT_USD):
      value = Math.round(value * 100) / 100;
      strRet = `${value === 0 ? `${!hideUnit ? 'Free' : '0'}` : `${!hideUnit ? `${displayUnit.USD} ` : ''}${numeral(value).format('0,0.00')}`.replace('.00', '')}`;
      break;
    default:
      break;
  }
  return strRet;
};

export const getFormatedCurrencyUnit = (currency: string): string => {
  let strRet = '';
  switch (currency) {
    case CRYPTO_ETHER:
    case currencyTypeToUnit(CRYPTO_ETHER):
      strRet = displayUnit.ETH;
      break;
    case CRYPTO_TETHER:
    case currencyTypeToUnit(CRYPTO_TETHER):
      strRet = displayUnit.USDT;
      break;
    case CRYPTO_MRC:
    case currencyTypeToUnit(CRYPTO_MRC):
      strRet = displayUnit.MERC;
      break;
    case FIAT_AUD:
    case currencyTypeToUnit(FIAT_AUD):
      strRet = displayUnit.AUD;
      break;
    case FIAT_USD:
    case currencyTypeToUnit(FIAT_USD):
      strRet = displayUnit.USD;
      break;
    default:
      break;
  }
  return strRet;
};

export const convertCurrency = (price: number, from: string, to: string): number => {
  const { curExRate } = gstore.getState().organization;
  return (price * curExRate[to]) / curExRate[from];
};
