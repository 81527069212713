import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, ThemeProvider, Box } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { AppBarProps } from '@material-ui/core';
import { createCustomTheme } from 'src/theme';
import useSettings from 'src/hooks/useSettings';

interface ActionBottombarProps extends AppBarProps {
  trigger?: boolean;
  children?: ReactNode;
}

const ActionBottombarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: 'rgb(32, 41, 55)'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
);

const ActionBottombar: FC<ActionBottombarProps> = (props) => {
  const { trigger, children, ...other } = props;

  const { settings } = useSettings();
  const themeMobile = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'MOBILE'
  });

  return (
    <ThemeProvider theme={themeMobile}>
      <ActionBottombarRoot
        sx={{
          top: 'auto',
          bottom: 0,
          backgroundColor: 'background.paper',
        }}
        {...other}
      >
        <Toolbar
          sx={{
            minHeight: trigger ? '72px' : '48px',
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end',
              mt: 0.7,
              '& button': {
                mx: 0.5
              }
            }}
          >
            {children}
          </Box>
        </Toolbar>
      </ActionBottombarRoot>
    </ThemeProvider>
  );
};

ActionBottombar.propTypes = {
  trigger: PropTypes.bool,
  children: PropTypes.node
};

ActionBottombar.defaultProps = {
  trigger: false
};

export default ActionBottombar;
