import { FC } from 'react';
import PropTypes from 'prop-types';
import { Badge, Box, CircularProgress, IconButton, SvgIcon, Tooltip } from '@material-ui/core';

interface ActionButtonProps {
  title: string;
  icon: any;
  onClick: any;
  isLoading?: boolean;
  disabled?: boolean;
  buttonSize?: number;
  iconSize?: number;
  badge?: boolean;
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
}

const ActionButton: FC<ActionButtonProps> = (props) => {
  const { title, icon: Icon, onClick, isLoading, disabled, iconSize, buttonSize, badge, color, ...others } = props;
  const realButtonSize = buttonSize || 32;
  const realIconSize = iconSize || 24;
  const marginSize = (realButtonSize - realIconSize) / 2;
  if (!marginSize) console.log(marginSize);
  return (
    <Box
      {...others}
      // sx={{ zIndex: 10 }}
    >
      <Tooltip
        title={title}
      >
        <span>
          <IconButton
            style={{
              width: realButtonSize,
              height: realButtonSize,
              // padding: isLoading ? (marginSize + realIconSize * 0.15) : marginSize
            }}
            onClick={onClick}
            disabled={!!isLoading || !!disabled}
          >
            {!isLoading && badge && (
              <Badge
                color="error"
                variant="dot"
              >
                <SvgIcon
                  sx={{
                    width: realIconSize,
                    height: realIconSize
                  }}
                  color={color || 'primary'}
                >
                  <Icon />
                </SvgIcon>
              </Badge>
            )}
            {!isLoading && !badge && (
              <SvgIcon
                sx={{
                  width: realIconSize,
                  height: realIconSize
                }}
                color={color || 'primary'}
              >
                <Icon />
              </SvgIcon>
            )}
            {!!isLoading && (
              <CircularProgress
                color="primary"
                size="100%"
              />
            )}
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

ActionButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.any,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  buttonSize: PropTypes.number,
  iconSize: PropTypes.number,
  badge: PropTypes.bool,
  color: PropTypes.any,
};

export default ActionButton;
