import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, IconButton, Input, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid } from '@material-ui/core';
import useMounted from 'src/hooks/useMounted';
import { CheckCircle, Close } from '@material-ui/icons';
import { shortId } from 'src/types/microservice';
import CryptoWallet from 'src/icons/CryptoWallet';
import { useDispatch, useSelector } from 'src/store';
import { lambdaGetGasPrice, lambdaGetWalletById, lambdaSearchWallet, lambdaSendERC20Token, lambdaUpdateRecentClients, lambdaGetUserMinInfos } from 'src/aws/lambdaDispatch';
import { Wallet } from 'src/../../Common/Model/wallet';
import toast from 'react-hot-toast';
import { setTransactions, setWalletInfo } from 'src/slices/wallet';
import { ACCZIOM_CLIENT, ACCZIOM_ORG, tokenData } from 'src/globals';
import { UserMinInfo } from 'src/../../Common/Model/common';
import SmallUserInfo from 'src/components/SmallUserInfo';
import { updateUserMinInfos } from 'src/slices/organization';
import CheckLabel from 'src/components/CheckLabel';
import { getActiveInfo, getUserMinInfoByID } from 'src/utils/getActiveOrgInfo';
import { getRealInviteeId, getRealInviteeInfo } from '../dialog/InviteDialog';
import NumberField from 'src/components/interact/NumberField';
import BottomPopupDialog from '../dialog/BottomPopupDialog';

interface SendDlgProps {
  open: boolean;
  onClose: () => void;
  curToken: string;
}

export interface UserWallet {
  userMinInfo: UserMinInfo;
  wallet: Wallet | null;
}

const SendDlg: FC<SendDlgProps> = (props) => {
  const { open, onClose, curToken } = props;
  const mounted = useMounted();
  // const navigate = useNavigate();
  const [to, setTo] = useState<string | UserWallet>('');
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [amount, setAmount] = useState<number>(0);
  const { wallet } = useSelector((state) => state.wallet);
  const [isAvailable, setAvailable] = useState(false);
  const [results, setResults] = useState<UserWallet[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [isSendTotal, setSendTotal] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [isChecking, setChecking] = useState(false);
  const [isEstimateGas, setEstimateGas] = useState(false);
  const [isEstimating, setEstimating] = useState(false);
  const [estimatedFee, setEstimatedFee] = useState(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { userMinInfos } = useSelector((state) => state.organization);
  const { transactions } = useSelector((state) => state.wallet);
  const activeInfo = getActiveInfo(ACCZIOM_CLIENT, ACCZIOM_ORG);
  const dispatch = useDispatch();

  const estimate = async () => {
    setEstimating(true);
    try {
      const re = await lambdaGetGasPrice(wallet.walletID.id, curToken);
      let nonEthBal = 0;
      if (curToken !== 'Ether') nonEthBal = (await lambdaGetWalletById(activeInfo, curToken)).balance;
      if (!mounted.current) return null;
      setEstimating(false);
      if (re) {
        setEstimatedFee(re.estimatedFee);
        if (isSendTotal) {
          if (re.balance > re.estimatedFee) {
            if (curToken === 'Ether') {
              setAmount(re.balance - re.estimatedFee);
            } else {
              setAmount(nonEthBal);
            }
          } else {
            toast.error('Your ethereum balance is not enough to send.');
            setAmount(0);
          }
        }
        return re;
      }
      setEstimatedFee(0);
    } catch (err) {
      console.log(JSON.stringify(err));
    }
    return null;
  };

  const updateRecentClients = async () => {
    if (typeof to === 'string') return;
    let clients = [...wallet.recentClients];
    if (clients.findIndex((client) => client.id === getRealInviteeId(to.userMinInfo)) > -1) {
      // eslint-disable-next-line no-nested-ternary, no-confusing-arrow
      clients.sort((x, y) => (x.id === getRealInviteeId(to.userMinInfo) ? -1 : (y.id === getRealInviteeId(to.userMinInfo) ? 1 : 0)));
    } else {
      clients = [getRealInviteeInfo(to.userMinInfo)].concat(clients);
      if (clients.length > 8) {
        clients.splice(8);
      }
    }
    try {
      const res = await lambdaUpdateRecentClients(wallet.walletID.id, clients);
      if (res) {
        const w = {
          ...wallet,
          recentClients: clients
        };
        dispatch(setWalletInfo(w));
        dispatch(updateUserMinInfos([to.userMinInfo]));
      }
    } catch (err) {
      console.log(JSON.stringify(err));
    }
  };

  const handleToggleSendTotal = async () => {
    setSendTotal(!isSendTotal);
    if (!isSendTotal) {
      setEstimating(true);
      try {
        const re = await lambdaGetGasPrice(wallet.walletID.id, curToken);
        let nonEthBal = 0;
        if (curToken !== 'Ether') nonEthBal = (await lambdaGetWalletById(wallet.walletID, curToken)).balance;
        if (!mounted.current) return;
        setEstimating(false);
        if (re) {
          setEstimatedFee(re.estimatedFee);
          if (re.balance > re.estimatedFee) {
            if (curToken === 'Ether') {
              setAmount(re.balance - re.estimatedFee);
            } else {
              setAmount(nonEthBal);
            }
          } else {
            toast.error('Your ethereum balance is not enough to send.');
            setAmount(0);
          }
        } else {
          setEstimatedFee(0);
        }
        document.getElementById('Amount')?.focus();
      } catch (err) {
        console.log(JSON.stringify(err));
      }
    } else {
      setAmount(0);
      document.getElementById('Amount')?.focus();
    }
  };

  const handleNext = async () => {
    if (amount <= 0) {
      toast.error('The amount should be larger than 0.');
      document.getElementById('Amount')?.focus();
      return;
    }
    setEstimateGas(true);
  };

  const handleReject = async () => {
    setEstimateGas(false);
  };

  const handleDone = async () => {
    setSubmitting(true);
    try {
      const res = await lambdaSendERC20Token(activeInfo, typeof to === 'string' ? to : getRealInviteeInfo(to.userMinInfo), amount, curToken);
      if (res && res.result === 'success') {
        dispatch(setTransactions([res.transaction, ...transactions]));
        if (typeof to !== 'string') dispatch(updateUserMinInfos([to.userMinInfo]));
        await updateRecentClients();
        toast.success('The transaction has been successfully queued.');
      } else {
        toast.error(res.errorMessage);
      }
    } catch (error) {
      console.error(JSON.stringify(error));
      toast.success('Queueing has been failed!');
    } finally {
      if (mounted.current) setSubmitting(false);
    }
    onClose();
  };

  const handleChangeAmount = (e) => {
    if (e.target.value === '') setAmount(null);
    else setAmount(Number(e.target.value));
    setSendTotal(false);
  };

  const checkTo = async (userMinInfo: UserMinInfo) => {
    setAvailable(false);
    setChecking(true);
    try {
      const res = await lambdaGetWalletById({ id: userMinInfo.uid, type: userMinInfo.type }, curToken);
      if (mounted.current) {
        if (res) {
          // if (typeof to !== 'string') {
          setTo({
            userMinInfo,
            wallet: res
          });
          // }
          setAvailable(true);
        } else {
          setAvailable(false);
          setTo('');
          toast.error(`Selected ${userMinInfo.type === 1 ? 'user' : 'organization'} doesn't have a wallet.`);
        }
      }
    } catch (err) {
      console.log(JSON.stringify(err));
    } finally {
      if (mounted.current) setChecking(false);
    }
  };

  const searchTo = async (value: string) => {
    setAvailable(false);
    if (!value || value.length === 0) {
      setShowResults(false);
      setResults([]);
      return;
    }
    if (value === '0') return;
    if (value === '0x') return;
    if (value.substring(0, 2) === '0x') {
      setShowResults(false);
      if (value.length !== 42) {
        setResults([]);
        setAvailable(false);
        return;
      }
      setAvailable(true);
      setResults([]);
      return;
    }
    setShowResults(true);
    setSearching(true);
    try {
      const res = await lambdaSearchWallet(value);
      if (mounted.current) {
        if (res && Array.isArray(res)) {
          const receivers = res.filter((item) => item.userMinInfo.uid !== activeInfo.id);
          receivers.sort((r0, r1) => {
            if (r0.wallet && !r1.wallat) return -1;
            if (!r0.wallet && r1.wallet) return 1;
            return 0;
          });
          setResults(receivers);
        } else {
          setResults([]);
        }
      }
    } catch (err) {
      console.log(JSON.stringify(err));
    } finally {
      if (mounted.current) setSearching(false);
    }
  };

  const handleCancelText = () => {
    setTo('');
    setResults([]);
    setAvailable(false);
    setShowCancelButton(false);
    setShowResults(false);
    setSendTotal(false);
    setAmount(0);
    setEstimateGas(false);
  };

  const handleChangeTo = (e) => {
    setTo(e.target.value);
    if (e.target.value.length > 0) {
      setShowCancelButton(true);
    }
    searchTo(e.target.value);
  };

  const handleChooseResult = (userWallet: UserWallet) => {
    if (!userWallet.wallet) {
      toast.error(`Selected ${userWallet.userMinInfo.type === 1 ? 'user' : 'organization'} doesn't have a wallet.`);
      return;
    }
    setTo(userWallet);
    setShowCancelButton(true);
    setAvailable(true);
  };

  const handleChooseRecent = (cliInfo) => {
    const defaultInfo = getUserMinInfoByID(cliInfo);
    const userMinInfo = { ...defaultInfo, allIds: [{ id: defaultInfo.uid, type: defaultInfo.type }] } as UserMinInfo;
    setTo({
      userMinInfo,
      wallet: null
    });
    setShowCancelButton(true);
    checkTo(userMinInfo);
  };

  useEffect(() => {
    if (open) {
      if (wallet.recentClients.length > 0) {
        const notLoadedInfos = wallet.recentClients.filter((info) => !userMinInfos.some((miniUser) => miniUser.allIds.map(({ id }) => id).includes(info.id)));
        if (notLoadedInfos.length > 0) {
          setLoading(true);
          lambdaGetUserMinInfos(notLoadedInfos)
            .then((info) => {
              dispatch(updateUserMinInfos(info));
            })
            .catch((err) => {
              console.log(JSON.stringify(err));
              toast.error('Recent client information loading failed!');
            })
            .finally(() => {
              if (mounted.current) setLoading(false);
            });
        }
      }
      setTo('');
      setResults([]);
      setAvailable(false);
      setShowCancelButton(false);
      setShowResults(false);
      setSendTotal(false);
      setEstimateGas(false);
      setAmount(0);
    }
  }, [open]);

  useEffect(() => {
    if (isAvailable) {
      document.getElementById('Amount')?.focus();
    }
  }, [isAvailable]);

  useEffect(() => {
    let interval;
    if (isEstimateGas) {
      estimate();
      const poll = async () => {
        await estimate();
      };
      interval = setInterval(poll, 10000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isEstimateGas]);

  return (
    <BottomPopupDialog
      open={open}
      onClose={onClose}
      title="Send To"
    >
      <Box
        sx={{
          mx: 2,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'background.default',
            borderRadius: 22,
            display: 'flex',
            height: 44,
            px: 2,
          }}
        >
          {isAvailable ? (
            <CheckCircle
              sx={{
                color: (theme) => theme.palette.success.main
              }}
              fontSize="small"
            />
          ) : (
            <CryptoWallet
              color="action"
              fontSize="small"
            />
          )}
          <Box
            sx={{
              flexGrow: 1,
              pl: 1,
            }}
          >
            {typeof to === 'string' && (
              <Input
                fullWidth
                disableUnderline
                onChange={handleChangeTo}
                placeholder="public address(0x) or your partner's name or email"
                value={to}
              />
            )}
            {typeof to !== 'string' && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <SmallUserInfo userInfo={to.userMinInfo} />
              </Box>
            )}
          </Box>
          {showCancelButton && (
            <IconButton
              onClick={handleCancelText}
              sx={{
                p: 0
              }}
            >
              <Close
                color="action"
                fontSize="medium"
              />
            </IconButton>
          )}
        </Box>
        <Box
          sx={{
            height: 450,
            mt: 2,
          }}
        >
          {isAvailable ? (
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
            >
              {!isEstimateGas ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  height="100%"
                >
                  <NumberField
                    fullWidth
                    id="Amount"
                    label="Amount"
                    margin="normal"
                    name="Amount"
                    onBlur={handleChangeAmount}
                    onChange={handleChangeAmount}
                    onFocus={(e) => e.target.select()}
                    // type="number"
                    value={amount}
                    variant="outlined"
                    error={amount === null}
                  />
                  <CheckLabel
                    checked={isSendTotal}
                    onChange={() => handleToggleSendTotal()}
                    label="Send total balance"
                    loading={isEstimating}
                    {...{
                      sx: {
                        ml: 2
                      }
                    }}
                  />
                  <Box
                    flexGrow={1}
                  />
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  height="100%"
                >
                  <Table
                    sx={{
                      '& th': {
                        py: 1.5,
                        px: 1,
                        fontSize: '16px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        bgcolor: 'primary.main',
                        color: 'white',
                        fontWeight: 'medium'
                      },
                      '& td': {
                        padding: 1.5
                      }
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {`Sending ${tokenData.find((token) => token.name === curToken).unit}`}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          Amount to send
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          {amount}
                          {` ${tokenData.find((token) => token.name === curToken).unit}`}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Estimated gas fee
                        </TableCell>
                        <TableCell align="right">
                          {isEstimating && (
                            <CircularProgress
                              color="primary"
                              size={14}
                              sx={{
                                mr: '4px'
                              }}
                            />
                          )}
                          {estimatedFee}
                          {' ETH'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Total
                        </TableCell>
                        <TableCell align="right">
                          {isEstimating && (
                            <CircularProgress
                              color="primary"
                              size={14}
                              sx={{
                                mr: '4px'
                              }}
                            />
                          )}
                          {curToken === 'Ether' ? `${amount + estimatedFee} ETH` : `${amount} ${tokenData.find((token) => token.name === curToken).unit} + ${estimatedFee} ETH`}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              )}
              <Box
                display="flex"
                justifyContent="center"
                my={2}
              >
                {!isEstimateGas ? (
                  <Button
                    color="primary"
                    size="medium"
                    sx={{
                      width: '120px'
                    }}
                    type="button"
                    variant="text"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    size="medium"
                    sx={{
                      width: '120px'
                    }}
                    type="button"
                    variant="text"
                    onClick={handleReject}
                  >
                    Reject
                  </Button>
                )}
                {!isEstimateGas ? (
                  <Button
                    color="primary"
                    size="medium"
                    sx={{
                      ml: 2,
                      width: '120px'
                    }}
                    type="button"
                    onClick={handleNext}
                    disabled={isSubmitting || amount === null}
                    startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
                    variant="contained"
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    size="medium"
                    sx={{
                      ml: 2,
                      width: '120px'
                    }}
                    type="button"
                    onClick={handleDone}
                    disabled={isSubmitting}
                    variant="contained"
                    startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
                  >
                    Done
                  </Button>
                )}
              </Box>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  mx: 2
                }}
              >
                <Table
                  sx={{
                    '& th': {
                      py: 1.5,
                      px: 1,
                      fontSize: '16px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      bgcolor: 'primary.main',
                      color: 'white',
                      fontWeight: 'medium'
                    }
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          py: 1
                        }}
                      >
                        {showResults ? 'Search results' : 'Recents'}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {showResults ? (
                    <TableBody>
                      {!isSearching && results.length > 0 && results.map((result) => (
                        <TableRow
                          hover
                          key={shortId()}
                          onClick={() => handleChooseResult(result)}
                        >
                          <TableCell
                            sx={{
                              py: 1
                            }}
                          >
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <SmallUserInfo userInfo={result.userMinInfo} />
                              </Box>
                              {!result.wallet && (
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: 'red'
                                  }}
                                >
                                  no wallet
                                </Typography>
                              )}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                      {!isSearching && results.length === 0 && (
                        <TableRow>
                          <TableCell
                            sx={{
                              py: 1,
                              border: 0
                            }}
                          >
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              sx={{
                                mt: 1,
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              No Clients found
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                      {isSearching && (
                        <TableRow>
                          <TableCell
                            sx={{
                              py: 1,
                              border: 0
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 1,
                                minWidth: '100%',
                              }}
                            >
                              <CircularProgress
                                color="primary"
                                size={20}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      {isLoading && (
                        <TableRow>
                          <TableCell>
                            <CircularProgress
                              sx={{
                                ml: 2
                              }}
                              color="primary"
                              size={14}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                      {!isLoading && wallet.recentClients?.map((recent) => (
                        <TableRow
                          hover
                          key={shortId()}
                          onClick={() => handleChooseRecent(recent)}
                        >
                          {(typeof to !== 'string' && recent.id === to.userMinInfo.uid) ? (
                            <TableCell>
                              <Grid
                                container
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <SmallUserInfo userInfo={getUserMinInfoByID(recent)} />
                                </Box>
                                {isChecking && (
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      color="textSecondary"
                                      ml={4}
                                      align="right"
                                    >
                                      Checking...
                                    </Typography>
                                    <CircularProgress
                                      sx={{
                                        ml: 2
                                      }}
                                      color="primary"
                                      size={14}
                                    />
                                  </Box>
                                )}
                              </Grid>
                            </TableCell>
                          ) : (
                            <TableCell>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <SmallUserInfo userInfo={getUserMinInfoByID(recent)} />
                              </Box>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </BottomPopupDialog>
  );
};

SendDlg.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  curToken: PropTypes.string.isRequired
};
export default SendDlg;
