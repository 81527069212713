import type { Address, Contact, EmailAddress, Phone } from 'src/../../Common/Model/contact';
import { getUserFormalName } from './getUserDisplayName';

export const applyFilters = (
  contacts: Contact[],
  query: string
): Contact[] => contacts.filter((contact) => {
  let matches = true;

  if (query) {
    const properties = ['displayName', 'legalName', 'tradingName', 'casualName', 'firstName', 'lastName', 'middleName', 'title'];
    let containsQuery = false;
    const formalName = getUserFormalName(contact);

    properties.forEach((property) => {
      if (contact[property]?.toLowerCase().includes(query.toLowerCase())) {
        containsQuery = true;
      }
      if (formalName.toLowerCase().includes(query.toLowerCase())) containsQuery = true;
    });

    if (!containsQuery) {
      matches = false;
    }
  }

  return matches;
});

export const getContactAddress = (contact: Contact, addressType?: 'PHYSICAL' | 'POBOX' | string) => {
  if (addressType && contact.addresses && contact.addresses.find((_) => _.addressType === addressType)) {
    return contact.addresses.find((_) => _.addressType = addressType);
  }
  if (contact.addresses && contact.addresses[0]) {
    return [
      contact.addresses[0].addressLine1,
      contact.addresses[0].addressLine2,
      contact.addresses[0].city,
      contact.addresses[0].region,
      contact.addresses[0].postalCode,
      contact.addresses[0].country,
    ].filter((_) => !!_).map((_) => _.toString().trim()).join(', ');
  }
  return '';
};

export const getSimpleContactAddress = (contact: Contact) => {
  if (contact.addresses && contact.addresses[0]) {
    return [
      contact.addresses[0].city,
      contact.addresses[0].country
    ].filter((_) => !!_).map((_) => _.trim()).join(', ');
  }
  return '';
};

export const getEmailAddress = (contact: Contact): string => {
  if (contact.emails && contact.emails[0]) {
    return contact.emails[0].emailAddress?.toLowerCase();
  }

  return '';
};

export const getAnyPhoneNumber = (contact: Contact): string => {
  if (contact.phoneNumbers && contact.phoneNumbers[0]) {
    return contact.phoneNumbers[0].e164Format;
  }

  return '';
};

export const getPrimaryAddress = (addresses): Address => addresses.find((data) => data.addressType === 'PHYSICAL');

export const getPhoneFromContact = (phoneNumbers, reason): string => {
  const filteredPhoneNumbers = [];
  phoneNumbers.forEach((data) => {
    if (reason === 'DEFAULT' || reason === 'MOBILE' || reason === 'FAX' || reason === 'DDI') {
      if (data.reason === reason) filteredPhoneNumbers.push(data.e164Format);
    } else if (data.reason !== 'DEFAULT' && data.reason !== 'MOBILE' && data.reason !== 'FAX' && data.reason !== 'DDI') {
      filteredPhoneNumbers.push(data.e164Format);
    }
  });
  return filteredPhoneNumbers.join(';');
};

export const getEmailFromContact = (emails, reason): string => {
  const filteredEmails = [];
  emails.forEach((data) => {
    if (reason === 'DEFAULT' || reason === 'ALTERNATIVE') {
      if (data.reason === reason) filteredEmails.push(data.emailAddress);
    } else if (data.reason !== 'DEFAULT' && data.reason !== 'ALTERNATIVE') {
      filteredEmails.push(data.emailAddress);
    }
  });
  return filteredEmails.join(';');
};

const contactWeights = {
  emails: 20,
  phoneNumbers: 11,
  addresses: 10,
  displayName: 100,
  firstName: 11,
  lastName: 11,
  middleName: 5,
  title: 8,
  note: 8,
  primaryEmailAddress: 15,
  primaryPhoneNumber: 15,
  taxNumber: 100,
  abn: 100,
  acn: 100,
};

const compareEmails = (a: EmailAddress[], b: EmailAddress[]): boolean => {
  if (!a || !b) return false;

  for (let i = 0; i < a.length; i++) {
    for (let j = 0; j < b.length; j++) {
      if (a[i]?.emailAddress?.length > 0 && a[i]?.emailAddress === b[i]?.emailAddress) return true;
    }
  }

  return false;
};

const comparePhoneNumbers = (a: Phone[], b: Phone[]): boolean => {
  if (!a || !b) return false;

  for (let i = 0; i < a.length; i++) {
    for (let j = 0; j < b.length; j++) {
      if (a[i]?.e164Format?.length > 0 && a[i]?.e164Format === b[i]?.e164Format) return true;
      if (a[i]?.freeForm?.length > 0 && a[i]?.freeForm === b[i]?.freeForm) return true;
    }
  }

  return false;
};

const compareAddress = (a: Address, b: Address): boolean => {
  if (!a || !b) return false;
  if (a.country && a.country !== b.country) return false;
  if (a.city && a.city !== b.city) return false;
  if (a.region && a.region !== b.region) return false;
  if (a.postalCode && a.postalCode !== b.postalCode) return false;
  if (a.addressLine1 && a.addressLine1 !== b.addressLine1) return false;

  return true;
};

const compareAddresses = (a: Address[], b: Address[]): boolean => {
  if (!a || !b) return false;

  for (let i = 0; i < a.length; i++) {
    for (let j = 0; j < b.length; j++) {
      if (compareAddress(a[i], b[i])) return true;
    }
  }

  return false;
};

export const compareContact = (a: Contact, b: Contact): number => {
  let score = 0;

  if (a.abn && a.abn === b.abn) score += contactWeights.abn;
  if (a.acn && a.acn === b.acn) score += contactWeights.acn;
  if (a.taxNumber && a.taxNumber === b.taxNumber) score += contactWeights.taxNumber;
  if (a.note && a.note?.toLowerCase() === b.note?.toLowerCase()) score += contactWeights.note;
  if (a.title && a.title?.toLowerCase() === b.title?.toLowerCase()) score += contactWeights.title;
  if (a.middleName && a.middleName?.toLowerCase() === b.middleName?.toLowerCase()) score += contactWeights.middleName;
  if (a.lastName && a.lastName?.toLowerCase() === b.lastName?.toLowerCase()) score += contactWeights.lastName;
  if (a.firstName && a.firstName?.toLowerCase() === b.firstName?.toLowerCase()) score += contactWeights.firstName;
  if (a.displayName && a.displayName?.toLowerCase() === b.displayName?.toLowerCase()) score += contactWeights.displayName;
  if (compareEmails(a.emails, b.emails)) score += contactWeights.primaryEmailAddress;
  if (comparePhoneNumbers(a.phoneNumbers, b.phoneNumbers)) score += contactWeights.primaryPhoneNumber;
  if (compareAddresses(a.addresses, b.addresses)) score += contactWeights.addresses;

  return score;
};

export const getSimilarContacts = (item: Contact, itemList: Contact[]) => {
  let maxScore = 29;
  let maxItem = null;
  itemList.forEach((comp) => {
    const curScore = compareContact(item, comp);
    if (curScore > maxScore) {
      maxScore = curScore;
      maxItem = comp;
    }
  });
  return maxItem;
};
