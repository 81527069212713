import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Share } from 'src/../../Common/Model/share';

interface ShareState {
  shares: Share[];
}

const initialState: ShareState = {
  shares: []
};

const slice = createSlice({
  name: 'share',
  initialState,
  reducers: {
    clearState(state: ShareState) {
      state.shares = [];
    },
    loadShares(state: ShareState, action: PayloadAction<{ shares: Share[]; }>) {
      const { shares } = action.payload;
      shares.forEach((share) => {
        if (!state.shares.some((old) => old.id === share.id)) {
          state.shares.push(share);
        }
      });
    },
    deleteShares(state: ShareState, action: PayloadAction<{ ids: string[]; }>) {
      const { ids } = action.payload;
      state.shares = state.shares.filter((share) => !ids.includes(share.id));
    },
    updateShares(state: ShareState, action: PayloadAction<{ shares: Share[]; removedIds: string[]; }>) {
      const { shares, removedIds } = action.payload;
      const newShares = state.shares.filter((share) => !removedIds.includes(share.id));
      shares.forEach((share) => {
        if (!newShares.some((old) => old.id === share.id)) {
          newShares.push(share);
        }
      });
      state.shares = newShares;
    },
    updateShare(state: ShareState, action: PayloadAction<{ id: string, body: any; }>) {
      const { id, body } = action.payload;
      state.shares.forEach((share) => {
        if (share.id === id) {
          Object.keys(body).forEach((key) => {
            share[key] = body[key];
          });
        }
      });
    }
  }
});

export const { reducer } = slice;

export const clearShareState = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearState());
};

export const loadShares = (shares: Share[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.loadShares({ shares }));
};

export const deleteShares = (ids: string[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.deleteShares({ ids }));
};

export const updateShares = (shares: Share[], removedIds: string[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateShares({ shares, removedIds }));
};

export const updateShare = (id: string, body: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateShare({ id, body }));
};

export default slice;
