import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import DashboardLayout from 'src/components/dashboard/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/layout/AuthGuard';
import GuestGuard from 'src/components/layout/GuestGuard';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const OrgCreatePad = Loadable(lazy(() => import('src/components/Configuration/Organization/OrgCreatePad')));
const Diagram = Loadable(lazy(() => import('src/pages/Diagram')));
const OrgSearch = Loadable(lazy(() => import('src/components/ClientDashboard/OrgSearch')));
const MicroserviceSearch = Loadable(lazy(() => import('src/components/ClientDashboard/MicroserviceSearch')));
const AssetSearch = Loadable(lazy(() => import('src/components/ClientDashboard/AssetSearch')));
const DirectOffer = Loadable(lazy(() => import('src/pages/dashboard/directoffer')));
const Dashboard = Loadable(lazy(() => import('src/pages/dashboard')));

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));

const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

const Fillup = Loadable(lazy(() => import('./pages/dashboard/Fillup')));

const MyFiles = Loadable(lazy(() => import('src/pages/dashboard/myfiles')));

const Configuration = Loadable(lazy(() => import('src/pages/dashboard/configuration')));
const Information = Loadable(lazy(() => import('src/pages/dashboard/configuration/information')));
// const Qboxero = Loadable(lazy(() => import('src/pages/dashboard/configuration/qboxero')));
// const Payment = Loadable(lazy(() => import('src/pages/dashboard/configuration/payment')));
const Security = Loadable(lazy(() => import('src/pages/dashboard/configuration/Security')));
const ExternalPlatforms = Loadable(lazy(() => import('src/components/Configuration/ExternalPlatforms')));
const Profile = Loadable(lazy(() => import('src/pages/dashboard/configuration/profile')));
const IDVerification = Loadable(lazy(() => import('src/pages/dashboard/configuration/IDVerification')));
const EditOrg = Loadable(lazy(() => import('src/pages/dashboard/configuration/editorg')));
const Notification = Loadable(lazy(() => import('src/pages/dashboard/configuration/notification')));
const Organization = Loadable(lazy(() => import('src/pages/dashboard/configuration/organization')));
const ChatThreadList = Loadable(lazy(() => import('./pages/dashboard/chat/ChatThreadList')));
const ChatThread = Loadable(lazy(() => import('./pages/dashboard/chat/ChatThread')));

const NetworkView = Loadable(lazy(() => import('src/pages/dashboard/network')));
const ReferralDetail = Loadable(lazy(() => import('src/pages/dashboard/network/ReferralDetail')));
const SelectReferralView = Loadable(lazy(() => import('src/pages/referral/PartnerReferral')));

const ServicePad = Loadable(lazy(() => import('src/pages/dashboard/microservice/list')));
const SingleServiceView = Loadable(lazy(() => import('src/pages/dashboard/microservice/SingleServiceView')));
const BundleServiceView = Loadable(lazy(() => import('src/pages/dashboard/microservice/BundleServiceView')));
const SingleServiceEdit = Loadable(lazy(() => import('src/pages/dashboard/microservice/SingleServiceEdit')));
const BundleServiceEdit = Loadable(lazy(() => import('src/pages/dashboard/microservice/BundleServiceEdit')));
const BundleServiceOffer = Loadable(lazy(() => import('src/pages/dashboard/request/BundleServiceOffer')));
const SingleServiceOffer = Loadable(lazy(() => import('src/pages/dashboard/request/SingleServiceOffer')));

const AssetBoard = Loadable(lazy(() => import('src/pages/dashboard/asset')));
const AssetEditView = Loadable(lazy(() => import('src/pages/dashboard/asset/EditAsset')));
const AssetDetailView = Loadable(lazy(() => import('src/pages/dashboard/asset/AssetDetail')));

const Ticket = Loadable(lazy(() => import('src/pages/dashboard/ticket/list')));
const TicketDetail = Loadable(lazy(() => import('src/pages/dashboard/ticket/detail')));

const ServiceRequest = Loadable(lazy(() => import('src/components/request/ServiceRequest')));

const AssetRequest = Loadable(lazy(() => import('src/pages/dashboard/asset/RequestAsset')));

const BusinessSearch = Loadable(lazy(() => import('src/pages/dashboard/bsearch')));
const Templates = Loadable(lazy(() => import('src/pages/dashboard/templates')));
const TemplateEdit = Loadable(lazy(() => import('src/pages/dashboard/templates/edit')));

const StructureBoard = Loadable(lazy(() => import('src/pages/dashboard/structure/UI')));
const Todo = Loadable(lazy(() => import('src/pages/dashboard/todo')));

const Wallet = Loadable(lazy(() => import('src/pages/dashboard/configuration/wallet')));

const AcceptInvite = Loadable(lazy(() => import('./pages/AcceptInvite')));

const NotFound = Loadable(lazy(() => import('src/pages/NotFound')));
const AboutUs = Loadable(lazy(() => import('src/pages/dashboard/AboutUsWrap')));

const AssignPad = Loadable(lazy(() => import('src/pages/dashboard/assign')));
const PayOrgFee = Loadable(lazy(() => import('src/components/PayOrgFee')));
// const AcceptInvitation = Loadable(lazy(() => import('src/components/Fillup/individual/AcceptInvitationForm')));
// External Page to review the invited service
const ExternalClientView = Loadable(lazy(() => import('src/pages/clientView')));
const OrgUpgrade = Loadable(lazy(() => import('src/components/Configuration/OrgUpgrade')));

const routes: RouteObject[] = [
  // root
  {
    path: '/',
    children: [
      {
        path: '/temp',
        element: (
          <AuthGuard isFree>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '/temp',
            element: <OrgCreatePad />
          }
        ]
      },
      {
        path: '/',
        element: (
          <AuthGuard isFree>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '/',
            element: <Dashboard />
          },
          {
            path: '/orgsearch',
            element: <OrgSearch />
          },
          {
            path: '/servicesearch',
            element: <MicroserviceSearch />
          },
          {
            path: '/assetsearch',
            element: <AssetSearch />
          }
        ]
      },
      {
        path: '/login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: '/register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: '/register/:code',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      }
    ]
  },
  // home
  {
    path: '/home',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/home',
        element: <Dashboard />
      }
    ]
  },
  {
    path: '/analysis',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/analysis',
        element: <Diagram />
      }
    ]
  },
  // authentication
  {
    path: '/authentication',
    children: [
      {
        path: '/authentication/password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: '/authentication/password-reset',
        element: <PasswordReset />
      },
      {
        path: '/authentication/verify-code',
        element: <VerifyCode />
      }
    ]
  },
  // fillup
  {
    path: '/fillup',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/fillup',
        element: <Fillup />
      }
    ]
  },
  {
    path: '/payorgfee',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/payorgfee',
        element: <PayOrgFee />
      }
    ]
  },
  // configuration
  {
    path: '/configuration',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/configuration',
        element: <Configuration />
      },
      {
        path: '/configuration/information',
        element: <Information />
      },
      {
        path: '/configuration/upgrade',
        element: <OrgUpgrade />
      },
      {
        path: '/configuration/externalplatforms',
        element: <ExternalPlatforms />
      },
      {
        path: '/configuration/profile',
        element: <Profile />
      },
      {
        path: '/configuration/organization',
        element: <Organization />
      },
      {
        path: '/configuration/id-verification',
        element: <IDVerification />
      },
      {
        path: '/configuration/security',
        element: <Security />
      },
      {
        path: '/configuration/notification',
        element: <Notification />
      },
      {
        path: '/configuration/mfa',
        element: <Configuration />
      },
      {
        path: '/configuration/org/new',
        element: <EditOrg />
      },
      {
        path: '/configuration/org/:id',
        element: <EditOrg />
      }
    ]
  },
  // messaging
  {
    path: '/communication',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/communication/external',
        element: <ChatThreadList />
      },
      {
        path: '/communication/external/:threadKey',
        element: <ChatThread />
      },
      {
        path: '/communication/internal',
        element: <ChatThreadList />
      },
      {
        path: '/communication/internal/:threadKey',
        element: <ChatThread />
      }
    ]
  },
  // network
  {
    path: '/management',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/management/templates',
        element: <Templates />
      },
      {
        path: '/management/templates/:option',
        element: <Templates />
      },
      {
        path: '/management/templates/new',
        element: <TemplateEdit />
      },
      {
        path: '/management/templates/edit/:templateId',
        element: <TemplateEdit />
      },
      {
        path: '/management/network',
        element: <NetworkView />
      },
      {
        path: '/management/network/contact',
        element: <NetworkView />
      },
      {
        path: '/management/network/partner',
        element: <NetworkView />
      },
      {
        path: '/management/network/partner/:orgId',
        element: <SelectReferralView />
      },
      {
        path: '/management/network/referral',
        element: <NetworkView />
      },
      {
        path: '/management/network/referral/:referId',
        element: <ReferralDetail />
      },
      {
        path: '/management/microservice',
        element: <ServicePad />
      },
      {
        path: '/management/microservice/service/new',
        element: <SingleServiceEdit />
      },
      {
        path: '/management/microservice/service/edit/:serviceId',
        element: <SingleServiceEdit />
      },
      {
        path: '/management/microservice/service/detail/:serviceId',
        element: <SingleServiceView />
      },
      {
        path: '/management/microservice/service/offer/:serviceId',
        element: <SingleServiceOffer />
      },
      {
        path: '/management/microservice/service-bundle/new',
        element: <BundleServiceEdit />
      },
      {
        path: '/management/microservice/service-bundle/edit/:serviceId',
        element: <BundleServiceEdit />
      },
      {
        path: '/management/microservice/service-bundle/detail/:serviceId',
        element: <BundleServiceView />
      },
      {
        path: '/management/microservice/service-bundle/offer/:serviceId',
        element: <BundleServiceOffer />
      },
      {
        path: '/management/asset',
        element: <AssetBoard />
      },
      {
        path: '/management/asset/new',
        element: <AssetEditView />
      },
      {
        path: '/management/asset/edit/:assetId',
        element: <AssetEditView />
      },
      {
        path: '/management/asset/detail/:assetId',
        element: <AssetDetailView />
      },
      {
        path: '/management/structure',
        element: <StructureBoard />
      },
      {
        path: '/management/structure/client',
        element: <StructureBoard />
      },
      {
        path: '/management/structure/supplier',
        element: <StructureBoard />
      },
      {
        path: '/management/structure/member',
        element: <StructureBoard />
      },
      {
        path: '/management/structure/role',
        element: <StructureBoard />
      },
      {
        path: '/management/structure/team',
        element: <StructureBoard />
      },
      {
        path: '/management/file',
        element: <MyFiles />
      },
      {
        path: '/management/assign',
        element: <AssignPad />
      },
      {
        path: '/management/assign/deal',
        element: <AssignPad />
      },
      {
        path: '/management/assign/service',
        element: <AssignPad />
      },
      {
        path: '/management/assign/asset',
        element: <AssignPad />
      },
      {
        path: '/management/assign/file',
        element: <AssignPad />
      },
      {
        path: '/management/assign/referral',
        element: <AssignPad />
      },
      {
        path: '/management/assign/partner',
        element: <AssignPad />
      }
    ]
  },
  // tickets
  {
    path: '/deal',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/deal/purchase',
        element: <Ticket />
      },
      {
        path: '/deal/purchase/detail/:ticketId',
        element: <TicketDetail />
      },
      {
        path: '/deal/purchase/service-bundle/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/purchase/service/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/sale',
        element: <Ticket />
      },
      {
        path: '/deal/sale/detail/:requestId',
        element: <TicketDetail />
      },
      {
        path: '/deal/sale/service-bundle/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/sale/service/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/referral',
        element: <Ticket />
      },
      {
        path: '/deal/referral/detail/:requestId',
        element: <Ticket />
      },
      {
        path: '/deal/referral/service-bundle/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/referral/service/:ticketId',
        element: <Ticket />
      },
      {
        path: '/deal/autodebit',
        element: <Ticket />
      },
      {
        path: '/deal/autodebit/detail/:ticketId',
        element: <Ticket />
      }
    ]
  },
  // products
  {
    path: '/marketplace',
    element: (
      <AuthGuard isFree>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/marketplace/organization',
        element: <OrgSearch />
      },
      {
        path: '/marketplace/microservice',
        element: <MicroserviceSearch />
      },
      {
        path: '/marketplace/microservice/service/:serviceId',
        element: <ServiceRequest />
      },
      {
        path: '/marketplace/microservice/service-bundle/:serviceId',
        element: <ServiceRequest />
      },
      {
        path: '/marketplace/asset',
        element: <AssetSearch />
      },
      {
        path: '/marketplace/asset/:assetId',
        element: <AssetRequest />
      }
    ]
  },
  {
    path: '/proposal',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/proposal',
        element: <DirectOffer />
      },
      {
        path: '/proposal/new',
        element: <DirectOffer />
      },
      {
        path: '/proposal/:ticketId',
        element: <DirectOffer />
      }
    ]
  },
  // todo
  {
    path: '/todo',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/todo',
        element: <Todo />
      },
    ]
  },
  // bs
  {
    path: '/bs',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/bs',
        element: <BusinessSearch />
      },
      {
        path: '/bs/search',
        element: <BusinessSearch />
      },
      {
        path: '/bs/anzsicsuggestion',
        element: <BusinessSearch />
      },
      {
        path: '/bs/favourites',
        element: <BusinessSearch />
      }
    ]
  },
  {
    path: '/wallet',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/wallet',
        element: <Wallet />
      }
    ]
  },
  // error
  {
    path: 'error',
    element: <NotFound />
  },
  {
    path: '/acceptinvite',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/acceptinvite',
        element: <AcceptInvite />
      }
    ]
  },
  {
    path: '/client-view/:encryptedCode',
    element: <ExternalClientView />,
  },
  {
    path: '/about',
    element: (
      <AuthGuard isFree>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/about/:domain',
        element: <AboutUs />
      }
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard isFree>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [{
      path: '/*',
      element: <NotFound />
    }]
  },
];

export default routes;
