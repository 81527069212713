import { FC, useCallback, useEffect, useState } from 'react';
import { Box, Button, Divider, Skeleton, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useDispatch, useSelector } from 'src/store';
import RefreshIcon from '@mui/icons-material/Refresh';
import useMounted from 'src/hooks/useMounted';
import { lambdaGetWalletBalances } from 'src/aws/lambdaDispatch';
import { setBalances, setActiveTokenName, setMRCL0 } from 'src/slices/wallet';
import toast from 'react-hot-toast';
import { CRYPTO_ETHER, CRYPTO_TETHER, CRYPTO_MRC, FIAT_AUD, FIAT_USD, tokenData, fiatData, ACCZIOM_USER, ACCZIOM_ORG } from 'src/globals';
import ActionButton from 'src/components/interact/ActionButton';
import MERcTransferDlg from 'src/components/wallet/MERcTransferDlg';
import SendDlg from 'src/components/wallet/senddlg';
import BuyMERcDialog from 'src/components/wallet/BuyMERcDialog';
import SellMERcDialog from 'src/components/wallet/SellMERcDialog';
import MRCDepositDialog from 'src/components/wallet/MRCDepositDialog';
import MRCWithdrawDialog from 'src/components/wallet/MRCWithdrawDialog';
import PropTypes from 'prop-types';
import { getFormatedPrice } from 'src/utils/priceUtils';
import { getActiveId } from 'src/utils/getActiveOrgInfo';
import { ManageHistory } from '@mui/icons-material';

export const CryptoWalletIcon: FC<{
  type: string;
  size?: number;
}> = ({
  type,
  size
}) => {
  const info = tokenData.find((v) => v.name === type);
  return (
    <img
      src={info.icon}
      style={{
        borderRadius: '50%',
        width: `${!size ? 24 : size}px`,
        height: `${!size ? 24 : size}px`,
      }}
      alt={type}
    />
  );
};

CryptoWalletIcon.propTypes = {
  type: PropTypes.string,
  size: PropTypes.number
};

export const FiatIcon: FC<{
  type: string;
  size?: number;
}> = ({
  type,
  size
}) => {
  const info = fiatData.find((v) => v.name === type);
  return (
    <img
      src={info.icon}
      style={{
        borderRadius: '50%',
        width: `${!size ? 24 : size}px`,
        height: `${!size ? 24 : size}px`,
      }}
      alt={type}
    />
  );
};

FiatIcon.propTypes = {
  type: PropTypes.string,
  size: PropTypes.number
};

const DLG_NONE = 0;
const DLG_TRANSACTION = 1;
const DLG_BUY = 2;
const DLG_SELL = 3;
const DLG_DEPOSIT = 4;
const DLG_WITHDRAW = 5;

const WalletSideBar: FC<{ handleOpenHistory: any; }> = ({ handleOpenHistory }) => {
  const { balances, activeTokenName, isMRCL0 } = useSelector((state) => state.wallet);
  const { activeOrgId } = useSelector((state) => state.organization);
  const mounted = useMounted();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDlgOpened, setDlgOpened] = useState<number>(DLG_NONE);

  const loadBalance = useCallback(async () => {
    setLoading(true);
    try {
      const info = await lambdaGetWalletBalances(getActiveId(ACCZIOM_USER, ACCZIOM_ORG));
      dispatch(setActiveTokenName(CRYPTO_MRC));
      dispatch(setMRCL0(true));
      dispatch(setBalances(info));
    } catch (err) {
      console.log(JSON.stringify(err));
      toast.error('Failed to get wallet balance!');
    } finally {
      if (mounted.current) setLoading(false);
    }
  }, [mounted, activeOrgId]);

  useEffect(() => {
    loadBalance();
  }, [loadBalance]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        p: 2
      }}
    >
      <Table
        sx={{
          '& th': {
            py: 1.5,
            px: 1,
            fontSize: '16px',
            bgcolor: 'primary.main',
            color: 'white',
            fontWeight: 'medium'
          },
          '& td': {
            py: 1,
            px: 2,
            fontSize: '18px',
            fontWeight: '600'
          },
          '& .MuiTableRow-hover:hover': {
            backgroundColor: '#e3f2fd !important',
            cursor: 'pointer'
          },
          '& .MuiTableRow-hover.Mui-selected': {
            backgroundColor: '#addaf8 !important',
          }
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              Currency
            </TableCell>
            <TableCell>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  '& button *': {
                    color: 'white !important'
                  }
                }}
              >
                Balance
                <Box flexGrow={1} />
                <ActionButton
                  title="Refresh"
                  icon={RefreshIcon}
                  onClick={loadBalance}
                  buttonSize={26}
                  iconSize={22}
                  color="action"
                  isLoading={isLoading}
                />
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& *': {
              border: 'none !important'
            }
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.secondary',
                fontSize: '14px !important',
                paddingLeft: '4px !important'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <CurrencyBitcoinIcon
                  sx={{
                    width: '20px',
                    height: '20px'
                  }}
                />
                <Typography
                  noWrap
                  height="20px"
                >
                  Crypto
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow
            hover
            onClick={() => {
              dispatch(setActiveTokenName(CRYPTO_MRC));
              dispatch(setMRCL0(true));
            }}
            selected={activeTokenName === CRYPTO_MRC && isMRCL0}
          >
            <TableCell sx={{ color: '#327534' }}>
              <Box
                display="flex"
                alignItems="center"
              >
                <CryptoWalletIcon type={CRYPTO_MRC} />
                &nbsp;&nbsp;MRC - L1
              </Box>
            </TableCell>
            <TableCell sx={{ color: '#327534' }}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  '& button *': {
                    color: 'white !important'
                  }
                }}
              >
                <Box
                  flexGrow={1}
                >
                  {!isLoading && balances ? balances.mrc.layer1 : <Skeleton variant="text" />}
                </Box>
                <ActionButton
                  title="History"
                  icon={ManageHistory}
                  onClick={handleOpenHistory}
                  buttonSize={26}
                  iconSize={22}
                  color="action"
                  disabled={activeTokenName !== CRYPTO_MRC || !isMRCL0}
                />
              </Box>
            </TableCell>
          </TableRow>
          <TableRow
            hover
            onClick={() => {
              dispatch(setActiveTokenName(CRYPTO_MRC));
              dispatch(setMRCL0(false));
            }}
            selected={activeTokenName === CRYPTO_MRC && !isMRCL0}
          >
            <TableCell
              sx={{ color: '#327534' }}
            >
              <Box
                display="flex"
                alignItems="center"
              >
                <CryptoWalletIcon type={CRYPTO_MRC} />
                &nbsp;&nbsp;MRC - L2
              </Box>
            </TableCell>
            <TableCell sx={{ color: '#327534' }}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  '& button *': {
                    color: 'white !important'
                  }
                }}
              >
                <Box
                  flexGrow={1}
                >
                  {!isLoading && balances ? balances.mrc.layer2 : <Skeleton variant="text" />}
                </Box>
                <ActionButton
                  title="History"
                  icon={ManageHistory}
                  onClick={handleOpenHistory}
                  buttonSize={26}
                  iconSize={22}
                  color="action"
                  disabled={activeTokenName !== CRYPTO_MRC || isMRCL0}
                />
              </Box>
            </TableCell>
          </TableRow>
          <TableRow
            hover
            onClick={() => dispatch(setActiveTokenName(CRYPTO_ETHER))}
            selected={activeTokenName === CRYPTO_ETHER}
          >
            <TableCell sx={{ color: '#627EEA' }}>
              <Box
                display="flex"
                alignItems="center"
              >
                <CryptoWalletIcon type={CRYPTO_ETHER} />
                &nbsp;&nbsp;ETH
              </Box>
            </TableCell>
            <TableCell sx={{ color: '#627EEA' }}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  '& button *': {
                    color: 'white !important'
                  }
                }}
              >
                <Box
                  flexGrow={1}
                >
                  {!isLoading && balances ? getFormatedPrice(balances.eth, CRYPTO_ETHER, true) : <Skeleton variant="text" />}
                </Box>
                <ActionButton
                  title="History"
                  icon={ManageHistory}
                  onClick={handleOpenHistory}
                  buttonSize={26}
                  iconSize={22}
                  color="action"
                  disabled={activeTokenName !== CRYPTO_ETHER}
                />
              </Box>
            </TableCell>
          </TableRow>
          <TableRow
            hover
            onClick={() => dispatch(setActiveTokenName(CRYPTO_TETHER))}
            selected={activeTokenName === CRYPTO_TETHER}
          >
            <TableCell sx={{ color: '#4DAB90' }}>
              <Box
                display="flex"
                alignItems="center"
              >
                <CryptoWalletIcon type={CRYPTO_TETHER} />
                &nbsp;&nbsp;USDT
              </Box>
            </TableCell>
            <TableCell sx={{ color: '#4DAB90' }}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  '& button *': {
                    color: 'white !important'
                  }
                }}
              >
                <Box
                  flexGrow={1}
                >
                  {!isLoading && balances ? getFormatedPrice(balances.usdt, CRYPTO_TETHER, true) : <Skeleton variant="text" />}
                </Box>
                <ActionButton
                  title="History"
                  icon={ManageHistory}
                  onClick={handleOpenHistory}
                  buttonSize={26}
                  iconSize={22}
                  color="action"
                  disabled={activeTokenName !== CRYPTO_TETHER}
                />
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.secondary',
                fontSize: '14px !important',
                paddingLeft: '4px !important'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <AttachMoneyIcon
                  sx={{
                    width: '20px',
                    height: '20px'
                  }}
                />
                <Typography
                  noWrap
                  height="20px"
                >
                  Fiat
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow
            hover
            onClick={() => dispatch(setActiveTokenName(FIAT_AUD))}
            selected={activeTokenName === FIAT_AUD}
          >
            <TableCell sx={{ color: '#A349A2' }}>
              <Box
                display="flex"
                alignItems="center"
              >
                <FiatIcon type={FIAT_AUD} />
                &nbsp;&nbsp;AUD
              </Box>
            </TableCell>
            <TableCell sx={{ color: '#A349A2' }}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  '& button *': {
                    color: 'white !important'
                  }
                }}
              >
                <Box
                  flexGrow={1}
                />
                <ActionButton
                  title="History"
                  icon={ManageHistory}
                  onClick={handleOpenHistory}
                  buttonSize={26}
                  iconSize={22}
                  color="action"
                  disabled={activeTokenName !== FIAT_AUD}
                />
              </Box>
            </TableCell>
          </TableRow>
          <TableRow
            hover
            onClick={() => dispatch(setActiveTokenName(FIAT_USD))}
            selected={activeTokenName === FIAT_USD}
          >
            <TableCell sx={{ color: '#01a65b' }}>
              <Box
                display="flex"
                alignItems="center"
              >
                <FiatIcon type={FIAT_USD} />
                &nbsp;&nbsp;USD
              </Box>
            </TableCell>
            <TableCell sx={{ color: '#01a65b' }}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  '& button *': {
                    color: 'white !important'
                  }
                }}
              >
                <Box
                  flexGrow={1}
                />
                <ActionButton
                  title="History"
                  icon={ManageHistory}
                  onClick={handleOpenHistory}
                  buttonSize={26}
                  iconSize={22}
                  color="action"
                  disabled={activeTokenName !== FIAT_USD}
                />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Divider
        sx={{
          mt: 3,
          mb: 2
        }}
      />
      <Box
        sx={{
          width: '100%',
          px: 4,
          py: 2
        }}
      >
        {/* {(activeTokenName === FIAT_AUD || activeTokenName === FIAT_USD) && (
          <>
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                width: '100%',
                mb: 2
              }}
              onClick={() => {}}
            >
              Withdraw
            </Button>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                width: '100%'
              }}
              onClick={() => {}}
            >
              Deposit
            </Button>
          </>
        )} */}
        {[CRYPTO_MRC, CRYPTO_ETHER, CRYPTO_TETHER].includes(activeTokenName) && (
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              width: '100%'
            }}
            onClick={() => setDlgOpened(DLG_TRANSACTION)}
          >
            Transfer
          </Button>
        )}
        {activeTokenName === CRYPTO_MRC && (
          <>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                width: '100%',
                mt: 2
              }}
              onClick={() => setDlgOpened(DLG_BUY)}
            >
              Buy
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                width: '100%',
                mt: 2
              }}
              onClick={() => setDlgOpened(DLG_SELL)}
            >
              Sell
            </Button>
            {isMRCL0 ? (
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  width: '100%',
                  mt: 2
                }}
                onClick={() => setDlgOpened(DLG_DEPOSIT)}
              >
                Deposit
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '100%',
                  mt: 2
                }}
                onClick={() => setDlgOpened(DLG_WITHDRAW)}
                // onClick={withdraw}
              >
                Withdraw
              </Button>
            )}
          </>
        )}
      </Box>
      {activeTokenName !== CRYPTO_MRC && isDlgOpened === DLG_TRANSACTION && (
        <SendDlg
          open={isDlgOpened === DLG_TRANSACTION}
          onClose={() => setDlgOpened(DLG_NONE)}
          curToken={activeTokenName}
        />
      )}
      {activeTokenName === CRYPTO_MRC && isDlgOpened === DLG_TRANSACTION && (
        <MERcTransferDlg
          open={isDlgOpened === DLG_TRANSACTION}
          onClose={() => setDlgOpened(DLG_NONE)}
          layer2={!isMRCL0}
        />
      )}
      {activeTokenName === CRYPTO_MRC && isDlgOpened === DLG_BUY && (
        <BuyMERcDialog
          open={isDlgOpened === DLG_BUY}
          handleClose={() => setDlgOpened(DLG_NONE)}
        />
      )}
      {activeTokenName === CRYPTO_MRC && isDlgOpened === DLG_SELL && (
        <SellMERcDialog
          open={isDlgOpened === DLG_SELL}
          handleClose={() => setDlgOpened(DLG_NONE)}
        />
      )}
      {activeTokenName === CRYPTO_MRC && isDlgOpened === DLG_DEPOSIT && (
        <MRCDepositDialog
          open={isDlgOpened === DLG_DEPOSIT}
          handleClose={() => setDlgOpened(DLG_NONE)}
        />
      )}
      {activeTokenName === CRYPTO_MRC && isDlgOpened === DLG_WITHDRAW && (
        <MRCWithdrawDialog
          open={isDlgOpened === DLG_WITHDRAW}
          handleClose={() => setDlgOpened(DLG_NONE)}
        />
      )}
    </Box>
  );
};

export default WalletSideBar;
