import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, InputAdornment, Typography } from '@material-ui/core';
import useMounted from 'src/hooks/useMounted';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'src/store';
import { lambdaMercWithdraw } from 'src/aws/lambdaDispatch';
import GasLimit from './GasLimit';
import { getActiveInfo } from 'src/utils/getActiveOrgInfo';
import { setTransactions } from 'src/slices/wallet';
import { ACCZIOM_CLIENT, ACCZIOM_ORG } from 'src/globals';
import { v4 as uuidv4 } from 'uuid';
import NumberField from 'src/components/interact/NumberField';
import BottomPopupDialog from '../dialog/BottomPopupDialog';
import ActionBottombar from '../interact/ActionBottombar';

interface MRCTransactionProps {
  open: boolean;
  handleClose: () => void;
}

const MRCWithdrawDialog: FC<MRCTransactionProps> = (props) => {
  const { open, handleClose } = props;
  const mounted = useMounted();
  const dispatch = useDispatch();
  const [fee, setFee] = useState<number>(1);
  const [isSubmitting, setSubmitting] = useState(false);
  const minGas = 2;
  const [gasLimit, setGasLimit] = useState<number>(2);

  const amountError = fee === null || (gasLimit > 0 && fee <= gasLimit);
  const gasError = gasLimit > 0 && gasLimit < minGas;

  const { wallet, balances, isMRCL0, transactions } = useSelector((state) => state.wallet);
  const activeInfo = getActiveInfo(ACCZIOM_CLIENT, ACCZIOM_ORG);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const res = await lambdaMercWithdraw(activeInfo, fee, gasLimit);
      if (res && !res.errorMessage) {
        dispatch(setTransactions([{
          uid: uuidv4(),
          fromId: activeInfo,
          from: wallet.address,
          fee: isMRCL0 ? fee : -fee,
          event: 'withdraw',
          time: new Date().toISOString(),
          txn: {},
          status: 'Pending'
        }, ...transactions]));
        toast.success('The transaction has been successfully queued.');
        handleClose();
      } else {
        toast.error('Failed to withdraw!');
      }
    } catch (err) {
      toast.error('Failed to withdraw!');
    } finally {
      if (mounted.current) setSubmitting(false);
    }
  };

  return (
    <BottomPopupDialog
      open={open}
      onClose={handleClose}
      title="Withdraw"
    >
      <Box
        sx={{
          mx: 2,
          mb: 1
        }}
      >
        <Typography>
          Withdraw from Layer 2 to Layer 1.
          Please enter the amount to deposit and click Withdraw button.
          The transaction may take several minutes.
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{
            my: 5
          }}
        >
          <NumberField
            size="small"
            value={fee}
            // type="number"
            onNumChange={(val) => {
              if (val === null) {
                setFee(val);
                return;
              }
              if (val < 0) val = 0;
              if (balances && balances.mrc && val > balances.mrc.layer2) val = balances.mrc.layer2;
              setFee(val);
            }}
            label="Amount *"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    MERc
                  </Typography>
                </InputAdornment>
              )
            }}
            sx={{
              mb: 2
            }}
            error={amountError}
            helperText={amountError && 'Amount should be larger than gas limit.'}
          />
          <GasLimit
            gasLimit={gasLimit}
            onSetGasLimit={(v) => setGasLimit(v)}
            gasParams={{
              type: 'withdraw',
              address: wallet.address,
              amount: fee,
            }}
            minGas={2}
            estimateDisabled={fee === 0}
          />
        </Box>
        {isSubmitting && (
          <Typography
            sx={{
              textAlign: 'center'
            }}
          >
            Transaction is submitting. You can close this dialog.
          </Typography>
        )}
        <ActionBottombar>
          <Button
            color="primary"
            size="medium"
            sx={{
              width: '120px'
            }}
            type="button"
            variant="text"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="medium"
            sx={{
              ml: 2,
              width: '120px'
            }}
            type="button"
            onClick={handleSubmit}
            disabled={isSubmitting || fee === 0 || amountError || gasError}
            variant="contained"
            startIcon={isSubmitting ? <CircularProgress size={14} /> : null}
          >
            Withdraw
          </Button>
        </ActionBottombar>
      </Box>
    </BottomPopupDialog>
  );
};

MRCWithdrawDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};
export default MRCWithdrawDialog;
