import * as LAMBDA_FN from 'src/../../Backend/constants.json';
import { Microservice } from 'src/../../Common/Model/microservice';
import { Todo } from 'src/../../Common/Model/todo';
import { lambdaRun } from './lambdaCall';

export const lambdaPutUserInfo = (_uid: string, _body: any) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'PUT', uid: _uid, body: _body })
);

export const lambdaGetUserInfo = (_uid: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'GET', uid: _uid })
);

export const lambdaSearchUser = (_query: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'SEARCH', query: _query })
);

export const lambdaInitialLoad = (_uid: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'INITIAL_LOAD', uid: _uid })
);

export const lambdaGetUserInfoByEmail = (_email: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'GET_BY_EMAIL', email: _email })
);

export const lambdaGetUserInfoByMemberId = (mid: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'GET_BY_MEMBER_ID', mid })
);

export const lambdaSearchUserByField = (_query: string, _fieldName: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'SEARCH_BY_FIELD', query: _query, fieldName: _fieldName })
);

export const lambdaGetUsersById = (_uid: string[]) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'GET_BY_IDS', uid: _uid })
);

export const lambdaPutUserInfoForFillup = (_uid: string, _body: any, email: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'PUT_FOR_FILLUP', uid: _uid, body: _body, email })
);

export const lambdaPutAvatar = (_uid: string, image: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'PUT_AVATAR', uid: _uid, body: image })
);

export const lambdaGetLinkedIdentities = (email: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_USER_DISPATCH, { method: 'GET_LINKED_IDENTITIES', email })
);

export const lambdaGetTwilioToken = (_uid: string, notAdd?: boolean): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TWILIO_REQUEST_TOKEN, { method: 'GET_TOKEN', uid: _uid }, notAdd)
);

export const lambdaAddServiceParticipants = (_sid: string, _identities: any[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TWILIO_REQUEST_TOKEN, { method: 'ADD_SERVICE_PARTICIPANTS', sid: _sid, identities: _identities })
);

export const lambdaGetAttachmentFileUploadUrl = (uid: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_ATTACHMENT_DISPATCH, { method: 'GET_UP_URL', uid })
);

export const lambdaPutStuff = (_uid: string, _path: string, data: string): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_ATTACHMENT_DISPATCH, { method: 'PUT_STUFF', uid: _uid, path: _path, body: data })
);

export const lambdaGetAttachmentFileDownloadUrl = (_uid: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_ATTACHMENT_DISPATCH, { method: 'GET_URL', uid: _uid })
);

export const lambdaCreateNewContact = (contact: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTACT_DISPATCH, { method: 'CREATE', body: contact })
);

export const lambdaUpdateContact = (contact: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTACT_DISPATCH, { method: 'UPDATE', body: contact })
);

export const lambdaDeleteContact = (contactInfo: any): Promise<number> => (
  lambdaRun(LAMBDA_FN.FN_CONTACT_DISPATCH, { method: 'DELETE', body: contactInfo })
);

export const lambdaLoadAllContacts = (_uid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTACT_DISPATCH, { method: 'LOAD_ALL', uid: _uid })
);

export const lambdaLoadContactsWithPagination = (_uid: string, page: number, limit: number, query: string, isIndividual: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTACT_DISPATCH, { method: 'LOAD_WITH_PAGINATION', uid: _uid, page, limit, query, isIndividual })
);

export const lambdaSearchMicroservicesByQuery = (query: string, userId: string, itemTypes: number[], itemRange: number, idMode: boolean): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'SEARCH_BY_QUERY', query, userId, itemTypes, itemRange, idMode })
);

export const lambdaGetMicroservicesPublished = (ownerId: string, offset: number, oids: string[], query: string, categories: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'GET', ownerId, offset, oids, query, categories })
);

export const lambdaGetMicroservicesOfPartner = (creatorId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'GET_FROM_PARTNER', creatorId })
);

export const lambdaGetServicesOfBundle = (bundle: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'GET_SERVICES_OF_BUNDLE', bundle })
);

export const lambdaGetMicroservicesByCreator = (creatorId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'GET_BY_CREATOR', creatorId })
);

export const lambdaGetMicroservicesRelated = (ownerId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'GET_BY_RELATED', ownerId })
);

export const lambdaGetMicroserviceById = (id: string, orgId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'GET_BY_ID', id, orgId })
);

export const lambdaCreateMicroservice = (body: Microservice): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'CREATE', body })
);

export const lambdaCreateMultiServices = (services: Microservice[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'CREATE_MULTI', services })
);

export const lambdaRemoveShares = (ids: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'REMOVE_SHARE', ids })
);

export const lambdaMicroserviceToReady = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'TO_READY', id })
);

export const lambdaUpdateMicroservice = (body: Microservice): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'UPDATE', id: body.id, body })
);

export const lambdaPublishMicroservice = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'PUBLISH', id })
);

export const lambdaRemoveMicroservice = (id: string): Promise<number> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'REMOVE', id })
);

export const lambdaRemoveMicroserviceFromReq = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'REMOVE_FROM_REQ', id })
);

export const lambdaRegisterVerification = (_uid: string, json: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_VERIFICATION_DISPATCH, { method: 'REGISTER', uid: _uid, body: json })
);

export const lambdaVerificationSetField = (_uid: string, _sid: string, json: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_VERIFICATION_DISPATCH, { method: 'SET_FIELD', sid: _sid, uid: _uid, body: json })
);

export const lambdaGetMicroserviceForRequest = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'GET_MICROSERVICE_FOR_REQUEST', id })
);

export const lambdaShareMicroservices = (ids: { id: string; type: number; }[], recipients: { id: string; type: number; }[], removedIds: string[], from: { id: string; type: number; }, fromAgent: { id: string; type: number; }[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MICROSERVICE_DISPATCH, { method: 'SHARE', ids, recipients, removedIds, from, fromAgent })
);

export const lambdaContactSyncDisconnect = (_uid: string, _platform: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTACT_DISPATCH, { method: 'SYNC_DISCONNECT', uid: _uid, platform: _platform })
);

export const lambdaSyncLodgeit = (_uid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTACT_DISPATCH, { method: 'SYNC_LODGEIT', uid: _uid })
);

export const lambdaContactSyncDisable = (_uid: string, _platform: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTACT_DISPATCH, { method: 'SYNC_DISABLE', uid: _uid, platform: _platform })
);

export const lambdaContactSyncGetStatus = (_uid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTACT_DISPATCH, { method: 'SYNC_STATUS', uid: _uid })
);

export const lambdaContactConnectGetStatus = (_uid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTACT_DISPATCH, { method: 'CONNECT_STATUS', uid: _uid })
);

// ************************** Procurement - RFQ ************************************
export const lambdaGetRFQRelated = (userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_RFQ_DISPATCH, { method: 'GET_BY_RELATED', userId })
);

export const lambdaRemoveRFQ = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_RFQ_DISPATCH, { method: 'REMOVE', id })
);

export const lambdaUpdateRFQ = (id: string, rfq: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_RFQ_DISPATCH, { method: 'UPDATE', rfq, id })
);
export const lambdaCreateRFQ = (rfq: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_RFQ_DISPATCH, { method: 'CREATE', rfq })
);

// ************************** Procurement - RFQ ************************************
export const lambdaGetApprising = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_APPRISING_DISPATCH, { method: 'GET', id })
);

export const lambdaCreateSimpleApprising = (info: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_APPRISING_DISPATCH, { method: 'CREATE_SIMPLE', info })
);

export const lambdaCreateCheckApprising = (info: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_APPRISING_DISPATCH, { method: 'CREATE_CHECK', info })
);

export const lambdaRemoveApprising = (id: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_APPRISING_DISPATCH, { method: 'REMOVE', id })
);

export const lambdaApprisingMarkAsRemoved = (id: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_APPRISING_DISPATCH, { method: 'MARK_AS_REMOVED', id })
);

export const lambdaGetApprisingDetails = (apprisings: any[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_APPRISING_DISPATCH, { method: 'DETAIL', apprisings })
);

export const lambdaMakeAssignApprisings = (itemId: string, href: string, addedIds: string[], removedIds: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_APPRISING_DISPATCH, { method: 'ASSIGN', itemId, href, addedIds, removedIds })
);

// ************************** Procurement - Propoasal ************************************
export const lambdaCreateProposal = (proposal: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PROPOSAL_DISPATCH, { method: 'CREATE', proposal })
);

export const lambdaUpdateProposal = (id: string, body: any): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_PROPOSAL_DISPATCH, { method: 'UPDATE', id, body })
);

export const lambdaCloseRFQAndProposal = (contractId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PROPOSAL_DISPATCH, { method: 'CLOSE_FROM_CONTRACT', contractId })
);

export const lambdaGetProposalbyRFQAndCustomer = (rfqId: string, customer: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PROPOSAL_DISPATCH, { method: 'GET_BY_RFQ_AND_CUSTOMER', rfqId, customer })
);

export const lambdaGetProposalbyRFQAndSupplier = (rfqId: string, supplier: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PROPOSAL_DISPATCH, { method: 'GET_BY_RFQ_AND_SUPPLIER', rfqId, supplier })
);

export const lambdaGetProposalFromChat = (convId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PROPOSAL_DISPATCH, { method: 'GET_FROM_CHAT', convId })
);

export const lambdaRemoveChatFromProposal = (convId: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_PROPOSAL_DISPATCH, { method: 'REMOVE_CHAT', convId })
);

export const lambdaRemoveContractFromProposal = (contractId: string): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_PROPOSAL_DISPATCH, { method: 'REMOVE_CONTRACT', contractId })
);
// ************************** Procurement - Propoasal ************************************

// ************************** Procurement - Contract ************************************
export const lambdaCreateContract = (contract: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTRACT_DISPATCH, { method: 'CREATE', contract })
);

export const lambdaUpdateContract = (id: string, body: any): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_CONTRACT_DISPATCH, { method: 'UPDATE', id, body })
);

export const lambdaCreateOrgFeeTicket = (request: any, contract: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTRACT_DISPATCH, { method: 'CREATE_ORG_TICKET', request, contract })
);

export const lambdaSignContractMerged = (contract: any, walletAddress: string, isCreate: boolean, isSupplier: boolean, ticketId: string, purchaseItem: any, invoices: any[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTRACT_DISPATCH, { method: 'SIGN_CONTRACT_MERGED', contract, walletAddress, isCreate, isSupplier, ticketId, purchaseItem, invoices })
);

export const lambdaSignTicket = (mid: string, oid: string, signType: number, ticket: any, walletOwner: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTRACT_DISPATCH, { method: 'MEMBER_SIGN', mid, oid, signType, ticket, walletOwner })
);

export const lambdaOrderAssetMerged = (reqBody, contract, customerWalletAddr, now, purchaseItem, invoices, demanderSigner): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTRACT_DISPATCH, { method: 'ASSET_ORDER_MERGED', reqBody, contract, customerWalletAddr, now, purchaseItem, invoices, demanderSigner })
);

export const lambdaRemoveContract = (id: string): Promise<number> => (
  lambdaRun(LAMBDA_FN.FN_CONTRACT_DISPATCH, { method: 'REMOVE', id })
);

export const lambdaGetContractRelated = (userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTRACT_DISPATCH, { method: 'GET_BY_RELATED', userId })
);

export const lambdaGetContractbyId = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTRACT_DISPATCH, { method: 'GET_BY_ID', id })
);

export const lambdaGetOnlyContractbyId = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTRACT_DISPATCH, { method: 'GET_CONTRACT_BY_ID', id })
);

export const lambdaPublishContract = (contract: any): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_CONTRACT_DISPATCH, { method: 'PUBLISH_CONTRACT', contract })
);

export const lambdaRemoveChatFromContract = (convId: string): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_CONTRACT_DISPATCH, { method: 'REMOVE_CHAT', convId })
);

export const lambdaGetContractFromChat = (convId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_CONTRACT_DISPATCH, { method: 'GET_FROM_CHAT', convId })
);
// ************************** Procurement - Contract ************************************

// ************************** Procurement - Schedule ************************************
export const lambdaGetSchedulesRelated = (userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_SCHEDULE_DISPATCH, { method: 'GET_BY_RELATED', userId })
);

export const lambdaCreateSchedules = (schedules: any[]): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_SCHEDULE_DISPATCH, { method: 'CREATE', schedules })
);

export const lambdaUpdateSchedules = (schedules: any[]): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_SCHEDULE_DISPATCH, { method: 'UPDATE', schedules })
);

export const lambdaUpdateSchedulesWithCommonBody = (ids: string[], body: any): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_SCHEDULE_DISPATCH, { method: 'UPDATE_COMMON_BODY', ids, body })
);

export const lambdaRemoveSchedule = (ids: string[]): Promise<number> => (
  lambdaRun(LAMBDA_FN.FN_SCHEDULE_DISPATCH, { method: 'REMOVE', ids })
);

export const lambdaGetSchedulesFromContract = (contractId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_SCHEDULE_DISPATCH, { method: 'GET_FROM_CONTRACT', contractId })
);
// ************************** Procurement - Schedule ************************************

// ************************** Procurement - Invoice ************************************
export const lambdaCreateInvoice = (scheduleId: string, invoice: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVOICE_DISPATCH, { method: 'CREATE', scheduleId, invoice })
);

export const lambdaCreateMultiInvoices = (invoices: any[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVOICE_DISPATCH, { method: 'CREATE_MULTI', invoices })
);

export const lambdaSignInvoiceAfterPayMerged = (invoice: any, customerWallet: string, supplierWallet: string, signedAt: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVOICE_DISPATCH, { method: 'SIGN_INVOICE_MERGED', invoice, customerWallet, supplierWallet, signedAt })
);

export const lambdaRemoveInvoice = (scheduleId: string, id: string): Promise<number> => (
  lambdaRun(LAMBDA_FN.FN_INVOICE_DISPATCH, { method: 'REMOVE', scheduleId, id })
);

export const lambdaGetInvoiceRelated = (userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVOICE_DISPATCH, { method: 'GET_BY_RELATED', userId })
);

export const lambdaGetInvoicebyId = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVOICE_DISPATCH, { method: 'GET_BY_ID', id })
);

export const lambdaPublishInvoice = (invoice: any): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_INVOICE_DISPATCH, { method: 'PUBLISH_INVOICE', invoice })
);

export const lambdaUpdateInvoice = (id: string, body: any): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_INVOICE_DISPATCH, { method: 'UPDATE', id, body })
);

export const lambdaUpdateMultiInvoices = (invoices: any[]): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_INVOICE_DISPATCH, { method: 'UPDATE_MULTI', invoices })
);

export const lambdaGetInvoicesFromContract = (contractId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVOICE_DISPATCH, { method: 'GET_FROM_CONTRACT', contractId })
);

export const lambdaGetInvoiceMappingsByPublisher = (userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVOICE_DISPATCH, { method: 'GET_MAPPING_BY_PUBLISHER', userId })
);
// ************************** Procurement - Invoice ************************************

// ************************** Procurement - Purchase Order ************************************
export const lambdaCreatePO = (po: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PO_DISPATCH, { method: 'CREATE', po })
);

export const lambdaRemovePO = (id: string): Promise<number> => (
  lambdaRun(LAMBDA_FN.FN_PO_DISPATCH, { method: 'REMOVE', id })
);

export const lambdaGetPORelated = (userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PO_DISPATCH, { method: 'GET_BY_RELATED', userId })
);

export const lambdaUpdatePO = (id: string, body: any): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_PO_DISPATCH, { method: 'UPDATE', id, body })
);

export const lambdaSupplierSignPO = (po: any): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_PO_DISPATCH, { method: 'SUPPLIER_SIGN', po })
);

export const lambdaPublishReceiptFromPO = (po: any): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_PO_DISPATCH, { method: 'PUBLISH_RECEIPT', po })
);

export const lambdaPublishPO = (po: any): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_PO_DISPATCH, { method: 'PUBLISH_PO', po })
);

// ************************** Procurement - Purchase Order ************************************
export const lambdaAddFeedback = (body: any, connectId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FEEDBACK_DISPATCH, { method: 'ADD', body, connectId })
);

export const lambdaCreateFeedback = (body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FEEDBACK_DISPATCH, { method: 'CREATE', body })
);

export const lambdaGetFeedback = (senderId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FEEDBACK_DISPATCH, { method: 'GET_BY_SENDER', senderId })
);

export const lambdaMarkFeedbackAsRead = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FEEDBACK_DISPATCH, { method: 'MARK_AS_READ', id })
);

export const lambdaSendRawFeedback = (emailParams: any, emailTransportAttachments: any[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FEEDBACK_DISPATCH, { method: 'SEND_RAW', emailParams, emailTransportAttachments })
);

export const lambdaGetProcurementCommit = (id: string, type: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PROCUREMENT_DISPATCH, { method: 'GET_COMMIT', id, type })
);

export const lambdaFindAddress = (_query: string) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ADDRESS_HELPER, { query: _query })
);
//* ****************************  FILESHARE  ************************************ */
export const lambdaCreateFilethread = (filethread: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FILESHARE_DISPATCH, { method: 'THREAD_CREATE', filethread })
);

export const lambdaUploadFileByIPFS = (buffer: any, filename: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_FILESHARE_DISPATCH, { method: 'IPFS_UPLOAD', buffer, filename })
);

export const lambdaDownloadFileByIPFS = (hash: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_FILESHARE_DISPATCH, { method: 'IPFS_DOWNLOAD', hash })
);

export const lambdaUpdateFilethread = (id: string, filethread: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FILESHARE_DISPATCH, { method: 'THREAD_UPDATE', id, filethread })
);

export const lambdaGetFileshareData = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FILESHARE_DISPATCH, { method: 'GET_DATA', id })
);

export const lambdaGetFilethreadById = (id: string, userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FILESHARE_DISPATCH, { method: 'THREAD_GET_BY_ID', id, userId })
);

export const lambdaSignFilethread = (id: string, filethread: any, signer: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FILESHARE_DISPATCH, { method: 'SIGN', id, filethread, signer })
);

export const lambdaShareFilethread = (from: any, fromAgent: any, toUsers: any[], itemId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FILESHARE_DISPATCH, { method: 'SHARE', from, fromAgent, toUsers, itemId })
);

export const lambdaUpdateFilemanage = (filemanage: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FILESHARE_DISPATCH, { method: 'MANAGE_UPDATE', filemanage })
);

export const lambdaPutMemberTopbarItems = (mid: string, items: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'PUT_TOPBAR', mid, items })
);

export const lambdaGetUserMinInfos = (_users: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_USERS_MIN', users: _users })
);

export const lambdaSearchOrganizations = (_query: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'SEARCH', query: _query })
);

export const lambdaSearchOrganizationByField = (_query: string, _fieldName: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'SEARCH_BY_FIELD', query: _query, fieldName: _fieldName })
);

export const lambdaGetOrgActivatedMembersOfUser = (_uid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_ACTIVATED', uid: _uid })
);

export const lambdaCheckRegisterInvite = (_uid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'CHECK_REGISTER_INVITE', uid: _uid })
);

export const lambdaGetOrganizationInfo = (_orgId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_ORG_INFO', orgId: _orgId })
);

export const lambdaGetOrgMemberInfo = (_mid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_ORG_BY_MID', mid: _mid })
);

export const lambdaGetMembersFromOrgs = (_oids: string[]) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_MEMBERS', oids: _oids })
);

export const lambdaGetMemberUsersById = (_mids: string[]) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_MEMBER_USERS_BY_MEMBER_ID', mids: _mids })
);

export const lambdaGetOrganizationsById = (_oids: string[]) : Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_BY_IDS', oids: _oids })
);

export const lambdaAcceptInvitations = (mids: string[], uid: string = null, isCreateOrg: boolean): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ACCEPT_INVITATIONS', mids, uid, isCreateOrg })
);

export const lambdaJoinAsClient = (userInfo: any, domain: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'JOIN_AS_CLIENT', userInfo, domain })
);

export const lambdaAcceptInvitationOnSingin = (organizationId: string, email: string, asMember: boolean): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ACCEPT_INVITATION_ON_SIGN_IN', organizationId, email, asMember })
);

export const lambdaAcceptOrgInvitationOnSingin = (organizationId: string, email: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ACCEPT_ORG_INVITATION_ON_SIGN_IN', organizationId, email })
);

export const lambdaGetPendingInvitations = (email: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_PENDING_INVITATIONS', email })
);

export const lambdaCreateOrganization = (org: any, adminRole: any, userInfo: any, isFirst: boolean, teamNames: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'CREATE_ORGANIZATION', org, adminRole, userInfo, isFirst, teamNames })
);

export const lambdaSetOrganizationInfo = (_body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'PUT', body: _body })
);

export const lambdaUpdateOrganizationInfo = (_orgId: string, _body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'UPDATE', orgId: _orgId, body: _body })
);

export const lambdaCheckDomainNameAvailable = (dn: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'CHECK_AVAILABILITY', domainName: dn })
);

export const lambdaCheckEmailAvailable = (email: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'CHECK_EMAIL_AVAILABILITY', email })
);

export const lambdaGetOrgMembersOfUser = (_uid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET', uid: _uid })
);

export const lambdaGetOrgMember = (_mid: string, _oid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_ORG_MEMBER', mid: _mid, oid: _oid })
);

export const lambdaAddOrgMember = (_body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ADD_MEMBER', body: _body })
);

export const lambdaAddAndDeleteMembers = (newMembers: any[], deleteIds: string[], orgId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ADD_DELETE_MEMBERS', newMembers, deleteIds, orgId })
);

export const lambdaDeleteOrgMember = (_mid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'DELETE_MEMBER', mid: _mid })
);

export const lambdaUpdateOrgMember = (_mid: string, _body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'UPDATE_MEMBER', mid: _mid, body: _body })
);

export const lambdaActivateOrgMember = (_mid: string, _rid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ACTIVATE_MEMBER', mid: _mid, rid: _rid })
);

export const lambdaUpdateOrgMemberWithEmail = (_body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'UPDATE_MEMBER_WITH_EMAIL', body: _body })
);

export const lambdaAddOrgMemberRole = (_body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ADD_MEMBER_ROLE', body: _body })
);

export const lambdaUpdateOrgMemberRole = (_rid: string, _body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'UPDATE_MEMBER_ROLE', rid: _rid, body: _body })
);

export const lambdaDeleteOrgMemberRole = (_rid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'DELETE_MEMBER_ROLE', rid: _rid })
);

export const lambdaAddOrgTeam = (_teamInfo: any, _memberIds: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'ADD_TEAM', teamInfo: _teamInfo, memberIds: _memberIds })
);

export const lambdaUpdateOrgTeam = (_tid: string, _teamInfo: any, _addMemberIds: string[], _deleteMemberIds: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'UPDATE_TEAM', tid: _tid, teamInfo: _teamInfo, addMemberIds: _addMemberIds, deleteMemberIds: _deleteMemberIds })
);

export const lambdaGetOrgStructure = (_oid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_ORG', oid: _oid })
);

export const lambdaGetOrgTeamMembers = (_tids: string[], _oid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_TEAM_MEMBER', tids: _tids, oid: _oid })
);

export const lambdaDeleteOrgTeam = (_tid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'DELETE_TEAM', tid: _tid })
);

export const lambdaGetOrgBannerUploadUrl = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_UP_BANNER_URL', id })
);

export const lambdaGetOrgBannerUrl = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ORGANIZATION_DISPATCH, { method: 'GET_BANNER_URL', id })
);

export const lambdaCreateWallet = (walletID: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'CREATE', walletID })
);

export const lambdaGetTemplateList = (uid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TEMPLATES_DISPATCH, { method: 'GET_LIST', uid })
);

export const lambdaGetTemplateBody = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TEMPLATES_DISPATCH, { method: 'GET_BODY', id })
);

export const lambdaDeleteTemplate = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TEMPLATES_DISPATCH, { method: 'DELETE', id })
);

export const lambdaCreateTemplate = (uid: string, body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TEMPLATES_DISPATCH, { method: 'CREATE', uid, body })
);

export const lambdaDuplicateTemplate = (uid: string, id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TEMPLATES_DISPATCH, { method: 'DUPLICATE', uid, id })
);

export const lambdaUpdateTemplate = (id: string, body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TEMPLATES_DISPATCH, { method: 'UPDATE', id, body })
);

export const lambdaFirstLoadWallet = (walletID: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'FIRST_LOAD', walletID })
);

export const lambdaRestoreWallet = (walletKey: string, walletID: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'RESTORE', walletKey, walletID })
);

export const lambdaUpdateRecentClients = (userId: string, clients: any[]): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'UPDATE_RECENTS', userId, clients })
);

export const lambdaGetWalletById = (owner: any, tokenName: string = 'Ether'): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'GET_BY_ID', owner, tokenName })
);

export const lambdaGetWalletAddress = (owner: any): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'GET_ADDRESS', owner })
);

export const lambdaGetWalletBalances = (userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'GET_BALANCE', userId })
);

export const lambdaGetWalletHistory = (ownerId: string, token: string, offset: number, layer2: boolean = false): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'GET_HISTORY', ownerId, token, offset, layer2 })
);

export const lambdaGetWalletTransactions = (address: string, token: string, page: number, offset: number, layer2: boolean = false): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'GET_TRANSACTION', address, token, page, offset, layer2 })
);

export const lambdaMercWithdraw = (owner: any, amount: number, gasFee: number = null): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MERCHANT_DISPATCH, { method: 'WITHDRAW', owner, amount, gasFee })
);

export const lambdaMercDeposit = (owner: any, amount: number, gasFee: number = null): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MERCHANT_DISPATCH, { method: 'DEPOSIT', owner, amount, gasFee })
);

export const lambdaMercTransfer = (from: any, to: any, amount: number, layer2: boolean, gasFee: number = null, materialIds: any = null): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MERCHANT_DISPATCH, { method: 'TRANSFER', from, to, amount, layer2, gasFee, materialIds })
);

export const lambdaMercSell = (owner: any, btcAddress: string, amount: number, layer2: boolean, gasFee: number, satoshiGasFee: number): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MERCHANT_DISPATCH, { method: 'SELL', owner, btcAddress, amount, layer2, gasFee, satoshiGasFee })
);

export const lambdaGetWalletRecentClientsMinInfo = (ids: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'RECENT_CLIENTS', ids })
);

export const lambdaGetGasPrice = (userId: string, tokenName: string = 'Ether'): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'GET_GAS_PRICE', userId, tokenName })
);

export const lambdaSearchWallet = (query: string, tokenName: string = ''): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'SEARCH', query, tokenName })
);

export const lambdaGetPrivateKey = (userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'PRIVKEY', userId })
);

export const lambdaGetSignature = (message: string, address: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'GET_SIGNATURE', message, address })
);

export const lambdaGetTxnFromEtherscan = (hash: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'TXN_FROM_ETHERSCAN', hash })
);

export const lambdaSendERC20Token = (from: any, to: any, amount: number, tokenName: string = 'Ether', materialIds: any = null): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_WALLET_DISPATCH, { method: 'SEND', from, to, amount, tokenName, materialIds })
);

export const lambdaSearchAssetsByQuery = (query: string, userId: string, itemTypes: number[], itemRange: number, idMode: boolean): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'SEARCH_BY_QUERY', query, userId, itemTypes, itemRange, idMode })
);

export const lambdaAddNewAsset = (body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'CREATE', body })
);

export const lambdaUpdateAsset = (id: string, body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'UPDATE', id, body })
);

export const lambdaUpdateMultiAssets = (ids: string[], body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'UPDATE_MULTI', ids, body })
);

export const lambdaUpdateAssetRelations = (add: any[], remove: any[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'UPDATE_RELATIONS', add, remove })
);

export const lambdaRemoveAsset = (id: string): Promise<number> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'DELETE', id })
);

export const lambdaRemoveAssetFromReq = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'DELETE_FROM_REQ', id })
);

export const lambdaGetAssetsByOwner = (ownerId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'GET_BY_OWNER', ownerId })
);

export const lambdaGetAssetsOfPartner = (ownerId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'GET_FROM_PARTNER', ownerId })
);

export const lambdaGetAssetById = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'GET_BY_ID', id })
);

export const lambdaGetAssetForDetail = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'GET_FOR_DETAIL', id })
);

export const lambdaGetOtherAssetsOfSupplier = (ownerId: string, curId: string, purpose: number): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'GET_OTHER_ASSETS', ownerId, curId, purpose })
);

export const lambdaGetAssetsPublished = (ownerId: string, offset: number, oids: string[], query: string, categories: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'SEARCH', ownerId, offset, oids, query, categories })
);

export const lambdaGetAssetThumbnail = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'GET_THUMBNAIL', id })
);

export const lambdaGetAssetActionsById = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'GET_ACTIONS_BY_ID', id })
);

export const lambdaAcceptSaleForAsset = (actionBody: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'ACCEPT_SALE', actionBody })
);

export const lambdaAcceptLeaseForAsset = (actionBody: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'ACCEPT_LEASE', actionBody })
);

export const lambdaPostponeLeaseForAsset = (assetId: string, ticketId: string, endDate: Date | string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'POSTPONE_LEASE', assetId, ticketId, endDate })
);

export const lambdaRegisterAssetFromTicket = (ticketId: string): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'REGISTER_FROM_TICKET', ticketId })
);

export const lambdaReturnLoanedAsset = (actionId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'RETURN_LEASE', actionId })
);

export const lambdaReturnLoanedAssetFromTicket = (ticketId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'RETURN_LEASE_FROM_TICKET', ticketId })
);

export const lambdaGetActionFromTicket = (ticketId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'GET_ACTION_FROM_TICKET', ticketId })
);

export const lambdaGetActionFromAsset = (assetId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ASSET_DISPATCH, { method: 'GET_ACTION_FROM_ASSET', assetId })
);

export const lambdaCreateRequest = (body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'CREATE', body })
);

export const lambdaGetRequestNumByDemander = (demander: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'NUM_BY_DEMANDER', demander })
);

export const lambdaSendRequestMerged = (oldRequestId: string, request: any, contract: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'SEND_REQUEST_MERGED', oldRequestId, request, contract })
);

export const lambdaGetProcurementOfTicketMerged = (ticket: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'GET_PROCUREMENT_OF_TICKET_MERGED', ticket })
);

export const lambdaArchiveRequest = (requestId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'ARCHIVE', requestId })
);

export const lambdaChangeAutoDebitOfRequest = (requestId: string, checked: boolean): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'AUTO_DEBIT_CHANGE', requestId, checked })
);

export const lambdaUpdateRequest = (uid: string, body: any, draftId: string = null): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'UPDATE', uid, body, draftId })
);

export const lambdaCloseRequestFromContract = (contractId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'CLOSE_FROM_CONTRACT', contractId })
);

export const lambdaCreateMsOffer = (customers: any[], body: any, draftId: string = null): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'CREATE_OFFER', customers, body, draftId })
);

export const lambdaGetRequestsRelated = (userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'GET_BY_RELATED', userId })
);

export const lambdaGetRequestById = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'GET_BY_ID', id })
);

export const lambdaGetRequestFromChat = (convId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'GET_FROM_CHAT', convId })
);

export const lambdaAddConversationToRequst = (uid: string, convId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'ADD_CHAT_THREAD', uid, convId })
);

export const lambdaRemoveChatFromRequest = (convId: string): Promise<string[]> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'REMOVE_CHAT', convId })
);

export const lambdaRemoveContractFromRequest = (contractId: string): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'REMOVE_CONTRACT', contractId })
);

export const lambdaRemoveRequest = (uid: string): Promise<number> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'REMOVE', uid })
);

export const lambdaDiscardRequest = (uid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'DISCARD_REQUEST', uid })
);

export const lambdaRejectRequest = (uid: string, reason: string): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_REQUEST_DISPATCH, { method: 'REJECT_REQUEST', uid, reason })
);

export const lambdaCreatePurchaseItem = (purchaseItem: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PURCHASE_DISPATCH, { method: 'CREATE', purchaseItem })
);

export const lambdaUpdatePurchaseItemFromPO = (contractId: string, material: any, deliveryDate: Date | string): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_PURCHASE_DISPATCH, { method: 'UPDATE_FROM_PO', contractId, material, deliveryDate })
);

export const lambdaUpdatePurchaseItemFromInvoice = (contractId: string, itemId: string, miniInvoice: any): Promise<boolean> => (
  lambdaRun(LAMBDA_FN.FN_PURCHASE_DISPATCH, { method: 'UPDATE_FROM_INVOICE', contractId, itemId, miniInvoice })
);

export const lambdaRemovePurchaseItem = (id: string): Promise<number> => (
  lambdaRun(LAMBDA_FN.FN_PURCHASE_DISPATCH, { method: 'REMOVE', id })
);

export const lambdaGetPurchaseItemsRelated = (userId: string, types: number[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PURCHASE_DISPATCH, { method: 'GET_BY_RELATED', userId, types })
);

export const lambdaGetPurchaseItemsFromContract = (contractId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PURCHASE_DISPATCH, { method: 'GET_FROM_CONTRACT', contractId })
);

export const lambdaCreateTodo = (body: Todo): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TODO_DISPATCH, { method: 'CREATE', body })
);

export const lambdaGetTodos = (id: String): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TODO_DISPATCH, { method: 'GET_ORG', id })
);

export const lambdaUpdateTodo = (id: string, content: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TODO_DISPATCH, { method: 'UPDATE', id, ...content })
);

export const lambdaAddTodoComment = (todoId: string, owner: any, orgId: string, message: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TODO_DISPATCH, { method: 'ADD_COMMENT', todoId, owner, orgId, message })
);

export const lambdaAddTodoAttachment = (todoId: string, owner: any, orgId: string, id: string, name: string, size: number): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TODO_DISPATCH, { method: 'ADD_ATTACHMENT', id, todoId, owner, orgId, name, size })
);

export const lambdaGetTodoAttachmentUploadUrl = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TODO_DISPATCH, { method: 'GET_UP_URL', id })
);

export const lambdaGetTodoAttachmentUrl = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TODO_DISPATCH, { method: 'GET_URL', id })
);

export const lambdaDeleteTodo = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TODO_DISPATCH, { method: 'REMOVE', id })
);

export const lambdaMarketplacePostRequest = (path:string, query?: any, update?: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MARKETPLACE_DISPATCH, { method: 'POST', path, query, update })
);

export const lambdaMarketplacePutImage = (uid: string, imgs: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MARKETPLACE_DISPATCH, { method: 'PUT_IMAGE', uid, imgs })
);

export const lambdaMarketplaceGetImages = (uid: string, imgs: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MARKETPLACE_DISPATCH, { method: 'GET_IMAGES', uid, imgs })
);

export const lambdaMarketplaceGetGoodCardThumb = (goodId: string, idx: number, ext: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MARKETPLACE_DISPATCH, { method: 'GET_CARD_THUMB', goodId, idx, ext })
);

export const lambdaMarketplaceGetSignedDownloadUrl = (_uid: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_MARKETPLACE_DISPATCH, { method: 'GET_DOWN_URL', uid: _uid })
);

export const lambdaMarketplaceGetSignedUploadUrl = (_uid: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_MARKETPLACE_DISPATCH, { method: 'GET_UP_URL', uid: _uid })
);

export const lambdaIOUContractPost = (uri: string, request?: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_IOUCONTRACT_DISPATCH, { method: 'POST', uri, request })
);

export const lambdaCreateAnzicTaxonomyInfoByType = (category: any, type: number): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAXONOMY_DISPATCH, { method: 'CREATE_ANZIC_BY_TYPE', category, type })
);

export const lambdaGetAnzicTaxonomyInfo = (): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAXONOMY_DISPATCH, { method: 'GET_ANZIC' })
);

export const lambdaGetAnzicTaxonomyInfoByType = (type: number): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAXONOMY_DISPATCH, { method: 'GET_ANZIC_BY_TYPE', type })
);

export const lambdaGetAnzicTaxonomyInfoByCodeAndType = (code: string, type: number): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAXONOMY_DISPATCH, { method: 'GET_ANZIC_BY_CODE_TYPE', code, type })
);

export const lambdaCreateOrUpdateTags = (objectId: string, objectType: number, names: string[], removedIds: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAG_DISPATCH, { method: 'CREATE_OR_UPDATE', objectId, objectType, names, removedIds })
);

export const lambdaDuplicateTags = (objectId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAG_DISPATCH, { method: 'DUPLICATE', objectId })
);

export const lambdaGetTags = (objectId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAG_DISPATCH, { method: 'GET', objectId })
);

export const lambdaGetMultiObjectTags = (ids: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_TAG_DISPATCH, { method: 'GET_MULTI', ids })
);

export const lambdaCreateStripeAccount = (user: any, refreshUrl: string, returnUrl: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PAYACCOUNT_DISPATCH, { method: 'CREATE_STRIPE_ACCOUNT', user, refreshUrl, returnUrl })
);

export const lambdaRemoveStripeAccount = (accountId: string): Promise<number> => (
  lambdaRun(LAMBDA_FN.FN_PAYACCOUNT_DISPATCH, { method: 'REMOVE_STRIPE_ACCOUNT', accountId })
);

export const lambdaEnableDirectDebitOnStripe = (user: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PAYACCOUNT_DISPATCH, { method: 'ENABLE_STRIPE_DIRECT_DEBIT', user })
);

export const lambdaPendingDDSetupIntentOnStripe = (accountId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PAYACCOUNT_DISPATCH, { method: 'PENDING_STRIPE_DDSETUPINTENT', accountId })
);

export const lambdaDisableDirectDebitOnStripe = (accountId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PAYACCOUNT_DISPATCH, { method: 'DISABLE_STRIPE_DIRECT_DEBIT', accountId })
);

export const lambdaGetStripeClientSecret = (intentId: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_PAYACCOUNT_DISPATCH, { method: 'GET_STRIPE_CLIENT_SECRET', intentId })
);

export const lambdaGetStripeAccount = (userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PAYACCOUNT_DISPATCH, { method: 'GET_STRIPE_BY_ID', userId })
);

export const lambdaGetStripeDetailAccount = (userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PAYACCOUNT_DISPATCH, { method: 'GET_STRIPE_DETAIL_BY_ID', userId })
);

export const lambdaGetStripeVerifyLink = (userId: string, refreshUrl: string, returnUrl: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PAYACCOUNT_DISPATCH, { method: 'GET_STRIPE_VERIFY_LINK', userId, refreshUrl, returnUrl })
);

export const lambdaPayInvoiceByStripe = (invoice: any, successUrl: string, cancelUrl: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PAYACCOUNT_DISPATCH, { method: 'PAY_INVOICE_BY_STRIPE', invoice, successUrl, cancelUrl })
);

export const lambdaPayMultiInvoicesByStripe = (invoices: any[], data: any): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_PAYACCOUNT_DISPATCH, { method: 'PAY_MULTI_INVOICES_BY_STRIPE', invoices, data })
);

export const lambdaGetInvitationUrl = (params: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVITATION_DISPATCH, { method: 'ENCRYPT_PARAMS', params })
);

export const lambdaGetInvitationDetails = (text: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVITATION_DISPATCH, { method: 'DECRYPT_PARAMS', text })
);

export const lambdaGetInvitationRelated = (userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVITATION_DISPATCH, { method: 'GET_BY_RELATED', userId })
);

export const lambdaUpdateInvitationsWithCommonBody = (ids: string[], body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVITATION_DISPATCH, { method: 'UPDATE_COMMON_BODY', ids, body })
);

export const lambdaCreateInvitations = (body: any, invitees: any[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVITATION_DISPATCH, { method: 'CREATE', body, invitees })
);

export const lambdaCreateInvitationWithEmail = (body: any, email: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVITATION_DISPATCH, { method: 'CREATE_WITH_EMAIL', body, email })
);

export const lambdaUpdateInvitationWithEmail = (body: any, email: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVITATION_DISPATCH, { method: 'UPDATE_WITH_EMAIL', body, email })
);

export const lambdaRemoveInvitation = (ids: string[]): Promise<number> => (
  lambdaRun(LAMBDA_FN.FN_INVITATION_DISPATCH, { method: 'REMOVE', ids })
);

export const lambdaRemoveChatFromInvitation = (convId: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_INVITATION_DISPATCH, { method: 'REMOVE_CHAT', convId })
);

export const lambdaAcceptInvitation = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVITATION_DISPATCH, { method: 'ACCEPT_INVITATION', id })
);

export const lambdaSearchUserWithFriend = (query: string, showUser: number): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_INVITATION_DISPATCH, { method: 'SEARCH_USERS_BY_QUERY', query, showUser })
);

export const lambdaGetReferralRelated = (userId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'GET_BY_RELATED', userId })
);

export const lambdaRemoveReferral = (id: string): Promise<number> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'REMOVE_REFER', id })
);

export const lambdaRemoveReferralFromReq = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'REMOVE_FROM_REQ', id })
);

export const lambdaRemoveChatFromReferral = (convId: string): Promise<string> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'REMOVE_CHAT', convId })
);

export const lambdaCreateReferral = (body: any, items: any[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'CREATE', body, items })
);

export const lambdaUpdateReferralsWithCommonBody = (ids: string[], body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'UPDATE_COMMON_BODY', ids, body })
);

export const lambdaAcceptReferral = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'ACCEPT_REFER', id })
);

export const lambdaCreateReferralFollowUp = (id: string, followUp: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'CREATE_FOLLOWUP', id, followUp })
);

export const lambdaCreateReferralChat = (id: string, chatId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'CREATE_CHAT', id, chatId })
);

export const lambdaAcceptReferralFollowUp = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'ACCEPT_FOLLOWUP', id })
);

export const lambdaRejectReferral = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'REJECT_REFER', id })
);

export const lambdaRejectReferralFollowUp = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'REJECT_FOLLOWUP', id })
);

export const lambdaGetReferralById = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'GET_BY_ID', id })
);

export const lambdaGetReferralMinById = (id: string, inviter: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'GET_MIN_BY_ID', id, inviter })
);

export const lambdaGetReferralByItem = (item: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'GET_BY_ITEM', item })
);

export const lambdaGetReferralByUsersAndItem = (item: any, creatorIds: string[], partnerId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'GET_BY_USERS_AND_ITEM', item, creatorIds, partnerId })
);

export const lambdaGetReferralItems = (creatorId: string, partnerId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_REFERRAL_DISPATCH, { method: 'GET_REFERRAL_ITEMS', creatorId, partnerId })
);

export const lambdaMRCAwardItem = (address: string, tokenURI: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MERCHANT_DISPATCH, { method: 'AWARDITEM', address, tokenURI })
);

export const lambdaGetMRCBalance = (address: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MERCHANT_DISPATCH, { method: 'BALANCE', address })
);

export const lambdaMRCRequest = (address: string, type: string, fee = 0, target = ''): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_MERCHANT_DISPATCH, { method: 'REQUEST', address, type, fee, target })
);

export const lambdaGetAnnouncements = (): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_ANNOUNCEMENT_DISPATCH, { method: 'GET' })
);

export const lambdaGetFavoritesAndPaidProfiles = (ownerId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_BSEARCH_DISPATCH, { method: 'GET', ownerId })
);

export const lambdaRemoveFavorite = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_BSEARCH_DISPATCH, { method: 'DELETE', id })
);

export const lambdaCreateFavorite = (body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_BSEARCH_DISPATCH, { method: 'CREATE', body })
);

export const lambdaGetBSSearchHistory = (ownerId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_BSEARCH_DISPATCH, { method: 'GET_HISTORY', ownerId })
);

export const lambdaUpdateBSSearchHistory = (owner: any, data: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_BSEARCH_DISPATCH, { method: 'UPDATE_HISTORY', owner, data })
);

export const lambdaGetPaidProfiles = (ownerId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_BSEARCH_DISPATCH, { method: 'GET_PAID_PROFILE', ownerId })
);

export const lambdaPutPaidProfile = (body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_BSEARCH_DISPATCH, { method: 'PUT_PAID_PROFILE', body })
);

export const lambdaPayAndGetProfile = (address: string, fee: string | number, data: any, owner: any, query: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_BSEARCH_DISPATCH, { method: 'GET_PAID_RESULT', address, fee, data, owner, query })
);

export const lambdaGetFilethreadUploadUrl = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FILESHARE_DISPATCH, { method: 'GET_UP_URL', id })
);

export const lambdaCreateRating = (rating: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_RATING_DISPATCH, { method: 'CREATE', rating })
);

export const lambdaGetRatingsByItemId = (itemId: string, offset: number, count: number): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_RATING_DISPATCH, { method: 'GET', itemId, offset, count })
);

export const lambdaRemoveRating = (id: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_RATING_DISPATCH, { method: 'REMOVE', id })
);

export const lambdaUpdateServiceRating = (rating: any, add: any[], remove: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_RATING_DISPATCH, { method: 'UPDATE', rating, add, remove })
);

export const lambdaGetPurchaseRatingStatistics = (itemId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_RATING_DISPATCH, { method: 'GET_PURCHASE_RATING_STATISTICS', itemId })
);

export const lambdaCreateFileResources = (resources: any[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FILERESOURCE_DISPATCH, { method: 'PUT', resources })
);

export const lambdaGetFileResourcesByItemId = (itemId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FILERESOURCE_DISPATCH, { method: 'GET', itemId })
);

export const lambdaUpdateFileResources = (add: any[], remove: string[]): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_FILERESOURCE_DISPATCH, { method: 'UPDATE', add, remove })
);

export const lambdaCreatePurchaseHistory = (body: any): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_PURCHASEHISTORY_DISPATCH, { method: 'CREATE', body })
);

export const lambdaSendMsgToPhone = (phoneNumber: string, msg: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_SNS_DISPATCH, { method: 'SNS_SEND', phoneNumber, msg })
);

export const lambdaVerificationGetStatus = (_uid: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_VERIFICATION_DISPATCH, { method: 'GET_STATUS', uid: _uid })
);

export const lambdaUpdateShare = (id: string, body: any): Promise<number> => (
  lambdaRun(LAMBDA_FN.FN_SHARE_DISPATCH, { method: 'UPDATE', id, body })
);

export const lambdaGetInvoices = (startDate: any, endDate: any, ownerId: string, isRevenue: boolean): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_DIAGRAM_DISPATCH, { method: 'GET_INVOICES', startDate, endDate, ownerId, isRevenue })
);

export const lambdaGetOrgStatistics = (ownerId: string): Promise<any> => (
  lambdaRun(LAMBDA_FN.FN_DIAGRAM_DISPATCH, { method: 'GET_STATISTICS', ownerId })
);
