import { ACCZIOM_TEAM } from 'src/globals';

export const convert2Array = (agent): any[] => (Array.isArray(agent) ? agent : [agent]);

export const getAgentIds = (agent): string[] => (
  convert2Array(agent)
    .filter((team) => !!team?.id && team?.type === ACCZIOM_TEAM)
    .map((team) => team.id)
);

export const getMyAgentIds = (agent, teamsInCurOrg): string[] => (
  convert2Array(agent)
    .filter((team) => (!!team?.id && team?.type === ACCZIOM_TEAM && teamsInCurOrg.some((myteam) => myteam.tid === team.id)))
    .map((team) => team.id)
);

export const getMyAgentNames = (agent: any, teamsInCurOrg: any[]): string => {
  const teamIds = getMyAgentIds(agent, teamsInCurOrg);
  if (teamIds.length === 0) return '---';
  return teamIds.map((id) => teamsInCurOrg.find((team) => team.tid === id).name).join(', ');
};

export const agentIncludes = (agent: any, teamIds: string[]): boolean => {
  const agentTeamIds = convert2Array(agent).filter((team) => !!team?.id && team?.type === ACCZIOM_TEAM).map(({ id }) => id);
  return teamIds.some((id) => agentTeamIds.includes(id));
};

export const getChangedAgents = (oldAgents: any, newAgents: any) => {
  const oldAgentsArray = convert2Array(oldAgents);
  const removedIds = oldAgentsArray.filter((oa) => !newAgents.some((na) => na.id === oa.id));
  const addedIds = newAgents.filter((na) => !oldAgentsArray.some((oa) => oa.id === na.id));
  return {
    addedIds: addedIds.map((agent) => agent.id),
    removedIds: removedIds.map((agent) => agent.id)
  };
};
