import { FC, useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

type NumberFieldProps = TextFieldProps & { onNumChange?: (number) => void; };

const NumberField: FC<NumberFieldProps> = (props) => {
  const { onNumChange, onChange, value, ...others } = props;
  const [txt, setTxt] = useState<string>(value === null ? '' : `${value}`);

  useEffect(() => {
    setTxt(value === null ? '' : `${value}`);
  }, [value]);

  return (
    <TextField
      {...others}
      value={txt}
      onChange={(e) => {
        if (!Number.isNaN(Number(e.target.value))) setTxt(e.target.value);
        if (onNumChange) {
          if (e.target.value === '') onNumChange(null);
          else if (!Number.isNaN(Number(e.target.value))) onNumChange(Number(e.target.value));
        }
        if (onChange && !Number.isNaN(Number(e.target.value))) onChange(e);
      }}
      // value={null2empty(value)}
    />
  );
};

export default NumberField;
