import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { MicroserviceInfo } from 'src/../../Common/Model/microservice';
import { SERVICE_REQUEST_STEP_INFOCOLLECT } from 'src/globals';
import { Request } from 'src/../../Common/Model/request';
import { Referral } from 'src/../../Common/Model/network';
import { UserMinInfoWithEntity } from 'src/components/interact/SelectPartners';

export interface MicroserviceForReq extends MicroserviceInfo {
  periodInfo?: any;
  selectedPaymentOption?: string;
  selectedPaymentPlatform?: any;
  modifications?: any;
  reqTitle?: string;
  hiMsg?: string;
  sid?: string;
  count?: number;
  answers?: any;
  completedQuestionnaire?: boolean;
  statistics?: any;
}

interface ServiceRequestState {
  service: MicroserviceForReq;
  bundledServices: MicroserviceForReq[];
  step: number;
  agreedEngagement: boolean;
  request: Request;
  postponeId: string;
  activeBannerUrl: string;
  referrals: Referral[];
  selectedReferralId: string;
  defaultInvitees: any[];
  convId: string;
  entityInfo?: any;
}

const initialState: ServiceRequestState = {
  service: null,
  bundledServices: [],
  step: SERVICE_REQUEST_STEP_INFOCOLLECT,
  agreedEngagement: false,
  request: null,
  postponeId: null,
  activeBannerUrl: null,
  referrals: null,
  selectedReferralId: null,
  defaultInvitees: [],
  convId: null,
  entityInfo: null
};

const slice = createSlice({
  name: 'serviceRequest',
  initialState,
  reducers: {
    clearState(state: ServiceRequestState): void {
      state.service = null;
      state.bundledServices.splice(0, state.bundledServices.length);
      state.step = SERVICE_REQUEST_STEP_INFOCOLLECT;
      state.agreedEngagement = false;
      state.request = null;
      state.postponeId = null;
      if (state.activeBannerUrl) window.URL.revokeObjectURL(state.activeBannerUrl);
      state.activeBannerUrl = null;
      state.referrals = null;
      state.entityInfo = null;
    },
    clearServiceRequestState(state: ServiceRequestState): void {
      state.service = null;
      state.bundledServices.splice(0, state.bundledServices.length);
      state.step = SERVICE_REQUEST_STEP_INFOCOLLECT;
      state.agreedEngagement = false;
      state.request = null;
      state.postponeId = null;
      if (state.activeBannerUrl) window.URL.revokeObjectURL(state.activeBannerUrl);
      state.activeBannerUrl = null;
      state.referrals = null;
      state.defaultInvitees.splice(0, state.defaultInvitees.length);
      state.convId = null;
      state.entityInfo = null;
    },
    setService(state: ServiceRequestState, action: PayloadAction<MicroserviceForReq>): void {
      state.service = action.payload;
    },
    setBundledServices(state: ServiceRequestState, action: PayloadAction<MicroserviceForReq[]>): void {
      state.bundledServices = action.payload;
    },
    updateService(state: ServiceRequestState, action: PayloadAction<any>): void {
      state.service = {
        ...state.service,
        ...action.payload
      };
    },
    updateBundledService(state: ServiceRequestState, action: PayloadAction<{ id: string; sid: string; info: any; }>): void {
      const { id, sid, info } = action.payload;
      state.bundledServices = state.bundledServices.map((bs) => {
        if (bs.id === id && bs.sid === sid) {
          return {
            ...bs,
            ...info
          };
        }
        return bs;
      });
    },
    setStep(state: ServiceRequestState, action: PayloadAction<number>): void {
      state.step = action.payload;
    },
    setAgreeEngagement(state: ServiceRequestState, action: PayloadAction<boolean>): void {
      state.agreedEngagement = action.payload;
    },
    setRequest(state: ServiceRequestState, action: PayloadAction<Request>): void {
      state.request = action.payload;
      if (action.payload.material?.entityInfo) {
        state.entityInfo = action.payload.material?.entityInfo;
      }
    },
    setPostponeId(state: ServiceRequestState, action: PayloadAction<string>): void {
      state.postponeId = action.payload;
    },
    setActiveBannerUrl(state: ServiceRequestState, action: PayloadAction<string>): void {
      if (state.activeBannerUrl) window.URL.revokeObjectURL(state.activeBannerUrl);
      state.activeBannerUrl = action.payload;
    },
    setReferrals(state: ServiceRequestState, action: PayloadAction<Referral[]>): void {
      state.referrals = action.payload;
    },
    setSelectedReferral(state: ServiceRequestState, action: PayloadAction<string>): void {
      state.selectedReferralId = action.payload;
    },
    setDefaultInvitees(state: ServiceRequestState, action: PayloadAction<UserMinInfoWithEntity[]>): void {
      state.defaultInvitees = action.payload;
    },
    setConvIdForProposal(state: ServiceRequestState, action: PayloadAction<string>): void {
      state.convId = action.payload;
    },
    setEntityInfo(state: ServiceRequestState, action: PayloadAction<any>): void {
      state.entityInfo = action.payload;
    }
  }
});

export const { reducer } = slice;

export const clearServiceRequestState = () : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.clearServiceRequestState());
};

export const clearState = () : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.clearState());
};

export const setService = (service: MicroserviceForReq) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setService(service));
};

export const setBundledServices = (services: MicroserviceForReq[]) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setBundledServices(services));
};

export const updateService = (info: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateService(info));
};

export const updateBundledService = (id: string, sid: string, info: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateBundledService({ id, sid, info }));
};

export const setStep = (step: number) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setStep(step));
};

export const setAgreeEngagement = (val: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setAgreeEngagement(val));
};

export const setRequest = (request: Request) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setRequest(request));
};

export const setPostponeId = (postponeId: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setPostponeId(postponeId));
};

export const setActiveBannerUrl = (url: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setActiveBannerUrl(url));
};

export const setReferrals = (referrals: Referral[]) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setReferrals(referrals));
};

export const setSelectedReferral = (id: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setSelectedReferral(id));
};

export const setDefaultInvitees = (invitees: UserMinInfoWithEntity[]) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setDefaultInvitees(invitees));
};

export const setConvIdForProposal = (convId: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setConvIdForProposal(convId));
};

export const setEntityInfo = (entityInfo: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setEntityInfo(entityInfo));
};

export default slice;
