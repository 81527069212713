import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { AppBar, Box, Button, CircularProgress, Divider, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { experimentalStyled, ThemeProvider } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'src/store';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import useMounted from 'src/hooks/useMounted';
import { setActiveOrgId, setActiveTeamId, setBannerUrl, setOrgStructures, updateCurExRate } from 'src/slices/organization';
import { initChatApi, initNeedReloads, loadContact, loadWallet } from 'src/worker/init';
import { lambdaGetFeedback, lambdaGetOrgActivatedMembersOfUser, lambdaGetOrgBannerUrl, lambdaJoinAsClient } from 'src/aws/lambdaDispatch';
import toast from 'react-hot-toast';
import OrgPopover from './OrgPopover';
import { loadApprising } from 'src/slices/apprising';
import { getPossibleHrefs } from 'src/utils/filterUrlsByRole';
import { ACCZIOM_CLIENT, ACCZIOM_MEMBER, ACCZIOM_ORG, ACCZIOM_TEAM, ACCZIOM_USER, allCurrencyUnits, CONNECT_DLG_JOIN, NAVBAR_HEIGHT, REQUIRE_AUTH_MODE, REQUIRE_NONE_MODE, REQUIRE_NOT_AUTH_MODE, STATUS_PENDING } from 'src/globals';
import { fetchUrl } from 'src/utils/fileUpDownload';
import { wsConnect, wsDisconnect, wsTeamConnect, wsTeamDisconnect } from 'src/apis/wsApi';
import useSearch from 'src/hooks/useSearch';
import getUserDisplayName from 'src/utils/getUserDisplayName';
import { setSearchHistory } from 'src/slices/favorite';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Item } from 'src/components/interact/NavMenuItem';
import { setConnectDlgOpen } from 'src/slices/user';
import { setSelectedMode } from 'src/slices/chat';
import { chatApi } from 'src/apis/chatApi';
import useAuth from 'src/hooks/useAuth';
import { createCustomTheme } from 'src/theme';
import useSettings from 'src/hooks/useSettings';
import { BiSearch } from 'react-icons/bi';
import NavItem from '../interact/NavItem';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import { getActiveOrgInfo, getActiveOrgMinInfo } from 'src/utils/getActiveOrgInfo';
import BalanceIcon from '@mui/icons-material/Balance';
import { Adjustments } from 'src/icons/Organization';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import AirlineStopsOutlinedIcon from '@mui/icons-material/AirlineStopsOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { clearShareState } from 'src/slices/share';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { getExchangeRatesForOneUSD } from 'src/utils/priceUtils';
import { Close } from '@material-ui/icons';
import { loadFeedback } from 'src/slices/feedback';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';

export const DashboardItems: Item[] = [
  {
    title: 'Marketplace',
    href: '/marketplace',
    onClick: null,
    authRequired: REQUIRE_NONE_MODE,
    icon: BalanceIcon,
    items: [
      {
        title: 'Organizations',
        href: '/marketplace/organization',
        onClick: null,
        icon: Adjustments
      },
      {
        title: 'Services',
        href: '/marketplace/microservice',
        onClick: null,
        icon: HomeRepairServiceOutlinedIcon
      },
      {
        title: 'Assets',
        href: '/marketplace/asset',
        onClick: null,
        icon: BusinessOutlinedIcon
      }
    ]
  },
  {
    title: 'Management',
    href: '/management',
    onClick: null,
    authRequired: REQUIRE_AUTH_MODE,
    icon: SettingsSuggestOutlinedIcon,
    items: [
      {
        title: 'Services',
        href: '/management/microservice',
        onClick: null,
        icon: HomeRepairServiceOutlinedIcon
      },
      {
        title: 'Assets',
        href: '/management/asset',
        onClick: null,
        icon: BusinessOutlinedIcon
      },
      {
        title: 'Structure',
        href: '/management/structure',
        onClick: null,
        icon: AccountTreeOutlinedIcon
      },
      {
        title: 'Network',
        href: '/management/network',
        onClick: null,
        icon: GroupOutlinedIcon
      },
      {
        title: 'Files',
        href: '/management/file',
        onClick: null,
        icon: SourceOutlinedIcon
      },
      {
        title: 'Assign',
        href: '/management/assign',
        onClick: null,
        icon: AssignmentTurnedInOutlinedIcon
      },
      {
        title: 'Templates',
        href: '/management/templates',
        onClick: null,
        icon: TextSnippetOutlinedIcon
      }
    ]
  },
  {
    title: 'Deals',
    href: '/deal',
    onClick: null,
    authRequired: REQUIRE_AUTH_MODE,
    icon: MonetizationOnOutlinedIcon,
    items: [
      {
        title: 'Sales',
        href: '/deal/sale',
        onClick: null,
        icon: SellOutlinedIcon
      },
      {
        title: 'Purchases',
        href: '/deal/purchase',
        onClick: null,
        icon: ShoppingBasketOutlinedIcon
      },
      {
        title: 'Referrals',
        href: '/deal/referral',
        onClick: null,
        icon: AirlineStopsOutlinedIcon
      },
      {
        title: 'Auto Debits',
        href: '/deal/autodebit',
        onClick: null,
        icon: AutoAwesomeOutlinedIcon
      }
    ]
  },
];

const DashboardItems1: Item[] = [
  {
    title: 'Sign In',
    href: '/login',
    onClick: null,
    authRequired: REQUIRE_NOT_AUTH_MODE,
    icon: AccountCircleOutlinedIcon,
    items: []
  }
];

const JoinUsItem: Item = {
  title: 'Join Us',
  href: null,
  onClick: null,
  authRequired: REQUIRE_NONE_MODE,
  icon: SensorOccupiedIcon,
  items: []
};

const searchBarUrls = {
  notAuthUrlsForSame: [
    '/',
    '/marketplace/organization',
    '/marketplace/microservice',
    '/marketplace/asset'
  ],
  notAuthUrlsForStart: [],
  authUrlsForSame: [
    '/marketplace/organization',
    '/marketplace/microservice',
    '/marketplace/asset',
    '/management/microservice',
    '/management/asset',
    '/management/structure',
    '/management/structure/client',
    '/management/structure/supplier',
    '/management/structure/member',
    '/management/structure/role',
    '/management/structure/team',
    '/management/network/findpartner',
    '/management/network/partner',
    '/management/network/referral',
    '/management/network/contact',
    '/management/network',
    '/management/file',
    '/management/assign',
    '/management/assign/deal',
    '/management/assign/service',
    '/management/assign/asset',
    '/management/assign/file',
    '/management/assign/referral',
    '/management/assign/partner',
    '/management/templates/shared',
    '/management/templates/draft',
    '/deal/sale',
    '/deal/purchase',
    '/deal/referral',
    '/deal/autodebit',
    '/bs/search',
    '/bs',
  ],
  authUrlsForStart: [
    '/communication/external',
    '/communication/internal'
  ],
};

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: '3px 0px 5px rgba(0, 0, 0, .5)',
    // borderColor: theme.palette.background.paper,
    // borderBottomWidth: '1px',
    // borderBottomStyle: 'solid'
  })
);

const DashboardNavbar: FC = () => {
  const mounted = useMounted();
  const dispatch = useDispatch();
  const searchApi = useSearch();
  // const { saveSettings } = useSettings();
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  const { domain: orgDomainName } = useParams();
  const { userInfo, inviteInfo, searchInfo, isSearching } = useSelector((state) => state.user);
  const { memberInfos, orgInfos, activeOrgId, orgsNeedReload } = useSelector((state) => state.organization);
  const [isSwitchingOrg, setIsSwitchingOrg] = useState<boolean>(false);
  const [items, setItems] = useState<Item[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const activeOrgInfo = getActiveOrgInfo();
  const activeOrgMinInfo = getActiveOrgMinInfo();
  const enabledSearch = (!isAuthenticated && !window.location.hostname.includes('.go.m.accziom.com') && (searchBarUrls.notAuthUrlsForSame.includes(pathname) || searchBarUrls.notAuthUrlsForStart.some((subHref) => pathname.startsWith(subHref))))
    || (isAuthenticated && !pathname.startsWith('/about/') && (searchBarUrls.authUrlsForSame.includes(pathname) || searchBarUrls.authUrlsForStart.some((subHref) => pathname.startsWith(subHref))));

  const handleNavigateChat = (mode: string) => {
    chatApi.processBeforeChangeMode();
    dispatch(setSelectedMode(mode));
  };

  const handleSearch = async (q: string): Promise<void> => {
    searchApi.current?.onSearch?.(q);
  };

  const handleJoinWithDomain = async (domain: string): Promise<void> => {
    try {
      const res = await lambdaJoinAsClient(activeOrgMinInfo, domain);
      if (res.status === 'error') {
        throw new Error('org not found');
      } else if (res.status === 'exist') {
        toast.error('You are already a client in accziom!');
      } else if (res.status === 'exist member') {
        toast.error('You have already tried to register as member!');
      } else if (res.status === 'exist joint') {
        toast.error('You already sent join request!');
      } else if (res.status === 'accepted') {
        toast.success('Successfully joint.');
      } else {
        toast.success('Successfully sent join request.');
      }
    } catch (err) {
      console.log(JSON.stringify(err));
      toast.error('Failed to join!');
    }
  };

  const handleJoinWithOutDomain = (): void => {
    dispatch(setConnectDlgOpen(CONNECT_DLG_JOIN));
  };

  const setDashboardItems = () => {
    if (!mounted.current) return;
    const hrefs = activeOrgInfo?.status === STATUS_PENDING ? ['/deal/purchase'] : getPossibleHrefs();
    const mm : Item[] = [];
    if (!inviteInfo && !userInfo?.isFirst) {
      let joinUsMode = false;
      if (!isAuthenticated) {
        if (window.location.hostname.includes('.go.m.accziom.com')) {
          joinUsMode = true;
          mm.push({
            ...JoinUsItem,
            onClick: async () => { await handleJoinWithOutDomain(); }
          } as Item);
        }
      } else if (pathname.startsWith('/about/')) {
        joinUsMode = true;
        mm.push({
          ...JoinUsItem,
          onClick: async () => { await handleJoinWithDomain(orgDomainName); }
        } as Item);
      }
      if (!joinUsMode) {
        DashboardItems1.forEach((item) => {
          if (!isAuthenticated && [REQUIRE_NONE_MODE, REQUIRE_NOT_AUTH_MODE].includes(item.authRequired)) mm.push(item);
          if (isAuthenticated && [REQUIRE_NONE_MODE, REQUIRE_AUTH_MODE].includes(item.authRequired)) {
            if (item.items.length < 1) {
              if (item.href && hrefs.filter((h) => h.startsWith(item.href)).length > 0) mm.push(item);
            } else {
              const subItems = [];
              item.items.forEach((subItem) => {
                if (subItem.href && hrefs.filter((h) => h.startsWith(subItem.href)).length > 0) {
                  if (['/communication/external', '/communication/internal'].includes(subItem.href)) {
                    subItems.push({
                      ...subItem,
                      onClick: () => { handleNavigateChat(subItem.href.split('/').at(2)); }
                    });
                  } else subItems.push(subItem);
                }
              });
              if (subItems.length > 0) mm.push({ ...item, items: subItems });
            }
          }
        });
      }
    }
    setItems(mm);
  };

  useEffect(() => {
    setDashboardItems();
  }, [mounted, activeOrgId, pathname, isAuthenticated]);

  const getDataOnLoad = () => {
    if (!userInfo || loading || !orgsNeedReload) return;
    setLoading(true);
    lambdaGetOrgActivatedMembersOfUser(userInfo.uid)
      .then((res) => {
        const { memberInfos: members, orgInfos: organizations, categoryInfos: categories, roleInfos: strRoles, teamInfos: teams } = res;
        const roles = [];
        strRoles.forEach((item) => {
          roles.push({
            rid: item.rid,
            oid: item.oid,
            name: item.name,
            level: item.level,
            department: JSON.parse(item.department),
            assignment: JSON.parse(item.assignment),
            production: JSON.parse(item.production),
            transaction: JSON.parse(item.transaction),
            createdAt: item.createdAt,
            updatedAt: item.updatedAt
          });
        });
        const memberData = [];
        const orgData = [];
        const categoryData = [];
        const roleData = [];
        const teamData = [];
        const teamIds = [];
        members.forEach((member) => {
          if (member.rid) {
            const role = roles.find((item) => item.rid === member.rid);
            const departmentValues = Object.values(role.department).map((obj) => Object.values(obj).includes(true));
            const assignmentValues = Object.values(role.assignment).map((obj) => Object.values(obj).includes(true));
            const teamCount = member.tids.length;
            if (departmentValues.includes(true) || assignmentValues.includes(true) || teamCount > 0) {
              memberData.push(member);
              const organization = organizations.find((item) => item.organizationId === member.oid);
              if (organization) orgData.push(organization);
              const category = categories.find((cat) => `${cat.code}` === `${organization.anzicCategory}`);
              if (category) categoryData.push(category);
              if (role) roleData.push(role);
              member.tids.forEach((tid) => {
                if (!teamIds.includes(tid)) {
                  teamIds.push(tid);
                  const team = teams.find((item) => item.tid === tid);
                  teamData.push(team);
                }
              });
            }
          } else {
            memberData.push(member);
            const organization = organizations.find((item) => item.organizationId === member.oid);
            if (organization) orgData.push(organization);
            const category = categories.find((cat) => `${cat.code}` === `${organization.anzicCategory}`);
            if (category) categoryData.push(category);
          }
        });
        memberData.sort((member0, member1) => { if (member0.type < member1.type) return 1; if (member0.type > member1.type) return -1; return 0; }); // sort by type, client first, member second
        dispatch(setOrgStructures(memberData, orgData, categoryData, roleData, teamData));
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        toast.error('Failed to get organization information!');
      })
      .finally(() => {
        if (mounted.current) setLoading(false);
      });
  };

  const switchOrg = async (orgId: string, teamId: string): Promise<void> => {
    // saveSettings({ orgId, teamId });
    if (process.env.NODE_ENV === 'production') {
      if (orgId === activeOrgId) {
        dispatch(setActiveTeamId(teamId));
        loadApprising(null, teamId);
        wsTeamConnect({
          id: teamId,
          type: ACCZIOM_TEAM,
          name: teamId
        });
      } else {
        const orgInfo = orgInfos.find((item) => item.organizationId === orgId);
        if (orgInfo?.domainName) {
          const domain = `${orgInfo.domainName}.go.m.accziom.com`;
          if (domain !== window.location.host) window.location.href = `https://${domain}`;
        }
      }
      return;
    }
    if (orgId === activeOrgId) {
      dispatch(setActiveTeamId(teamId));
      loadApprising(null, teamId);
      wsTeamConnect({
        id: teamId,
        type: ACCZIOM_TEAM,
        name: teamId
      });
      return;
    }
    setIsSwitchingOrg(true);
    try {
      const curMember = memberInfos.find((member) => member.oid === orgId);
      const chatLoaded = await initChatApi(curMember.mid, dispatch);
      if (chatLoaded) {
        await loadWallet(curMember.type === ACCZIOM_MEMBER ? { id: orgId, type: ACCZIOM_ORG } : { id: userInfo.uid, type: ACCZIOM_USER }, dispatch);
        loadContact(curMember.type === ACCZIOM_MEMBER ? orgId : userInfo.uid, dispatch);
        dispatch(setActiveTeamId(teamId));
        loadApprising(curMember.type === ACCZIOM_MEMBER ? orgId : curMember.mid, teamId);
        lambdaGetFeedback(curMember.type === ACCZIOM_MEMBER ? orgId : curMember.mid).then((res) => {
          dispatch(loadFeedback(res.feedback, res.feedbackConnection));
        });
        const orgInfo = orgInfos.find((item) => item.organizationId === orgId);
        if (orgInfo.banner) {
          const newBannerUrl = await lambdaGetOrgBannerUrl(orgInfo.banner);
          const newLocalUrl = await fetchUrl(newBannerUrl);
          dispatch(setBannerUrl(newLocalUrl));
        } else dispatch(setBannerUrl(null));
        getExchangeRatesForOneUSD(allCurrencyUnits)
          .then((rates) => { dispatch(updateCurExRate(rates)); })
          .catch((err) => {
            toast.error('Failed to get exchange rate!');
            console.log(JSON.stringify(err));
          });
        dispatch(setSearchHistory([]));
        dispatch(clearShareState());
        dispatch(setActiveOrgId(orgId));
        initNeedReloads(dispatch);
        wsDisconnect();
        wsTeamDisconnect();
        wsConnect(curMember.type === ACCZIOM_MEMBER ? ({
          id: curMember.oid,
          type: ACCZIOM_ORG,
          name: orgInfos.find((v) => v.organizationId === orgId).tradingName
        }) : ({
          id: curMember.mid,
          type: ACCZIOM_CLIENT,
          name: getUserDisplayName(userInfo)
        }));
      } else toast.error('Chat has not been initialized.');
    } catch (err) {
      toast.error('Switching organization has been failed.');
    } finally {
      if (mounted.current) setIsSwitchingOrg(false);
    }
  };

  useEffect(() => {
    getDataOnLoad();
  }, [mounted, orgsNeedReload]);

  useEffect(() => {
    if (mounted.current) {
      getExchangeRatesForOneUSD(allCurrencyUnits)
        .then((rates) => { dispatch(updateCurExRate(rates)); })
        .catch((err) => {
          toast.error('Failed to get exchange rate!');
          console.log(err);
        });
    }
  }, [mounted]);

  const { settings } = useSettings();
  const themeSearch = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'CUSTOM'
  });
  const navigate = useNavigate();

  const [query, setQuery] = useState<string>('');
  useEffect(() => {
    if (mounted.current) setQuery('');
  }, [mounted, pathname]);

  return (
    <DashboardNavbarRoot>
      <Box
        width="100%"
        sx={{
          height: NAVBAR_HEIGHT,
          display: 'flex',
          alignItems: 'center',
          pl: 1,
        }}
      >
        {enabledSearch ? (
          <ThemeProvider theme={themeSearch}>
            <TextField
              size="small"
              sx={{
                width: 'calc(100% - 80px)',
                py: -1,
              }}
              id="searchinput"
              InputProps={{
                sx: {
                  '& input': {
                    padding: '5px'
                  },
                  fontSize: 20,
                  borderRadius: '36px',
                  py: 0
                },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <IconButton
                      onClick={() => navigate(isAuthenticated ? '/home' : '/')}
                      sx={{
                        p: 0
                      }}
                    >
                      <img
                        src="/favicon.svg"
                        alt="Logo"
                        style={{
                          height: 28,
                          width: 28,
                          marginLeft: '0px'
                        }}
                      />
                    </IconButton>
                    <Divider
                      orientation="vertical"
                      sx={{
                        height: '100%',
                        py: 1.5,
                        pl: 1
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    {query.length > 0 && (
                      <IconButton
                        onClick={() => {
                          setQuery('');
                          document.getElementById('searchinput').focus();
                          if (!isSearching) handleSearch('');
                        }}
                      >
                        <Close
                          sx={{
                            color: 'textSecondary',
                            fontSize: 30,
                            pr: 1
                          }}
                        />
                      </IconButton>
                    )}
                    {!!searchInfo?.hasButton && (
                      <Box
                        sx={{
                          position: 'absolute',
                          zIndex: 1000,
                          right: 0,
                          top: 0,
                          width: 43,
                          height: 39,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Box
                          sx={{
                            width: 37,
                            height: 32,
                            bgcolor: isSearching ? 'transparent' : 'primary.main',
                            borderRadius: 1,
                            color: 'background.paper',
                            '&:hover': {
                              bgcolor: '#0f68a3'
                            },
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: '.3s'
                          }}
                          onClick={() => {
                            if (!isSearching) handleSearch(query);
                          }}
                        >
                          {isSearching ? (
                            <CircularProgress
                              size={16}
                            />
                          ) : (
                            <BiSearch
                              style={{
                                width: 18,
                                height: 18
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    )}
                  </InputAdornment>
                )
              }}
              onChange={(e) => {
                setQuery(e.target.value);
                if (!searchInfo?.hasButton) handleSearch(e.target.value);
              }}
              placeholder={searchInfo?.placeHolder ? searchInfo.placeHolder : ''}
              value={query}
            />
          </ThemeProvider>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start'
            }}
          >
            <NavItem
              sourcePath="/favicon.svg"
              altName="Logo"
              height={28}
              href={isAuthenticated ? '/home' : '/'}
            />
          </Box>
        )}
        <Box flexGrow={1} />
        {true && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            {items.map((item) => (
              <Button
                variant="text"
                color="primary"
                key={item.title}
                component={RouterLink}
                to="/login"
              >
                {item.title}
              </Button>
            ))}
          </Box>
        )}
        {isAuthenticated && !['/fillup', '/acceptinvite'].includes(pathname) && (
          <OrgPopover
            loading={loading}
            onChange={switchOrg}
            isSwitchingOrg={isSwitchingOrg}
          />
        )}
      </Box>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
