import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Feedback, FeedbackConnection } from 'src/../../Common/Model/feedback';

interface FeedbackState {
  feedbacks: Feedback[];
  connections: FeedbackConnection[];
}

const initialState: FeedbackState = {
  feedbacks: [],
  connections: []
};

const slice = createSlice({
  name: 'apprising',
  initialState,
  reducers: {
    clearState(state: FeedbackState) {
      state.feedbacks = [];
      state.connections = [];
    },
    loadFeedback(state: FeedbackState, action: PayloadAction<{ feedbacks: Feedback[], connections: FeedbackConnection[] }>) {
      state.feedbacks = action.payload.feedbacks;
      state.connections = action.payload.connections;
    },
    addNewFeedback(state: FeedbackState, action: PayloadAction<{ feedback: Feedback, connection: FeedbackConnection }>) {
      state.feedbacks = [...state.feedbacks, action.payload.feedback];
      state.connections = [...state.connections, action.payload.connection];
    },
    markAsRead(state: FeedbackState, action: PayloadAction<{ id: string }>) {
      const idx = state.feedbacks.findIndex((feedback) => feedback.id === action.payload.id);
      if (idx >= 0) {
        state.feedbacks[idx] = {
          ...state.feedbacks[idx],
          read: true
        };
      }
    }
  }
});

export const { reducer } = slice;

export const clearState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const loadFeedback = (feedbacks: Feedback[], connections: FeedbackConnection[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.loadFeedback({ feedbacks, connections }));
};

export const addNewFeedback = (feedback: Feedback, connection: FeedbackConnection): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNewFeedback({ feedback, connection }));
};

export const markAsRead = (id: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.markAsRead({ id }));
};

export default slice;
