import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Divider,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import { accsiomURIs, mrcPort } from 'src/config';
import { useSelector } from 'src/store';
import { toast } from 'react-hot-toast';
import { QRCodeCanvas } from 'qrcode.react';
import CheckLabel from 'src/components/CheckLabel';
import GasLimit from './GasLimit';
import useMounted from 'src/hooks/useMounted';
import NumberField from 'src/components/interact/NumberField';
import BottomPopupDialog from '../dialog/BottomPopupDialog';
import ActionBottombar from '../interact/ActionBottombar';

const BuyMERcDialog: FC<{
  open: boolean;
  handleClose: any;
}> = ({
  open,
  handleClose
}) => {
  const mounted = useMounted();
  const [isSubmitting, setSubmitting] = useState(false);
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState<number>(1);
  const [layer2, setLayer2] = useState(true);
  const [isQRShowed, setQRShowed] = useState(false);
  const [msg, setMsg] = useState('');
  const minGas = 1;
  const [gasLimit, setGasLimit] = useState<number>(minGas);

  const addressError = !address;
  const gasError = gasLimit < minGas;
  const valueError = amount === null || amount < 1;

  const { wallet } = useSelector((state) => state.wallet);
  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const res = await axios.get(`${accsiomURIs.bs_uri}:${mrcPort}/get_reserve_single_address`, {
        params: { amount }
      });
      const btcReserveAddress = res.data.data.address;
      if (!btcReserveAddress) throw new Error('No BTC reserve account ready.');
      await axios.get(`${accsiomURIs.bs_uri}:${mrcPort}/set_exchange_info`, {
        params: {
          btc_address: address,
          mrc_address: wallet.address,
          layer2,
          gasLimit
        }
      });
      const desc = 'Buy MERc';
      const btc = amount / 1e8;
      if (mounted.current) {
        setMsg(`bitcoin:${btcReserveAddress}?amount=${btc}&message=${desc}`);
        setQRShowed(true);
      }
    } catch (err) {
      console.log(JSON.stringify(err));
      toast.error('Failed to buy.');
    } finally {
      if (mounted.current) setSubmitting(false);
    }
  };

  return (
    <BottomPopupDialog
      onClose={handleClose}
      open={open}
      title="Buy MERc"
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            sx={{
              mb: 3,
              textAlign: 'center'
            }}
          >
            Please input Bitcoin address and amount.
          </Typography>
          <TextField
            autoFocus
            label="Address *"
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            size="small"
            sx={{
              mb: 3
            }}
            error={addressError}
            helperText={addressError && 'Address is required.'}
          />
          <NumberField
            label="Amount"
            variant="outlined"
            value={amount}
            onNumChange={(nv) => {
              if (nv === null) {
                setAmount(nv);
                return;
              }
              if (nv < 1) nv = 1;
              setAmount(nv);
            }}
            error={valueError}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                >
                  MERc
                </InputAdornment>
              )
            }}
            size="small"
            sx={{
              mb: 2
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            <Box
              sx={{
                mb: 2
              }}
            >
              <CheckLabel
                checked={layer2}
                onChange={(e) => setLayer2(e.target.checked)}
                label="Deposit to Layer2"
              />
            </Box>
            <GasLimit
              gasLimit={gasLimit}
              onSetGasLimit={(v) => setGasLimit(v)}
              gasParams={{
                type: 'minting',
                address: wallet.address,
                ledger: layer2 ? 2 : 1,
                amount,
                btc_tx: 'TEST_ESTIMATE'
              }}
              estimateDisabled={!amount}
              minGas={1}
            />
          </Box>
          {isQRShowed && (
            <>
              <Divider sx={{ mt: 2 }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{ my: 2 }}
                >
                  {`Please scan below QR code and send ${amount / 1e8} BTCs.`}
                </Typography>
                <QRCodeCanvas
                  value={msg}
                  size={200}
                  fgColor="#000000"
                />
                <Box
                  sx={{
                    width: '100%',
                    mt: 2
                  }}
                >
                  <Typography
                    color="text.secondary"
                    variant="body2"
                  >
                    Bitcoin Transaction Code
                  </Typography>
                  <Typography
                    sx={{
                      wordBreak: 'break-all'
                    }}
                  >
                    {msg}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
      <ActionBottombar>
        <Button
          variant="text"
          onClick={handleClose}
          sx={{
            mx: 2,
            my: 1
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          startIcon={isSubmitting ? <CircularProgress size={14} /> : null}
          disabled={isSubmitting || addressError || gasError || valueError}
          sx={{
            mx: 2,
            my: 1
          }}
        >
          Buy
        </Button>
      </ActionBottombar>
    </BottomPopupDialog>
  );
};

BuyMERcDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default BuyMERcDialog;
