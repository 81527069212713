import type { FC } from 'react';
import {
  Box,
  Button,
  Dialog,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/Info';

interface TooltipDlgProps {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
  lblOk: string;
  lblClose: string;
  content: string;
}

const TooltipDlg: FC<TooltipDlgProps> = (props): JSX.Element => {
  const { open, lblOk, lblClose, onOk, content, onClose } = props;

  const handleOk = () => {
    onOk();
    onClose();
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <Box
        sx={{
          display: 'flex',
          pt: 3,
          pb: 2,
          px: 3
        }}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <InfoIcon
          sx={{
            fontSize: '24px',
            mr: 3
          }}
          color="secondary"
        />
        <Box>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {content}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          px: 3,
          py: 1.5
        }}
      >
        <Button
          color="primary"
          sx={{ mr: 2 }}
          variant="text"
          onClick={handleClose}
        >
          {lblClose}
        </Button>
        <Button
          color="secondary"
          variant="text"
          onClick={handleOk}
        >
          {lblOk}
        </Button>
      </Box>
    </Dialog>
  );
};

TooltipDlg.propTypes = {
  open: PropTypes.bool,
  lblOk: PropTypes.string,
  lblClose: PropTypes.string,
  content: PropTypes.string.isRequired,
  onOk: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

TooltipDlg.defaultProps = {
  lblOk: 'Ok',
  lblClose: 'Close'
};

export default TooltipDlg;
