import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { PayAccount } from 'src/../../Common/Model/payAccount';

interface PayAccountState {
  stripeAccount: PayAccount | null;
  needReload: boolean;
}

const initialState: PayAccountState = {
  stripeAccount: null,
  needReload: true
};

const slice = createSlice({
  name: 'payAccount',
  initialState,
  reducers: {
    clearState(state: PayAccountState) {
      state.stripeAccount = null;
      state.needReload = true;
    },
    setNeedReload(state: PayAccountState, action: PayloadAction<boolean>): void {
      state.needReload = action.payload;
    },
    updateStripeAccount(state: PayAccountState, action: PayloadAction<any>): void {
      const updated = action.payload;
      Object.keys(updated).forEach((key) => {
        (state.stripeAccount)[key] = updated[key];
      });
    },
    setStripeAccount(state: PayAccountState, action: PayloadAction<PayAccount>): void {
      state.stripeAccount = action.payload;
    },
    removeStripeAccount(state: PayAccountState): void {
      if (state.stripeAccount?.customerID) {
        state.stripeAccount.accountID = '';
        state.stripeAccount.status = '';
      } else state.stripeAccount = null;
    }
  }
});

export const { reducer } = slice;

export const clearPayAccountState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setNeedReload(needReload));
};

export const updateStripeAccount = (body: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateStripeAccount(body));
};
export const setStripeAccount = (body: PayAccount) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setStripeAccount(body));
};

export const removeStripeAccount = () : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.removeStripeAccount());
};

export default slice;
