import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { Template } from 'src/../../Common/Model/template';

interface TemplateState {
  list: Template[];
}

const initialState: TemplateState = {
  list: []
};

const slice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    clearState(state: TemplateState) {
      state.list = [];
    },
    setTemplateList(state: TemplateState, action: PayloadAction<Template[]>): void {
      state.list = action.payload as Template[];
    },
    setTemplateBody(state: TemplateState, action: PayloadAction<Template>): void {
      const t = action.payload as Template;
      const i = state.list.findIndex((item) => item.id === t.id);
      if (i >= 0) state.list[i].body = t.body;
    },
    removeTemplate(state: TemplateState, action: PayloadAction<string>): void {
      const id = action.payload as string;
      state.list = state.list.filter((item) => item.id !== id);
    },
    createTemplate(state: TemplateState, action: PayloadAction<Template>): void {
      const t = action.payload as Template;
      state.list.push(t);
    },
    updateTemplate(state: TemplateState, action: PayloadAction<Template>): void {
      const t = action.payload as Template;
      const i = state.list.findIndex((item) => item.id === t.id);
      if (i < 0) return;
      state.list[i] = t;
    }
  }
});

export const { reducer } = slice;

export const clearTemplateState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setTemplateList = (l: Template[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTemplateList(l));
};
export const setTemplateBody = (b: Template): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTemplateBody(b));
};

export const removeTemplate = (id: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeTemplate(id));
};
export const createTemplate = (b: Template): AppThunk => async (dispatch) => {
  dispatch(slice.actions.createTemplate(b));
};
export const updateTemplate = (b: Template): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateTemplate(b));
};

export default slice;
