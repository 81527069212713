import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { Wallet } from 'src/../../Common/Model/wallet';
import objCopy from 'src/utils/objCopy';
import { tokenData } from 'src/globals';

interface Balances {
  eth: number;
  usdt: number;
  mrc: { layer1: number; layer2: number; };
}

interface WalletState {
  wallet: Wallet | null;
  balances: Balances;
  activeTokenName: string;
  isMRCL0: boolean;
  transactions: any[];
  lastTransaction: any;
  MRCBalance: any;
}

// const w: Wallet = {
//   id: '1',
//   walletID: 'kaito140411@outlook.com',
//   address: '0x1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ',
//   balance: '0.987654321',
// };
const initialState: WalletState = {
  wallet: null,
  balances: null,
  activeTokenName: tokenData[0].name,
  isMRCL0: true,
  transactions: [],
  lastTransaction: null,
  MRCBalance: null
};

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    clearState(state: WalletState) {
      state.wallet = null;
      state.balances = null;
      state.activeTokenName = tokenData[0].name;
      state.isMRCL0 = true;
      state.transactions = [];
    },
    setActiveTokenName(state: WalletState, action: PayloadAction<string>): void {
      state.activeTokenName = action.payload as string;
    },
    setMRCL0(state: WalletState, action: PayloadAction<boolean>): void {
      state.isMRCL0 = action.payload;
    },
    updateWallet(state: WalletState, action: PayloadAction<Wallet>): void {
      const wallet = action.payload;
      objCopy(wallet, state.wallet);
    },
    updateLastTransaction(state: WalletState, action: PayloadAction<{ txn: any }>): void {
      const { txn } = action.payload;
      state.lastTransaction = txn;
    },
    setWallet(state: WalletState, action: PayloadAction<Wallet>): void {
      const wallet = action.payload as Wallet;
      state.wallet = wallet;
    },
    setActiveWallet(state: WalletState, action: PayloadAction<any>): void {
      const { body, tokenName } = action.payload;
      if (tokenName === state.activeTokenName) {
        state.wallet = body as Wallet;
      }
    },
    setMRCBalance(state: WalletState, action: PayloadAction<any>): void {
      state.MRCBalance = action.payload;
    },
    setBalances(state: WalletState, action: PayloadAction<Balances>): void {
      state.balances = action.payload;
    },
    setTransactions(state: WalletState, action: PayloadAction<any[]>): void {
      state.transactions = action.payload;
    },
  }
});

export const clearWalletState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setActiveTokenName = (tokenName: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setActiveTokenName(tokenName));
};
export const setMRCL0 = (val: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setMRCL0(val));
};
export const setWalletInfo = (_body: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setWallet(_body));
};
export const setActiveWalletInfo = (_body: any, _token: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setActiveWallet({ body: _body, tokenName: _token }));
};
export const updateLastTransaction = (txn: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateLastTransaction({ txn }));
};
export const setMRCBalance = (balance: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setMRCBalance(balance));
};
export const setBalances = (balances: Balances): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setBalances(balances));
};

export const setTransactions = (transactions: any[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTransactions(transactions));
};

export const { reducer } = slice;

export default slice;
