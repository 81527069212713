import type { Contact } from 'src/../../Common/Model/contact';

export const getUserFormalName = (body: any): string => {
  if (body) {
    return [body.firstName, body.middleName, body.lastName].filter((_) => !!_).map((_) => _.trim()).join(' ');
  }
  return '';
};

const getUserDisplayName = (body: any): string => {
  if (body) {
    if (body.penName) return body.penName;
    if (body.displayName) return body.displayName;
    const formalName = getUserFormalName(body);
    if (formalName) return formalName;
  }
  return 'Unknown';
};

export const getContactDisplayName = (contact: Contact): string => {
  const { isIndividual, displayName, legalName, tradingName, casualName } = contact;
  const formalName = getUserFormalName(contact);
  if (formalName) return formalName;
  if (isIndividual && casualName) return casualName;
  if (tradingName) return tradingName;
  if (!isIndividual && legalName) return legalName;
  if (displayName) return displayName;
  return 'Unknown';
};

export default getUserDisplayName;
