import React, { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  Typography
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@material-ui/core/transitions';

const TransitionUp = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => (
  <Slide
    direction="up"
    ref={ref}
    {...props}
  />
));

const useStyles = makeStyles({
  dialogPaper: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    margin: 0,
    borderRadius: '12px 12px 0px 0px',
    boxShadow: 'none',
  },
});

const BottomPopupDialog: FC<{
  title?: any;
  open?: boolean;
  onClose?: any;
  children?: any;
  height?: any;
}> = (props) => {
  const {
    title: Title,
    open,
    onClose,
    height,
    children
  } = props;
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      onBackdropClick={onClose}
      open={open}
      classes={{
        paper: classes.dialogPaper
      }}
      TransitionComponent={TransitionUp}
    >
      {Title !== undefined && (
        <Box
          sx={{
            height: '70px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2
          }}
        >
          {typeof Title === 'string' ? (
            <Typography
              variant="h5"
              sx={{ px: 3 }}
            >
              {Title}
            </Typography>
          ) : (
            <Title />
          )}
          <IconButton
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <Box
        sx={{
          height: height !== null && height !== undefined ? height : '75vh',
          width: '100%',
          overflow: 'auto'
        }}
      >
        {children}
      </Box>
    </Dialog>
  );
};

BottomPopupDialog.propTypes = {
  title: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
  height: PropTypes.any
};

export default BottomPopupDialog;
